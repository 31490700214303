export const SidebarToggleButton = () => {
  return (
    <svg
      width={18}
      height={12}
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12C0.71667 12 0.479003 11.904 0.287003 11.712C0.0950034 11.52 -0.000663206 11.2827 3.46021e-06 11C3.46021e-06 10.7167 0.0960036 10.479 0.288004 10.287C0.480004 10.095 0.717337 9.99934 1 10H17C17.2833 10 17.521 10.096 17.713 10.288C17.905 10.48 18.0007 10.7173 18 11C18 11.2833 17.904 11.521 17.712 11.713C17.52 11.905 17.2827 12.0007 17 12H1ZM1 7C0.71667 7 0.479003 6.904 0.287003 6.712C0.0950034 6.52 -0.000663206 6.28267 3.46021e-06 6C3.46021e-06 5.71667 0.0960036 5.479 0.288004 5.287C0.480004 5.095 0.717337 4.99934 1 5H17C17.2833 5 17.521 5.096 17.713 5.288C17.905 5.48 18.0007 5.71734 18 6C18 6.28334 17.904 6.521 17.712 6.713C17.52 6.905 17.2827 7.00067 17 7H1ZM1 2C0.71667 2 0.479003 1.904 0.287003 1.712C0.0950034 1.52 -0.000663206 1.28267 3.46021e-06 1C3.46021e-06 0.71667 0.0960036 0.479004 0.288004 0.287004C0.480004 0.0950036 0.717337 -0.000663206 1 3.4602e-06H17C17.2833 3.4602e-06 17.521 0.0960036 17.713 0.288004C17.905 0.480004 18.0007 0.717337 18 1C18 1.28334 17.904 1.521 17.712 1.713C17.52 1.905 17.2827 2.00067 17 2H1Z"
        fill="black"
      />
    </svg>
  );
};

export const Close = () => {
  return (
    <svg
      width="20px"
      xmlns="http://www.w3.org/2000/svg"
      fill="hsla(0,0%,100%,.7)"
      viewBox="0 0 384 512"
    >
      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
    </svg>
  );
};

export const Calendar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" />
    </svg>
  );
};
export const Teams = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
      <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" />
    </svg>
  );
};

export const Events = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm80 64c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80z" />
    </svg>
  );
};
export const Setting = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z" />
    </svg>
  );
};
export const Help = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
    </svg>
  );
};
export const Upgrade = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M0 80V229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
    </svg>
  );
};
export const Signout = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.857143 13.3333C0.857143 14.25 1.62857 15 2.57143 15H9.42857C10.3714 15 11.1429 14.25 11.1429 13.3333V3.33333H0.857143V13.3333ZM12 0.833333H9L8.14286 0H3.85714L3 0.833333H0V2.5H12V0.833333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Spinner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="spiner_icon"
      fill="#0070bf"
      width="18px"
      height="18px"
      viewBox="0 0 512 512"
    >
      <path d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z" />
    </svg>
  );
};

export const Clock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
    </svg>
  );
};

export const CalendarForModal = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.39517 5.05493C8.17612 5.05493 8.80942 4.42163 8.80942 3.64028V1.41426C8.80942 0.633301 8.17612 0 7.39517 0C6.61377 0 5.98047 0.633301 5.98047 1.41426V3.64028C5.98047 4.42168 6.61377 5.05493 7.39517 5.05493Z"
        fill="#070707"
      />
      <path
        d="M17.8263 5.05493C18.6072 5.05493 19.2405 4.42163 19.2405 3.64028V1.41426C19.2406 0.633301 18.6073 0 17.8263 0C17.0449 0 16.4116 0.633301 16.4116 1.41426V3.64028C16.4116 4.42168 17.0449 5.05493 17.8263 5.05493Z"
        fill="#070707"
      />
      <path
        d="M21.3589 2.01599H20.4837V3.64031C20.4837 5.10598 19.2916 6.29807 17.8263 6.29807C16.3606 6.29807 15.1686 5.10598 15.1686 3.64031V2.01599H10.0528V3.64031C10.0528 5.10598 8.86074 6.29807 7.39551 6.29807C5.92978 6.29807 4.7377 5.10598 4.7377 3.64031V2.01599H3.86245C1.9812 2.01599 0.456055 3.54114 0.456055 5.42239V21.5936C0.456055 23.4749 1.9812 25 3.86245 25H21.3589C23.2402 25 24.7653 23.4749 24.7653 21.5936V5.42239C24.7653 3.54114 23.2401 2.01599 21.3589 2.01599ZM23.1079 21.5936C23.1079 22.5579 22.3232 23.3426 21.3589 23.3426H3.86245C2.89814 23.3426 2.11353 22.5579 2.11353 21.5936V7.57512H23.1079V21.5936Z"
        fill="#070707"
      />
      <path
        d="M11.8495 18.4362H9.07959V21.206H11.8495V18.4362Z"
        fill="#070707"
      />
      <path
        d="M11.8495 14.1431H9.07959V16.9129H11.8495V14.1431Z"
        fill="#070707"
      />
      <path
        d="M7.55605 18.4362H4.78662V21.206H7.55605V18.4362Z"
        fill="#070707"
      />
      <path
        d="M7.55605 14.1431H4.78662V16.9129H7.55605V14.1431Z"
        fill="#070707"
      />
      <path d="M20.435 9.8501H17.6655V12.62H20.435V9.8501Z" fill="#070707" />
      <path d="M16.1423 9.8501H13.3721V12.62H16.1423V9.8501Z" fill="#070707" />
      <path
        d="M16.1423 14.1431H13.3721V16.9129H16.1423V14.1431Z"
        fill="#070707"
      />
      <path d="M20.435 18.4362H17.6655V21.206H20.435V18.4362Z" fill="#070707" />
      <path
        d="M20.435 14.1431H17.6655V16.9129H20.435V14.1431Z"
        fill="#070707"
      />
      <path
        d="M16.1423 18.4362H13.3721V21.206H16.1423V18.4362Z"
        fill="#070707"
      />
      <path d="M11.8495 9.8501H9.07959V12.62H11.8495V9.8501Z" fill="#070707" />
    </svg>
  );
};
