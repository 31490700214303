import moment from "moment";

export const minutesOfDay = (m) => {
  return m.minutes() + m.hours() * 60;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getStartAndEndDate = (date) => {
  let startDate = date.startOf("month").format("YYYY-MM-DD");

  const startDateDay = moment(startDate, "YYYY-MM-DD").day();

  if (startDateDay != 0) {
    for (let i = startDateDay; i > 0; i--) {
      startDate = moment(startDate, "YYYY-MM-DD")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    }
  }

  let lastDate = date.endOf("month").format("YYYY-MM-DD");

  const lastDateDay = moment(lastDate, "YYYY-MM-DD").day();

  if (lastDateDay != 6) {
    for (let i = lastDateDay; i < 6; i++) {
      lastDate = moment(lastDate, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  }
  return {
    startDate,
    endDate: lastDate,
  };
};

export const classHandler = (date) => {
  let removeClass = document.querySelector(".select-today");

  if (removeClass) {
    removeClass.classList.remove("select-today");
  }
  let element = document.querySelector(
    `[data-date='${moment(date).format("YYYY-MM-DD")}']`
  );
  if (element) {
    element.classList.add("select-today");
  }
};

export const classHandlerForSwitch = (date, className) => {
  let removeClass = document.querySelector(`.${className}`);

  if (removeClass) {
    removeClass.classList.remove(className);
  }
  let element = document.querySelector(
    `[data-date='${moment(date).format("YYYY-MM-DD")}']`
  );
  if (element) {
    element.classList.add(className);
  }
};

export const shiftsImageHanlder = (date, img, selectedMonthAndYear) => {
  const currMonthAndYear = moment().format("MM-YYYY");
  const eventDate = moment(date, "DD/MM/YYYY");
  const todaysDate = moment();

  if (currMonthAndYear == selectedMonthAndYear) {
    if (
      (eventDate.isSameOrAfter(todaysDate) ||
        todaysDate.format("DD-MM-YYYY") === eventDate.format("DD-MM-YYYY")) &&
      eventDate.format("MM-YYYY") == currMonthAndYear
    ) {
      return `assets/img/${img}.png`;
      // not fade
    } else {
      // fade
      return `assets/img/${img}-faded.png`;
    }
  } else {
    if (eventDate.format("MM-YYYY") == selectedMonthAndYear) {
      //not fade
      return `assets/img/${img}.png`;
    } else {
      // fade
      return `assets/img/${img}-faded.png`;
    }
  }
};

export const getDiffrenceHours = (startTime, endTime) => {
  return (moment(endTime).diff(moment(startTime), "minutes") / 60).toFixed(2);
};
export const addHeadingOnPicker = () => {
  setTimeout(function () {
    const node = document.createElement("div");
    node.innerHTML = `
           <div class='row'>
              <div class='col-4'>
                 Hours
              </div>
              <div class='col-4'>
                Minutes
              </div>
              <div class='col-4'>
              
              </div>
            </div>
       `;

    let ele = document.getElementsByClassName("MuiPickersLayout-root");
    document.body?.classList?.add("scroll-off");
    // console.log(ele[0],"kk")
    node.classList.add("newClass");
  }, 25);
};

export const dotColourHandler = (date, selectedMonthAndYear, color) => {
  const currMonthAndYear = moment().format("MM-YYYY");
  const eventDate = moment(date, "DD/MM/YYYY");
  const todaysDate = moment();

  if (currMonthAndYear == selectedMonthAndYear) {
    if (
      (eventDate.isSameOrAfter(todaysDate) ||
        todaysDate.format("DD-MM-YYYY") === eventDate.format("DD-MM-YYYY")) &&
      eventDate.format("MM-YYYY") == currMonthAndYear
    ) {
      return `${color}`;
      // not fade
    } else {
      return `${color}-fade`;
      // fade
    }
  } else {
    if (eventDate.format("MM-YYYY") == selectedMonthAndYear) {
      if (eventDate.isAfter(moment())) {
        return `${color}`;
      } else {
        return `${color}-fade`;
      }
    } else {
      return `${color}-fade`;
    }
  }
};

export const dotHandlerForCalendarViewTwo = (
  events = [],
  notes = [],
  notifications = [],
  switchedShifts = [],
  selectedMonthAndYear,
  setEvents
) => {
  // const selectedMonthAndYearFormat = moment(
  //   selectedMonthAndYear,
  //   "MM-YYYY"
  // ).format("MM/YYYY");

  let { startDate, endDate } = getStartAndEndDate(
    moment(selectedMonthAndYear, "MM-YYYY")
  );

  startDate = moment(startDate, "YYYY-MM-DD");
  endDate = moment(endDate, "YYYY-MM-DD");

  const dotObj = {};

  while (startDate.isSameOrBefore(endDate)) {
    dotObj[startDate.format("DD/MM/YYYY")] = [
      "white",
      "white",
      "white",
      "white",
    ];
    startDate.add({ day: 1 });
  }

  const newEvents = [];

  for (let key in dotObj) {
    const isEventExist = events.find((n) => n.date === key);

    const arr = dotObj[key];

    if (isEventExist) {
      arr[0] = dotColourHandler(key, selectedMonthAndYear, "purple");
    }

    //notes

    const isNoteExist = notes.find((n) => n.date === key);

    if (isNoteExist) {
      arr[1] = dotColourHandler(key, selectedMonthAndYear, "yellow");
    }

    //notifications

    const isNotiExist = notifications.find((n) => n.date === key);

    if (isNotiExist) {
      arr[2] = dotColourHandler(key, selectedMonthAndYear, "red");
    }

    //switched shifts

    const isSwitchedShiftExist = switchedShifts.find((n) => n.date === key);

    if (isSwitchedShiftExist) {
      arr[3] = dotColourHandler(key, selectedMonthAndYear, "green");
    }

    dotObj[key] = arr;
    newEvents.push({
      start: moment(key, "DD/MM/YYYY")
        .set({ hour: 2, minute: 20, second: 30 })
        .format("YYYY-MM-DDTHH:mm:ss"),
      color: "none",
      dots: arr,
    });
  }

  setEvents((prev) => [...prev.filter((p) => p.image_url), ...newEvents]);
};

export const dotHandlerForCalendarView = (
  allData,
  setEvents,
  selectedMonthAndYear,
  color
) => {
  // const selectedMonthAndYearFormat = moment(
  //   selectedMonthAndYear,
  //   "MM-YYYY"
  // ).format("MM/YYYY");
  // const startOfMonth = moment(selectedMonthAndYear, "MM-YYYY")
  //   .startOf("month")
  //   .format("D");

  // const endOfMonth = moment(selectedMonthAndYear, "MM-YYYY")
  //   .endOf("month")
  //   .format("D");

  let newEvent = [];
  allData.forEach((data) => {
    const prev = newEvent.find((n) => n.date === data.date);
    if (!prev) {
      newEvent.push(data);
    }
  });
  // for (let i = 0; i < endOfMonth; i++) {
  //   let date = moment(
  //     `${Number(startOfMonth) + i}/${selectedMonthAndYearFormat}`,
  //     "DD/MM/YYYY"
  //   ).format("DD/MM/YYYY");

  //   const data = newEvent.find((n) => n.date == date);
  //   if (!data) {
  //     newEvent.push({ date, isWhite: true });
  //   }
  // }

  setEvents((prev) => [
    ...prev,
    ...newEvent.map((e) => ({
      start: moment(e.date, "DD/MM/YYYY")
        .set({ hour: 2, minute: 20, second: 30 })
        .format("YYYY-MM-DDTHH:mm:ss"),
      // color: e.isWhite
      //   ? "white"
      //   : dotColourHandler(e.date, selectedMonthAndYear, color),
      color: dotColourHandler(e.date, selectedMonthAndYear, color),
    })),
  ]);
};

export const phoneValidation = {
  am: /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
  ae: /^((\+?971)|0)?5[024568]\d{7}$/,
  bh: /^(\+?973)?(3|6)\d{7}$/,
  dz: /^(\+?213|0)(5|6|7)\d{8}$/,
  eg: /^((\+?20)|0)?1[0125]\d{8}$/,
  iq: /^(\+?964|0)?7[0-9]\d{8}$/,
  jo: /^(\+?962|0)?7[789]\d{7}$/,
  kw: /^(\+?965)[569]\d{7}$/,
  sa: /^(!?(\+?966)|0)?5\d{8}$/,
  sy: /^(!?(\+?963)|0)?9\d{8}$/,
  tn: /^(\+?216)?[2459]\d{7}$/,
  by: /^(\+?375)?(24|25|29|33|44)\d{7}$/,
  bg: /^(\+?359|0)?8[789]\d{7}$/,
  bd: /^(\+?880|0)1[13456789][0-9]{8}$/,
  cz: /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  dk: /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
  de: /^(\+49)?0?1(5[0-25-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7}$/,
  at: /^(\+43|0)\d{1,4}\d{3,12}$/,
  gr: /^(\+?30|0)?(69\d{8})$/,
  au: /^(\+?61|0)4\d{8}$/,
  gb: /^(\+?44|0)7\d{9}$/,
  gg: /^(\+?44|0)1481\d{6}$/,
  gh: /^(\+233|0)(20|50|24|54|27|57|26|56|23|28)\d{7}$/,
  hk: /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
  mo: /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
  ie: /^(\+?353|0)8[356789]\d{7}$/,
  in: /^(\+?91|0)?[6789]\d{9}$/,
  ke: /^(\+?254|0)(7|1)\d{8}$/,
  mt: /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
  mu: /^(\+?230|0)?\d{8}$/,
  ng: /^(\+?234|0)?[789]\d{9}$/,
  nz: /^(\+?64|0)[28]\d{7,9}$/,
  pk: /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
  rw: /^(\+?250|0)?[7]\d{8}$/,
  sg: /^(\+65)?[89]\d{7}$/,
  sl: /^(?:0|94|\+94)?(7(0|1|2|5|6|7|8)( |-)?\d)\d{6}$/,
  tz: /^(\+?255|0)?[67]\d{8}$/,
  ug: /^(\+?256|0)?[7]\d{8}$/,
  us: /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
  za: /^(\+?27|0)\d{9}$/,
  zm: /^(\+?26)?09[567]\d{7}$/,
  cl: /^(\+?56|0)[2-9]\d{1}\d{7}$/,
  ec: /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
  es: /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
  mx: /^(\+?52)?(1|01)?\d{10,11}$/,
  pa: /^(\+?507)\d{7,8}$/,
  py: /^(\+?595|0)9[9876]\d{7}$/,
  uy: /^(\+598|0)9[1-9][\d]{6}$/,
  ee: /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
  ir: /^(\+?98[\-\s]?|0)9[0-39]\d[\-\s]?\d{3}[\-\s]?\d{4}$/,
  fi: /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
  fj: /^(\+?679)?\s?\d{3}\s?\d{4}$/,
  fo: /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  fr: /^(\+?33|0)[67]\d{8}$/,
  gf: /^(\+?594|0|00594)[67]\d{8}$/,
  gp: /^(\+?590|0|00590)[67]\d{8}$/,
  mq: /^(\+?596|0|00596)[67]\d{8}$/,
  re: /^(\+?262|0|00262)[67]\d{8}$/,
  il: /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/,
  hu: /^(\+?36)(20|30|70)\d{7}$/,
  id: /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
  it: /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
  jp: /^(\+81[ \-]?(\(0\))?|0)[6789]0[ \-]?\d{4}[ \-]?\d{4}$/,
  kz: /^(\+?7|8)?7\d{9}$/,
  gl: /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  kr: /^((\+?82)[ \-]?)?0?1([0|1|6|7|8|9]{1})[ \-]?\d{3,4}[ \-]?\d{4}$/,
  lt: /^(\+370|8)\d{8}$/,
  my: /^(\+?6?01){1}(([0145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/,
  no: /^(\+?47)?[49]\d{7}$/,
  np: /^(\+?977)?9[78]\d{8}$/,
  be: /^(\+?32|0)4?\d{8}$/,
  nl: /^(\+?31|0)6?\d{8}$/,
  pl: /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
  br: /(?=^(\+?5{2}\-?|0)[1-9]{2}\-?\d{4}\-?\d{4}$)(^(\+?5{2}\-?|0)[1-9]{2}\-?[6-9]{1}\d{3}\-?\d{4}$)|(^(\+?5{2}\-?|0)[1-9]{2}\-?9[6-9]{1}\d{3}\-?\d{4}$)/,
  pt: /^(\+?351)?9[1236]\d{7}$/,
  ro: /^(\+?4?0)\s?7\d{2}(\/|\s|\.|\-)?\d{3}(\s|\.|\-)?\d{3}$/,
  ru: /^(\+?7|8)?9\d{9}$/,
  si: /^(\+386\s?|0)(\d{1}\s?\d{3}\s?\d{2}\s?\d{2}|\d{2}\s?\d{3}\s?\d{3})$/,
  sk: /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  rs: /^(\+3816|06)[- \d]{5,9}$/,
  se: /^(\+?46|0)[\s\-]?7[\s\-]?[02369]([\s\-]?\d){7}$/,
  th: /^(\+66|66|0)\d{9}$/,
  tr: /^(\+?90|0)?5\d{9}$/,
  ua: /^(\+?38|8)?0\d{9}$/,
  vn: /^(\+?84|0)((3([2-9]))|(5([2689]))|(7([0|6-9]))|(8([1-6|89]))|(9([0-9])))([0-9]{7})$/,
  cn: /^((\+|00)86)?1([358][0-9]|4[579]|6[67]|7[01235678]|9[189])[0-9]{8}$/,
  tw: /^(\+?886\-?|0)?9\d{8}$/,
};

export const shiftSwitchFromNotification = (
  allAssignedShifts,
  myShfit,
  senderShift
) => {
  const oldAllAssignedShifts = [...allAssignedShifts];
  let newAllAssignedShifts = oldAllAssignedShifts.filter(
    (s) => s.date != myShfit.date
  );
  const {
    date,
    start_time,
    end_time,
    start_period,
    workplace_name,
    shift_name,
  } = senderShift;

  const myNewShift = {
    date,
    id_user: myShfit.id_user,
    id_event_date: myShfit.id_event_date,
    shift: {
      start_time,
      end_time,
      start_period,
      is_shifted: true,
      workplace_name,
      shift_name,
    },
  };

  newAllAssignedShifts = [...newAllAssignedShifts, myNewShift];
  return newAllAssignedShifts;
};
