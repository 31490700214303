import React, { useRef, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import moment from "moment";

export default function RTimePicker({ open, close, onChange, defaultValue }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={["DesktopTimePicker"]}>
        <DesktopTimePicker
          defaultValue={defaultValue}
          timeSteps={{ minutes: 1 }}
          open={open}
          onClose={() => {
            close(false);
            document.body?.classList?.remove("scroll-off");
          }}
          autoFocus={false}
          className="d-none"
          onChange={onChange}
          closeOnSelect={false}
          reduceAnimations={true}
          classes={{
            root: "why-root MuiModal-root",
            hidden: "why-hidden MuiModal-root",
            backdrop: "why-backdrop MuiModal-root",
          }}
          slotProps={{
            actionBar: {
              actions: ["cancel", "accept"],
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
