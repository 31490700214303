import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import useRequest from "../../hooks/useRequest";
import { useDispatch, useSelector } from "react-redux";
import { addAllAssignedNotes } from "../../store/calendarData/action";
import UpgradeBanner from "../../Component/UpgradeBanner";

const EditNote = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [date, setDate] = useState("");

  const { allAssignedNotes } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { request, response } = useRequest();
  const { request: editNoteRequest, response: editNoteResponse } = useRequest();

  useEffect(() => {
    request("GET", `v1/event/note/${id}`);
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { text, date } = response.data.note;
        setDate(date);
        setValue("text", text);
      }
    }
  }, [response]);

  useEffect(() => {
    if (editNoteResponse) {
      if (editNoteResponse.status == "SUCCESS") {
        const { text } = editNoteResponse.data.note;
        const newNoteData = allAssignedNotes.map((n) => {
          if (n.id_note == id) {
            n.text = text;
          }
          return n;
        });
        dispatch(addAllAssignedNotes(newNoteData));

        navigate("/calendar", {
          state: {
            date: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          },
        });
      }
    }
  }, [editNoteResponse]);

  const editNoteHandler = (data) => {
    const { text } = data;
    editNoteRequest("POST", "v1/event/edit-note", { text, id_note: id });
  };

  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="headerContant"></div>
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5 cursor"
          onClick={() =>
            navigate("/calendar", {
              state: {
                date: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
              },
            })
          }
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
        </span>
        <h1>Edit Note</h1>
      </div>
      <p className="color-text-50 fw-500 mb-3 note_date_title">
        {" "}
        {date && moment(date, "DD/MM/YYYY").format("MMMM Do, YYYY")}{" "}
      </p>
      {/* Section */}
      <div className="page-section">
        <div className="container">
          <div className="innerPageBox">
            <div className="stepper-Outbody h-10000">
              <form id="regForm" onSubmit={handleSubmit(editNoteHandler)}>
                <div className="stepper-body">
                  <div className="stepper-content">
                    <div className="tab" style={{ display: "block" }}>
                      <div className="form-field">
                        <div className="form-input">
                          <input
                            name="ShiftName"
                            type="TEXT"
                            placeholder="Note Text"
                            defaultValue=""
                            {...register("text", {
                              required: "This field is mandatory.",
                              setValueAs: (v) => v.trim(),
                            })}
                          />
                          <span className="inputborder" />
                        </div>
                        {errors.text && (
                          <p className="error-msg">{errors.text.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="content-footer" style={{ overflow: "auto" }}>
                    <button
                      type="button"
                      className="btn-link formBtn color-text-50"
                      id="prevBack"
                      onClick={() =>
                        navigate("/calendar", {
                          state: {
                            date: moment(date, "DD/MM/YYYY").format(
                              "YYYY-MM-DD"
                            ),
                          },
                        })
                      }
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="btn-white formBtn"
                      id="nextBtn"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNote;
