import { Link, useLocation } from "react-router-dom";

const SignupSuccessfully = () => {
  const location = useLocation();
  const { isMessageShow } = location.state;

  return (
    <section className="fullcontent bgBlue">
      <div className="container">
        <div className="content">
          <div
            className="sign-up-content color-white"
            style={{ justifyContent: "center" }}
          >
            <Link className="logo" to="/">
              <img src="assets/img/LOGO.svg" alt="Turno" />
            </Link>
            <span className="spaceSpan"></span>
            <h1 className="no-transform success_title mb-4">
              Thanks for signing up!
            </h1>
            <p className="color-white-70 text-center">
              {!isMessageShow &&
                "Please check your email to activate your account."}
            </p>
            {/* <h3 className="my-6" style={{ color: "white" }}>
              Successfully Signed up!
            </h3>
            Please check your email to complete the registration process. */}
            {/* {location.state?.message} */}
            <Link to="/" className="sign_in_link">
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupSuccessfully;
