import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useRequest from "../hooks/useRequest";
import { toast } from "react-toastify";
import { Spinner } from "../Component/Svg";

const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({ mode: "onBlur" });

  const navigate = useNavigate();
  const [queryObj, setQueryObj] = useState({});

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    setValue("email", currentParams["email"]);
    setValue("first_name", currentParams["name"]);
    setQueryObj(currentParams); // get new values onchange
  }, [searchParams]);

  const { request, response, loading } = useRequest();

  useEffect(() => {
    document.body.classList.remove("scroll-off");
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        navigate("/signup-successfully", {
          state: { isMessageShow: response.data.is_invited },
          // replace: true,
        });
      } else {
        // toast.error(response.message);
        setError("email", { type: "alreadyExist", message: response.message });
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    const { first_name, last_name, email, password, is_shift } = data;
    let body = {
      first_name,
      last_name,
      email,
      password,
      is_shift: JSON.parse(is_shift),
    };
    if (Object.keys(queryObj).length > 0) {
      body.refer_token = queryObj["invite-token"];
    }

    if (!loading) {
      request("POST", "v1/auth/register", body);
    }
  };

  return (
    <section className="fullcontent bgBlue">
      <div className="container">
        <div className="content">
          <div className="sign-up-content color-white">
            <a className="logo">
              <img src="assets/img/LOGO.svg" alt="Turno" />
            </a>
            <span className="spaceSpan"></span>
            <h1 className="my-6">Sign Up</h1>
            <span className="spaceSpan"></span>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={
                  errors.first_name
                    ? "form-field form-field-white has-error"
                    : "form-field form-field-white"
                }
              >
                <div className="form-input">
                  <input
                    name="fname"
                    type="text"
                    placeholder="First Name"
                    {...register("first_name", {
                      required: "This field is mandatory.",
                      setValueAs: (v) => v.trim(),
                    })}
                  />
                  <span className="inputborder" />
                </div>
                {errors.first_name && (
                  <p className="error-msg">{errors.first_name.message}</p>
                )}
              </div>
              <span className="spaceSpan"></span>
              <div
                className={
                  errors.last_name
                    ? "form-field form-field-white has-error"
                    : "form-field form-field-white"
                }
              >
                <div className="form-input">
                  <input
                    name="lname"
                    type="text"
                    placeholder="Last Name"
                    {...register("last_name", {
                      required: "This field is mandatory.",
                      setValueAs: (v) => v.trim(),
                    })}
                  />

                  <span className="inputborder" />
                </div>
                {errors.last_name && (
                  <p className="error-msg">{errors.last_name.message}</p>
                )}
              </div>
              <span className="spaceSpan"></span>
              <div
                className={
                  errors.email
                    ? "form-field form-field-white has-error"
                    : "form-field form-field-white"
                }
              >
                <div className="form-input">
                  <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    {...register("email", {
                      onChange: (e) => {
                        if (errors.email?.message) {
                          let value = e.target.value;
                          if (
                            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                              value
                            )
                          ) {
                            setError("email", {
                              type: "manual",
                              message: "Please provide a valid email.",
                            });
                          } else {
                            clearErrors("email");
                          }
                        }
                      },
                      required: "This field is mandatory.",
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: "Please provide a valid email.",
                      },
                      setValueAs: (v) => v.trim(),
                    })}
                  />

                  <span className="inputborder" />
                </div>
                {errors.email && (
                  <p className="error-msg">{errors.email.message}</p>
                )}
              </div>
              <span className="spaceSpan"></span>
              <div
                className={
                  errors.password
                    ? "form-field form-field-white has-error"
                    : "form-field form-field-white"
                }
              >
                <div className="form-input">
                  <input
                    name="password"
                    type="password"
                    placeholder="Password (min 6 characters)"
                    autoComplete="off"
                    {...register("password", {
                      onChange: (e) => {
                        if (errors.password?.message) {
                          let value = e.target.value;
                          if (!/^.{6,}$/.test(value)) {
                            setError("password", {
                              type: "manual",
                              message:
                                "Password must be 6 characters or more with a mix of uppercase, lowercase, numbers and special characters.",
                            });
                          } else {
                            clearErrors("password");
                          }
                        }
                      },
                      required: "This field is mandatory.",
                      pattern: {
                        value: /^.{6,}$/,
                        // value:
                        //   /^(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{6,}).*$/,
                        message:
                          "Password must be 6 characters or more with a mix of uppercase, lowercase, numbers and special characters.",
                      },
                      setValueAs: (v) => v.trim(),
                    })}
                  />

                  <span className="inputborder" />
                </div>
                {errors.password && (
                  <p className="error-msg">{errors.password.message}</p>
                )}
              </div>
              <span className="spaceSpan"></span>
              {/* <div
                className={
                  errors.confirm_password
                    ? "form-field form-field-white has-error"
                    : "form-field form-field-white"
                }
              >
             <div className="form-input">
                  <input
                    name="password"
                    type="password"
                    placeholder="Confirm Password"
                    autoComplete="off"
                    {...register("confirm_password", {
                      required: "This field is mandatory.",

                      validate: (value) => {
                        const { password } = getValues();
                        const isSame = password === value;
                        if (!isSame) {
                          return "Confirm password must be same as password!";
                        }
                        return true;
                      },
                      setValueAs: (v) => v.trim(),
                    })}
                  />

                  <span className="inputborder" />
                </div>
                {errors.confirm_password && (
                  <p className="error-msg">{errors.confirm_password.message}</p>
                )}
              </div> */}
              {/* <span className="spaceSpan"></span> */}
              <div className="form-field form-field-white mb-20">
                <div className="sign-up-radio">
                  <p className=" line-height-1">Are you a shift worker?</p>
                  <div className="d-flex align-items-center">
                    <div className="radio-wrap radio-wrap-white mr-7">
                      <label>
                        <input
                          type="radio"
                          name="shiftWorker"
                          value={true}
                          {...register("is_shift", {
                            required: "Please select one of the choices..",
                          })}
                        />
                        <span className="icon" />
                        <span className="text">Yes</span>
                      </label>
                    </div>
                    <div className="radio-wrap radio-wrap-white">
                      <label>
                        <input
                          type="radio"
                          name="shiftWorker"
                          value={false}
                          {...register("is_shift", {
                            required: "Please select one of the choices..",
                          })}
                        />
                        <span className="icon" />
                        <span className="text">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                {errors.is_shift && (
                  <span
                    className="error-msg with-icon has-error"
                    style={{ display: "flex", justifyContent: "left" }}
                  >
                    {errors.is_shift.message}
                  </span>
                )}
              </div>
              <button
                type="submit"
                className="btn btn-white w-100 mt-2 submitButton"
                // disabled
              >
                {loading ? <Spinner /> : "Get Started"}
              </button>

              <p className="fw-200 mt-25 color-white-70 line-height-1">
                By continuing, you agree to the{" "}
                <a
                  className="link color-white-70"
                  href="https://www.termsfeed.com/live/203f10ee-93d2-471a-b846-fe6451e2fa9a"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </p>
            </form>
            <span className="spaceSpan"></span>
            <div className="mt-6">
              Already have an account?{" "}
              <Link className="link font-base color-white" to="/">
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
