import { useSelector } from "react-redux";
import Header from "../../Component/Header";
import UpgradeBanner from "../../Component/UpgradeBanner";
const About = () => {
  const { role } = useSelector((state) => state.auth);
  return (
    <>
      <div className="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
        <div className="headerContant"></div>
        <div className="setting_header about_header light_header">
          <Header heading="About" link="/settings"></Header>
          {/* <Sidebar from="events" /> */}
          <div className="content-header-bottom">
            <div className="profile_img2">
              <img src="/assets/img/alogo-dark.png" alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="list-wrap">
            <div className="profile-info">
              <div className="company_about">
                <h6 className="company_about_heading">About Turno</h6>
                <p className="company_about_text">
                  Turno was created out of the need to help shift workers take
                  control of their time and improve their work-life balance.
                </p>
              </div>
              <div className="company_about">
                <h6 className="company_about_heading">Version</h6>
                <p className="company_about_text">2.23.0</p>
              </div>
              <div className="company_about">
                <h6 className="company_about_heading">Follow us on</h6>
                <ul className="social_links">
                  <li>
                    <a
                      href="https://www.facebook.com/TurnoInc"
                      target="_blank"
                      className=""
                    >
                      <img src="/assets/img/facebook.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/turnoinc"
                      target="_blank"
                      className=""
                    >
                      <img src="/assets/img/linkedin.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/TurnoInc"
                      target="_blank"
                      className=""
                    >
                      <img src="/assets/img/twitter.svg" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
