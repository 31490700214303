import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AdminLayout } from "../../Component/AdminLayout";
import { Spinner } from "../../Component/Svg";
import useRequest from "../../hooks/useRequest";

const AdminSetting = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [settings, setSettings] = useState([]);

  const { request, response } = useRequest();
  const {
    request: editSettingRequest,
    response: editSettingResponse,
    loading,
  } = useRequest();

  useEffect(() => {
    request("GET", "v1/admin/settings");
  }, []);

  useEffect(() => {
    if (response) {
      const { settings } = response.data;
      if (settings && settings.length > 0) {
        setSettings(settings);
        settings.forEach((s) => {
          setValue(`${s.id_setting}`, s.value);
        });
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    editSettingRequest("POST", "v1/admin/edit-settings", data);
  };

  return (
    <AdminLayout>
      <div className="mainContent">
        <div className="adminCard sm allSidePadding">
          <div className="SettingPage">
            <div className="inCardHeader">
              <div className="jss325">
                <h6 className="adminCardTitle-h6">Settings</h6>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="main-input sm">
              <div className="form-group setting_div ">
                {settings.length > 0 &&
                  settings.map((setting) => (
                    <div className="m-2">
                      <label className="admin-input-label" for="trial_days">
                        {setting.label}
                      </label>
                      <div className="MuiInputBase-root MuiInput-root mb-4">
                        <input
                          class="adminSettingInput admin-inputType"
                          id="trial_days"
                          type="number"
                          {...register(`${setting.id_setting}`, {
                            required: "This field is mandatory.",
                            max: {
                              value: setting.max,
                              message: "Please select a valid number.",
                            },
                            min: {
                              value: setting.min,
                              message: "Please select a valid number.",
                            },
                          })}
                        />
                      </div>
                      {errors[setting.id_setting] && (
                        <p className="error-msg">
                          {errors[setting.id_setting].message}
                        </p>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            {settings.length > 0 && (
              <div class="admin-buttons">
                <button type="submit" class="btn btn-white">
                  {loading ? <Spinner /> : "Save"}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminSetting;
