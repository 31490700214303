import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../Component/Header";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useRequest from "../../../hooks/useRequest";
import { addAllWorkplaces } from "../../../store/calendarData/action";
import { DeleteIcon } from "../../../Component/Svg";
import UpgradeBanner from "../../../Component/UpgradeBanner";

const ViewOne = () => {
  const { allWorkplaces } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);

  const { id } = useParams();
  const navigate = useNavigate();
  const [workplace, setWorkplace] = useState();
  const dispatch = useDispatch();
  const { request, response } = useRequest();
  const { request: deleteWorkPlaceRequest, response: deleteWorkPlaceResponse } =
    useRequest();

  useEffect(() => {
    if (allWorkplaces.length > 0) {
      const workplace = allWorkplaces.find((w) => w.id_workplace == id);
      if (workplace) {
        setWorkplace(workplace);
      } else {
        request("GET", `v1/workplace/workplace/${id}`);
      }
    } else {
      request("GET", `v1/workplace/workplace/${id}`);
    }
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { workplace } = response.data;
        setWorkplace(workplace);
      }
    }
  }, [response]);

  useEffect(() => {
    if (deleteWorkPlaceResponse) {
      if (deleteWorkPlaceResponse.status == "SUCCESS") {
        const newWorkplaces = allWorkplaces.filter(
          (workP) => workP.id_workplace != id
        );
        dispatch(addAllWorkplaces(newWorkplaces));
        navigate("/work-list");
      }
    }
  }, [deleteWorkPlaceResponse]);

  return (
    <>
      <div className="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
        <div className="headerContant"></div>
        <div className="setting_header light_header">
          <Header heading="Work" link="/work-list">
            <div>
              <Link to={`/edit-work/${id}`}>
                <i className="icon-edit font-md icon-shadow"></i>
              </Link>
              <button
                className="delete_btn btn-icon w-30 color-warning cursor"
                onClick={() =>
                  deleteWorkPlaceRequest(
                    "DELETE",
                    `v1/workplace/delete-workplace/${id}`
                  )
                }
              >
                {" "}
                <DeleteIcon />{" "}
              </button>
            </div>
          </Header>
          {/* <Sidebar from="events" /> */}
          <div className="content-header-bottom">
            <div className="profile_img">
              <i className="icon-building"></i>
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="list-wrap">
            <div className="profile-info">
              <p className="profile_main_title"> {workplace?.hospital_name}</p>
              <div className="profile_feeds">
                <p className="main_title"> Address</p>
                <p className="sub_title"> {workplace?.hospital_address} </p>
              </div>
              <div className="profile_feeds">
                <p className="main_title"> City</p>
                <p className="sub_title"> {workplace?.city}</p>
              </div>
              <div className="profile_feeds">
                <p className="main_title"> State/Province</p>
                <p className="sub_title"> {workplace?.state}</p>
              </div>
              <div className="profile_feeds">
                <p className="main_title"> Country</p>
                <p className="sub_title"> {workplace?.country} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOne;
