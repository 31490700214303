import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../Component/Header";
import { DeleteIcon } from "../../../Component/Svg";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { API } from "../../../constant/api";
import { addManager } from "../../../store/auth/action";
import UpgradeBanner from "../../../Component/UpgradeBanner";

const ViewOne = () => {
  const { id } = useParams();
  const { managers, role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [manager, setManager] = useState();
  const { request, response } = useRequest();
  const { request: deleteManagerRequest, response: deleteManagerResponse } =
    useRequest();

  useEffect(() => {
    if (managers.length > 0) {
      let manager = managers.find((m) => m.id_manager == id);
      if (manager) {
        setManager(manager);
        let picture = manager.picture;
        if (picture) {
          setImage(`${API.IMG}/${picture}`);
        }
      } else {
        request("GET", `v1/manager/manager/${id}`);
      }
    } else {
      request("GET", `v1/manager/manager/${id}`);
    }
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { managers } = response.data;
        setManager(managers);
        const { picture } = managers;
        if (picture) {
          let image = `${API.IMG}/${picture}`;
          setImage(image);
        }
      }
    }
  }, [response]);

  useEffect(() => {
    if (deleteManagerResponse) {
      if (deleteManagerResponse.status == "SUCCESS") {
        let oldManager = managers.filter((m) => m.id_manager != id);
        dispatch(addManager(oldManager));
        navigate("/manager-list");
      }
    }
  }, [deleteManagerResponse]);

  const titleObj = {
    manager: "Manager",
    "team lead": "Team Lead",
  };

  return (
    <>
      <div className="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

        <div className="headerContant"></div>
        <div className="setting_header light_header">
          <Header heading="Manager" link="/manager-list">
            <div>
              <Link to={`/edit-manager/${id}`}>
                <i className="icon-edit font-md icon-shadow"></i>
              </Link>
              <button
                className="delete_btn btn-icon w-30 color-warning cursor"
                onClick={() =>
                  deleteManagerRequest(
                    "DELETE",
                    `v1/manager/delete-manager/${id}`
                  )
                }
              >
                {" "}
                <DeleteIcon />{" "}
              </button>
            </div>
          </Header>
          {/* <Sidebar from="events" /> */}
          <div className="content-header-bottom">
            <div className="profile_img">
              {image ? <img src={image} /> : <i className="icon-user"></i>}
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="list-wrap">
            <div className="profile-info">
              <p className="profile_main_title">
                {" "}
                {`${manager?.first_name ?? ""} ${manager?.last_name ?? ""}`}
              </p>
              <div className="profile_feeds">
                <p className="main_title"> Title</p>
                <p className="sub_title">
                  {" "}
                  {manager?.title && titleObj[manager.title]}{" "}
                </p>
              </div>
              <div className="profile_feeds">
                <p className="main_title"> Phone</p>
                <p className="sub_title">
                  {" "}
                  {manager?.mobile_number &&
                    `+${manager?.country_code} - ${manager?.mobile_number}`}
                  {/* +{manager?.country_code}-{manager?.mobile_number}{" "} */}
                </p>
              </div>
              <div className="profile_feeds">
                <p className="main_title"> Email</p>
                <p className="sub_title"> {manager?.email}</p>
              </div>
              {manager?.birthday && (
                <div className="profile_feeds">
                  <p className="main_title"> Birthday</p>
                  <p className="sub_title">
                    {" "}
                    {manager?.birthday &&
                      moment(manager?.birthday).format("YYYY-MM-DD")}
                  </p>
                </div>
              )}
              <div className="profile_feeds">
                <p className="main_title"> Gender</p>
                <p className="sub_title text-capitalize"> {manager?.gender}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOne;
