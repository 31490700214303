import { Modal } from "react-bootstrap";
import moment from "moment";
import { Spinner } from "./Svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UpgradeMessageModal from "./UpgradeMessageModal";

const NotificationModal = ({
  notificationModalShow,
  sendingUserName,
  setNotificationModalShow,
  notificationModalData,
  switchShiftAndGiveawayAcceptRejectHandler,
  invitationStatusHanlder,
  switchShiftAndGiveawayAcceptRejectLoading,
  locationChangeWarningModalAcceptSwitchShift,
  setLocationChangeWarningModalAcceptSwitchShift,
  locationChangeWarningModalAcceptGiveaway,
  setLocationChangeWarningModalAcceptGiveaway,
}) => {
  const { allWorkplaces } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);

  const [
    acceptRejectButtonClickForGiveaway,
    setAcceptRejectButtonClickForGiveaway,
  ] = useState({
    reject: false,
    accpet: false,
  });
  const [
    acceptRejectButtonClickForSwitch,
    setAcceptRejectButtonClickForSwitch,
  ] = useState({
    reject: false,
    accpet: false,
  });

  const [allWorkplacesNames, setAllWorkPlacesNames] = useState([]);

  const [isUpgradeModalShow, setIsUpgradeModalShow] = useState(false);

  useEffect(() => {
    if (allWorkplaces.length > 0) {
      setAllWorkPlacesNames(
        allWorkplaces.map((workplace) => workplace.hospital_name)
      );
    }
  }, [allWorkplaces]);

  return (
    <>
      <Modal
        show={notificationModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        // backdrop="static"
        onHide={() => setNotificationModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setNotificationModalShow(false)}
          />
          <div className="modal-body p-0">
            {/* Invitation */}

            {notificationModalData?.notification_text == "Invitation" && (
              <div className="invitation_box">
                <a className="notifications_info">
                  <span className="notifications_info_left"> </span>
                  <span className="notifications_info_right">
                    <h6 className="notifications_info_title">
                      {/* owebest tech */}
                      {sendingUserName}
                    </h6>
                    <p className="notifications_info_link">
                      <span className="text">Invitation</span>
                    </p>
                  </span>
                </a>
                <div className="modal_info">
                  <p className="info_text">
                    {sendingUserName} wants to share calendars with you.
                  </p>
                  {notificationModalData.status == "pending" ? (
                    <div class="single-view-footer justify-around">
                      <button
                        type="button"
                        class="btn btn-link color-warning"
                        onClick={() =>
                          invitationStatusHanlder(
                            notificationModalData.share_calendar_request_id,
                            "rejected",
                            notificationModalData.id_notification
                          )
                        }
                      >
                        Decline
                      </button>
                      <button
                        type="button"
                        class="btn invitation_accept"
                        onClick={() =>
                          invitationStatusHanlder(
                            notificationModalData.share_calendar_request_id,
                            "accepted",
                            notificationModalData.id_notification
                          )
                        }
                      >
                        Accept
                      </button>
                    </div>
                  ) : (
                    <span className="info_suggestion">
                      You have already{" "}
                      {notificationModalData.status == "rejected"
                        ? "declined"
                        : notificationModalData.status}{" "}
                      this invite request{" "}
                    </span>
                  )}
                </div>
              </div>
            )}

            {/* Sharing calendars disabled */}
            {/* <div className="invitation_box ">
            <a className="notifications_info">
              <span className="notifications_info_left"> </span>
              <span className="notifications_info_right">
                <h6 className="notifications_info_title"> owebest tech</h6>
                <p className="notifications_info_link">
                  <span className="text">Sharing calendars disabled</span>
                </p>
              </span>
            </a>
            <div className="modal_info">
              <p className="info_text">
                owebest tech wants to share calendars with you.{" "}
              </p>
              <span className="info_suggestion">
                You have already rejected this invite request{" "}
              </span>
            </div>
          </div> */}

            {/* Sharing calendars disabled */}
            {[
              "Sharing calendars disabled",
              "Sharing calendars enabled",
            ].includes(notificationModalData?.notification_text) && (
              <div className="invitation_box ">
                <a className="notifications_info">
                  <span className="notifications_info_left"> </span>
                  <span className="notifications_info_right">
                    <h6 className="notifications_info_title">
                      {" "}
                      {sendingUserName}
                    </h6>
                    <p className="notifications_info_link">
                      <span className="text">
                        {notificationModalData?.notification_text}
                      </span>
                    </p>
                  </span>
                </a>
                <div className="modal_info">
                  <p className="pick_shift"> Pick up this shift: </p>
                  <span className="icon-cl">
                    {" "}
                    <i class="icon-calendar font-md color-black-30"></i>
                  </span>
                </div>
              </div>
            )}

            {/* Invitation accepted */}

            {["Invitation accepted", "Invitation declined"].includes(
              notificationModalData?.notification_text
            ) && (
              <div className="invitation_box ">
                <a className="notifications_info">
                  <span className="notifications_info_left"> </span>
                  <span className="notifications_info_right">
                    <h6 className="notifications_info_title">
                      {sendingUserName}{" "}
                    </h6>
                    <p className="notifications_info_link">
                      <span className="text">
                        {notificationModalData?.notification_text}
                      </span>
                    </p>
                  </span>
                </a>
                <div className="modal_info">
                  <p className="info_text mb-0">
                    <span> {sendingUserName} </span> has{" "}
                    {notificationModalData?.notification_text?.split(" ")[1]} to
                    share calendars with you.
                  </p>
                </div>
              </div>
            )}

            {notificationModalData?.notification_text == "Switch request" && (
              <div className="invitation_box ">
                <a className="notifications_info">
                  <span className="notifications_info_left"> </span>
                  <span className="notifications_info_right">
                    <h6 className="notifications_info_title">
                      {" "}
                      {sendingUserName}
                    </h6>
                    <p className="notifications_info_link">
                      <span className="text">
                        {notificationModalData?.notification_text}
                      </span>
                    </p>
                  </span>
                </a>
                <div className="modal_info">
                  <p className="pick_shift"> Switch your shift: </p>
                  <ul className="pick_shift_list">
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-calendar font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift font-md fw-800">
                        {/* {" "}
                      Tue, Jul 25th, 2023{" "} */}
                        {moment(
                          notificationModalData?.receiver_user_event_date?.date,
                          "DD/MM/YYYY"
                        ).format("ddd, MMM Do, YYYY")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> Start Time : </span>{" "}
                        {moment(
                          notificationModalData?.receiver_user_event_date
                            ?.start_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>{" "}
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> End Time : </span>{" "}
                        {moment(
                          notificationModalData?.receiver_user_event_date
                            ?.end_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                  </ul>
                  <div className=" my-3 text-end">
                    {" "}
                    <i class="icon-switch-vertical color-text-70 font-lg pr_40"></i>{" "}
                  </div>
                  <p className="pick_shift"> For this shift: </p>
                  <ul className="pick_shift_list">
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-calendar font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift font-md fw-800">
                        {/* {" "}
                      Tue, Jul 25th, 2023{" "} */}
                        {moment(
                          notificationModalData?.sending_user_event_date?.date,
                          "DD/MM/YYYY"
                        ).format("ddd, MMM Do, YYYY")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> Start Time : </span>{" "}
                        {moment(
                          notificationModalData?.sending_user_event_date
                            ?.start_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>{" "}
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> End Time : </span>{" "}
                        {moment(
                          notificationModalData?.sending_user_event_date
                            ?.end_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                  </ul>
                  {!notificationModalData.is_covered ? (
                    notificationModalData.status == "pending" ? (
                      <div class="single-view-footer justify-around">
                        <button
                          type="button"
                          class="btn btn-link color-warning"
                          onClick={() => {
                            if (role == "blocked_user") {
                              setIsUpgradeModalShow(true);
                              return;
                            } else {
                              setAcceptRejectButtonClickForSwitch({
                                reject: true,
                                accpet: false,
                              });
                              switchShiftAndGiveawayAcceptRejectHandler(
                                "rejected",
                                notificationModalData,
                                "switch-request-approval"
                              );
                            }
                          }}
                        >
                          {acceptRejectButtonClickForSwitch.reject &&
                          switchShiftAndGiveawayAcceptRejectLoading ? (
                            <Spinner />
                          ) : (
                            "Decline"
                          )}
                        </button>
                        <button
                          type="button"
                          class="btn invitation_accept"
                          onClick={() => {
                            if (role == "blocked_user") {
                              setIsUpgradeModalShow(true);
                              return;
                            } else {
                              if (
                                !allWorkplacesNames.includes(
                                  notificationModalData.sending_user_event_date
                                    .workplace_name
                                )
                              ) {
                                setLocationChangeWarningModalAcceptSwitchShift(
                                  true
                                );
                              } else {
                                setAcceptRejectButtonClickForSwitch({
                                  reject: false,
                                  accpet: true,
                                });
                                switchShiftAndGiveawayAcceptRejectHandler(
                                  "accepted",
                                  notificationModalData,
                                  "switch-request-approval"
                                );
                              }
                            }
                          }}
                        >
                          {acceptRejectButtonClickForSwitch.accpet &&
                          switchShiftAndGiveawayAcceptRejectLoading &&
                          !locationChangeWarningModalAcceptSwitchShift ? (
                            <Spinner />
                          ) : (
                            "Accept"
                          )}
                        </button>
                      </div>
                    ) : (
                      <span className="info_suggestion">
                        You have already{" "}
                        {notificationModalData.status == "rejected"
                          ? "declined"
                          : notificationModalData.status}{" "}
                        this switch request{" "}
                      </span>
                    )
                  ) : (
                    <span className="info_suggestion">
                      This shift is covered by someone else{" "}
                    </span>
                  )}
                </div>
              </div>
            )}

            {notificationModalData?.notification_text == "Giveaway request" && (
              <div className="invitation_box ">
                <a className="notifications_info">
                  <span className="notifications_info_left"> </span>
                  <span className="notifications_info_right">
                    <h6 className="notifications_info_title">
                      {" "}
                      {sendingUserName}
                    </h6>
                    <p className="notifications_info_link">
                      <span className="text">
                        {notificationModalData?.notification_text}
                      </span>
                    </p>
                  </span>
                </a>
                <div className="modal_info">
                  <p className="pick_shift"> Pick Up shift details: </p>
                  <ul className="pick_shift_list">
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-calendar font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift font-md fw-800">
                        {/* {" "}
                      Tue, Jul 25th, 2023{" "} */}
                        {moment(
                          notificationModalData?.sending_user_event_date?.date,
                          "DD/MM/YYYY"
                        ).format("ddd, MMM Do, YYYY")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> Start Time : </span>{" "}
                        {moment(
                          notificationModalData?.sending_user_event_date
                            ?.start_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>{" "}
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> End Time : </span>{" "}
                        {moment(
                          notificationModalData?.sending_user_event_date
                            ?.end_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                  </ul>
                  {!notificationModalData.is_covered ? (
                    notificationModalData.status == "pending" ? (
                      <div class="single-view-footer justify-around">
                        <button
                          type="button"
                          class="btn btn-link color-warning"
                          onClick={() => {
                            if (role == "blocked_user") {
                              setIsUpgradeModalShow(true);
                              return;
                            } else {
                              setAcceptRejectButtonClickForGiveaway({
                                reject: true,
                                accpet: false,
                              });
                              switchShiftAndGiveawayAcceptRejectHandler(
                                "rejected",
                                notificationModalData,
                                "giveaway-request-approval"
                              );
                            }
                          }}
                        >
                          {acceptRejectButtonClickForGiveaway.reject &&
                          switchShiftAndGiveawayAcceptRejectLoading ? (
                            <Spinner />
                          ) : (
                            "Decline"
                          )}
                        </button>
                        <button
                          type="button"
                          class="btn invitation_accept"
                          onClick={() => {
                            if (role == "blocked_user") {
                              setIsUpgradeModalShow(true);
                              return;
                            } else {
                              if (
                                !allWorkplacesNames.includes(
                                  notificationModalData.sending_user_event_date
                                    .workplace_name
                                )
                              ) {
                                setLocationChangeWarningModalAcceptGiveaway(
                                  true
                                );
                              } else {
                                setAcceptRejectButtonClickForGiveaway({
                                  reject: false,
                                  accpet: true,
                                });
                                switchShiftAndGiveawayAcceptRejectHandler(
                                  "accepted",
                                  notificationModalData,
                                  "giveaway-request-approval"
                                );
                              }
                            }
                          }}
                        >
                          {acceptRejectButtonClickForGiveaway.accpet &&
                          switchShiftAndGiveawayAcceptRejectLoading &&
                          !locationChangeWarningModalAcceptGiveaway ? (
                            <Spinner />
                          ) : (
                            "Accept"
                          )}
                        </button>
                      </div>
                    ) : (
                      <span className="info_suggestion">
                        You have already{" "}
                        {notificationModalData.status == "rejected"
                          ? "declined"
                          : notificationModalData.status}{" "}
                        this giveaway request{" "}
                      </span>
                    )
                  ) : (
                    <span className="info_suggestion">
                      This giveaway is covered by someone else{" "}
                    </span>
                  )}
                </div>
              </div>
            )}

            {notificationModalData?.notification_text ==
              "Giveaway accepted by manager" && (
              <div className="invitation_box ">
                <a className="notifications_info">
                  <span className="notifications_info_left"> </span>
                  <span className="notifications_info_right">
                    <h6 className="notifications_info_title">
                      {" "}
                      {sendingUserName}
                    </h6>
                    <p className="notifications_info_link">
                      <span className="text">
                        {notificationModalData?.notification_text}
                      </span>
                    </p>
                  </span>
                </a>
                <div className="modal_info">
                  <p className="pick_shift"> Pick Up shift details: </p>
                  <ul className="pick_shift_list">
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-calendar font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift font-md fw-800">
                        {/* {" "}
                      Tue, Jul 25th, 2023{" "} */}
                        {moment(
                          notificationModalData?.sending_user_event_date?.date,
                          "DD/MM/YYYY"
                        ).format("ddd, MMM Do, YYYY")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> Start Time : </span>{" "}
                        {moment(
                          notificationModalData?.sending_user_event_date
                            ?.start_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>{" "}
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> End Time : </span>{" "}
                        {moment(
                          notificationModalData?.sending_user_event_date
                            ?.end_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {[
              "Pending switch approval by manager",
              "Switch accepted by user.",
              "Pending switch acceptance",
              "Switch accepted by manager",
              "Switch declined by user",
            ].includes(notificationModalData?.notification_text) && (
              <div className="invitation_box ">
                <a className="notifications_info">
                  <span className="notifications_info_left"> </span>
                  <span className="notifications_info_right">
                    <h6 className="notifications_info_title">
                      {" "}
                      {sendingUserName}
                    </h6>
                    <p className="notifications_info_link">
                      <span className="text">
                        {notificationModalData?.notification_text}
                      </span>
                    </p>
                  </span>
                </a>
                <div className="modal_info">
                  <p className="pick_shift"> Switch your shift: </p>
                  <ul className="pick_shift_list">
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-calendar font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift font-md fw-800">
                        {/* {" "}
                      Tue, Jul 25th, 2023{" "} */}
                        {moment(
                          notificationModalData?.receiver_user_event_date?.date,
                          "DD/MM/YYYY"
                        ).format("ddd, MMM Do, YYYY")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> Start Time : </span>{" "}
                        {moment(
                          notificationModalData?.receiver_user_event_date
                            ?.start_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>{" "}
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> End Time : </span>{" "}
                        {moment(
                          notificationModalData?.receiver_user_event_date
                            ?.end_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                  </ul>
                  <div className=" my-3 text-end">
                    {" "}
                    <i class="icon-switch-vertical color-text-70 font-lg pr_40"></i>{" "}
                  </div>
                  <p className="pick_shift"> For this shift: </p>
                  <ul className="pick_shift_list">
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-calendar font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift font-md fw-800">
                        {/* {" "}
                      Tue, Jul 25th, 2023{" "} */}
                        {moment(
                          notificationModalData?.sending_user_event_date?.date,
                          "DD/MM/YYYY"
                        ).format("ddd, MMM Do, YYYY")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> Start Time : </span>{" "}
                        {moment(
                          notificationModalData?.sending_user_event_date
                            ?.start_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>{" "}
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> End Time : </span>{" "}
                        {moment(
                          notificationModalData?.sending_user_event_date
                            ?.end_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {[
              "Pending giveaway acceptance",
              "Giveaway accepted by user.",
              "Giveaway declined by user",
            ].includes(notificationModalData?.notification_text) && (
              <div className="invitation_box ">
                <a className="notifications_info">
                  <span className="notifications_info_left"> </span>
                  <span className="notifications_info_right">
                    <h6 className="notifications_info_title">
                      {" "}
                      {sendingUserName}
                    </h6>
                    <p className="notifications_info_link">
                      <span className="text">
                        {notificationModalData?.notification_text}
                      </span>
                    </p>
                  </span>
                </a>
                <div className="modal_info">
                  <p className="pick_shift"> Giveaway shift details : </p>
                  <ul className="pick_shift_list">
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-calendar font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift font-md fw-800">
                        {/* {" "}
                      Tue, Jul 25th, 2023{" "} */}
                        {moment(
                          notificationModalData?.receiver_user_event_date?.date,
                          "DD/MM/YYYY"
                        ).format("ddd, MMM Do, YYYY")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> Start Time : </span>{" "}
                        {moment(
                          notificationModalData?.receiver_user_event_date
                            ?.start_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                    <li className="pick_shift_item">
                      <span className="icon-cl">
                        {" "}
                        <i class="icon-clock font-md color-black-30"></i>{" "}
                      </span>
                      <span className="pick_shift">
                        {" "}
                        <span className="time_status"> End Time : </span>{" "}
                        {moment(
                          notificationModalData?.receiver_user_event_date
                            ?.end_time
                        ).format("hh:mm A")}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {/* Resend Invitation */}
            {notificationModalData?.notification_text ==
              "Resend Invitation" && (
              <div className="invitation_box ">
                <a className="notifications_info">
                  <span className="notifications_info_left"> </span>
                  <span className="notifications_info_right">
                    <h6 className="notifications_info_title">
                      {" "}
                      {sendingUserName}
                    </h6>
                    <p className="notifications_info_link">
                      <span className="text">Resend Invitation</span>
                    </p>
                  </span>
                </a>
                <div className="modal_info"></div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      {/* Shift accept loaction change popup */}
      <Modal
        show={locationChangeWarningModalAcceptSwitchShift}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal thememodal2"
        onHide={() => {
          setLocationChangeWarningModalAcceptSwitchShift(false);
        }}
      >
        <button
          type="button"
          className="btn-close"
          // data-bs-dismiss="modal"
          // aria-label="Close"

          onClick={() => {
            setLocationChangeWarningModalAcceptSwitchShift(false);
            // setDeleteEventId(null);
          }}
        />
        <div
        // className="modal-content"
        >
          <div className="modal-body sure_modal_body">
            <div className="text-center pt-8 px-6">
              {/* <p className="font-lg fw-700 mb-4">Are you sure?</p> */}
              <p className="font-md">
                This work location is not in your list, do you still want to
                accept? Location :{" "}
                {notificationModalData?.sending_user_event_date?.workplace_name}
              </p>
            </div>
          </div>
          <div className="modal-footer shift_time_footer">
            <button
              type="button"
              className="btn button_no"
              onClick={() => {
                setLocationChangeWarningModalAcceptSwitchShift(false);
                // setDeleteEventId(null);
              }}
            >
              No
            </button>
            <button
              type="button"
              className="btn button_yes"
              onClick={() => {
                setAcceptRejectButtonClickForSwitch({
                  reject: false,
                  accpet: true,
                });
                switchShiftAndGiveawayAcceptRejectHandler(
                  "accepted",
                  notificationModalData,
                  "switch-request-approval"
                );
              }}
            >
              {acceptRejectButtonClickForSwitch.accpet &&
              switchShiftAndGiveawayAcceptRejectLoading ? (
                <Spinner />
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </Modal>

      {/* For giveaway */}
      <Modal
        show={locationChangeWarningModalAcceptGiveaway}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal thememodal2"
        onHide={() => {
          setLocationChangeWarningModalAcceptGiveaway(false);
        }}
      >
        <button
          type="button"
          className="btn-close"
          // data-bs-dismiss="modal"
          // aria-label="Close"

          onClick={() => {
            setLocationChangeWarningModalAcceptGiveaway(false);
            // setDeleteEventId(null);
          }}
        />
        <div
        // className="modal-content"
        >
          <div className="modal-body sure_modal_body">
            <div className="text-center pt-8 px-6">
              {/* <p className="font-lg fw-700 mb-4">Are you sure?</p> */}
              <p className="font-md">
                This work location is not in your list, do you still want to
                accept? Location :{" "}
                {notificationModalData?.sending_user_event_date?.workplace_name}
              </p>
            </div>
          </div>
          <div className="modal-footer shift_time_footer">
            <button
              type="button"
              className="btn button_no"
              onClick={() => {
                setLocationChangeWarningModalAcceptGiveaway(false);
                // setDeleteEventId(null);
              }}
            >
              No
            </button>
            <button
              type="button"
              className="btn button_yes"
              onClick={() => {
                setAcceptRejectButtonClickForGiveaway({
                  reject: false,
                  accpet: true,
                });
                switchShiftAndGiveawayAcceptRejectHandler(
                  "accepted",
                  notificationModalData,
                  "giveaway-request-approval"
                );
              }}
            >
              {acceptRejectButtonClickForGiveaway.accpet &&
              switchShiftAndGiveawayAcceptRejectLoading ? (
                <Spinner />
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </Modal>
      {isUpgradeModalShow && (
        <UpgradeMessageModal
          isShowModal={isUpgradeModalShow}
          setIsShowModal={setIsUpgradeModalShow}
        />
      )}
    </>
  );
};

export default NotificationModal;
