import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "../Component/Svg";
import useRequest from "../hooks/useRequest";

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    // clearErrors,
    getValues,
  } = useForm({ mode: "onBlur" });

  const { request, response, loading } = useRequest();

  useEffect(() => {
    if (searchParams.has("token")) {
      setToken(searchParams.get("token"));
      searchParams.delete("token");
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        toast.success(response.message);
        navigate("/");
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    const { password, confirm_password } = data;
    if (!loading) {
      request("POST", "v1/auth/reset-password", {
        token,
        password,
        confirm_password,
      });
    }
  };
  return (
    <section className="fullcontent bgBlue">
      <div className="container">
        <div className="content">
          <div className="sign-up-content color-white">
            <a className="logo" href="/">
              <img src="/assets/img/LOGO.svg" alt="Turno" />
            </a>
            <h1 className="mt-6 mb-3">Reset Password</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={`form-field form-field-white  ${
                  errors.password && "has-error"
                } `}
              >
                <div className="form-input">
                  <input
                    name="password"
                    type="password"
                    placeholder="New Password"
                    autoComplete="off"
                    defaultValue=""
                    {...register("password", {
                      required: "This field is mandatory.",
                      pattern: {
                        value:
                          /^(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{6,}).*$/,
                        message:
                          "Password must be 6 characters or more with a mix of uppercase, lowercase, numbers and special characters.",
                      },
                      setValueAs: (v) => v.trim(),
                    })}
                  />
                  <span className="inputborder" />
                </div>
                {errors.password && (
                  <p className="error-msg">{errors.password.message}</p>
                )}
              </div>
              <div
                className={`form-field form-field-white  ${
                  errors.password && "has-error"
                } `}
              >
                <div className="form-input">
                  <input
                    name="password"
                    type="password"
                    placeholder="Confirm Password"
                    autoComplete="off"
                    defaultValue=""
                    {...register("confirm_password", {
                      required: "This field is mandatory.",

                      validate: (value) => {
                        const { password } = getValues();
                        const isSame = password === value;
                        if (!isSame) {
                          return "Confirm password must be same as password!";
                        }
                        return true;
                      },
                      setValueAs: (v) => v.trim(),
                    })}
                  />
                  <span className="inputborder" />
                </div>
                {errors.confirm_password && (
                  <p className="error-msg">{errors.confirm_password.message}</p>
                )}
              </div>
              {/* <div className="form-field form-field-white has-error">
                <div className="form-input">
                  <input
                    name="password"
                    type="password"
                    placeholder="Confirm Password"
                    autoComplete="off"
                    defaultValue=""
                  />
                  <span className="inputborder" />
                </div>
              </div> */}
              <button type="submit" className="btn btn-white w-100 mt-6">
                {loading ? <Spinner /> : "Submit"}
              </button>
            </form>
            <p className="mt-6">
              Don't have an account?{" "}
              <Link className="link font-base color-white" to="/signup">
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ResetPassword;
