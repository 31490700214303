import { ADD_ALL_CONNECTIONS, CLEAR_CONNECTION_DATA } from "./actionTypes";

export const addAllConnections = (updates) => {
  return {
    type: ADD_ALL_CONNECTIONS,
    updates,
  };
};
export const clearConnectionData = () => {
  return {
    type: CLEAR_CONNECTION_DATA,
  };
};
