import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";

export const DatePickerForAdmin = ({ value, onChange, label, minDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={["MobileDatePicker"]}>
        <DemoItem label={label}>
          <MobileDatePicker
            value={value}
            onChange={(newValue) => onChange(newValue)}
            format="DD-MM-YYYY"
            maxDate={moment()}
            minDate={moment(minDate, "DD-MM-YYYY")}
            closeOnSelect={true}
            slotProps={{
              actionBar: {
                actions: [],
              },
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
};
