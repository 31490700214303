import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { clearAdminData } from "../store/admin/action";
import { logout } from "../store/auth/action";

export const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { firstName, lastName } = useSelector((state) => state.auth);
  const [isMinSidebar, setIsMinSidebar] = useState(false);

  const logoutHandler = (e) => {
    e.stopPropagation();
    dispatch(logout());
    dispatch(clearAdminData());

    navigate("/");
  };

  return (
    <div className={`admin-dashboard ${isMinSidebar ? "minSidebar" : ""}`}>
      <header className="adminHeader">
        <div className="header-root">
          {/* <button
            className="sideBarIcon"
            tabindex="0"
            type="button"
            aria-label="Open drawer"
          >
            <span className="MuiIconButton-label">
              <svg
                className="lineSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                role="presentation"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </svg>
            </span>
            <span className="MuiTouchRipple-root"></span>
          </button> */}
          <h6 className="LoginUserName ms-auto">
            {firstName} {lastName}
          </h6>
        </div>
      </header>
      <div className="adminSide-menu ">
        <div className="Leftmenu">
          <div className="sideBarLogo">
            <span className="logo-img">
              <img
                src="https://turnofr.stage04.obdemo.com/static/media/logo-dark.8ea90838.svg"
                alt="Turno"
              />
            </span>
            <button
              className="openSideBarButton"
              onClick={() => setIsMinSidebar((prev) => !prev)}
            >
              <span className="MuiIconButton-label">
                <svg
                  className="adminSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                </svg>
              </span>
              <span className="adminTouchRipple-root"></span>
            </button>
          </div>
          <hr className="sideDivider-root" />
          <ul className="MuiList-root MuiList-padding">
            <li>
              <NavLink to="/admin/statistics">
                <div className="Item-gutters">
                  <div className="adminItemIcon-root">
                    <svg
                      className="adminSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z"></path>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                    </svg>
                  </div>
                  <div className="adminListItemText">
                    <span className="sidebar-subheading">Statistics</span>
                  </div>
                  <span className="adminTouchRipple-root"></span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/users">
                <div className="Item-gutters">
                  <div className="adminItemIcon-root">
                    <svg
                      className="adminSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path>
                    </svg>
                  </div>
                  <div className="adminListItemText">
                    <span className="sidebar-subheading">Users</span>
                  </div>
                  <span className="adminTouchRipple-root"></span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink className="" to="/admin/feedback">
                <div className="Item-gutters">
                  <div className="adminItemIcon-root">
                    <svg
                      className="adminSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 12h-2v-2h2v2zm0-4h-2V6h2v4z"></path>
                    </svg>
                  </div>
                  <div className="adminListItemText">
                    <span className="sidebar-subheading">Feedback</span>
                  </div>
                  <span className="adminTouchRipple-root"></span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink className="" to="/admin/settings">
                <div className="Item-gutters">
                  <div className="adminItemIcon-root">
                    <svg
                      className="adminSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path
                        transform="scale(1.2, 1.2)"
                        fill="none"
                        d="M0 0h20v20H0V0z"
                      ></path>
                      <path
                        transform="scale(1.2, 1.2)"
                        d="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
                      ></path>
                    </svg>
                  </div>
                  <div className="adminListItemText">
                    <span className="sidebar-subheading">Settings</span>
                  </div>
                  <span className="adminTouchRipple-root"></span>
                </div>
              </NavLink>
            </li>
          </ul>
          <hr className="sideDivider-root" />
          <ul className="MuiList-root MuiList-padding">
            <li>
              <NavLink className="" to="/admin/profile">
                <div className="Item-gutters">
                  <div className="adminItemIcon-root">
                    <svg
                      className="adminSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                    </svg>
                  </div>
                  <div className="adminListItemText">
                    <span className="sidebar-subheading">Profile</span>
                  </div>
                  <span className="adminTouchRipple-root"></span>
                </div>
              </NavLink>
            </li>
            <li>
              <a>
                <div className="Item-gutters" onClick={(e) => logoutHandler(e)}>
                  <div className="adminItemIcon-root">
                    <svg
                      className="adminSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                    </svg>
                  </div>
                  <div className="adminListItemText">
                    <span className="sidebar-subheading">Log out</span>
                  </div>
                  <span className="adminTouchRipple-root"></span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {children}
    </div>
  );
};
