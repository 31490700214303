import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

import useRequest from "../hooks/useRequest";
import { Spinner } from "../Component/Svg";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
  } = useForm({ mode: "onBlur" });

  const recaptchaRef = useRef(null);
  const navigate = useNavigate();
  const { request, response, loading } = useRequest();

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success(response.message);
        navigate("/");
      } else {
        setError("notExist", { type: "notExist", message: response.message });
      }
    }
  }, [response]);

  const capchaOnChange = (value) => {
    if (value) {
      setValue("captcha", value);
      clearErrors("captcha", "");
    }
  };

  const onSubmit = (data) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue && process.env.NODE_ENV !== "development") {
      setError("captcha", {
        type: "required",
        message: "This field is mandatory.",
      });
      return;
    }
    if (!loading) {
      request("POST", "v1/auth/forgot-password", { ...data });
    }
  };

  return (
    <section className="fullcontent bgBlue">
      <div className="container">
        <div className="content">
          <div className="sign-up-content color-white">
            <a className="logo" href="/">
              <img src="/assets/img/LOGO.svg" alt="Turno" />
            </a>
            <h1 className="mt-6 mb-3">Forgot Password?</h1>
            <p className="color-white-70 text-center mb-6 line-height-1">
              We will send a reset password link to your email.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={
                  errors.email
                    ? "form-field form-field-white has-error"
                    : "form-field form-field-white"
                }
              >
                <div className="form-input">
                  <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    {...register("email", {
                      onChange: () => {
                        if (errors.notExist) {
                          clearErrors("notExist");
                        }
                      },
                      required: "This field is mandatory.",
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: "Please enter a valid email address.",
                      },
                      setValueAs: (v) => v.trim(),
                    })}
                  />
                  <span className="inputborder" />
                </div>
                {errors.email && (
                  <p className="error-msg">{errors.email.message}</p>
                )}
                {errors.notExist && (
                  <p className="error-msg has-error with-icon">
                    {errors.notExist.message}
                  </p>
                )}
              </div>
              <div className="form-field form-field-white recaptcha ">
                <div className="form-input">
                  <ReCAPTCHA
                    {...register("captcha", {
                      required:
                        process.env.NODE_ENV === "development"
                          ? false
                          : "This field is required",
                    })}
                    sitekey="6LdiIKcnAAAAACU0YUBLUkTPL7KLnYOSACGj7amD" // Client Key
                    // sitekey="6LfvWfUlAAAAAC_-0BdYf5KBxdx2vG0gtbAUvO3U" // V2 Old
                    // sitekey="6LcwR6UnAAAAAGJQmSerha3lGn3ps4mOH5epYNHo" // V3 New
                    ref={recaptchaRef}
                    onChange={capchaOnChange}
                  />
                  {/* <img src="/assets/img/recapatcha.png" alt="" /> */}
                </div>
                {errors.captcha && (
                  <p className="error-msg">{errors.captcha.message}</p>
                )}
              </div>
              <button
                type="submit"
                className="btn btn-white w-100 mtop-10 submitButton"
              >
                {loading ? <Spinner /> : "Send"}
              </button>
              <Link className="link d-block fw-200 mt-25 color-white-70" to="/">
                Log in
              </Link>
            </form>
            <p className="mt-6">
              Don't have an account?{" "}
              <Link className="link font-base color-white" to="/signup">
                Sign Up
              </Link>
              {/* <a className="link font-base color-white" href="signup.html">
                Sign Up
              </a> */}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
