import { ADD_ALL_CONNECTIONS, CLEAR_CONNECTION_DATA } from "./actionTypes";

const initialState = {
  connections: [],
};

const connectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALL_CONNECTIONS:
      return {
        ...state,
        connections: [...action.updates],
      };
    case CLEAR_CONNECTION_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default connectionReducer;
