import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { API } from "../constant/api";
import useRequest from "../hooks/useRequest";
import { logout } from "../store/auth/action";
import { clearCalendarData } from "../store/calendarData/action";
import { clearConnectionData } from "../store/connections/action";
import { clearNotificationData } from "../store/notifications/action";
import {
  classHandler,
  dotHandlerForCalendarView,
  getStartAndEndDate,
  shiftsImageHanlder,
  dotHandlerForCalendarViewTwo,
} from "../utils/fn";
import Picker from "./Picker";

import {
  Calendar,
  Close,
  Events,
  Help,
  Setting,
  SidebarToggleButton,
  Signout,
  Teams,
  Upgrade,
} from "./Svg";
import UpgradeMessageModal from "./UpgradeMessageModal";

const Sidebar = ({
  from,
  calendarRef,
  assignedShiftsRequest,
  currentMonthAndYearRef,
  setSelectedMonthAndYearForHeading,
  selectedMonthAndYearForHeading,
  defaultDateForPicker,
  setEvents,
  dateClickHandler,
  heading,
  currentDate,
}) => {
  const { allAssignedShifts, allAssignedEvents, allAssignedNotes } =
    useSelector((state) => state.calendar);
  const { firstName, lastName, profilePic, socket, role } = useSelector(
    (state) => state.auth
  );
  let currentMonth = moment().format("MM-YYYY");
  const { notifications, notificationCount } = useSelector(
    (state) => state.notifications
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUpgradeModalShow, setIsUpgradeModalShow] = useState(false);

  const { request: logoutRequest, response: logoutResponse } = useRequest();

  // useEffect(() => {
  //   if (from == "calendar") {
  //     notificationCountRequest("GET", "v1/notification/notifications-count");
  //   }
  // }, []);

  // useEffect(() => {
  //   if (notificationCountRespose) {
  //     const { notifications_count } = notificationCountRespose.data;
  //   }
  // }, [notificationCountRespose]);

  const monthAndYearRef = useRef();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle("scroll-off");
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.classList.remove("scroll-off");
  };

  const logoutHandler = (e) => {
    e.stopPropagation();
    dispatch(logout());
    dispatch(clearCalendarData());
    dispatch(clearNotificationData());
    dispatch(clearConnectionData());
    logoutRequest("GET", "v1/auth/logout");
    navigate("/");
    if (socket) {
      socket.disconnect();
    }
  };

  const onChange = useCallback(
    (e) => {
      let value = e.target.value;
      setSelectedMonthAndYearForHeading(value);
      let gotoDateValue = `${value} 1`;
      currentMonthAndYearRef.current = moment(
        gotoDateValue,
        "MMMM YYYY D"
      ).format("MM-YYYY");
      gotoDateValue = moment(gotoDateValue, "MMMM YYYY D").format("YYYY-MM-DD");
      dateClickHandler(gotoDateValue, true);
      let calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(gotoDateValue);

      classHandler(gotoDateValue);
      let newShift = allAssignedShifts.map((s) => ({
        start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
        image_url: shiftsImageHanlder(
          s.date,
          s?.shift?.start_period,
          currentMonthAndYearRef.current
        ),
      }));

      setEvents(newShift);

      if (from == "calendar") {
        dotHandlerForCalendarViewTwo(
          allAssignedEvents,
          allAssignedNotes,
          notifications,
          allAssignedShifts.filter((s) => s?.shift?.is_shifted),
          currentMonthAndYearRef.current,
          setEvents
        );
      } else {
        dotHandlerForCalendarView(
          allAssignedShifts.filter((s) => s?.shift?.is_shifted),
          setEvents,
          currentMonthAndYearRef.current,
          "green"
        );
      }

      if (false && from == "calendar") {
        dotHandlerForCalendarView(
          allAssignedEvents,
          setEvents,
          currentMonthAndYearRef.current,
          "purple"
        );
        dotHandlerForCalendarView(
          allAssignedNotes,
          setEvents,
          currentMonthAndYearRef.current,
          "yellow"
        );
        dotHandlerForCalendarView(
          notifications,
          setEvents,
          currentMonthAndYearRef.current,
          "red"
        );
      }

      // assignedShiftsRequest(
      //   "GET",
      //   `v1/event/calender-events?start_date=${startDate}&end_date=${endDate}`
      // );
      // dateClickHandler(value);
    },
    [allAssignedShifts, allAssignedEvents, allAssignedNotes, notifications]
  );

  const navigationHandler = (from, to, date) => {
    if (role == "blocked_user") {
      setIsUpgradeModalShow(true);
    } else {
      navigate(to, { state: { from, date } });
    }
  };

  return (
    <>
      <div className="headerContant">
        <header id="header">
          <div className="container">
            <div className="row m-0">
              <div className="col-12 p-0">
                <nav className="navbar navbar-expand-lg ">
                  <div
                    className={isMenuOpen ? "overlay overlay-show" : "overlay"}
                    onClick={closeMenu}
                  >
                    {" "}
                  </div>
                  <div
                    className={`collapse navbar-collapse${
                      isMenuOpen ? " menu-show" : ""
                    }`}
                  >
                    <div className="userSide main-menu ">
                      <span
                        className="sideMenuClose cursor"
                        onClick={closeMenu}
                      >
                        <i class="icon-close"></i>
                      </span>
                      <div className="user-img w80">
                        {profilePic ? (
                          <img
                            className="img"
                            src={`${API.IMG}/${profilePic}`}
                            alt=""
                          />
                        ) : (
                          <i className="icon-user"></i>
                        )}
                      </div>
                      <p className="user-name">{`${firstName} ${lastName}`}</p>
                    </div>
                    <ul
                      className="menu-list new_menu-list navbar-nav"
                      onClick={closeMenu}
                    >
                      <li className="nav-item cursor">
                        <NavLink to="/calendar" className="nav-link">
                          <span className="menu-label-icon">
                            <i class="icon-calendar-o color-white"></i>
                          </span>
                          <span className="menuNme">Calendar</span>
                        </NavLink>
                      </li>

                      <li className="nav-item cursor">
                        <NavLink to="/team" className="nav-link ">
                          <span className="menu-label-icon">
                            <i class="icon-users color-white"></i>
                          </span>
                          <span className="menuNme">Team</span>
                        </NavLink>
                      </li>
                      <li className="nav-item cursor">
                        <NavLink to="/events" className="nav-link ">
                          <span className="menu-label-icon">
                            <i class="icon-events color-white"></i>
                          </span>
                          <span className="menuNme">Events</span>
                        </NavLink>
                      </li>
                      <li className="nav-item cursor">
                        <NavLink to="/settings" className="nav-link ">
                          <span className="menu-label-icon">
                            <i class="icon-settings color-white"></i>
                          </span>
                          <span className="menuNme">Settings</span>
                        </NavLink>
                      </li>
                      <li className="nav-item cursor">
                        <a
                          href="http://help.turnoapp.com"
                          target="_blank"
                          className="nav-link "
                        >
                          <span className="menu-label-icon">
                            <i class="icon-help color-white"></i>
                          </span>
                          <span className="menuNme">Help</span>
                        </a>
                      </li>
                      <li className="nav-item cursor">
                        <NavLink to="/feedback" className="nav-link ">
                          <span className="menu-label-icon">
                            <i class="icon-feedback color-white"></i>
                          </span>
                          <span className="menuNme">Feedback</span>
                        </NavLink>
                      </li>
                      <li className="nav-item cursor">
                        <NavLink
                          to="/upgrade-membership"
                          className="nav-link  "
                        >
                          <span className="menu-label-icon">
                            <i class="icon-upgrade-membership color-white"></i>
                          </span>
                          <span className="menuNme">Upgrade</span>
                        </NavLink>
                      </li>
                      <li className="nav-item cursor" onClick={logoutHandler}>
                        <a className="nav-link  ">
                          <span className="menu-label-icon">
                            <i class="icon-sign-out color-white"></i>
                          </span>
                          <span className="menuNme">Sign Out</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* For Mobile */}
                  {/*  ======= User DropDown =========  */}
                  {/* <div class="nav-item dropdown user_dropdown">
                                  <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="user-drop" role="button"
                                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <img src="img/user.png" alt="">
                                  </a>
                                  <div class="dropdown-menu" aria-labelledby="user-drop">
                                      <div class="user_info">
                                          <div class="user_name">
                                              <div>Alex Willson</div>
                                              <div class="user_email">
                                                  <small>alexwillson@gmail.com</small>
                                              </div>
                                          </div>
                                          <ul>
                                              <li>
                                                  <a href="#!"><i class="ion-android-person"></i> My Profile</a>
                                              </li>
                                              <li>
                                                  <a href="#!"><i class="ion-log-out"></i> Logout</a>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div> */}
                </nav>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className="InnerPageHead bg-white calendar-toolbar">
        <div
          className={`extra_nav for_mobile ${
            from == "settings" ? "light_text" : ""
          } `}
        >
          <button
            className={`navbar-toggler menu-icon${
              isMenuOpen ? " menu-opened" : ""
            }`}
            type="button"
            onClick={toggleMenu}
          >
            <i className="icon-menu font-sm icon-shadow"></i>
          </button>
          {["calendar", "team"].includes(from) ? (
            <span className="pageHeadTitle">
              <div className="time-picker">
                <div className="time">
                  <Picker
                    format={"MMMM YYYY"}
                    timeRef={monthAndYearRef}
                    onChange={onChange}
                    defaultDateForPicker={defaultDateForPicker}
                  />
                  <a
                    href="javascript:void(0)"
                    className="calendar-date js-date-picker"
                    onClick={() => monthAndYearRef.current.click()}
                  >
                    {selectedMonthAndYearForHeading}
                    <i class="icon-caret-down"></i>
                  </a>
                </div>
              </div>
              {/* <p className="selected-time">{value}</p> */}
              {/* <a
                href="/"
                className="calendar-date js-date-picker"
                value="May 2023"
              ></a> */}
            </span>
          ) : (
            <span class="pageHeadTitle">{heading}</span>
          )}
          {from == "calendar" && (
            <ul className="calendar-menu">
              <li className="nav-item">
                <button
                  onClick={() =>
                    navigationHandler("/calendar", "/switch-shift", currentDate)
                  }
                  // state={{ from: "/calendar", date: currentDate }}
                  className="btn-icon"
                >
                  <i className="icon-switch-horizontal font-xl icon-shadow" />
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() =>
                    navigationHandler("/calendar", "/request-invite")
                  }
                  // state={{ from: "/calendar" }}
                  className="btn-icon"
                >
                  <i className="icon-share font-md icon-shadow" />
                </button>
              </li>
              <li className="nav-item">
                <Link
                  to="/notifications-list"
                  // href="notifications-list.html"
                  className="notifications-btn btn-icon"
                >
                  <i className="icon-notification font-xmd icon-shadow" />
                  {notificationCount ? <span>{notificationCount}</span> : ""}
                </Link>
              </li>
            </ul>
          )}
          {from == "team" && (
            <ul className="calendar-menu">
              <li className="nav-item">
                <Link
                  to="/connections"
                  state={{ from: "team" }}
                  // className="btn btn-link color-primary no-padding"
                  style={{
                    color: "#0070bf",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  Team View
                </Link>
              </li>
            </ul>
          )}
        </div>

        {isUpgradeModalShow && (
          <UpgradeMessageModal
            isShowModal={isUpgradeModalShow}
            setIsShowModal={setIsUpgradeModalShow}
          />
        )}
      </div>
    </>
  );
};

export default Sidebar;
