import { useEffect, useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";

import { Clock, DeleteIcon } from "../../Component/Svg";
import useRequest from "../../hooks/useRequest";
import { useDispatch, useSelector } from "react-redux";
import { addAllAssignedEvents } from "../../store/calendarData/action";
import Icons from "../../Component/Icons";
import UpgradeBanner from "../../Component/UpgradeBanner";

const DayPersonalEvents = () => {
  const { allAssignedEvents } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [eventDetailModalShow, setEventDetailModalShow] = useState(false);
  const [eventDataForModal, setEventDataForModal] = useState({});
  const [eventData, setEventData] = useState([]);
  const {
    request: eventDeleteOnDateRequest,
    response: eventDeleteOnDateResponse,
  } = useRequest();
  const { request, response } = useRequest();
  const navigate = useNavigate();

  useEffect(() => {
    if (allAssignedEvents.length > 0) {
      let eventOnADate = allAssignedEvents.filter(
        (data) => data.date == moment(id, "YYYY-MM-DD").format("DD/MM/YYYY")
      );
      if (eventOnADate) {
        setEventData(eventOnADate);
      } else {
        request("GET", `v1/event/events-on-date/${id}`);
      }
    } else {
      request("GET", `v1/event/events-on-date/${id}`);
    }
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        setEventData(response.data?.events);
      }
    }
  }, [response]);

  const eventDeleteOnDateHandler = (id) => {
    setEventDetailModalShow(false);
    let newEventData = eventData.filter((e) => e.id_event_date != id);
    setEventData(newEventData);

    // set Redux state
    const newEvents = allAssignedEvents.filter((a) => a.id_event_date != id);
    dispatch(addAllAssignedEvents(newEvents));
    if (id) {
      eventDeleteOnDateRequest("DELETE", `v1/event/event-date-delete/${id}`);
    }
  };

  const eventModalOpenHandler = (id) => {
    setEventDetailModalShow(true);
    let eventDataModal = eventData.find((e) => e.id_event_date == id);
    if (eventDataModal) {
      setEventDataForModal(eventDataModal);
    }
  };

  // onClick={() =>
  //   navigate("/calendar", {
  //     state: {
  //       date: location.state?.date,
  //     },
  //   })
  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5"
          // to="/calendar"
          onClick={() => {
            navigate("/calendar", {
              state: {
                date: id,
              },
            });
          }}
        >
          <i className="fas fa-arrow-left"></i>
        </span>
        <h1>Personal Events</h1>
      </div>

      <div className="page-section">
        <div className="container">
          <div className="notificationList">
            <div className="p-3">
              <div className="list-wrap">
                <div>
                  <ul className="notiListUl">
                    <p className="color-text-50 fw-500 mb-2">
                      {moment(id).format("MMM Do YYYY")}
                    </p>
                    {eventData.length > 0 &&
                      eventData.map((e) => (
                        <li
                          className="cursor"
                          onClick={() =>
                            eventModalOpenHandler(e?.id_event_date)
                          }
                        >
                          <a className="notiListCard">
                            <span className="notiCard-label bg-purple"></span>
                            <div className="notiCard-body">
                              <p className="font-md notiTitile mb-1">Event</p>
                              <p className="color-text-70 font-base NotiStatus">
                                <span className="ellipsis">{e.event_name}</span>
                              </p>
                            </div>
                          </a>
                        </li>
                      ))}
                    {/* <li>
                      <a className="notiListCard">
                        <span className="notiCard-label bg-purple"></span>
                        <div className="notiCard-body">
                          <p className="font-md fw-500 mb-1">Event</p>
                          <p className="color-text-70 font-base NotiStatus">
                            <span className="ellipsis">
                              Invitation accepted
                            </span>
                          </p>
                        </div>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={eventDetailModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        // backdrop="static"
        onHide={() => setEventDetailModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setEventDetailModalShow(false)}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span
                className={`${
                  !eventDataForModal?.is_default ? "icon-font" : ""
                } label-icon bg-purple view_design`}
              >
                {/* <span className="label-icon bg-purple view_design"> */}
                <i
                  className={`${
                    eventDataForModal?.is_default
                      ? `icon-${
                          Icons[eventDataForModal.icon_name]
                        } icon-font-size`
                      : "fas fa-ellipsis-h"
                  } color-white box-30`}
                />
              </span>
              <div className="inner_header_view">
                <p className="font-lg fw-500 mb10 line-height-1">Event</p>
                <p className="color-text-70 text-capitalize">
                  {/* {eventDataForModal?.icon} */}{" "}
                  {eventDataForModal?.event_name}
                </p>
              </div>
            </div>

            <div className="single-view-body">
              <p className="font-md fw-700 mb-4 text-capitalize">
                {eventDataForModal?.icon_name}
              </p>

              <ul className="list-with-icons">
                <li>
                  <Clock />
                  <span className="color-text-50 w-70">Start Time:</span>
                  <span className="color-text-70">
                    {moment(eventDataForModal?.start_time).format("hh:mm A")}
                  </span>
                </li>
                <li>
                  {" "}
                  <Clock />
                  <span className="color-text-50 w-70">End Time:</span>
                  <span className="color-text-70">
                    {moment(eventDataForModal?.end_time).format("hh:mm A")}
                  </span>
                </li>
              </ul>
            </div>
            <div
              className="single-view-footer"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <Link
                className="btn-icon w-30 color-text"
                to={`/edit-event/${eventDataForModal?.id_event_date}`}
              >
                <i className="icon-edit icon-shadow blue-color"></i>
              </Link>
              <a
                className="btn-icon w-30 color-warning cursor"
                onClick={() =>
                  eventDeleteOnDateHandler(eventDataForModal?.id_event_date)
                }
              >
                {/* <i className="fas fa-trash" /> */}
                <DeleteIcon />
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default DayPersonalEvents;
