import moment from "moment";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";

import { DatePickerForAdmin } from "../../Component/DatePickerForAdmin";
import { AdminLayout } from "../../Component/AdminLayout";
import {
  FormControl,
  MenuItem,
  Select,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import useRequest from "../../hooks/useRequest";

const rows = [
  // { id: "number", label: "#", minWidth: false },
  { id: "created_at", label: "Created Date", minWidth: false },
  { id: "first_name", label: "User name", minWidth: false },
  { id: "email", label: "Email address", minWidth: false },
  { id: "title", label: "Title", minWidth: false },
  { id: "mobile_number", label: "Phone number", minWidth: false },
  { id: "birthday", label: "Birthday", minWidth: false },
  // { id: "gender", label: "Gender", minWidth: false },
  { id: "pricing_plan", label: "Pricing plan", minWidth: false },
  { id: "role_name", label: "Role", minWidth: false },
  { id: "login_number", label: "Login number", minWidth: false },
  { id: "invite_sent_number", label: "Invite sent number", minWidth: true },
  { id: "login_duration", label: "Login duration", minWidth: false },
  { id: "connections_number", label: "Connections number", minWidth: false },
  { id: "remaining_trial_days", label: "Days Left in Trial", minWidth: false },
  { id: "is_active", label: "Status", minWidth: false },


  
];

const queryHanlder = (
  orderBy = "desc",
  sortBy = "id_user",
  page = 1,
  rowsPerPage = 10,
  fromDate = "",
  toDate = "",
  searchValue = ""
) => {
  if (fromDate) {
    fromDate = moment(fromDate).format("YYYY-MM-DD");
  }
  if (toDate) {
    toDate = moment(toDate).format("YYYY-MM-DD");
  }

  return `v1/admin/users-dashboard?from_date=${fromDate}&to_date=${toDate}&search=${searchValue}&page=${page}&items_per_page=${rowsPerPage}&sort_by=${sortBy}&order_by=${orderBy}`;
};

const AdminUsers = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [isFirstTimeSearch, setIsFirstTimeSearch] = useState(true);

  const [sortableItem, setSortableItem] = useState({
    orderBy: "desc",
    sortBy: "id_user",
    page: 1,
    rowsPerPage: 10,
  });

  const [isError, setIsError] = useState({
    toDate: null,
    fromDate: null,
  });

  const { request, response } = useRequest();
  const { request: changeRoleRequest, response: changeRoleResponse } =
    useRequest();

  useEffect(() => {
    request(
      "GET",
      queryHanlder(
        sortableItem.orderBy,
        sortableItem.sortBy,
        sortableItem.page,
        sortableItem.rowsPerPage,
        fromDate,
        toDate
      )
    );
    document.body?.classList?.remove("scroll-off");
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { total_items, users, page } = response.data;
        setTotalUsers(total_items);
        setUsers(users);
        setAllUsers(users);
        setSortableItem((prev) => ({ ...prev, page }));
      }
    }
  }, [response]);

  const sortableItemClickHandler = (sortBy, prevOrder) => {
    if (sortBy == sortableItem.sortBy) {
      let newOrder = prevOrder == "asc" ? "desc" : "asc";
      setSortableItem((prev) => ({ ...prev, sortBy, orderBy: newOrder }));
      request(
        "GET",
        queryHanlder(
          newOrder,
          sortBy,
          sortableItem.page,
          sortableItem.rowsPerPage,
          fromDate,
          toDate,
          searchValue
        )
      );
    } else {
      setSortableItem((prev) => ({ ...prev, sortBy, orderBy: "desc" }));
      request(
        "GET",
        queryHanlder(
          "desc",
          sortBy,
          sortableItem.page,
          sortableItem.rowsPerPage,
          fromDate,
          toDate,
          searchValue
        )
      );
    }
  };

  const pageChangeHandler = (e, page) => {
    request(
      "GET",
      queryHanlder(
        sortableItem.orderBy,
        sortableItem.sortBy,
        page + 1,
        sortableItem.rowsPerPage,
        fromDate,
        toDate,
        searchValue
      )
    );
  };

  const roleChangeHandler = (e, id) => {
    const newRole = e.target.value;
    const oldUsers = [...users];
    const newUser = oldUsers.map((user) => {
      if (user.id_user == id) {
        user.role = newRole;
      }
      return user;
    });
    setUsers(newUser);
    changeRoleRequest("POST", "v1/admin/update-role", {
      id_user: id,
      role: newRole,
    });
  };

  const fromDateOnchange = (value) => {
    if (toDate && toDate.isBefore(value)) {
      setIsError((prev) => ({
        ...prev,
        toDate: "Date should not be before minimal date",
      }));
      return;
    } else {
      setIsError((prev) => ({
        ...prev,
        toDate: null,
      }));
    }
    request(
      "GET",
      queryHanlder(
        sortableItem.orderBy,
        sortableItem.sortBy,
        sortableItem.page,
        sortableItem.rowsPerPage,
        value,
        toDate ? toDate : "",
        searchValue
      )
    );
    setFromDate(value);
  };
  const toDateOnchange = (value) => {
    if (fromDate && value.isBefore(fromDate)) {
      setIsError((prev) => ({
        ...prev,
        toDate: "Date should not be before minimal date",
      }));
      return;
    } else {
      setIsError((prev) => ({
        ...prev,
        toDate: null,
      }));
    }
    request(
      "GET",
      queryHanlder(
        sortableItem.orderBy,
        sortableItem.sortBy,
        sortableItem.page,
        sortableItem.rowsPerPage,
        fromDate ? fromDate : "",
        value,
        searchValue
      )
    );
    setToDate(value);
  };

  const searchHandler = () => {
    setIsFirstTimeSearch(false);
    request(
      "GET",
      queryHanlder(
        sortableItem.orderBy,
        sortableItem.sortBy,
        1,
        sortableItem.rowsPerPage,
        fromDate,
        toDate,
        searchValue
      )
    );
  };

  const searchInputOnchange = (e) => {
    if (e.target.value == "" && !isFirstTimeSearch) {
      request(
        "GET",
        queryHanlder(
          sortableItem.orderBy,
          sortableItem.sortBy,
          sortableItem.page,
          sortableItem.rowsPerPage,
          fromDate,
          toDate,
          e.target.value
        )
      );
    }
    setSearchValue(e.target.value);
  };

  return (
    <AdminLayout>
      <div className="mainContent">
        {/* <div className="adminCard sm"> */}
        <div className="adminCard add_full_size">
          <div className="StatisticsPage">
            <div className="inCardHeader">
              <div className="jss325">
                <h6 className="adminCardTitle-h6">Users</h6>
              </div>

              <div className="toolbar-filters-input">
                <div className="toolbar-filters">
                  <div className="datepickers d-flex">
                    <div className="date-picker date-picker-icon">
                      <DatePickerForAdmin
                        value={fromDate}
                        // setValue={setFromDate}
                        onChange={fromDateOnchange}
                        label="From"
                      />
                    </div>
                    <div className="date-picker date-picker-icon">
                      <DatePickerForAdmin
                        value={toDate}
                        // setValue={setToDate}
                        onChange={toDateOnchange}
                        minDate={fromDate}
                        label="To"
                      />
                      {isError.toDate && (
                        <p className="error-msg">{isError.toDate}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="search-icon-box">
                  <input
                    className="search-icon-btn"
                    placeholder="Search..."
                    onChange={searchInputOnchange}
                  />{" "}
                  <div className="svg-block">
                    <SearchIcon className="cursor" onClick={searchHandler} />
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-table-wrapper">
              <div className="table-responsive tabl_height">
                <Table
                  className="table expandable-table"
                  aria-labelledby="tableTitle"
                >
                  <TableHead>
                    <TableRow>
                      {rows.map((row) => (
                        <TableCell
                          key={row.id}
                          padding="default"
                          className={row.minWidth ? "min-width" : ""}
                          sortDirection={
                            sortableItem.sortBy === row.id ? "desc" : false
                          }
                        >
                          <Tooltip
                            title="Sort"
                            placement={
                              row.numeric ? "bottom-end" : "bottom-start"
                            }
                            enterDelay={300}
                          >
                            <TableSortLabel
                              active={sortableItem.sortBy === row.id}
                              direction={sortableItem.orderBy}
                              onClick={() =>
                                sortableItemClickHandler(
                                  row.id,
                                  sortableItem.orderBy
                                )
                              }
                            >
                              {row.label}
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.length > 0 ? (
                      users.map((user) => (
                        <TableRow hover key={user.id_user}>
                          {/* <TableCell className="nowrap">1</TableCell> */}
                          <TableCell>{user.created_at}</TableCell>

                          <TableCell className="nowrap">
                            {user.first_name} {user.last_name}
                          </TableCell>
                          <TableCell className="nowrap">{user.email}</TableCell>
                          <TableCell>{user.title ? user.title : "-"}</TableCell>
                          <TableCell>{user.mobile_number ? user.mobile_number : "-"}</TableCell>
                          <TableCell>{user.birthday ? user.birthday : "-"}</TableCell>
                          <TableCell>{
                            user.pricing_plan === "month" ? "Monthly" :
                            user.pricing_plan === "year" ? "Yearly" : "-"}             
                            
                          </TableCell>



                          
                          {/* <TableCell className="text-capitalize">
                            {user.gender}
                          </TableCell> */}


                          <TableCell className="nowrap">
                            <FormControl>
                              <Select
                                value={user.role}
                                onChange={(e) =>
                                  roleChangeHandler(e, user.id_user)
                                }
                                inputProps={{ name: "role" }}
                              >
                                <MenuItem
                                  disabled={
                                    user.role === "trial_user" ||
                                    user.role === "blocked_user"
                                      ? true
                                      : null
                                  }
                                  value={"blocked_user"}
                                >
                                  blocked
                                </MenuItem>
                                <MenuItem
                                  disabled={
                                    user.role === "trial_user" ? true : null
                                  }
                                  value={"trial_user"}
                                >
                                  trial
                                </MenuItem>
                                <MenuItem
                                  disabled={
                                    user.role === "ambassador_user"
                                      ? true
                                      : null
                                  }
                                  value={"ambassador_user"}
                                >
                                  ambassador
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>

                          <TableCell>
                            {user.total_login_count
                              ? user.total_login_count
                              : "-"}
                          </TableCell>
                          <TableCell>{user.invitation_sent_count}</TableCell>
                          <TableCell>
                            {user.avg_login_duration
                              ? user.avg_login_duration
                              : "-"}
                          </TableCell>
                          <TableCell>{user.total_connection_count}</TableCell>
                          <TableCell>{user.remaining_trial_days ? user.remaining_trial_days : 0}</TableCell>
                          <TableCell>{user.is_active ? "Activated" : "Not Activated"}</TableCell>


                          
                          
                        </TableRow>
                      ))
                    ) : (
                      <TableRow style={{ height: "100%" }}></TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
            <TablePagination
              className={"pagination_box"}
              labelRowsPerPage=""
              rowsPerPageOptions={[]}
              component="div"
              count={totalUsers}
              rowsPerPage={sortableItem.rowsPerPage}
              page={sortableItem.page - 1}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onPageChange={(e, page) => pageChangeHandler(e, page)}
              onRowsPerPageChange={(e) => console.log(e, "onRowsPerPageChange")}
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminUsers;
