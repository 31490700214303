export const ADD_ASSIGNED_SHIFTS = "ADD_ASSIGNED_SHIFTS";
export const ADD_EVENT = "ADD_EVENT";
export const REMOVE_EVENT = "REMOVE_EVENT";
export const Is_Calendar_Data_Exist = "Is_Calendar_Data_Exist";
export const ADD_ASSIGNED_EVENTS = "ADD_ASSIGNED_EVENTS";
export const ADD_ASSIGNED_NOTES = "ADD_ASSIGNED_NOTES";
export const ADD_WORKPLACES = "ADD_WORKPLACES";
export const ADD_ONE_WORKPLACES = "ADD_ONE_WORKPLACES";
export const ADD_TEAM_SHIFTS = "ADD_TEAM_SHIFTS";
export const CLEAR_CALENDAR_DATA = "CLEAR_CALENDAR_DATA";
