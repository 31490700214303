import { ADD_STATISTICS_DATA, CLEAR_ADMIN_DATA } from "./actionTypes";

const initialState = {
  statistics: {},
  //   notificationCount: 0,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STATISTICS_DATA:
      return {
        ...state,
        statistics: { ...action.payload },
      };
    case CLEAR_ADMIN_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default adminReducer;
