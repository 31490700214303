import InputBase from "@mui/material/InputBase";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import TablePagination from "@mui/material/TablePagination";

import Sidebar from "../../Component/Sidebar";
import { Spinner } from "../../Component/Svg";
import useRequest from "../../hooks/useRequest";
import { useSelector } from "react-redux";
import UpgradeBanner from "../../Component/UpgradeBanner";

const Feedback = () => {
  const { role } = useSelector((state) => state.auth);

  const { request, response, loading } = useRequest();
  const [isSubmited, setIsSubmited] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    if (response) {
      setIsSubmited(true);
    }
  }, [response]);

  const feedbackSubmitHandler = (data) => {
    const { text } = data;
    request("POST", "v1/auth/add-feedback", {
      text: text.trim(),
    });
  };

  return (
    <>
      <div class="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
        <div className="headerContant"></div>
        <div class="setting_header">
          <Sidebar from="settings" heading="Feedback" />
          <div class="content-header-bottom">
            <i class="icon-feedback settings_icon"></i>
          </div>
        </div>
        <div className="content-body">
          {!isSubmited ? (
            <div className="list-wrap">
              <form onSubmit={handleSubmit(feedbackSubmitHandler)}>
                <div className="profile-info feedback-content">
                  <div className="form-field">
                    <p className="form-label color-text-50 fw-500 mb-4">
                      Leave Feedback
                    </p>
                    <div className="form-input">
                      {/* <textarea 
                      class="form-input w-100 border-bottom autosize"
                      placeholder="Enter Your Feedback"
                    ></textarea> */}

                      <Controller
                        name="text"
                        control={control}
                        rules={{
                          maxLength: {
                            value: 250,
                            message:
                              "The text entered exceeds the maximum length.",
                          },
                          required: {
                            value: true,
                            message: "This field is mandatory.",
                          },
                        }}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <InputBase
                              className="form-input w-100 border-bottom textareaBorder"
                              multiline
                              placeholder="Enter Your Feedback"
                              rowsMax={6}
                              value={value}
                              onChange={onChange}
                            />
                          );
                        }}
                      />

                      <span className="inputborder"></span>
                    </div>
                    {errors.text && (
                      <p className="error-msg with-icon has-error">
                        {errors.text.message}
                      </p>
                    )}
                    <div class="invitee_will">
                      The maximum length of a message is 250 characters.
                    </div>
                  </div>
                  <a
                    href="https://www.facebook.com/messages/t/276040549885946"
                    // onClick={() => {
                    //   logEvent("click Join the conversation on Facebook");
                    // }}
                    target="_blank"
                    className="btn btn-link no-padding color-primary share-link"
                  >
                    <img src="/assets/img/messenger.png" alt="Messenger icon" />
                    <span className="fw-500">
                      Join the conversation on Facebook
                    </span>
                  </a>
                  <div className="content-footer justify-content-end bottomSingalBtn">
                    <button
                      type="submit"
                      className="btn-white formBtn mr-6"
                      id="nextBtn"
                      // onClick={feedbackSubmitHandler}
                    >
                      {loading ? <Spinner /> : "Send"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <>
              <div className="feedback_center ">
                <img
                  src="/assets/img/success.svg"
                  className="success_img"
                  alt="success icon"
                />
                <p class="font-md">You feedback was successfully submitted.</p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Feedback;
