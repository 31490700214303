import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment";
import "pickerjs/dist/picker.min.css";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import useRequest from "../../hooks/useRequest";
import { addHeadingOnPicker, minutesOfDay } from "../../utils/fn";
import RTimePicker from "../../Component/TimePicker";
import { useDispatch, useSelector } from "react-redux";
import { addAllAssignedEvents } from "../../store/calendarData/action";
import UpgradeBanner from "../../Component/UpgradeBanner";

const currentYear = moment().format("YYYY");
const currentMonth = moment().format("MM") - 1;
const currentDate = moment().format("DD");

const EditEvent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const { allAssignedEvents } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(0);

  const [startTime, setStartTime] = useState(null);
  const [isStartTimeOpen, setIsStartTimeOpen] = useState(false);
  const [isEndTimeOpen, setIsEndTimeOpen] = useState(false);
  const [newDate, setNewDate] = useState("");
  const [endTime, setEndTime] = useState(null);
  const [isDefaultObj, setIsDefaultObj] = useState({
    bool: false,
    error: false,
    eventName: null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
    getValues,
    watch,
  } = useForm();

  const { request, response } = useRequest();

  const { request: getEventDataRequest, response: getShiftDataResponse } =
    useRequest();

  useEffect(() => {
    getEventDataRequest("GET", `v1/event/eventdate/${id}`);
  }, []);

  useEffect(() => {
    if (getShiftDataResponse) {
      let { end_time, start_time, icon_name, is_default, event_id } =
        getShiftDataResponse?.data?.events;
      setNewDate(getShiftDataResponse?.data?.events?.date);
      setValue(
        "start_time",
        moment(start_time).set({
          year: currentYear,
          month: currentMonth,
          date: currentDate,
        })
      );
      setValue(
        "end_time",
        moment(end_time).set({
          year: currentYear,
          month: currentMonth,
          date: currentDate,
        })
      );
      start_time = moment(start_time).format("hh:mm A");
      end_time = moment(end_time).format("hh:mm A");
      setValue("icon_name", icon_name);
      setValue("event_id", event_id);
      setValue("is_default", is_default);
      setIsDefaultObj({
        bool: is_default,
        error: false,
        eventName: icon_name,
      });
      setStartTime(start_time);
      setEndTime(end_time);
    }
  }, [getShiftDataResponse]);

  useEffect(() => {
    if (currentTab == 1) {
      register("start_time", {
        required:
          currentTab == 1 &&
          "The Start Time and End Time must be selected to continue.",
      });
      register("end_time", {
        required:
          currentTab == 1 &&
          "The Start Time and End Time must be selected to continue.",
      });
    }
  }, [currentTab]);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { event_id, icon_name, start_time, end_time } =
          response.data.new_event_date;
        // let newEvents = allAssignedEvents.map((event) => {
        //   if (event.event_id == event_id) {
        //     event = { ...event, icon_name, start_time, end_time };
        //   }
        //   return event;
        // });
        // dispatch(addAllAssignedEvents(newEvents));

        // navigate("/calendar", {
        //   state: {
        //     date: location.state?.date,
        //   },
        // });
      }
    }
  }, [response]);

  const startTimeFunction = (event) => {
    clearErrors("start_time");
    setStartTime(moment(event._d).format("hh:mm A"));
    setValue("start_time", event._d);
  };

  const endTimeFunction = (event) => {
    clearErrors("start_time");
    setValue("end_time", event._d);
    setEndTime(moment(event._d).format("hh:mm A"));
  };

  const nextHandler = () => {
    if (currentTab === 1) {
      if (startTime === endTime) {
        //show Error
        setError("start_time", {
          type: "manual",
          message: "Please select a valid time.",
        });
      } else {
        setCurrentTab((prev) => prev + 1);
      }
    } else {
      setCurrentTab((prev) => prev + 1);
    }
  };

  function nextPrev(n) {
    if (n === "next") {
      setCurrentTab((prev) => prev + 1);
    } else if (n === "prev") {
      setCurrentTab((prev) => prev - 1);
    }
  }

  const editShiftHandlerSubmit = (data) => {
    let { end_time, icon_name, start_time, event_id, is_default } = data;

    if (
      startTime === endTime ||
      moment(endTime, "h:mma").isBefore(moment(startTime, "h:mma"))
    ) {
      //show Error
      setError("start_time", {
        type: "manual",
        message: "Please select a valid time.",
      });
      return;
    }
    start_time = moment(start_time);
    end_time = moment(end_time);

    const startTimeMinutes = minutesOfDay(start_time);
    const endTimeMinutes = minutesOfDay(end_time);

    if (startTimeMinutes > endTimeMinutes) {
      end_time = end_time.add({ days: 1 });
    }
    end_time = moment(end_time).format("YYYY-MM-DD HH:mm:00");
    start_time = moment(start_time).format("YYYY-MM-DD HH:mm:00");
    let body = {
      end_time,
      icon_name,
      start_time,
      id_event_date: parseInt(id),
      event_id,
      is_default,
    };

    let newEvents = allAssignedEvents.map((event) => {
      if (event.event_id == event_id) {
        if (is_default) {
          event = { ...event, icon_name, start_time, end_time };
        } else {
          event = { ...event, icon_name, start_time, end_time };
        }
      }
      return event;
    });
    dispatch(addAllAssignedEvents(newEvents));

    request("POST", `v1/event/event-eventdate-edit`, body);
    navigate("/calendar", {
      state: {
        date: location.state?.date
          ? location.state?.date
          : moment(newDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      },
    });
    // return;
  };

  const {
    name: iconName,
    onChange: iconOnChange,
    onBlur: iconOnBlur,
    ref: iconRef,
  } = { ...register("icon_name") };

  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="headerContant"></div>
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5 cursor"
          onClick={() =>
            navigate("/calendar", {
              state: {
                date: location.state?.date
                  ? location.state?.date
                  : moment(newDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
              },
            })
          }
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
        </span>
        <h1>Edit Event</h1>
      </div>
      {/* Section */}
      <div className="page-section">
        <div className="container">
          <div className="innerPageBox">
            <div className="MuiPaper-root MuiPaper-elevation0 MuiStepper-root MuiStepper-horizontal stepper-header">
              <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 0 || currentTab === 1 || currentTab === 2
                    ? "active"
                    : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span className="MuiStepLabel-labelContainer">
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label MuiStepLabel-active">
                      Name
                    </span>
                  </span>
                </span>
              </span>
              <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 1 || currentTab === 2 ? "active" : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal MuiStepLabel-disabled step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span className="MuiStepLabel-labelContainer">
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label">
                      Duration
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="stepper-Outbody h-10000">
              <form id="regForm">
                <div className="stepper-body">
                  <div className="stepper-content">
                    {currentTab === 0 ? (
                      <div className="tab" style={{ display: "block" }}>
                        <div className="form-field">
                          <div className="form-input">
                            <input
                              name={iconName}
                              type="TEXT"
                              placeholder="Event Name"
                              onChange={(e) => {
                                // if (isDefaultObj.bool) {
                                //   setIsDefaultObj((prev) => ({
                                //     ...prev,
                                //     error: true,
                                //   }));
                                //   setValue(iconName, isDefaultObj.eventName);
                                // }
                              }}
                              onBlur={iconOnBlur}
                              ref={iconRef}
                              // defaultValue=""
                              // {...register("icon_name", {
                              //   required:
                              //     currentTab == 0 && "This field is mandatory.",
                              //   // setValueAs: (v) => v.trim(),
                              // })}
                            />
                            <span className="inputborder" />
                          </div>
                          {errors.icon_name && (
                            <p className="error-msg">
                              {errors.icon_name.message}
                            </p>
                          )}
                          {isDefaultObj.error && (
                            <p className="error-msg">
                              You can change only date for default event.
                            </p>
                          )}
                        </div>
                      </div>
                    ) : currentTab === 1 ? (
                      <div
                        className="tab"
                        style={{ display: currentTab === 1 ? "block" : "none" }}
                      >
                        <div>
                          <div className="time-picker">
                            <div className="time">
                              <RTimePicker
                                open={isStartTimeOpen}
                                close={setIsStartTimeOpen}
                                onChange={startTimeFunction}
                                defaultValue={moment(getValues("start_time"))}
                              />

                              <button
                                type="button"
                                className="btn btn-white"
                                onClick={() => {
                                  setIsStartTimeOpen(true);
                                  addHeadingOnPicker();
                                }}
                              >
                                <i className="far fa-clock" />
                                <span>Start Time</span>
                              </button>
                              <p className="selected-time">{startTime}</p>
                            </div>
                            <div className="time">
                              <RTimePicker
                                open={isEndTimeOpen}
                                close={setIsEndTimeOpen}
                                onChange={endTimeFunction}
                                defaultValue={moment(getValues("end_time"))}
                              />
                              <button
                                type="button"
                                className="btn btn-white "
                                onClick={() => {
                                  setIsEndTimeOpen(true);
                                  addHeadingOnPicker();
                                }}
                              >
                                <i className="far fa-clock" />
                                <span>End Time</span>
                              </button>
                              <p className="selected-time">{endTime}</p>
                            </div>
                            {(errors.start_time || errors.end_time) && (
                              <p className="error-msg with-icon">
                                {errors.start_time?.message ||
                                  errors.end_time?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="content-footer" style={{ overflow: "auto" }}>
                    {currentTab == 0 && (
                      <button
                        type="button"
                        className="btn-link formBtn color-text-50"
                        id="prevBack"
                        onClick={() =>
                          navigate("/calendar", {
                            state: {
                              date: location.state?.date
                                ? location.state?.date
                                : moment(newDate, "DD/MM/YYYY").format(
                                    "YYYY-MM-DD"
                                  ),
                            },
                          })
                        }
                        // onClick={() => navigate("/calendar")}
                      >
                        Cancel
                      </button>
                    )}
                    {currentTab == 1 && (
                      <button
                        type="button"
                        className="btn-link formBtn color-text-50"
                        id="prevBtn"
                        onClick={() => nextPrev("prev")}
                      >
                        Back
                      </button>
                    )}
                    {currentTab == 0 && (
                      <button
                        type="submit"
                        className="btn-white formBtn"
                        id="nextBtn"
                        onClick={handleSubmit(nextHandler)}
                      >
                        Next
                      </button>
                    )}
                    {currentTab == 1 && (
                      <button
                        type="button"
                        className="btn-white formBtn"
                        id="nextBtn"
                        onClick={handleSubmit(editShiftHandlerSubmit)}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEvent;
