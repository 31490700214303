import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Component/Loader";
import Sidebar from "../../Component/Sidebar";
import { Spinner } from "../../Component/Svg";
import useRequest from "../../hooks/useRequest";
import {
  addCurrentSubscriptionData,
  changeCurrentSubscriptionData,
  upgradeMembership,
} from "../../store/auth/action";

const UpgradeMembership = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    cardInfo,
    currentSubscriptionData: {
      id_stripeplans: stripePlanId,
      subscription_id: subscriptionId,
      is_deleted: isCurrentPlanDeleted,
      current_period_end: currentPlanEndDate,
    },
    role,
  } = useSelector((state) => state.auth);

  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isPrevCardModalOpen, setIsPrevCardModalOpen] = useState(false);
  const [isCancelSubscriptionModal, setIsCancelSubscriptionModal] =
    useState(false);

  const [isFreePlanModalOpen, setIsFreePlanModalOpen] = useState(false);
  const [isFreeDetailedPlanModalOpen, setIsFreeDetailedPlanModalOpen] = useState(false);


  const [plans, setPlans] = useState([]);
  const [planForModal, setPlanForModal] = useState({});
  const [selectedStripePlanId, setSelectedStripePlanId] = useState(null);

  const { request, response } = useRequest();
  const {
    request: addSubscriptionRequest,
    response: addSubscriptionResponse,
    loading: addSubscriptionLoading,
  } = useRequest();
  const {
    request: upgradeSubscriptionRequest,
    response: upgradeSubscriptionResponse,
    loading: upgradeSubscriptionLoading,
  } = useRequest();

  const {
    request: cancelSubcriptionRequest,
    response: cancelSubcriptionResponse,
    loading: cancelSubcriptionLoading,
  } = useRequest();

  useEffect(() => {
    request("GET", "v1/stripe/plans");
  }, []);

  useEffect(() => {
    if (response) {
      const { stripe_plans } = response.data;
      setPlans(stripe_plans);
    }
  }, [response]);

  useEffect(() => {
    if (cancelSubcriptionResponse) {
      if (cancelSubcriptionResponse.status == "SUCCESS") {
        if (cancelSubcriptionResponse.data.status == "canceled") {
          setIsCancelSubscriptionModal(false);
          setIsFreePlanModalOpen(false);
          setIsFreeDetailedPlanModalOpen(false)
          dispatch(changeCurrentSubscriptionData(true));
          toast.success("Your plan has been canceled successfully.");
        }
      }
    }
  }, [cancelSubcriptionResponse]);

  const planGetStartedHandler = (id_stripeplans) => {
    setSelectedStripePlanId(id_stripeplans);
    if (Object.keys(cardInfo).length == 0) {
      navigate(`/payment/${id_stripeplans}`);
    } else {
      setIsPrevCardModalOpen(true);
    }
  };

  useEffect(() => {
    if (addSubscriptionResponse) {
      if (addSubscriptionResponse.status == "SUCCESS") {
        toast.success(addSubscriptionResponse.message);
        dispatch(addCurrentSubscriptionData(addSubscriptionResponse.data));
        dispatch(upgradeMembership("member_user"));
        navigate("/calendar");
      } else {
        setIsPlanModalOpen(false);
        setIsPrevCardModalOpen(false);
        toast.error(addSubscriptionResponse.message);
      }
    }
  }, [addSubscriptionResponse]);

  useEffect(() => {
    if (upgradeSubscriptionResponse) {
      if (upgradeSubscriptionResponse.status == "SUCCESS") {
        toast.success(upgradeSubscriptionResponse.message);
        const { subscription_id, id_stripeplans } =
          upgradeSubscriptionResponse.data;

        if (subscription_id && id_stripeplans) {
          dispatch(
            addCurrentSubscriptionData({
              subscriptionId: subscription_id,
              stripePlanId: id_stripeplans,
            })
          );
        }
        navigate("/calendar");
      } else {
        toast.error(upgradeSubscriptionResponse.message);
      }
    }
  }, [upgradeSubscriptionResponse]);

  const existingPaymentHandler = () => {
    // if (role == "member_user") {
    //   upgradeSubscriptionRequest("POST", "v1/stripe/update-subscription", {
    //     id_stripeplans: planForModal.id_stripeplans,
    //     subscription_id: subscriptionId,
    //   });
    // } else {
    let body = {
      id_stripeplans: planForModal.id_stripeplans,
    };
    if (!isCurrentPlanDeleted) {
      body.subscription_id = subscriptionId;
    }
    addSubscriptionRequest("POST", "v1/stripe/add-subscription", body);
    // }
  };

  const cancelPlanSubscriptionHandler = () => {
    cancelSubcriptionRequest("DELETE", "v1/stripe/cancel-subscription");
  };

  return (
    <>
      <div className="content-center membership_content">
        <div className="headerContant"></div>
        <div className="setting_header upgrade_header">
          <Sidebar from="upgrade-membership" heading="Upgrade Membership" />
          {/* <div className="content-header-bottom">
            <i className="icon-settings settings_icon"></i>
          </div> */}
        </div>
        <div className="content-body">
          <ul className="pricing-list">
            {plans.length > 0 && (
              <>
                <li
                  className="pricing-items"
                  onClick={() => {
                    if (!isCurrentPlanDeleted) {
                      if (role != "blocked_user") {
                        setIsFreePlanModalOpen(true);
                      } else {
                        setIsFreeDetailedPlanModalOpen(true) // new modal
                      }
                    }
                  }}
                >
                  <a className="pricing_box">
                    <div className="pricing-content">
                      <div
                        className="pricing-item blue"
                        // onClick={() => }
                      >
                        <span className="pricing-type d-flex">
                          {role == "blocked_user" && (
                            <span className="type current-plan">
                              Current Plan
                            </span>
                          )}
                          {/* <span className="type">Monthly</span> */}
                        </span>
                        <span className="membership">Free</span>
                        <p className="pric">
                          $ 0
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                {plans.map((plan) => (
                  <li
                    className="pricing-items"
                    onClick={() => {
                      if (isCurrentPlanDeleted) {
                        setIsPlanModalOpen(true);
                        setPlanForModal(plan);
                      } else if (stripePlanId != plan.id_stripeplans) {
                        setIsPlanModalOpen(true);
                        setPlanForModal(plan);
                      }
                    }}
                  >
                    <a className="pricing_box">
                      <div className="pricing-content">
                        <div
                          className={`pricing-item  ${
                            plan.product_name === "yearly"
                              ? "pink"
                              : plan.product_name === "monthly"
                              ? "blue-light"
                              : "blue"
                          } 
                          `}
                        >
                          <span className="pricing-type d-flex">
                            {/* {plan.product_name === "yearly" && (
                              <span className="type">Yearly</span>
                            )} */}
                            {plan.id_stripeplans == stripePlanId && (
                              <span className="type current-plan">
                                Current Plan
                              </span>
                            )}

                            {!isCurrentPlanDeleted
                              ? plan.id_stripeplans == stripePlanId && (
                                  <span
                                    className="type current-plan Cancel"
                                    onClick={() =>
                                      setIsCancelSubscriptionModal(true)
                                    }
                                  >
                                    Cancel Subscription
                                  </span>
                                )
                              : plan.id_stripeplans == stripePlanId && (
                                  <span className="type current-plan Cancel">
                                    Expired On :{" "}
                                    {moment(
                                      currentPlanEndDate,
                                      "YYYY-MM-DD"
                                    ).format("DD-MM-YYYY")}
                                  </span>
                                )}

                            {/* {plan.product_name === "monthly" && (
                              <span className="type">Monthly</span>
                            )} */}
                          </span>
                          <span className="membership">{`${
                            plan.product_name === "monthly"
                              ? "Monthly"
                              : plan.product_name === "yearly"
                              ? "Yearly"
                              : ""
                          }`}</span>

                          {/* <span className="membership text-capitalize">
                          premium
                        </span> */}
                          <p className="pric">
                            $
                            {plan.product_name === "yearly"
                              ? (plan.amount / 12).toFixed(2)
                              : plan.amount}
                            <span> / month</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
        <Modal
          show={isPlanModalOpen}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="thememodal thememodal2"
          onHide={() => {
            setIsPlanModalOpen(false);
          }}
        >
          <button
            type="button"
            className="btn-close"
            // data-bs-dismiss="modal"
            // aria-label="Close"

            onClick={() => {
              setIsPlanModalOpen(false);
            }}
          />
          <div
          // className="modal-content"
          >
            <div className="modal-body sure_modal_body p-0">
              <div className="pt-8 px-6">
                <div className="pricing_outer_box">
                  <a className="pricing_box">
                    <div className="pricing-content">
                      <div
                        className={`pricing-item  ${
                          planForModal.product_name === "yearly"
                            ? "pink"
                            : planForModal.product_name === "monthly"
                            ? "blue-light"
                            : "blue"
                        } 
                          `}
                      >
                        {/* <div className="pricing-type-modal">
                          {planForModal.product_name === "yearly" && (
                            <span className="type">Yearly</span>
                          )}

                          {planForModal.product_name === "monthly" && (
                            <span className="type">Monthly</span>
                          )}
                        </div> */}

                        <span className="membership">
                          {planForModal.product_name === "yearly" && "Yearly"}
                          {planForModal.product_name === "monthly" && "Monthly"}
                        </span>
                        <p className="pric">
                          $
                          {planForModal.product_name === "yearly"
                            ? (planForModal.amount / 12).toFixed(2)
                            : planForModal.amount}
                          <span> /month</span>
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="pricing_box_info">
                    <div className="pricing_info_title">
                      Welcome and Happy Switching!
                    </div>
                    {planForModal.product_name === "standard" ? (
                      <ul class="list-with-icons pricing_info_list">
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">
                            Organize your work schedule
                          </span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited shifts</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited events</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited notes</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited switches</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited giveaways</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Team Calendar</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Team switch suggestions</span>
                        </li>

                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Team giveaway suggestions</span>
                        </li>

                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">
                            Share calendar with friends and family
                          </span>
                        </li>
                      </ul>
                    ) : (
                      <ul class="list-with-icons pricing_info_list">
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">
                            Organize your work schedule
                          </span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited shifts</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited events</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited notes</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited switches</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited giveaways</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Team Calendar</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Team switch suggestions</span>
                        </li>

                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Team giveaway suggestions</span>
                        </li>

                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">
                            Share calendar with friends and family
                          </span>
                        </li>
                      </ul>
                    )}

                    <p class=" mt-3 text-center ">
                      {/* {`$ ${planForModal.amount} USD billed  ${
                        planForModal.product_name === "yearly"
                          ? "yearly"
                          : "monthly"
                      }`} */}

                      {planForModal.product_name === "yearly"
                        ? `$ ${planForModal.amount} USD billed yearly`
                        : `$ ${planForModal.amount} USD billed monthly`}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer shift_time_footer">
              <button
                type="button"
                className={`btn button_yes ${
                  planForModal.product_name === "yearly"
                    ? "yearly_premium"
                    : planForModal.product_name === "monthly"
                    ? "monthly_premium"
                    : "free_plan"
                } 
                  `}
                onClick={() =>
                  planGetStartedHandler(planForModal.id_stripeplans)
                }
              >
                Get Started
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          show={isPrevCardModalOpen}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="thememodal thememodal2"
          onHide={() => {
            setIsPrevCardModalOpen(false);
          }}
        >
          <button
            type="button"
            className="btn-close"
            // data-bs-dismiss="modal"
            // aria-label="Close"

            onClick={() => {
              setIsPrevCardModalOpen(false);
              // setDeleteEventId(null);
            }}
          />
          <div
          // className="modal-content"
          >
            <div className="modal-body sure_modal_body">
              <div className="text-center pt-8 px-6">
                {/* <p className="font-lg fw-700 mb-4">Are you sure?</p> */}
                <p className="font-md">
                  You're about to upgrade your plan. How would you like to pay
                  for this? Continue with Existing Payment Method / Add a New
                  Payment Method
                </p>
              </div>
            </div>
            <div className="modal-footer shift_time_footer otherBigBtn">
              <button
                type="button"
                className="btn button_yes"
                onClick={existingPaymentHandler}
              >
                {addSubscriptionLoading || upgradeSubscriptionLoading ? (
                  <Spinner />
                ) : (
                  "Buy with Existing payment method"
                )}
              </button>
              <button
                type="button"
                className="btn button_yes"
                onClick={() => navigate(`/payment/${selectedStripePlanId}`)}
              >
                Buy with New payment method
              </button>
            </div>
          </div>
        </Modal>

        {/* Cancel Subscription modal */}
        <Modal
          show={isCancelSubscriptionModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="thememodal thememodal2"
          onHide={() => {
            setIsCancelSubscriptionModal(false);
          }}
        >
          <button
            type="button"
            className="btn-close"
            onClick={() => setIsCancelSubscriptionModal(false)}
          />
          <div>
            <div className="modal-body sure_modal_body">
              <div className="text-center pt-8 px-6">
                {/* <p className="font-lg fw-700 mb-4">Are you sure?</p> */}
                <p className="font-md">
                  Are you sure you want to cancel this subscription ?
                </p>
              </div>
            </div>
            <div className="modal-footer shift_time_footer">
              <button
                type="button"
                className="btn button_no"
                onClick={() => setIsCancelSubscriptionModal(false)}
              >
                No
              </button>
              <button
                type="button"
                className="btn button_yes"
                onClick={cancelPlanSubscriptionHandler}
              >
                {cancelSubcriptionLoading ? <Spinner /> : "Yes"}
              </button>
            </div>
          </div>
        </Modal>

        {/* Free Plan Modal */}
        <Modal
          show={isFreePlanModalOpen}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="thememodal thememodal2"
          onHide={() => {
            setIsFreePlanModalOpen(false);
          }}
        >
          <button
            type="button"
            className="btn-close"
            onClick={() => setIsFreePlanModalOpen(false)}
          />
          <div>
            <div className="modal-body sure_modal_body">
              <div className="text-center pt-8 px-6">
                <p className="font-lg fw-700 mb-4">
                  {" "}
                  Are you sure you want to switch to the free plan?
                </p>
                <p className="font-md">
                  After this, your current plan will be canceled.
                </p>
              </div>
            </div>
            <div className="modal-footer shift_time_footer">
              <button
                type="button"
                className="btn button_no"
                onClick={() => setIsFreePlanModalOpen(false)}
              >
                No
              </button>
              <button
                type="button"
                className="btn button_yes"
                onClick={cancelPlanSubscriptionHandler}
              >
                {cancelSubcriptionLoading ? <Spinner /> : "Yes"}
              </button>
            </div>
          </div>
        </Modal>

        {/* Detailed Free Plan Modal */}

        <Modal
          show={isFreeDetailedPlanModalOpen}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="thememodal thememodal2"
          onHide={() => {
            setIsFreeDetailedPlanModalOpen(false);
          }}
        >
          <button
            type="button"
            className="btn-close"
            // data-bs-dismiss="modal"
            // aria-label="Close"

            onClick={() => {
              setIsFreeDetailedPlanModalOpen(false);
            }}
          />
          <div
          // className="modal-content"
          >
            <div className="modal-body sure_modal_body p-0">
              <div className="pt-8 px-6">
                <div className="pricing_outer_box">
                  <a className="pricing_box">
                    <div className="pricing-content">
                      <div
                        className={`pricing-item  blue`}
                      >
                        

                        <span className="membership">
                         Free
                        </span>
                        <p className="pric">
                          $ 0 
                          
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="pricing_box_info">
                    <div className="pricing_info_title">
                      Welcome and Happy Switching!
                    </div>

                      <ul class="list-with-icons pricing_info_list">
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">
                            Organize your work schedule
                          </span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited shifts</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited events</span>
                        </li>
                        <li>
                          <i class="icon-check font-xsm color-primary"></i>
                          <span class="fw-500">Unlimited notes</span>
                        </li>
                        
                      </ul>
                   

                    <p class=" mt-3 text-center ">
                      

                      Limited Time
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer shift_time_footer">
              <button
                type="button"
                className={`btn button_yes free_plan 
                  `}
                onClick={() =>
                  navigate("/calendar")
                }
              >
                Get Started
              </button>
            </div>
          </div>
        </Modal>


      </div>
    </>
  );
};

export default UpgradeMembership;
