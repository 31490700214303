import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const UpgradeBanner = () => {
  const { role } = useSelector((state) => state.auth);

  return (
    <>
      {role == "trial_user" ? (
        <TrialUserBanner />
      ) : role == "blocked_user" ? (
        <BlockedUserBanner />
      ) : (
        ""
      )}
    </>
  );
};

const TrialUserBanner = () => {
  const { remainingDays } = useSelector((state) => state.auth);

  return (
    <div class="upgrade-membership-note d-flex">
      <Link className="font-sm fw-200 text_center " to="/upgrade-membership">
        {remainingDays >= 0 ? (
          <p className="mr-4">
            You only have &nbsp;<span class="fw-600">{remainingDays}</span>
            &nbsp; days remaining in your trial.
            <span className="fw-600 nowrap"> Upgrade now</span>
          </p>
        ) : (
          <p className="mr-4">
            Your Trial Period Has Ended - Please Upgrade Your Plan.
            <span className="fw-600 nowrap"> Upgrade now</span>
          </p>
        )}
        {/* <i className="icon-forward-arrow icon-shadow font-xs"></i> */}
      </Link>
    </div>
  );
};

const BlockedUserBanner = () => {
  return (
    <div class="upgrade-membership-note block_user d-flex">
      <Link className="font-sm fw-200 text_center " to="/upgrade-membership">
        <p className="mr-4">
          You trial has expired - You are now in the Free plan.
          <span className="fw-600 nowrap"> Upgrade now</span>
        </p>
        {/* <i className="icon-forward-arrow icon-shadow font-xs"></i> */}
      </Link>
    </div>
  );
};

export default UpgradeBanner;
