import { Link } from "react-router-dom";
import Header from "../../../Component/Header";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useRequest from "../../../hooks/useRequest";
import { logout } from "../../../store/auth/action";
import { API } from "../../../constant/api";
import { Spinner } from "../../../Component/Svg";
import UpgradeBanner from "../../../Component/UpgradeBanner";

const Profile = () => {
  const {
    firstName,
    lastName,
    email,
    profilePic,
    // gender,
    dob,
    mobile_number,
    countryCode,
    title,
    role,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [image, setImage] = useState(profilePic);

  const [deactivateModalShow, setDeactivateModalShow] = useState(false);
  const { request, response, loading } = useRequest();

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        dispatch(logout());
      }
    }
  }, [response]);

  const deactivateAccountHandler = () => {
    if (!loading) {
      request("GET", "v1/auth/deactivate");
    }
  };

  return (
    <>
      <div className="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

        <div className="headerContant"></div>
        <div className="setting_header light_header">
          <Header heading="Profile" link="/settings">
            <Link to="/edit-profile">
              <i className="icon-edit font-md icon-shadow"></i>
            </Link>
          </Header>
          {/* <Sidebar from="events" /> */}
          <div className="content-header-bottom">
            <div className="profile_img">
              {image ? (
                <img src={`${API.IMG}/${profilePic}`} />
              ) : (
                <i className="icon-user"></i>
              )}
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="list-wrap">
            <div className="profile-info">
              <p className="profile_main_title">
                {" "}
                {`${firstName} ${lastName}`}
              </p>
              <div className="profile_feeds">
                <p className="main_title"> Title</p>
                <p className="sub_title"> {title} </p>
              </div>
              <div className="profile_feeds">
                <p className="main_title"> Phone</p>
                <p className="sub_title">
                  {" "}
                  {mobile_number && `+${countryCode} - ${mobile_number}`}{" "}
                </p>
              </div>
              <div className="profile_feeds">
                <p className="main_title"> Email</p>
                <p className="sub_title"> {email}</p>
              </div>
              {dob && (
                <div className="profile_feeds">
                  <p className="main_title"> Birthday</p>
                  <p className="sub_title">
                    {" "}
                    {moment(dob).format("YYYY-MM-DD")}
                  </p>
                </div>
              )}
              {/* <div className="profile_feeds">
                <p className="main_title"> Gender</p>
                <p className="sub_title text-capitalize"> {gender}</p>
              </div> */}
              <div className="profile_feeds">
                <p className="main_title"> Password</p>
                <Link
                  to="/change-password"
                  state={{ from: "/profile" }}
                  className="sub_title profile_link"
                >
                  {" "}
                  Change Password
                </Link>
              </div>
              <div className="profile_feeds">
                <p className="main_title"> Account</p>
                <a
                  onClick={() => {
                    setDeactivateModalShow(true);
                  }}
                  className="sub_title profile_link"
                >
                  {" "}
                  Deactivate Account
                </a>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={deactivateModalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="thememodal"
          onHide={() => setDeactivateModalShow(false)}
        >
          <button
            type="button"
            className="btn-close"
            // data-bs-dismiss="modal"
            // aria-label="Close"

            onClick={() => {
              setDeactivateModalShow(false);
            }}
          />
          <div
          // className="modal-content"
          >
            <div className="modal-body sure_modal_body">
              <div className="text-center pt-8 px-6">
                <p className="font-lg fw-700 mb-4">Are you sure?</p>
                <p className="font-md">
                  You will have access to your data for 365 days after
                  deactivation. If you would like to have your data deleted
                  sooner, please contact us at{" "}
                  <a href="/" target="_blank">
                    support@turnoapp.com
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="modal-footer shift_time_footer">
              <button
                type="button"
                className="btn button_no"
                onClick={() => {
                  setDeactivateModalShow(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn button_yes"
                onClick={() => deactivateAccountHandler()}
              >
                {loading ? <Spinner /> : "Deactivate"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Profile;
