import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "../../Component/Svg";
import UpgradeBanner from "../../Component/UpgradeBanner";
import useRequest from "../../hooks/useRequest";

const RequestInvite = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    setValue
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { request, response, loading } = useRequest();
  // const { request: requestConnections, response: responseConnections } = useRequest();

  const { email, role } = useSelector((status) => status.auth);
  const { connections } = useSelector((status) => status.connection);
  const [ errorMessage , setErrorMessage ] = useState();
  const [allConnections, setAllConnections] = useState(connections)
  const [disableShareNow,setDisableShareNow]    = useState(false)  


  const navigationHandler = () => {
    if (location.state?.from) {
      return navigate(location.state?.from, {
        state: {
          from: location.state?.backRoute,
        },
      });
    } else {
      return navigate("/connections", {
        state: { from: location.state?.backRoute },
      });
    }
  };

  useEffect(() => {
    if (response) {
     if (response.status == "SUCCESS") {
        // navigate("/")
        navigationHandler();
      } else{
        setErrorMessage(response.message)
      }
    }
  }, [response]);

  // useEffect(() => {
  
  //   requestConnections("GET", "v1/share-calendar/connections");
  // }, []);


  // useEffect(() => {
  //   if (responseConnections) {
  //     if (responseConnections.status == "SUCCESS") {
  //       const { connections } = responseConnections.data;
  //       setAllConnections(connections);
  //     }
  //   }
  // }, [responseConnections]);

  


  const onSubmit = (data) => {
    const { to_email, notes } = data;
    if (to_email == email) {
      setError("to_email", {
        type: "sameEmail",
        message: "You can not request yourself.",
      });
      return;
    } else {
      clearErrors("to_email");
    }
    request("POST", "v1/share-calendar/invite-user", {
      to_email,
      notes,
    });
  };

  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

      <div className="headerContant"></div>
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5 cursor"
          onClick={() => navigationHandler()}
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
        </span>
        <h1>Share Calendar</h1>
      </div>
      {/* Section */}
      <div className="page-section">
        <div className="container">
          <div className="innerPageBox">
            <div className="MuiPaper-root MuiPaper-elevation0 MuiStepper-root MuiStepper-horizontal stepper-header">
              <span className="step MuiStep-root MuiStep-horizontal active">
                <span className="MuiStepLabel-root MuiStepLabel-horizontal step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span className="MuiStepLabel-labelContainer">
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label MuiStepLabel-active">
                      New Connection
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="stepper-Outbody h-10000">
              <form id="regForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="stepper-body">
                  <div className="stepper-content">
                    <div className="tab" style={{ display: "block" }}>
                      <div className="form-field">
                        <div className="form-input">
                          <input
                            name="email"
                            type="TEXT"
                            placeholder="Email"
                            {...register("to_email", {
                              required: "This field is mandatory.",
                              pattern:
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            })}
                            onChange={(e) => {
                              setValue("to_email",e.target.value)
                              setError("to_email","")
                              if(allConnections.find(con => con.email == e.target.value)){
                                setDisableShareNow(true);
                              }else{
                                setDisableShareNow(false);
                              }
                            }}
                          />
                          <span className="inputborder" />
                        </div>
                        {/* {errors.to_email && (
                          <p className="error-msg">{errors.to_email.message}</p>
                        )} */}
                          {errors.to_email && errors.to_email.type === "required" && (
                          <p className="error-msg">
                          {errors.to_email.message}
                          </p>
                        )}
                         {errors.to_email && errors.to_email.type === "pattern" && (
                          <p className="error-msg">
                            Please enter a valid email.
                          </p>
                        )}
                      </div>

                      <div className="tab" style={{ display: "block" }}>
                        <div className="form-field">
                          <div className="form-input">
                            <input
                              name="note"
                              type="text"
                              placeholder="Notes"
                              {...register("notes", {
                                // required: "This field is mandatory.",
                                // setValueAs: (v) => v.trim(),
                              })}
                            />
                            <span className="inputborder" />
                          </div>
                          {errors.notes && (
                            <p className="error-msg">{errors.notes.message}</p>
                          )}
                        </div>
                        {errorMessage ?  <p className="error-msg" style={{marginLeft: "35px"}}>{errorMessage}</p> :""}
                      </div>
                    </div>
                  </div>
                  <div className="content-footer" style={{ overflow: "auto" }}>
                    <button
                      type="button"
                      className="btn-link formBtn color-text-50"
                      id="prevBack"
                      onClick={() => navigationHandler()}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className={disableShareNow ? "formBtn color-text-5" : "btn-white formBtn"}
                      id="nextBtn"
                      disabled= {disableShareNow ? true : false}
                    >
                      {loading ? <Spinner /> : "Share Now"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestInvite;
