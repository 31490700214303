import React, {useCallback, useState} from 'react'
import Cropper from "react-easy-crop";

const ImageCroper = ({ image, setCropedImage,handleCrop }) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation]   =   useState(0)
    const [coord, setCoord] = useState(null);
    const [initialImage, setInitialImage] = useState(image);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCoord(croppedAreaPixels);
    }, []);

    const getCropImage = () => {
      cropImage64(image, coord.x, coord.y, coord.width, coord.height, rotation).then(
        async(data) => {

          const croppedImage = data?.url;
          const blobType = data?.file?.type;

          const buffer = await data?.file.arrayBuffer();
          if(buffer){
              const imageName = 'image_'+new Date().getTime()+'.jpg';
              let uploadImage = new File([buffer], imageName, {
                  type:blobType,
          
              });
              setCropedImage(croppedImage,uploadImage);
          }
            
        }
      );
    };

    const handleReset = () => {
      setRotation(0);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    }
    const cropImage = (image, x, y, newWidth, newHeight, rotation) => {
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        
        const ctx = canvas.getContext("2d");

   
        if(rotation != 0){
          const orientationChanged =
            rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
          if (orientationChanged) {
            canvas.width = image.height
            canvas.height = image.width
          } else {
            canvas.width = image.width
            canvas.height = image.height
          }
        
          ctx.translate(canvas.width / 2, canvas.height / 2)
          ctx.rotate((rotation * Math.PI) / 180)
          ctx.drawImage(image, -image.width / 2, -image.height / 2)
        }else{
          ctx.drawImage(image, x, y, newWidth, newHeight, 0, 0, newWidth, newHeight);
        }
     
        // return canvas.toDataURL("image/jpeg");

        return new Promise((resolve, reject) => {
            canvas.toBlob((file) => {
              resolve({file, url:URL.createObjectURL(file)})
            }, 'image/jpeg')
          })
      }
      
      
    const cropImage64 = async(base64, x, y, newWidth, newHeight, rotation) => {

        const img = await image64ToImage(base64);
        return cropImage(img, x, y, newWidth, newHeight,rotation);
    }

    function image64ToImage(base64) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = base64;
            img.onload = () => {
                resolve(img);
            };
            img.onerror = () => {
                reject(img);
            };
        });
    }

    return (
      <div className="cropper">
        <div className="cropper-container">
            <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
              rotation={rotation}
            cropShape="round"
            showGrid={false}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            />
        </div>
        <div className="cropper-controls">
         
                <div>
                Zoom <input
                    className="cropper-zoom-range"
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.01}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                        setZoom(e.target.value);
                    }}
                />

                Rotate <input
                    className="cropper-zoom-range"
                    type="range"
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    onChange={(e) => {
                        setRotation(e.target.value);
                    }}
                />
                 
                </div>
              
           
            <input type="button" onClick={() => getCropImage(rotation)} value="Crop" />
            <input type="button" onClick={() => handleReset()} value="Reset" />
            <input type="button" onClick={() => handleCrop(false)} value="Cancel" />
        </div>
    </div>
  )
}

export default ImageCroper
