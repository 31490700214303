import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const UpgradeMessageModal = ({ isShowModal, setIsShowModal }) => {
  //   const [isShowModal, setIsShowModal] = useState(true);
  const navigate = useNavigate();
  return (
    <Modal
      show={isShowModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="thememodal thememodal2"
      onHide={() => {
        setIsShowModal(false);
      }}
    >
      <button
        type="button"
        className="btn-close"
        onClick={() => setIsShowModal(false)}
      />
      <div
      // className="modal-content"
      >
        <div className="modal-body sure_modal_body">
          <div className="text-center pt-8 px-6">
            <p className="font-md">
              Your Trial Period Has Ended - Please Upgrade Your Plan.
            </p>
          </div>
        </div>
        <div className="modal-footer shift_time_footer">
          <button
            type="button"
            className="btn button_no"
            onClick={() => setIsShowModal(false)}
          >
            No
          </button>
          <button
            type="button"
            className="btn button_yes"
            onClick={() => navigate("/upgrade-membership")}
          >
            Upgrade Plan
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeMessageModal;
