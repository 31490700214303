import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Header from "../../Component/Header";
import { Spinner } from "../../Component/Svg";
import UpgradeBanner from "../../Component/UpgradeBanner";
import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";

const InvitedUser = () => {
  const { role } = useSelector((state) => state.auth);
  const [requestedUser, setRequestedUser] = useState([]);

  const [actionPerformingOn, setActionPerformingOn] = useState(null);

  const { request, response } = useRequest();
  const {
    request: resendRequest,
    response: resendRenponse,
    loading: resendLoading,
  } = useRequest();
  const {
    request: cancelRequest,
    response: cancelResponse,
    loading: cancelLoading,
  } = useRequest();

  useEffect(() => {
    request("GET", "v1/share-calendar/request-status");
  }, []);

  useEffect(() => {
    if (response) {
      const { requested_users } = response.data;
      setRequestedUser(requested_users);
    }
  }, [response]);

  useEffect(() => {
    if (resendRenponse) {
      if (resendRenponse.status == "SUCCESS") {
        toast.success(resendRenponse.message);
        const newRequestedUser = requestedUser.map((user) => {
          if (user.id_share_calendar_request == actionPerformingOn) {
            user.status = "pending";
          }
          return user;
        });
        setRequestedUser(newRequestedUser);
        request("GET", "v1/share-calendar/request-status");
      } else {
        toast.error(resendRenponse.message);
      }
    }
  }, [resendRenponse]);

  useEffect(() => {
    if (cancelResponse) {
      if (cancelResponse.status == "SUCCESS") {
        toast.success(cancelResponse.message);
        setRequestedUser((prev) =>
          prev.filter(
            (ru) => ru.id_share_calendar_request != actionPerformingOn
          )
        );
      } else {
        toast.error(cancelResponse.message);
      }
    }
  }, [cancelResponse]);

  const resendHandler = (to_email, id) => {
    setActionPerformingOn(id);
    resendRequest("POST", "v1/share-calendar/invite-user", {
      to_email,
    });
  };

  const cancelRequestHanlder = (id_share_calendar_request) => {
    setActionPerformingOn(id_share_calendar_request);
    cancelRequest("POST", "v1/share-calendar/cancel-request", {
      id_share_calendar_request,
    });
  };

  return (
    <div className="content-center  manager_list_page">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

      <div className="headerContant"></div>
      <div className="light_header">
        <Header heading="Invited Users" link="/settings"></Header>
        {/* <Sidebar from="events" /> */}
      </div>
      <div className="content-body">
        {requestedUser.length > 0 ? (
          <div className="list-wrap ">
            <ul className="manager_list blue_scroll_bar listingCardLi">
              {requestedUser.map((user) => (
                <li className="manager_list_item" key={user.id_user}>
                  <div className="listing_Card">
                    <span className="manager_img">
                      {user.user_picture ? (
                        <img src={`${API.IMG}/${user.user_picture}`} />
                      ) : (
                        <i className="icon-user"></i>
                      )}
                    </span>
                    <span className="flex1">
                      {" "}
                      <span className="listingEmail d-block">{user.email}</span>
                      <span className="listingdate d-flex">
                        <span className="statusActionBtns">
                          {user.status == "pending" ? (
                            <>
                              <button
                                className="btn-white formBtn"
                                onClick={() =>
                                  resendHandler(
                                    user.email,
                                    user.id_share_calendar_request
                                  )
                                }
                              >
                                {resendLoading &&
                                actionPerformingOn ==
                                  user.id_share_calendar_request ? (
                                  <Spinner />
                                ) : (
                                  "Resend"
                                )}
                              </button>
                              <button
                                className="btn-white formBtn cancelRequestBtn"
                                onClick={() =>
                                  cancelRequestHanlder(
                                    user.id_share_calendar_request
                                  )
                                }
                              >
                                {cancelLoading &&
                                actionPerformingOn ==
                                  user.id_share_calendar_request ? (
                                  <Spinner />
                                ) : (
                                  "Cancel"
                                )}
                              </button>
                            </>
                          ) : user.status == "rejected" ? (
                            <button
                              className="btn-white formBtn"
                              onClick={() =>
                                resendHandler(
                                  user.email,
                                  user.id_share_calendar_request
                                )
                              }
                            >
                              {resendLoading &&
                              actionPerformingOn ==
                                user.id_share_calendar_request ? (
                                <Spinner />
                              ) : (
                                "Resend"
                              )}
                            </button>
                          ) : (
                            ""
                          )}
                        </span>
                        <span className="listCardStatus">
                          <span className="d-block mb-2">{user.status}</span>
                          {user.created_at}
                        </span>
                      </span>
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="content-body no_data_show  no_data_show2 placeholder-content">
            <img src="/assets/img/connections.svg" />
            <p class="font-md">You don't have a requested yet.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvitedUser;
