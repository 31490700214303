import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Elements, injectStripe, StripeProvider } from "react-stripe-elements";
import StripeForm from "../../../Component/StripeForm";
import Sidebar from "../../../Component/Sidebar";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";
import { addCardInfo } from "../../../store/auth/action";
import { useDispatch, useSelector } from "react-redux";
import UpgradeBanner from "../../../Component/UpgradeBanner";

const SplitForm = injectStripe(StripeForm);

const AddPaymentMethod = () => {
  const [error, setError] = useState("");
  const { role } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { request, response, loading: addCardLoading } = useRequest();

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { results } = response.data;
        dispatch(addCardInfo(results));
        navigate("/manage-payment");
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  const handleChange = async (data) => {
    console.log("data",data)
    let cardData = undefined;
    if (data.error && !data.source) {
      cardData = undefined;
    }
    if (data.source && !data.error) {
      cardData = {
        // Poxel live-i jamanak
        card_id: data.source.id,
        // card_id: "tok_visa"
      };
    }
console.log(cardData ,"cardData")
    if (typeof cardData === "object") {
      request("POST", "v1/stripe/add-card", cardData);
      // const res = await this.props.addCard(cardData);
      // if (res && res.status === 201 && res.data.id) {
      //   this.props.history.push("/manage-payment");
      // }
    }
  };

  return (
    <>
      <div class="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

        <div className="headerContant"></div>
        <div class="setting_header">
          <Sidebar from="settings" heading="Add Payment Method" />
          <div className="content-header-bottom">
            <i className="icon-credit-card settings_icon color-white-30"></i>
          </div>
        </div>

        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
          <Elements>
            <SplitForm
              handleChange={handleChange}
              from={"add-payment-method"}
              loadingFromAddPayment={addCardLoading}
              //  error={error}
              //  update={true}
            />
          </Elements>
        </StripeProvider>
      </div>
    </>
  );
};

export default AddPaymentMethod;
