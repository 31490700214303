import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import {
  addAllNotifications,
  addNotificationCount,
} from "../../store/notifications/action";
import { useNavigate, useSearchParams } from "react-router-dom";
import NotificationModal from "../../Component/NotificationModal";
import {
  addAllAssignedEvents,
  addAllAssignedNotes,
  addAllAssignedShifts,
  addAllWorkplaces,
} from "../../store/calendarData/action";
import { shiftSwitchFromNotification } from "../../utils/fn";
import UpgradeBanner from "../../Component/UpgradeBanner";
import UpgradeMessageModal from "../../Component/UpgradeMessageModal";

let IconObj = {
  "Giveaway request": (
    <i className="icon-calendar-remove color-text-70 font-md mr-3" />
  ),
  "Giveaway covered": <i className="icon-close font-xsm mr-3 color-warning" />,
  "Pending giveaway acceptance from user": (
    <i className="icon-clock font-md color-text-70 mr-3" />
  ),
  "Pending giveaway approval by manager": (
    <i className="icon-clock font-md color-text-70 mr-3" />
  ),
  "Giveaway approved by manager": (
    <i className="icon-check font-xsm mr-3 color-success" />
  ),
  "Giveaway rejected by manager": (
    <i className="icon-close font-xsm mr-3 color-warning" />
  ),
  "Sharing Calendars Enabled": (
    <i className="icon-eye font-md mr-3 color-text-70" />
  ),
  "Sharing Calendars Disabled": (
    <i className="icon-eye-disabled font-md mr-3 color-text-70" />
  ),
  "Pending switch acceptance from user": (
    <i className="icon-clock font-md color-text-70 mr-3" />
  ),
  "Switch request": <i className="icon-switch-horizontal font-xmd mr-3" />,
  "Switch rejected by user": (
    <i className="icon-close font-xsm mr-3 color-warning" />
  ),
  "Switch rejected by manager": (
    <i className="icon-close font-xsm mr-3 color-warning" />
  ),
  "Switch approved by manager": (
    <i className="icon-check font-xsm mr-3 color-success" />
  ),
  "Switch covered": <i className="icon-close font-xsm mr-3 color-warning" />,
  "Pending switch approval by manager": (
    <i className="icon-clock font-md color-text-70 mr-3" />
  ),
  Invitation: <i className="icon-share color-text-70 font-sm mr-3" />,
  "Invitation accepted": <i className="icon-check font-xsm mr-3" />,
  "Invitation rejected": (
    <i className="icon-close font-xsm mr-3 color-warning" />
  ),
};

const NotificationsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFirstTime, allAssignedShifts } = useSelector(
    (state) => state.calendar
  );
  const { socket, role } = useSelector((state) => state.auth);

  const [searchParams] = useSearchParams();
  let param, paramDateValue;
  for (const entry of searchParams.entries()) {
    [param, paramDateValue] = entry;
  }

  const { notifications } = useSelector((state) => state.notifications);
  const [notification, setNotification] = useState(false);

  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const [isUpgradeModalShow, setIsUpgradeModalShow] = useState(false);

  const [notificationModalData, setNotificationModalData] = useState();
  const [sendingUserName, setSendingUserName] = useState("");

  const [idNotification, setIdNotification] = useState(null);
  const [notificationStatus, setNotificationStatus] = useState(null);
  const [idSwitchShiftRequest, setIdSwitchShiftRequest] = useState(null);

  const [
    locationChangeWarningModalAcceptSwitchShift,
    setLocationChangeWarningModalAcceptSwitchShift,
  ] = useState(false);
  const [
    locationChangeWarningModalAcceptGiveaway,
    setLocationChangeWarningModalAcceptGiveaway,
  ] = useState(false);

  const { request, response } = useRequest();
  const { request: notificationRequest, response: notificationResponse } =
    useRequest();
  const {
    request: notificationStatusReadRequest,
    response: notificationStatusReadResponse,
  } = useRequest();

  const {
    request: switchShiftAndGiveawayAcceptRejectRequest,
    response: switchShiftAndGiveawayAcceptRejectResponse,
    loading: switchShiftAndGiveawayAcceptRejectLoading,
  } = useRequest();

  const { request: workplacesRequest, response: workplacesResponse } =
    useRequest();

  useEffect(() => {
    if (notifications.length > 0) {
      notificationSetHandler(notifications);
    }
    // else {
    notificationRequest("GET", "v1/notification/notifications");
    if (isFirstTime) {
      workplacesRequest("GET", "v1/workplace/workplaces");
    }
    // }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("NotificationResponse", (data) => {
        if (data.status) {
          notificationRequest("GET", "v1/notification/notifications");
        }
      });
      return () => {
        socket.off("NotificationResponse");
      };
    }
  }, [socket]);

  // useEffect(() => {
  //   if (assignedShiftsResponse) {
  //     let shifts = assignedShiftsResponse.data.shifts;
  //     let events = assignedShiftsResponse.data.events;
  //     let notes = assignedShiftsResponse.data.notes;
  //     dispatch(addAllAssignedShifts(shifts));
  //     dispatch(addAllAssignedEvents(events));
  //     dispatch(addAllAssignedNotes(notes));
  //   }
  // }, [assignedShiftsResponse]);

  useEffect(() => {
    if (workplacesResponse) {
      const { workplaces } = workplacesResponse.data;
      dispatch(addAllWorkplaces(workplaces));
    }
  }, [workplacesResponse]);

  useEffect(() => {
    if (notificationResponse) {
      const { notifications_count, notifications } = notificationResponse.data;
      dispatch(addAllNotifications(notifications));
      dispatch(addNotificationCount(notifications_count));
      notificationSetHandler(notifications);
    }
  }, [notificationResponse]);

  useEffect(() => {
    if (switchShiftAndGiveawayAcceptRejectResponse) {
      setNotificationModalShow(false);
      setLocationChangeWarningModalAcceptSwitchShift(false);
      setLocationChangeWarningModalAcceptGiveaway(false);
      if (switchShiftAndGiveawayAcceptRejectResponse.status == "SUCCESS") {
        let newNotifictions = notification.map((noti) => {
          return {
            date: noti.date,
            notifications: noti.notifications.map((n) => {
              if (n.id_notification == idNotification) {
                n = { ...n, status: notificationStatus };
              }
              return n;
            }),
          };
        });
        setNotification(newNotifictions);
      } else if (switchShiftAndGiveawayAcceptRejectResponse.status == "FAIL") {
        toast.error(switchShiftAndGiveawayAcceptRejectResponse.message);
        let newNotifictions = notification.map((noti) => {
          return {
            date: noti.date,
            notifications: noti.notifications.map((n) => {
              if (n.id_notification == idNotification) {
                n = { ...n, is_covered: true };
              }
              return n;
            }),
          };
        });
        setNotification(newNotifictions);
      }
    }
  }, [switchShiftAndGiveawayAcceptRejectResponse]);

  useEffect(() => {
    if (notificationStatusReadResponse) {
    }
  }, [notificationStatusReadResponse]);

  const notificationClickHandler = (noti) => {
    setNotificationModalShow(true);
    setNotificationModalData(noti);
    setSendingUserName(
      `${noti.sending_user?.first_name} ${noti.sending_user?.last_name}`
    );
    if (noti.notification_status == "unread") {
      let newNotifictions = notification.map((notify) => {
        return {
          date: notify.date,
          notifications: notify.notifications.map((n) => {
            if (n.id_notification == noti.id_notification) {
              n = { ...n, notification_status: "read" };
            }
            return n;
          }),
        };
      });
      setNotification(newNotifictions);
      notificationStatusReadRequest(
        "POST",
        "v1/notification/update-notification-status",
        {
          id: noti.id_notification,
          status: "read",
        }
      );
      dispatch(addNotificationCount((prev) => prev - 1));
    }
  };

  const invitationStatusHanlder = (id, status) => {
    request("POST", "v1/share-calendar/update-connection-status", {
      id_share_calendar_request: id,
      status,
      is_request: true,
    });

    let newNotifictions = notification.map((noti) => {
      return {
        date: noti.date,
        notifications: noti.notifications.map((n) => {
          if (n.share_calendar_request_id == id) {
            n = { ...n, status };
          }
          return n;
        }),
      };
    });
    setNotification(newNotifictions);
    setNotificationModalShow(false);
  };

  const notificationSetHandler = (notifications) => {
    let notificationByDate = [];
    let filterdNotifications = [];
    if (param) {
      // date: "26/06/2023
      const data = notifications.filter(
        (n) =>
          n.date == moment(paramDateValue, "YYYY-MM-DD").format("DD/MM/YYYY")
      );
      filterdNotifications.push(...data);
    } else {
      filterdNotifications.push(...notifications);
    }
    notificationByDate = filterdNotifications.reduce((acc, curr) => {
      const data = acc.findIndex(
        (n) =>
          moment(curr.created_at, "DD/MM/YYYY hh:mm:ss").format("DD/MM/YYYY") ==
          n.date
      );
      if (data >= 0) {
        acc[data].notifications.push(curr);
      } else {
        acc.push({
          date: moment(curr.created_at, "DD/MM/YYYY hh:mm:ss").format(
            "DD/MM/YYYY"
          ),
          notifications: [curr],
        });
      }
      return acc;
    }, []);
    // Sort Date
    // notificationByDate = notificationByDate.sort(function (a, b) {
    //   const value = moment(a.date, "DD/MM/YYYY").isBefore(
    //     moment(b.date, "DD/MM/YYYY")
    //   )
    //     ? 1
    //     : -1;
    //   return value;
    // });

    // notificationByDate = notificationByDate.map((noti) => {
    //   const notifications = noti.notifications.sort((a, b) => {
    //     const value = moment(a.created_at, "DD/MM/YYYY hh:mm:ss").isBefore(
    //       moment(b.created_at, "DD/MM/YYYY hh:mm:ss")
    //     )
    //       ? 1
    //       : -1;
    //     return value;
    //   });
    //   noti.notifications = notifications;
    //   return noti;
    // });
    setNotification(notificationByDate);
  };

  const switchShiftAndGiveawayAcceptRejectHandler = (
    to_user_status,
    notification,
    api
  ) => {
    const {
      id_notification,
      switch_shift_request_id: id_switch_shift,
      receiver_user_event_date: myShfit,
      sending_user_event_date: senderShift,
    } = notification;

    setIdNotification(id_notification);
    setNotificationStatus(to_user_status);
    setIdSwitchShiftRequest(id_switch_shift);
    switchShiftAndGiveawayAcceptRejectRequest(
      "POST",
      `v1/switch-shift/${api}`,
      {
        to_user_status,
        id_switch_shift,
        id_notification,
      }
    );
    // setNotificationModalShow(false);
  };

  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="headerContant"></div>
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5 cursor"
          onClick={() => navigate("/calendar")}
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
        </span>
        <h1>Notifications</h1>
      </div>
      <div className="p-3 notification_main_box blue_scroll_bar">
        {notification.length > 0 &&
          notification.map((noti, i) => (
            <div className="notificationsDate-box" key={i}>
              <div className="notificationsDate">
                {" "}
                {moment(noti.date, "DD/MM/YYYY").format("MMMM Do YYYY")}
              </div>
              <ul className="notifications_list">
                {noti.notifications.length > 0 &&
                  noti.notifications.map((n) => (
                    <li onClick={() => notificationClickHandler(n)}>
                      <a
                        className={`notifications_info ${
                          n.notification_status == "unread" && "unRead"
                        } `}
                      >
                        <span className="notifications_info_left"> </span>
                        <span className="notifications_info_right">
                          <h6 className="notifications_info_title">
                            {" "}
                            {n.sending_user.first_name}{" "}
                            {n.sending_user.last_name}
                          </h6>
                          <p className="notifications_info_link">
                            <span className="icon">
                              {IconObj[n.notification_text]}
                            </span>
                            <span className="text">{n.notification_text}</span>
                          </p>
                        </span>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
      </div>

      <NotificationModal
        notificationModalShow={notificationModalShow}
        setNotificationModalShow={setNotificationModalShow}
        notificationModalData={notificationModalData}
        switchShiftAndGiveawayAcceptRejectHandler={
          switchShiftAndGiveawayAcceptRejectHandler
        }
        sendingUserName={sendingUserName}
        invitationStatusHanlder={invitationStatusHanlder}
        switchShiftAndGiveawayAcceptRejectLoading={
          switchShiftAndGiveawayAcceptRejectLoading
        }
        locationChangeWarningModalAcceptSwitchShift={
          locationChangeWarningModalAcceptSwitchShift
        }
        setLocationChangeWarningModalAcceptSwitchShift={
          setLocationChangeWarningModalAcceptSwitchShift
        }
        locationChangeWarningModalAcceptGiveaway={
          locationChangeWarningModalAcceptGiveaway
        }
        setLocationChangeWarningModalAcceptGiveaway={
          setLocationChangeWarningModalAcceptGiveaway
        }
      />
    </div>
  );
};

export default NotificationsList;
