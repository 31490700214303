import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useCallback, useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import Snackbar from "@mui/material/Snackbar";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import AlloyFinger from "alloyfinger";
import { useDispatch, useSelector } from "react-redux";

import { FRONTENDURL } from "../constant/api";
import useRequest from "../hooks/useRequest";

import {
  capitalizeFirstLetter,
  classHandler,
  classHandlerForSwitch,
  getDiffrenceHours,
  getStartAndEndDate,
  shiftsImageHanlder,
} from "../utils/fn";
import {
  addAllAssignedEvents,
  addAllAssignedNotes,
  addAllAssignedShifts,
  addEvents,
  addTeamShifts,
  isCalendarDataExist,
} from "../store/calendarData/action";
import Picker from "./Picker";
import Sidebar from "./Sidebar";

export default function TeamCalendarForSwitchShift({
  selectDateForTabOne,
  setToDate,
  clearErrors,
  errors,
  setError,
  selectDateForTabTwo,
  setSelectDateForTabTwo,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const monthAndYearRef = useRef();

  const { teamShifts, isFirstTime, allAssignedShifts } = useSelector(
    (state) => state.calendar
  );
  const { userId } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const calendarRef = useRef();
  const currentMonthAndYearRef = useRef(moment().format("MM-YYYY"));

  const [defaultDateForPicker, setDefaultDateForPicker] = useState(
    moment().format("MMMM YYYY")
  );

  const [events, setEvents] = useState([]); // icons on date
  const [connectionsShifts, setConnectionsShifts] = useState([]);
  const [shiftForModal, setShiftForModal] = useState({});

  const [selectedDate, setSelectedDate] = useState(
    moment().format("MMMM Do YYYY")
  );
  const [shiftData, setShiftData] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD")); // this is for go to day-detail page

  const [isShiftsUpdated, setIsShiftsUpdated] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [shiftDetailModalShow, setShiftDetailModalShow] = useState(false);

  const [selectedMonthAndYearForHeading, setSelectedMonthAndYearForHeading] =
    useState(moment().format("MMMM YYYY"));

  const { request: assignedShiftsRequest, response: assignedShiftsResponse } =
    useRequest();
  const {
    request: connectionShiftsRequest,
    response: connectionShiftsResponse,
  } = useRequest();

  const swiperHandler = useCallback(
    function (evt) {
      let elem = document.getElementById("swiper_id");

      if (evt.direction == "Left") {
        elem.classList.add("left_swipe");
        elem.classList.remove("right_swipe");

        calendarRef.current.getApi().next();
        let currMonthYear = calendarRef.current.calendar.currentData.viewTitle;
        setDefaultDateForPicker(
          moment(currMonthYear, "MMM YYYY").format("MMMM YYYY")
        );
        currentMonthAndYearRef.current = moment(
          currMonthYear,
          "MMM YYYY"
        ).format("MM-YYYY");
        // let currDateElement = document.querySelector(
        //   `[data-date='${moment().format("YYYY-MM-DD")}']`
        // );
        // console.log(currDateElement, "currDateElement");
        // if (currDateElement) {
        //   currDateElement.classList.remove("select-today");
        // }

        setSelectedMonthAndYearForHeading(currMonthYear);

        // classHandler(moment(currMonthYear, "MMMM YYYY"));
        classHandlerForSwitch(selectDateForTabOne, "end_date");
        classHandlerForSwitch(moment(currMonthYear, "MMMM YYYY"), "start_date");
        dateClickHandler(moment(currMonthYear, "MMMM YYYY"));
        setEvents(
          allAssignedShifts.map((s) => ({
            start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            image_url: shiftsImageHanlder(
              s.date,
              s?.shift?.start_period,
              currentMonthAndYearRef.current
            ),
          }))
        );
      } else if (evt.direction == "Right") {
        elem.classList.remove("left_swipe");
        elem.classList.add("right_swipe");

        calendarRef.current.getApi().prev();
        let currMonthYear = calendarRef.current.calendar.currentData.viewTitle;
        setDefaultDateForPicker(
          moment(currMonthYear, "MMM YYYY").format("MMMM YYYY")
        );
        currentMonthAndYearRef.current = moment(
          currMonthYear,
          "MMM YYYY"
        ).format("MM-YYYY");

        // let currDateElement = document.querySelector(
        //   `[data-date='${moment().format("YYYY-MM-DD")}']`
        // );

        // if (currDateElement) {
        //   currDateElement.classList.remove("select-today");
        // }

        setSelectedMonthAndYearForHeading(currMonthYear);
        // classHandler(moment(currMonthYear, "MMMM YYYY"));
        classHandlerForSwitch(selectDateForTabOne, "end_date");
        classHandlerForSwitch(moment(currMonthYear, "MMMM YYYY"), "start_date");
        dateClickHandler(moment(currMonthYear, "MMMM YYYY"));
        // console.log("teamShifts", teamShifts);
        setEvents(
          allAssignedShifts.map((s) => ({
            start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            image_url: shiftsImageHanlder(
              s.date,
              s?.shift?.start_period,
              currentMonthAndYearRef.current
            ),
          }))
        );
      }
    },
    [allAssignedShifts, events]
  );

  useEffect(() => {
    const element = document.getElementsByClassName(
      "fc-scrollgrid-section-sticky"
    );
    if (element?.[0]?.classList) {
      element[0].classList.remove("fc-scrollgrid-section-sticky");
    }
    document.body?.classList?.remove("scroll-off");
    if (teamShifts.length > 0) {
      let filterdTeamShifts = teamShifts.filter(
        (s) => s.shift.shift_name != "Off Day"
      );
      console.log(filterdTeamShifts, "filterdTeamShifts");
      if (selectDateForTabTwo) {
        let shifts = filterdTeamShifts.filter(
          (shift) =>
            shift.date ==
            moment(selectDateForTabTwo, "YYYY-MM-DD").format("DD/MM/YYYY")
        );

        setShiftData(
          shifts.map((d) => ({
            icon: d.shift.start_period,
            name: d.shift.shift_name,
            start_time: d.shift.start_time,
            end_time: d.shift.end_time,
            id_shift: d.shift.id_shift,
            id_event_date: d.id_event_date,
            workplace_name: d.shift.workplace_name,
            user_name:
              d.id_user == userId ? "Me" : `${d.first_name} ${d.last_name}`,
          }))
        );
      }
      setConnectionsShifts((prev) => [...prev, ...filterdTeamShifts]);
    } else {
      connectionShiftsRequest("GET", "v1/share-calendar/connection-events");
    }
    if (isFirstTime) {
      assignedShiftsRequest("GET", `v1/event/calender-events`);
    } else {
      if (selectDateForTabOne) {
        setDefaultDateForPicker(
          moment(selectDateForTabOne, "YYYY-MM-DD").format("MMMM YYYY")
        );
        if (selectDateForTabOne) {
          currentMonthAndYearRef.current = moment(
            selectDateForTabOne,
            "YYYY-MM-DD"
          ).format("MM-YYYY");
          setSelectedMonthAndYearForHeading(
            moment(currentMonthAndYearRef.current, "MM-YYYY").format(
              "MMMM YYYY"
            )
          );

          let calendarApi = calendarRef.current.getApi();
          calendarApi.gotoDate(selectDateForTabOne);
          classHandlerForSwitch(selectDateForTabOne, "end_date");
          if (selectDateForTabTwo) {
            classHandlerForSwitch(selectDateForTabTwo, "start_date");
          }
          // classHandler(selectDateForTabOne);

          // dateClickHandler(moment(selectDateForTabOne, "YYYY-MM-DD"));
          setIsShiftsUpdated(moment(selectDateForTabOne, "YYYY-MM-DD"));
        }
        // navigate("/calendar");
      } else {
        setIsShiftsUpdated(moment(currentDate));
        // dateClickHandler(moment(currentDate));
      }
      // Removing off day shifts
      let newShift = allAssignedShifts.filter(
        (s) => s.shift.start_period != "off_day"
      );

      setConnectionsShifts((prev) => [...prev, ...newShift]);

      setEvents(
        newShift.map((s) => ({
          start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          image_url: shiftsImageHanlder(
            s.date,
            s?.shift?.start_period,
            currentMonthAndYearRef.current
          ),
        }))
      );
    }
  }, []);

  useEffect(() => {
    let element = calendarRef.current.getApi().el;
    var af = new AlloyFinger(element, {
      swipe: swiperHandler,
    });
    return () => {
      af = af.destroy();
    };
  }, [swiperHandler]);

  useEffect(() => {
    if (assignedShiftsResponse) {
      if (assignedShiftsResponse.status == "SUCCESS") {
        dispatch(isCalendarDataExist(false));
        let shifts = assignedShiftsResponse.data.shifts;
        let events = assignedShiftsResponse.data.events;
        let notes = assignedShiftsResponse.data.notes;

        // Put data into redux store
        dispatch(addAllAssignedShifts(shifts));
        dispatch(addAllAssignedEvents(events));
        dispatch(addAllAssignedNotes(notes));

        // Removing off day shifts
        shifts = shifts.filter((s) => s.shift.start_period != "off_day");

        setConnectionsShifts((prev) => [...prev, ...shifts]);
        setEvents(
          shifts.map((s) => ({
            start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            image_url: shiftsImageHanlder(
              s.date,
              s?.shift?.start_period,
              currentMonthAndYearRef.current
            ),
          }))
        );

        setShiftDetailModalShow(false);
        let selectDate;
        if (currentMonthAndYearRef.current == moment().format("MM-YYYY")) {
          if (selectedDate) {
            selectDate = moment(selectedDate, "MMM Do YYYY").format(
              "DD/MM/YYYY"
            );
          } else {
            selectDate = moment().format("DD/MM/YYYY");
          }
        } else {
          selectDate = moment(currentMonthAndYearRef.current, "MM-YYYY").format(
            "DD/MM/YYYY"
          );
        }
        const data = shifts.filter((ad) => ad.date == selectDate);
        if (data.length > 0) {
          setShiftData((prev) => [
            ...prev,
            {
              icon: data.shift.start_period,
              name: data.shift.shift_name,
              start_time: data.shift.start_time,
              end_time: data.shift.end_time,
              id_shift: data.shift.id_shift,
              id_event_date: data.id_event_date,
              workplace_name: data.shift.workplace_name,
              user_name: "Me",
            },
          ]);
        } else {
          // setShiftData(null);
        }
      }
    }
  }, [assignedShiftsResponse]);

  useEffect(() => {
    if (connectionShiftsResponse) {
      let connectionShifts = connectionShiftsResponse.data.connection_shifts;

      let filterdTeamShifts = connectionShifts.filter(
        (s) => s.shift.shift_name != "Off Day"
      );

      setConnectionsShifts((prev) => [...prev, ...filterdTeamShifts]);
    }
  }, [connectionShiftsResponse]);

  useEffect(() => {
    if (isShiftsUpdated) {
      // dateClickHandler(isShiftsUpdated);
      setIsShiftsUpdated(false);
    }
  }, [isShiftsUpdated]);

  const dateClickHandler = (date) => {
    console.log(date, "date");
    clearErrors("toDate");
    if (currentMonthAndYearRef.current != moment(date).format("MM-YYYY")) {
      return;
    }
    // setIsAlreadyAsignedShiftSnakebar(false);
    // classHandler(date);
    classHandlerForSwitch(date, "start_date");
    setSelectDateForTabTwo(moment(date).format("YYYY-MM-DD"));

    // To avoid past date
    if (!moment().isSameOrBefore(moment(date), "day")) {
      setError("toDate", {
        type: "pastDate",
        message: "Please select a date in the future to switch.",
      });
      return;
    } else {
      clearErrors("toDate");
    }

    let selectDate = moment(date).format("DD/MM/YYYY");
    setCurrentDate(moment(date).format("YYYY-MM-DD"));
    // setSelectDateForSwitch(moment(date).format("YYYY-MM-DD"));

    setSelectedDate(moment(date).format("MMMM Do YYYY"));
    setToDate(moment(date).format("YYYY-MM-DD"));

    // if (assignedShifts && assignedShifts.length > 0) {
    if (connectionsShifts && connectionsShifts.length > 0) {
      // const data = assignedShifts.find((ad) => ad.date == selectDate);
      const data = connectionsShifts.filter((ad) => ad.date == selectDate);

      if (data.length > 0) {
        data.forEach((d) => {
          if (d.id_user == userId) {
            setError("toDate", {
              type: "alreadyShift",
              message: "You already have a shift on this date.",
            });
          }
        });
        const sortedArr = data.reduce((acc, element) => {
          if (element.id_user == userId) {
            return [element, ...acc];
          }
          return [...acc, element];
        }, []);

        setShiftData(
          sortedArr.map((d) => ({
            icon: d.shift.start_period,
            name: d.shift.shift_name,
            start_time: d.shift.start_time,
            end_time: d.shift.end_time,
            id_shift: d.shift.id_shift,
            id_event_date: d.id_event_date,
            workplace_name: d.shift.workplace_name,
            user_name:
              d.id_user == userId ? "Me" : `${d.first_name} ${d.last_name}`,
          }))
        );
      } else {
        setShiftData([]);
      }
    } else {
      setShiftData([]);
    }
  };

  const eventClickHandler = (date) => {
    dateClickHandler(date);
  };

  // const events = [
  //   {
  //     title: "Meeting",
  //     start: new Date(),
  //     image_url: "assets/img/afternoon.png",
  //   },
  //   {
  //     start: new Date("2023-05-14"),
  //     image_url: "assets/img/day.png",
  //   },
  // ];
  // const goToDayDeatailHandler = () => {
  //   if (shiftData) {
  //     navigate(`/calendar/day-details/${currentDate}`);
  //   }
  // };
  const dayCellDidMountHandler = (e) => {
    let cell = e.el;
    if (moment(e.date).format("MM-YYYY") == currentMonthAndYearRef.current) {
      cell.classList.add("cursor");
    }
    // if (e.isToday) {
    //   // cell.classList.add("select-today");
    // }
  };

  const dayHeaderDidMountHandler = (e) => {
    let day = e.el;
    if (moment().format("ddd") == e.text) {
      day.classList.add("day-color");
    }
  };
  const shiftDetailModalHandler = (id) => {
    const oldShiftData = [...shiftData];

    let data = oldShiftData.find((s) => s.id_event_date == id);

    setShiftForModal(data);

    setShiftDetailModalShow(true);
  };

  const pickerOnChange = useCallback(
    (e) => {
      let value = e.target.value;
      setSelectedMonthAndYearForHeading(value);
      let gotoDateValue = `${value} 1`;
      currentMonthAndYearRef.current = moment(gotoDateValue).format("MM-YYYY");
      gotoDateValue = moment(gotoDateValue).format("YYYY-MM-DD");
      dateClickHandler(gotoDateValue);
      let calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(gotoDateValue);
      classHandlerForSwitch(selectDateForTabOne, "end_date");
      classHandlerForSwitch(gotoDateValue, "start_date");
      // classHandler(gotoDateValue);
      let newShift = allAssignedShifts.map((s) => ({
        start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
        image_url: shiftsImageHanlder(
          s.date,
          s?.shift?.start_period,
          currentMonthAndYearRef.current
        ),
      }));
      setEvents(newShift);
    },
    [allAssignedShifts, connectionsShifts]
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="content-center lightBg ">
      {/* <Sidebar
        from="team"
        calendarRef={calendarRef}
        dateClickHandler={dateClickHandler}
        assignedShiftsRequest={assignedShiftsRequest}
        currentMonthAndYearRef={currentMonthAndYearRef}
        setSelectedMonthAndYearForHeading={setSelectedMonthAndYearForHeading}
        selectedMonthAndYearForHeading={selectedMonthAndYearForHeading}
        setEvents={setEvents}
        defaultDateForPicker={defaultDateForPicker}
        setDefaultDateForPicker={setDefaultDateForPicker}
      /> */}
      <div className="InnerPageHead bg-white calendar-toolbar">
        <span className="pageHeadTitle">
          <div className="time-picker">
            <div className="time">
              <Picker
                format={"MMMM YYYY"}
                timeRef={monthAndYearRef}
                onChange={pickerOnChange}
                defaultDateForPicker={defaultDateForPicker}
              />
              <a
                href="javascript:void(0)"
                className="calendar-date js-date-picker"
                onClick={() => monthAndYearRef.current.click()}
              >
                {selectedMonthAndYearForHeading}
                <i class="icon-caret-down"></i>
              </a>
            </div>
          </div>
        </span>
        <ul className="calendar-menu">
          <Link
            className="nav-item"
            style={{ fontWeight: "500", fontSize: "17px" }}
          >
            Choose Date
          </Link>
        </ul>
      </div>

      <div className="page-section ">
        <div className="container">
          <div className="SingleDatePicker SingleDatePicker_1" id="swiper_id">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={events}
              // hiddenDays={[]}
              eventContent={renderEventContent}
              dateClick={(e) => dateClickHandler(e.date)}
              // selectable={true}
              ref={calendarRef}
              eventClick={(e) => eventClickHandler(e.event.start)}
              dayCellDidMount={dayCellDidMountHandler}
              dayHeaderDidMount={dayHeaderDidMountHandler}
              // eventDisplay={"list-item"}
              // contentHeight={10}
              // dayCellWillUnmount={function (arg) {
              //   console.log(arg, "dayCellWillUnmount");
              // }}
              // dayCellClassNames={function (arg) {
              //   console.log(arg, "dayCellClassNames");
              // }}
              // dayCellContent={function (arg) {
              //   console.log(arg, "dayCellContent");
              // }}

              fixedWeekCount={false}
              height="auto"
            />
          </div>

          <div className="calendar-info">
            <div className="infoHeader">
              <span className="color-text-50 fw-500 py-1">{selectedDate}</span>
              {/* <a
                className={`btn-icon color-primary ${
                  shiftData ? "cursor" : "eventZero"
                }`}
                onClick={goToDayDeatailHandler}
              >
                <i className="icon-list"></i>
              </a> */}
            </div>
            <ul className="day-details">
              {/* <li className="cursor" onClick={() => shiftDetailModalHandler()}> */}

              {shiftData.length > 0 ? (
                shiftData.map((sd) => (
                  <li
                    className="cursor"
                    key={sd.id_event_date}
                    onClick={() => shiftDetailModalHandler(sd.id_event_date)}
                  >
                    <a
                      className={`shift ${
                        sd?.name == "Off Day" ? "non-clickable" : ""
                      }`}
                    >
                      <span className="icon">
                        <img
                          src={`${FRONTENDURL}/assets/img/${sd?.icon}.png`}
                          alt="afternoon icon"
                        />
                      </span>
                      <span
                        className={`text mr-1 text-capitalize active-event 
                          
                            `}
                      >
                        {sd?.user_name}
                      </span>
                    </a>
                    <p className="shift-time fw-500">
                      {sd?.name == "Off Day"
                        ? `${getDiffrenceHours(sd?.start_time, sd?.end_time)} h`
                        : moment(sd?.start_time).format("hh:mm A") +
                          " - " +
                          moment(sd?.end_time).format("hh:mm A")}

                      <span
                        className="font-sm color-text-30"
                        style={{ color: "rgba(32,33,35,.3)" }}
                      >
                        {" "}
                        {sd?.name == "Off Day"
                          ? ""
                          : `(${getDiffrenceHours(
                              sd?.start_time,
                              sd?.end_time
                            )} h)`}
                      </span>
                    </p>
                  </li>
                ))
              ) : (
                <li className="cursor">
                  <a className="non-clickable">
                    <span className="icon me-1">
                      <i className="bg-grey"></i>
                    </span>
                    <span className="text">
                      Your team members do not have shifts on this day.{" "}
                    </span>
                  </a>
                </li>
              )}

              {errors?.toDate && (
                <p className="error-msg">{errors.toDate.message}</p>
              )}
              {/* </li> */}
            </ul>
            {/* <div className="dropDownMenu">
              <div>
                <button
                  // aria-describedby={id}
                  variant="contained"
                  onClick={handleClick}
                  className="btn-rounded btn-add"
                >
                  <i className="icon-plus" />
                </button>
                <Popover
                  className="menu add-to-menu"
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <div className="menu-dropdown">
                    <ul className="drop-menu-list">
                      <li>
                        <a>
                          <span className="icon">
                            <i className="icon-switch-horizontal color-text-70 font-xl" />
                          </span>
                          <span
                            className="text cursor"
                            onClick={() =>
                              navigate("/switch-shift", {
                                state: {
                                  from: "/team",
                                },
                              })
                            }
                          >
                            Switch
                          </span>
                        </a>
                      </li>

                      <li
                      // onClick={() => {
                      //   logEvent("click Add Event in Calendar");
                      //   this.handleCurrentDate();
                      // }}
                      >
                        <a>
                          <span className="icon">
                            <i className="icon-calendar-remove color-text-70 font-lg" />
                          </span>
                          <span
                            className="text cursor"
                            // onClick={() =>
                            //   navigate("/add-event", {
                            //     state: { date: currentDate },
                            //   })
                            // }
                          >
                            Giveaway
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                        // onClick={() => {
                        //   logEvent("click Add Note in Calendar");
                        //   this.handleCurrentDate();
                        // }}
                        >
                          <span className="icon">
                            <i className="icon-share color-text-70 font-md" />
                          </span>
                          <span
                            className="text cursor"
                            onClick={() =>
                              navigate("/request-invite", {
                                state: {
                                  from: "/team",
                                },
                              })
                            }
                          >
                            Invite
                          </span>
                        </a>
                      </li>
                    </ul>

                    <div className="menu-footer">
                      <span className="font-md fw-800 text-capitalize">
                        Request
                      </span>
                    </div>
                  </div>
                </Popover>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/*Shift Modal */}
      <Modal
        show={shiftDetailModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        // backdrop="static"
        onHide={() => setShiftDetailModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setShiftDetailModalShow(false)}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span className="single-view-label">
                <img
                  src={`/assets/img/${shiftForModal?.icon}.png`}
                  alt="day icon"
                />
              </span>
              <div>
                <p className="font-lg fw-500 mb10 line-height-1">Shift</p>
                <p className="color-text-70 text-capitalize">
                  {shiftForModal?.name == "Off Day"
                    ? "Not Available"
                    : shiftForModal?.icon}
                </p>
              </div>
            </div>
            <div className="single-view-body">
              <p class="font-md fw-700 mb-4 text-capitalize">
                {shiftForModal?.user_name}
              </p>

              <ul className="list-with-icons">
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                  <span className="color-text-50 w-70">Start Time:</span>
                  <span className="color-text-70">
                    {moment(shiftForModal?.start_time).format("hh:mm A")}
                  </span>
                </li>
                <li>
                  {" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                  <span className="color-text-50 w-70">End Time:</span>
                  <span className="color-text-70">
                    {moment(shiftForModal?.end_time).format("hh:mm A")}
                  </span>
                </li>
              </ul>
              <p className="color-text-50 w-70 mb-2">
                Duration:{" "}
                {getDiffrenceHours(
                  shiftForModal?.start_time,
                  shiftForModal?.end_time
                )}
                h
              </p>
              <p className="color-text-50 w-70 mb-0">
                Location: {shiftForModal?.workplace_name}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      {/* <b>{eventInfo.timeText}</b> */}
      {/* <i>{eventInfo.event.title}</i> */}

      {eventInfo.backgroundColor ? (
        <ul className="event-dot-custom">
          <li className={`bg-${eventInfo.backgroundColor}`}></li>
        </ul>
      ) : (
        <div className="shift-day-img">
          <img
            src={`${FRONTENDURL}/${eventInfo.event.extendedProps.image_url}`}
            className="day-icon-img"
            alt="shift day"
          />
        </div>
      )}
    </>
  );
}
