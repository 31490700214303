import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useCallback, useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import Snackbar from "@mui/material/Snackbar";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import AlloyFinger from "alloyfinger";
import { useDispatch, useSelector } from "react-redux";

import { FRONTENDURL } from "../constant/api";
import Sidebar from "../Component/Sidebar";
import useRequest from "../hooks/useRequest";
import {
  capitalizeFirstLetter,
  classHandler,
  dotHandlerForCalendarView,
  getDiffrenceHours,
  getStartAndEndDate,
  shiftsImageHanlder,
  shiftSwitchFromNotification,
  dotHandlerForCalendarViewTwo,
} from "../utils/fn";
import { DeleteIcon } from "../Component/Svg";
import {
  addAllAssignedEvents,
  addAllAssignedNotes,
  addAllAssignedShifts,
  addAllWorkplaces,
  addEvents,
  isCalendarDataExist,
} from "../store/calendarData/action";
import Icons from "../Component/Icons";
import {
  addAllNotifications,
  addNotificationCount,
} from "../store/notifications/action";
import NotificationModal from "../Component/NotificationModal";
import { toast } from "react-toastify";
import UpgradeBanner from "../Component/UpgradeBanner";
import UpgradeMessageModal from "../Component/UpgradeMessageModal";

const dotColorOrderObj = {
  purple: 1,
  "purple-fade": 1,
  yellow: 2,
  "yellow-fade": 2,
  red: 3,
  "red-fade": 3,
  green: 4,
  "green-fade": 4,
};

export default function Calendar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    allAssignedShifts,
    isFirstTime,
    allAssignedEvents,
    allAssignedNotes,
  } = useSelector((state) => state.calendar);

  const { socket, role } = useSelector((state) => state.auth);

  const { notifications } = useSelector((state) => state.notifications);

  const navigate = useNavigate();
  const calendarRef = useRef();
  const currentMonthAndYearRef = useRef(moment().format("MM-YYYY"));
  const currentMonth = moment().format("MM-YYYY");

  const [defaultDateForPicker, setDefaultDateForPicker] = useState(
    moment(location.state?.date).format("MMMM YYYY") ||
      moment().format("MMMM YYYY")
  );

  const [isUpgradeModalShow, setIsUpgradeModalShow] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [shifts, setShifts] = useState([]); //All shifts
  const [events, setEvents] = useState([]); // icons on date

  const [selectedShiftId, setSelectedShiftId] = useState(); //For assign shift to date
  const [isAssigningShifts, setIsAssigningShifts] = useState(false); //for assign a shift to a date
  const [isAlreadyAsignedShiftSnakebar, setIsAlreadyAsignedShiftSnakebar] =
    useState(false);

  const [selectedDate, setSelectedDate] = useState(
    moment().format("MMMM Do YYYY")
  );
  const [shiftData, setShiftData] = useState(null);
  const [eventDataonADate, setEventDataonADate] = useState([]);
  const [noteDataonADate, setNoteDataonADate] = useState([]);
  const [notificationOnADate, setNotificationOnADate] = useState([]);
  const [idNotification, setIdNotification] = useState(null);
  const [notificationStatus, setNotificationStatus] = useState(null);

  const [
    locationChangeWarningModalAcceptSwitchShift,
    setLocationChangeWarningModalAcceptSwitchShift,
  ] = useState(false);

  const [
    locationChangeWarningModalAcceptGiveaway,
    setLocationChangeWarningModalAcceptGiveaway,
  ] = useState(false);

  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD")); // this is for go to day-detail page

  const [shiftDetailModalShow, setShiftDetailModalShow] = useState(false);
  const [eventDetailModalShow, setEventDetailModalShow] = useState(false);
  const [noteDetailModalShow, setNoteDetailModalShow] = useState(false);

  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const [notificationModalData, setNotificationModalData] = useState();
  const [sendingUserName, setSendingUserName] = useState("");

  const [selectedMonthAndYearForHeading, setSelectedMonthAndYearForHeading] =
    useState(moment().format("MMMM YYYY"));

  const { request, response } = useRequest();

  const { request: eventRequest, response: eventResponse } = useRequest();
  const { request: assignedShiftsRequest, response: assignedShiftsRespose } =
    useRequest();

  const {
    request: assignedShiftsForSocketRequest,
    response: assignedShiftsForSocketRespose,
  } = useRequest();

  const { request: workplacesRequest, response: workplacesResponse } =
    useRequest();

  const {
    request: assignShiftToADateRequest,
    response: assignShiftToADateResponse,
  } = useRequest();

  const {
    request: shiftDeleteOnDateRequest,
    response: shiftDeleteOnDateResponse,
  } = useRequest();

  const {
    request: eventDeleteOnDateRequest,
    response: eventDeleteOnDateResponse,
  } = useRequest();

  const {
    request: noteDeleteOnDateRequest,
    response: noteDeleteOnDateResponse,
  } = useRequest();

  const { request: notificationRequest, response: notificationResponse } =
    useRequest();
  const {
    request: notificationRequestBecauseOfSocket,
    response: notificationResponseBecauseOfSocket,
  } = useRequest();

  const {
    request: switchShiftAndGiveawayAcceptRejectRequest,
    response: switchShiftAndGiveawayAcceptRejectResponse,
    loading: switchShiftAndGiveawayAcceptRejectLoading,
  } = useRequest();

  const {
    request: invitationStatusRequest,
    response: invitationStatusResponse,
  } = useRequest();

  const swiperHandler = useCallback(
    function (evt) {
      let elem = document.getElementById("swiper_id");
      // setIsAssigningShifts(false);
      let currMonthYear;
      // alert(`evt.direction ${evt.direction}`);
      if (evt.direction == "Left") {
        elem.classList.add("left_swipe");
        elem.classList.remove("right_swipe");

        calendarRef.current.getApi().next();
        currMonthYear = calendarRef.current.calendar.currentData.viewTitle;
        setDefaultDateForPicker(
          moment(currMonthYear, "MMM YYYY").format("MMMM YYYY")
        );
        currentMonthAndYearRef.current = moment(
          currMonthYear,
          "MMM YYYY"
        ).format("MM-YYYY");
      } else if (evt.direction == "Right") {
        elem.classList.remove("left_swipe");
        elem.classList.add("right_swipe");

        calendarRef.current.getApi().prev();
        currMonthYear = calendarRef.current.calendar.currentData.viewTitle;
        setDefaultDateForPicker(
          moment(currMonthYear, "MMM YYYY").format("MMMM YYYY")
        );
        currentMonthAndYearRef.current = moment(
          currMonthYear,
          "MMM YYYY"
        ).format("MM-YYYY");
      } else {
        return;
      }

      // showAlert && alert(`swiperHandler ${currMonthYear}`);

      setSelectedMonthAndYearForHeading(currMonthYear);

      classHandler(moment(currMonthYear, "MMMM YYYY"));
      dateClickHandler(moment(currMonthYear, "MMMM YYYY"), true);
      // console.log("allAssignedShifts", allAssignedShifts);
      setEvents(
        allAssignedShifts.map((s) => ({
          start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          image_url: shiftsImageHanlder(
            s.date,
            s?.shift?.start_period,
            currentMonthAndYearRef.current
          ),
        }))
      );

      dotHandlerForCalendarViewTwo(
        allAssignedEvents,
        allAssignedNotes,
        notifications,
        allAssignedShifts.filter((s) => s?.shift?.is_shifted),
        currentMonthAndYearRef.current,
        setEvents
      );

      // dotHandlerForCalendarView(
      //   allAssignedEvents,
      //   setEvents,
      //   currentMonthAndYearRef.current,
      //   "purple"
      // );

      // dotHandlerForCalendarView(
      //   allAssignedNotes,
      //   setEvents,
      //   currentMonthAndYearRef.current,
      //   "yellow"
      // );

      // dotHandlerForCalendarView(
      //   notifications,
      //   setEvents,
      //   currentMonthAndYearRef.current,
      //   "red"
      // );

      // dotHandlerForCalendarView(
      //   allAssignedShifts.filter((s) => s?.shift?.is_shifted),
      //   setEvents,
      //   currentMonthAndYearRef.current,
      //   "green"
      // );
    },
    [allAssignedShifts, events, selectedShiftId]
  );

  useEffect(() => {
    request("GET", "v1/event/shifts");
    eventRequest("GET", "v1/event/events");

    notificationRequest("GET", "v1/notification/notifications");
    // const { startDate, endDate } = getStartAndEndDate(
    //   moment(currentDate, "YYYY-MM-DD")
    // );

    // console.log(startDate, endDate);

    const element = document.getElementsByClassName(
      "fc-scrollgrid-section-sticky"
    );
    if (element?.[0]?.classList) {
      element[0].classList.remove("fc-scrollgrid-section-sticky");
    }
    document.body?.classList?.remove("scroll-off");
    if (isFirstTime) {
      assignedShiftsRequest("GET", `v1/event/calender-events`);
    } else {
      if (location.state) {
        const { isAssigningShifts, date } = location.state;
        if (isAssigningShifts) {
          setIsAssigningShifts(location.state.isAssigningShifts);
        } else if (date) {
          currentMonthAndYearRef.current = moment(date, "YYYY-MM-DD").format(
            "MM-YYYY"
          );

          // showAlert && alert(`useEffect ${currentMonthAndYearRef.current}`);

          setSelectedMonthAndYearForHeading(
            moment(currentMonthAndYearRef.current, "MM-YYYY").format(
              "MMMM YYYY"
            )
          );

          let calendarApi = calendarRef.current.getApi();
          calendarApi.gotoDate(date);
          classHandler(date);
          dateClickHandler(moment(date, "YYYY-MM-DD"));
        }
        navigate("/calendar");
      } else {
        dateClickHandler(moment(currentDate));
      }
      setEvents(
        allAssignedShifts.map((s) => ({
          start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          image_url: shiftsImageHanlder(
            s.date,
            s?.shift?.start_period,
            currentMonthAndYearRef.current
          ),
        }))
      );

      dotHandlerForCalendarViewTwo(
        allAssignedEvents,
        allAssignedNotes,
        notifications,
        allAssignedShifts.filter((s) => s?.shift?.is_shifted),
        currentMonthAndYearRef.current,
        setEvents
      );

      // dotHandlerForCalendarView(
      //   allAssignedEvents,
      //   setEvents,
      //   currentMonthAndYearRef.current,
      //   "purple"
      // );

      // dotHandlerForCalendarView(
      //   allAssignedNotes,
      //   setEvents,
      //   currentMonthAndYearRef.current,
      //   "yellow"
      // );

      // dotHandlerForCalendarView(
      //   notifications,
      //   setEvents,
      //   currentMonthAndYearRef.current,
      //   "red"
      // );
      // This is for is shifted true
      // dotHandlerForCalendarView(
      //   allAssignedShifts.filter((s) => s?.shift?.is_shifted),
      //   setEvents,
      //   currentMonthAndYearRef.current,
      //   "green"
      // );
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("SwitchShiftUsersResponse", (data) => {
        if (data.status) {
          assignedShiftsForSocketRequest("GET", `v1/event/calender-events`);
        }
      });
      socket.on("NotificationResponse", (data) => {
        // console.log("data",data)
        if (data.status) {
          notificationRequestBecauseOfSocket(
            "GET",
            "v1/notification/notifications"
          );
        }
      });
      return () => {
        socket.off("SwitchShiftUsersResponse");
        socket.off("NotificationResponse");
      };
    }
  }, [socket]);

  useEffect(() => {
    let element = calendarRef.current.getApi().el;
    var af = new AlloyFinger(element, {
      swipe: swiperHandler,
    });
    return () => {
      af = af.destroy();
    };
  }, [swiperHandler]);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        setShifts(response?.data?.shifts);
      }
    }
  }, [response]);

  useEffect(() => {
    if (eventResponse) {
      if (eventResponse.status == "SUCCESS") {
        let events = eventResponse?.data?.events;
        dispatch(addEvents(events));
      }
    }
  }, [eventResponse]);

  useEffect(() => {
    if (notificationResponseBecauseOfSocket) {
      const { notifications_count, notifications } =
        notificationResponseBecauseOfSocket.data;
      dispatch(addAllNotifications(notifications));
      dispatch(addNotificationCount(notifications_count));

      let selectDate;
      if (currentMonthAndYearRef.current == moment().format("MM-YYYY")) {
        if (selectedDate) {
          selectDate = moment(selectedDate, "MMM Do YYYY").format("DD/MM/YYYY");
        } else {
          selectDate = moment().format("DD/MM/YYYY");
        }
      } else {
        selectDate = moment(currentMonthAndYearRef.current, "MM-YYYY").format(
          "DD/MM/YYYY"
        );
      }

      const notificationData = notifications.filter(
        (ad) => ad.date == selectDate
      );
      if (notificationData) {
        setNotificationOnADate(notificationData);
      } else {
        setNotificationOnADate([]);
      }
      // setEvents((prev) =>
      //   prev.filter((e) => !["red", "red-fade"].includes(e.color))
      // );

      dotHandlerForCalendarViewTwo(
        allAssignedEvents,
        allAssignedNotes,
        notifications,
        allAssignedShifts.filter((s) => s?.shift?.is_shifted),
        currentMonthAndYearRef.current,
        setEvents
      );

      // dotHandlerForCalendarView(
      //   notifications,
      //   setEvents,
      //   currentMonthAndYearRef.current,
      //   "red"
      // );
    }
  }, [notificationResponseBecauseOfSocket]);

  useEffect(() => {
    if (
      isFirstTime &&
      assignedShiftsRespose &&
      notificationResponse &&
      assignedShiftsRespose.status == "SUCCESS"
    ) {
      dispatch(isCalendarDataExist(false));
      let shifts = assignedShiftsRespose.data.shifts;
      let events = assignedShiftsRespose.data.events;
      let notes = assignedShiftsRespose.data.notes;
      dispatch(addAllAssignedShifts(shifts));
      dispatch(addAllAssignedEvents(events));
      dispatch(addAllAssignedNotes(notes));

      setEvents((prev) => [
        ...prev,
        ...shifts.map((s) => ({
          start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          image_url: shiftsImageHanlder(
            s.date,
            s?.shift?.start_period,
            currentMonthAndYearRef.current
          ),
          is_shifted: s?.shift?.is_shifted,
        })),
      ]);

      setShiftDetailModalShow(false);
      let selectDate;
      if (currentMonthAndYearRef.current == moment().format("MM-YYYY")) {
        if (selectedDate) {
          selectDate = moment(selectedDate, "MMM Do YYYY").format("DD/MM/YYYY");
        } else {
          selectDate = moment().format("DD/MM/YYYY");
        }
      } else {
        selectDate = moment(currentMonthAndYearRef.current, "MM-YYYY").format(
          "DD/MM/YYYY"
        );
      }

      const data = shifts.find((ad) => ad.date == selectDate);
      if (data) {
        setShiftData({
          icon: data.shift.start_period,
          name: data.shift.shift_name,
          start_time: data.shift.start_time,
          end_time: data.shift.end_time,
          id_shift: data.shift.id_shift,
          id_event_date: data.id_event_date,
          workplace_name: data.shift.workplace_name,
          is_shifted: data.shift.is_shifted,
          shifted_type: data.shift.shifted_type,
        });
      } else {
        setShiftData(null);
      }

      const eventData = events.filter((ad) => ad.date == selectDate);
      if (eventData) {
        setEventDataonADate(eventData);
      } else {
        setEventDataonADate([]);
      }

      const noteData = notes.filter((ad) => ad.date == selectDate);
      if (noteData) {
        setNoteDataonADate(noteData);
      } else {
        setNoteDataonADate([]);
      }

      {
        const { notifications_count, notifications } =
          notificationResponse.data;

        dispatch(addAllNotifications(notifications));
        dispatch(addNotificationCount(notifications_count));

        let selectDate;
        if (currentMonthAndYearRef.current == moment().format("MM-YYYY")) {
          if (selectedDate) {
            selectDate = moment(selectedDate, "MMM Do YYYY").format(
              "DD/MM/YYYY"
            );
          } else {
            selectDate = moment().format("DD/MM/YYYY");
          }
        } else {
          selectDate = moment(currentMonthAndYearRef.current, "MM-YYYY").format(
            "DD/MM/YYYY"
          );
        }

        const notificationData = notifications.filter(
          (ad) => ad.date == selectDate
        );
        if (notificationData) {
          setNotificationOnADate(notificationData);
        } else {
          setNotificationOnADate([]);
        }

        dotHandlerForCalendarViewTwo(
          events,
          notes,
          notifications,
          shifts.filter((s) => s?.shift?.is_shifted),
          currentMonthAndYearRef.current,
          setEvents
        );
      }
    } else if (!isFirstTime && notificationResponse) {
      const { notifications_count, notifications } = notificationResponse.data;
      dispatch(addAllNotifications(notifications));
      dispatch(addNotificationCount(notifications_count));

      let selectDate;
      if (currentMonthAndYearRef.current == moment().format("MM-YYYY")) {
        if (selectedDate) {
          selectDate = moment(selectedDate, "MMM Do YYYY").format("DD/MM/YYYY");
        } else {
          selectDate = moment().format("DD/MM/YYYY");
        }
      } else {
        selectDate = moment(currentMonthAndYearRef.current, "MM-YYYY").format(
          "DD/MM/YYYY"
        );
      }

      const notificationData = notifications.filter(
        (ad) => ad.date == selectDate
      );
      if (notificationData) {
        setNotificationOnADate(notificationData);
      } else {
        setNotificationOnADate([]);
      }

      dotHandlerForCalendarViewTwo(
        allAssignedEvents,
        allAssignedNotes,
        notifications,
        allAssignedShifts.filter((s) => s?.shift?.is_shifted),
        currentMonthAndYearRef.current,
        setEvents
      );
    }
  }, [assignedShiftsRespose, notificationResponse, isFirstTime]);

  useEffect(() => {
    if (assignedShiftsForSocketRespose) {
      if (assignedShiftsForSocketRespose.status == "SUCCESS") {
        let shifts = assignedShiftsForSocketRespose.data.shifts;
        let events = assignedShiftsForSocketRespose.data.events;
        let notes = assignedShiftsForSocketRespose.data.notes;
        dispatch(addAllAssignedShifts(shifts));
        dispatch(addAllAssignedEvents(events));
        dispatch(addAllAssignedNotes(notes));

        setEvents((prev) => [
          ...shifts.map((s) => ({
            start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            image_url: shiftsImageHanlder(
              s.date,
              s?.shift?.start_period,
              currentMonthAndYearRef.current
            ),
            is_shifted: s?.shift?.is_shifted,
          })),
        ]);
        dotHandlerForCalendarViewTwo(
          events,
          notes,
          notifications,
          shifts.filter((s) => s?.shift?.is_shifted),
          currentMonthAndYearRef.current,
          setEvents
        );

        setShiftDetailModalShow(false);
        let selectDate;
        if (currentMonthAndYearRef.current == moment().format("MM-YYYY")) {
          if (selectedDate) {
            selectDate = moment(selectedDate, "MMM Do YYYY").format(
              "DD/MM/YYYY"
            );
          } else {
            selectDate = moment().format("DD/MM/YYYY");
          }
        } else {
          selectDate = moment(currentMonthAndYearRef.current, "MM-YYYY").format(
            "DD/MM/YYYY"
          );
        }

        const data = shifts.find((ad) => ad.date == selectDate);
        if (data) {
          setShiftData({
            icon: data.shift.start_period,
            name: data.shift.shift_name,
            start_time: data.shift.start_time,
            end_time: data.shift.end_time,
            id_shift: data.shift.id_shift,
            id_event_date: data.id_event_date,
            workplace_name: data.shift.workplace_name,
            is_shifted: data.shift.is_shifted,
            shifted_type: data.shift.shifted_type,
          });
        } else {
          setShiftData(null);
        }

        const eventData = events.filter((ad) => ad.date == selectDate);
        if (eventData) {
          setEventDataonADate(eventData);
        } else {
          setEventDataonADate([]);
        }

        const noteData = notes.filter((ad) => ad.date == selectDate);
        if (noteData) {
          setNoteDataonADate(noteData);
        } else {
          setNoteDataonADate([]);
        }
      }
    }
  }, [assignedShiftsForSocketRespose]);

  useEffect(() => {
    if (assignShiftToADateResponse) {
      if (assignShiftToADateResponse.status == "SUCCESS") {
        let newShift = assignShiftToADateResponse.data.new_shift_date;
        // let previousShifts = assignedShifts.filter(
        //   (as) => as.date != newShift.date
        // );
        let previousShifts = allAssignedShifts.filter(
          (as) => as.date != newShift.date
        );
        dispatch(addAllAssignedShifts([...previousShifts, newShift]));
        // setAssignedShifts([...previousShifts, newShift]);
        if (
          currentDate ==
          moment(newShift.date, "DD/MM/YYYY").format("YYYY-MM-DD")
        ) {
          setShiftData({
            icon: newShift.shift.start_period,
            name: newShift.shift.shift_name,
            start_time: newShift.shift.start_time,
            end_time: newShift.shift.end_time,
            id_shift: newShift.shift.id_shift,
            id_event_date: newShift.id_event_date,
            workplace_name: newShift.shift.workplace_name,
            is_shifted: newShift.shift.is_shifted,
            shifted_type: newShift.shift.shifted_type,
          });
        }
        // setEvents((prev) => [
        //   ...prev,
        //   {
        //     start: moment(newShift.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
        //     image_url: shiftsImageHanlder(
        //       newShift.date,
        //       newShift?.shift?.start_period,
        //       currentMonthAndYearRef.current
        //     ),
        //   },
        // ]);

        // const { startDate, endDate } = getStartAndEndDate(
        //   moment(currentDate, "YYYY-MM-DD")
        // );
        // assignedShiftsRequest(
        //   "GET",
        //   `v1/event/calender-events?start_date=${startDate}&end_date=${endDate}`
        // );
      }
    }
  }, [assignShiftToADateResponse]);

  useEffect(() => {
    if (switchShiftAndGiveawayAcceptRejectResponse) {
      setNotificationModalShow(false);
      setLocationChangeWarningModalAcceptSwitchShift(false);
      setLocationChangeWarningModalAcceptGiveaway(false);
      if (switchShiftAndGiveawayAcceptRejectResponse.status == "SUCCESS") {
        let newNotifictions = notifications.map((noti) => {
          if (noti.id_notification == idNotification) {
            noti = { ...noti, status: notificationStatus };
          }
          return noti;
        });

        if (notificationOnADate.length == 1) {
          setNotificationOnADate(
            newNotifictions.filter((d) => d.id_notification == idNotification)
          );
        }

        dispatch(addAllNotifications(newNotifictions));
      } else if (switchShiftAndGiveawayAcceptRejectResponse.status == "FAIL") {
        toast.error(switchShiftAndGiveawayAcceptRejectResponse.message);
        let newNotifictions = notifications.map((noti) => {
          if (noti.id_notification == idNotification) {
            noti = { ...noti, is_covered: true };
          }
          return noti;
        });

        if (notificationOnADate.length == 1) {
          setNotificationOnADate(
            newNotifictions.filter((d) => d.id_notification == idNotification)
          );
        }

        dispatch(addAllNotifications(newNotifictions));
      }
    }
  }, [switchShiftAndGiveawayAcceptRejectResponse]);

  const selectedShiftHandler = (id_shift) => {
    setSelectedShiftId(id_shift);
  };

  const dateClickHandler = (date, isSwiper = false) => {
    if (currentMonthAndYearRef.current != moment(date).format("MM-YYYY")) {
      return;
    }
    setIsAlreadyAsignedShiftSnakebar(false);
    classHandler(date);
    let selectDate = moment(date).format("DD/MM/YYYY");
    setCurrentDate(moment(date).format("YYYY-MM-DD"));

    if (selectedShiftId && isAssigningShifts && !isSwiper) {
      if (role == "blocked_user") {
        let selectDate = moment(date).format("MM-YYYY");
        if (selectDate != currentMonth) {
          // navigate("/upgrade-membership");
          setIsUpgradeModalShow(true);
          // <UpgradeMessageModal />;
          return;
        }
      }
      let isAlreadyAsignedShift = allAssignedShifts.find(
        (ad) => ad.date == selectDate
      );

      if (isAlreadyAsignedShift) {
        setIsAlreadyAsignedShiftSnakebar(true);
        return;
      }
      let newShift = shifts.find((shift) => shift.id_shift == selectedShiftId);

      newShift = {
        shift: {
          start_period: newShift.start_period,
          shift_name: newShift.shift_name,
          start_time: newShift.start_time,
          end_time: newShift.end_time,
          id_shift: newShift.id_shift,
        },
      };

      dispatch(
        addAllAssignedShifts([
          ...allAssignedShifts,
          {
            ...newShift,
            date: moment(date).format("DD/MM/YYYY"),
          },
        ])
      );
      setEvents((prev) => [
        ...prev,
        {
          start: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          image_url: shiftsImageHanlder(
            date,
            newShift?.shift.start_period,
            currentMonthAndYearRef.current
          ),
        },
      ]);

      setShiftData({
        icon: newShift.shift.start_period,
        name: newShift.shift.shift_name,
        start_time: newShift.shift.start_time,
        end_time: newShift.shift.end_time,
        id_shift: newShift.shift.id_shift,
        workplace_name: newShift.shift.workplace_name,
        is_shifted: newShift.shift.is_shifted,
        shifted_type: newShift.shift.shifted_type,
      });

      assignShiftToADateRequest("POST", "v1/event/add-shift-date", {
        id_shift: selectedShiftId,
        date: moment(date).format("YYYY-MM-DD"),
      });
    } else {
      setSelectedDate(moment(date).format("MMMM Do YYYY"));

      // if (assignedShifts && assignedShifts.length > 0) {
      if (allAssignedShifts && allAssignedShifts.length > 0) {
        // const data = assignedShifts.find((ad) => ad.date == selectDate);
        const data = allAssignedShifts.find((ad) => ad.date == selectDate);
        // console.log(data, "data", assignedShifts, selectDate);

        if (data) {
          const { shift } = data;
          setShiftData({
            icon: shift.start_period,
            name: shift.shift_name,
            start_time: shift.start_time,
            end_time: shift.end_time,
            id_shift: shift.id_shift,
            id_event_date: data.id_event_date,
            workplace_name: shift.workplace_name,
            is_shifted: shift.is_shifted,
            shifted_type: shift.shifted_type,
          });
        } else {
          setShiftData(null);
        }
      } else {
        setShiftData(null);
      }

      if (allAssignedEvents && allAssignedEvents.length > 0) {
        // const data = assignedShifts.find((ad) => ad.date == selectDate);
        const data = allAssignedEvents.filter((ad) => ad.date == selectDate);
        // console.log(data, "data", assignedShifts, selectDate);
        if (data) {
          setEventDataonADate(data);
        } else {
          setEventDataonADate([]);
        }
      } else {
        setEventDataonADate([]);
      }

      if (allAssignedNotes && allAssignedNotes.length > 0) {
        // const data = assignedShifts.find((ad) => ad.date == selectDate);
        const data = allAssignedNotes.filter((ad) => ad.date == selectDate);
        // console.log(data, "data", assignedShifts, selectDate);
        if (data) {
          setNoteDataonADate(data);
        } else {
          setNoteDataonADate([]);
        }
      } else {
        setNoteDataonADate([]);
      }

      if (notifications && notifications.length > 0) {
        // const data = assignedShifts.find((ad) => ad.date == selectDate);
        const data = notifications.filter((ad) => ad.date == selectDate);
        // console.log(data, "data", notifications, selectDate);
        if (data) {
          setNotificationOnADate(data);
        } else {
          setNotificationOnADate([]);
        }
      } else {
        setNotificationOnADate([]);
      }
    }
  };
  // console.log("notificationOnADate", notificationOnADate);

  const eventClickHandler = (date) => {
    dateClickHandler(date);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const events = [
  //   {
  //     title: "Meeting",
  //     start: new Date(),
  //     image_url: "assets/img/afternoon.png",
  //   },
  //   {
  //     start: new Date("2023-05-14"),
  //     image_url: "assets/img/day.png",
  //   },
  // ];
  const goToDayDeatailHandler = () => {
    if (
      shiftData ||
      noteDataonADate.length > 0 ||
      eventDataonADate.length > 0
    ) {
      navigate(`/calendar/day-details/${currentDate}`);
    }
  };

  const dayCellDidMountHandler = (e) => {
    let cell = e.el;
    if (moment(e.date).format("MM-YYYY") == currentMonthAndYearRef.current) {
      cell.classList.add("cursor");
    }
    if (e.isToday) {
      cell.classList.add("select-today");
    }
  };

  const dayHeaderDidMountHandler = (e) => {
    let day = e.el;
    if (moment().format("ddd") == e.text) {
      day.classList.add("day-color");
    }
  };

  const shiftDeleteOnDateHandler = (id) => {
    setShiftDetailModalShow(false);
    // let newShifts = assignedShifts.filter((shift) => shift.id_event_date != id);
    // setAssignedShifts(newShifts);
    let newShifts = allAssignedShifts.filter(
      (shift) => shift.id_event_date != id
    );
    dispatch(addAllAssignedShifts(newShifts));

    let newEvents = events.filter((event) => {
      if (
        event.start == currentDate
        //  ||
        // (event.start ==
        //   moment(currentDate, "YYYY-MM-DD").format("YYYY-MM-DDT02:20:30") &&
        //   event.color.split("-")[0] == "green")
      ) {
        return false;
      } else {
        return true;
      }
    });

    newEvents = newEvents.map((event) => {
      if (
        event.start ==
          moment(currentDate, "YYYY-MM-DD").format("YYYY-MM-DDT02:20:30") &&
        event.dots[3].split("-")[0] == "green"
      ) {
        const dots = event.dots;
        dots[3] = "white";
        return {
          ...event,
          dots,
        };
      }
      return {
        ...event,
      };
    });

    setEvents(newEvents);
    setShiftData(null);
    if (id) {
      shiftDeleteOnDateRequest("DELETE", `v1/event/event-date-delete/${id}`);
    }
  };
  const shiftDetailModalHandler = () => {
    if (shiftData) {
      setShiftDetailModalShow(true);
    }
  };

  const eventDeleteOnDateHandler = (id) => {
    setEventDetailModalShow(false);
    let newEvents = allAssignedEvents.filter(
      (event) => event.id_event_date != id
    );
    dispatch(addAllAssignedEvents(newEvents));
    let date = moment(currentDate, "YYYY-MM-DD")
      .set({ hour: 2, minute: 20, second: 30 })
      .format("YYYY-MM-DDTHH:mm:ss");

    let newEvent = events.filter((event) => {
      if (event.start == date) {
        if (["purple-fade", "purple"].includes(event.dots[0])) {
          const dots = event.dots;
          dots[0] = "white";
          return {
            ...event,
            dots,
          };
        }
        return {
          ...event,
        };
      } else {
        return {
          ...event,
        };
      }
    });
    // console.log("newEvent", newEvent);
    setEvents(newEvent);
    setEventDataonADate([]);
    if (id) {
      eventDeleteOnDateRequest("DELETE", `v1/event/event-date-delete/${id}`);
    }
  };

  const noteDeleteOnDateHandler = (id) => {
    setNoteDetailModalShow(false);
    let newNotes = allAssignedNotes.filter((event) => event.id_note != id);
    dispatch(addAllAssignedNotes(newNotes));
    let date = moment(currentDate, "YYYY-MM-DD")
      .set({ hour: 2, minute: 20, second: 30 })
      .format("YYYY-MM-DDTHH:mm:ss");

    let newEvent = events.map((event) => {
      if (event.start == date) {
        if (["yellow-fade", "yellow"].includes(event.dots[1])) {
          const dots = event.dots;
          dots[1] = "white";
          return {
            ...event,
            dots,
          };
        }
        return {
          ...event,
        };
      } else {
        return {
          ...event,
        };
      }
    });
    setEvents(newEvent);
    setNoteDataonADate([]);
    if (id) {
      noteDeleteOnDateRequest("DELETE", `v1/event/note-delete/${id}`);
    }
  };

  // if (showAlert) {
  //   alert(`first - ${selectedMonthAndYearForHeading}`);
  //   alert(`2 - ${newStateForCheck}`);
  // }
  const switchShiftAndGiveawayAcceptRejectHandler = (
    to_user_status,
    notification,
    api
  ) => {
    if (role == "blocked_user") {
      setIsUpgradeModalShow(true);
      return;
    }
    const {
      id_notification,
      switch_shift_request_id: id_switch_shift,
      receiver_user_event_date: myShfit,
      sending_user_event_date: senderShift,
    } = notification;

    setIdNotification(id_notification);
    setNotificationStatus(to_user_status);
    switchShiftAndGiveawayAcceptRejectRequest(
      "POST",
      `v1/switch-shift/${api}`,
      {
        to_user_status,
        id_switch_shift,
        id_notification,
      }
    );
    // setNotificationModalShow(false);
  };

  const invitationStatusHanlder = (id, status, id_notification) => {
    invitationStatusRequest(
      "POST",
      "v1/share-calendar/update-connection-status",
      {
        id_share_calendar_request: id,
        status,
        is_request: true,
      }
    );

    let newNotifictions = notifications.map((noti) => {
      if (noti.id_notification == id_notification) {
        noti = { ...noti, status: status };
      }
      return noti;
    });

    // let newNotifictions = notifications.map((noti) => {
    //   return {
    //     date: noti.date,
    //     notifications: noti.notifications.map((n) => {
    //       if (n.share_calendar_request_id == id) {
    //         n = { ...n, status };
    //       }
    //       return n;
    //     }),
    //   };
    // });
    dispatch(addAllNotifications(newNotifictions));
    // setNotification(newNotifictions);
    setNotificationModalShow(false);
  };

  const blockUserPermissionHandler = (route) => {
    if (role == "blocked_user") {
      let selectDate = moment(currentDate, "YYYY-MM-DD").format("MM-YYYY");
      if (selectDate != currentMonth) {
        // navigate("/upgrade-membership");
        // <UpgradeMessageModal />;
        handleClose();
        setIsUpgradeModalShow(true);
        return;
      } else {
        navigate(route, {
          state: { date: currentDate },
        });
      }
    } else {
      navigate(route, {
        state: { date: currentDate },
      });
    }
  };

  return (
    <div className="content-center lightBg ">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <Sidebar
        from="calendar"
        calendarRef={calendarRef}
        dateClickHandler={dateClickHandler}
        assignedShiftsRequest={assignedShiftsRequest}
        currentMonthAndYearRef={currentMonthAndYearRef}
        setSelectedMonthAndYearForHeading={setSelectedMonthAndYearForHeading}
        selectedMonthAndYearForHeading={selectedMonthAndYearForHeading}
        setEvents={setEvents}
        defaultDateForPicker={defaultDateForPicker}
        setDefaultDateForPicker={setDefaultDateForPicker}
        currentDate={currentDate}
      />
      <div className="page-section ">
        <div className="container">
          <div className="SingleDatePicker SingleDatePicker_1" id="swiper_id">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={events}
              // hiddenDays={[]}
              eventContent={renderEventContent}
              dateClick={(e) => dateClickHandler(e.date)}
              // selectable={true}
              ref={calendarRef}
              eventClick={(e) => eventClickHandler(e.event.start)}
              dayCellDidMount={dayCellDidMountHandler}
              dayHeaderDidMount={dayHeaderDidMountHandler}
              // eventDisplay={"list-item"}
              // contentHeight={10}
              // dayCellWillUnmount={function (arg) {
              //   console.log(arg, "dayCellWillUnmount");
              // }}
              // dayCellClassNames={function (arg) {
              //   console.log(arg, "dayCellClassNames");
              // }}
              // dayCellContent={function (arg) {
              //   console.log(arg, "dayCellContent");
              // }}

              fixedWeekCount={false}
              height="auto"
            />
          </div>

          {isAssigningShifts ? (
            <div className="quick-add">
              <p className="color-text-50 fw-500 mb20 ml10">
                Choose from the following
              </p>
              <div className="ScrollbarsCustom trackXVisible">
                <div className="ScrollbarWrapper">
                  <div className="ScrollbarContent">
                    <ul className="quick-add-list">
                      {shifts &&
                        shifts.map((shift) => (
                          <li
                            className={
                              selectedShiftId == shift.id_shift && "selected"
                            }
                            key={shift.id_shift}
                            onClick={() => selectedShiftHandler(shift.id_shift)}
                          >
                            <img
                              src={`/assets/img/${shift?.start_period}.png`}
                              className="day-icon-img"
                              alt="shift day"
                            />
                            <p className="name text-capitalize">
                              {shift.shift_name == "Off Day"
                                ? "Not Available"
                                : shift.shift_name}
                            </p>
                            <p className="duration">
                              {moment(shift?.start_time).format("hh:mm A")} -
                              {moment(shift?.end_time).format("hh:mm A")}(
                              {getDiffrenceHours(
                                shift?.start_time,
                                shift?.end_time
                              )}{" "}
                              h)
                            </p>
                            <p className="type text-capitalize">
                              {capitalizeFirstLetter(
                                shift.start_period == "off_day"
                                  ? "Day"
                                  : shift.start_period
                              )}
                            </p>
                          </li>
                        ))}
                      <li className="add-shift">
                        <Link to="/add-shift?quick-mode=true">
                          <div className="icon">
                            <i className="icon-plus"></i>
                          </div>
                          <p className="name">Add Shift</p>
                          <p className="duration">Duration</p>
                          <p className="type">Shift Type</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {isAlreadyAsignedShiftSnakebar && (
                <div className="notification">
                  <button
                    onClick={() => setIsAlreadyAsignedShiftSnakebar(false)}
                    className="close"
                  >
                    <i className="icon-close" />
                  </button>
                  <div className="d-flex align-items-center">
                    <i className="icon-warning color-warning me-3" />
                    <p>You cannot add a shift for this day</p>
                  </div>
                </div>
              )}
              <button
                className="btn-rounded btn-cancel"
                onClick={() => {
                  setIsAssigningShifts(false);
                  setSelectedShiftId("");
                  handleClose();
                }}
              >
                <i className="icon-close"></i>
              </button>
            </div>
          ) : (
            <>
              <div className="calendar-info">
                <div className="infoHeader">
                  <span className="color-text-50 fw-500 py-1">
                    {selectedDate}
                  </span>
                  <a
                    className={`btn-icon color-primary ${
                      shiftData ||
                      eventDataonADate.length > 0 ||
                      noteDataonADate.length > 0
                        ? "cursor"
                        : "eventZero"
                    }`}
                    onClick={goToDayDeatailHandler}
                  >
                    <i className="icon-list"></i>
                  </a>
                </div>
                <ul className="day-details">
                  <li
                    className="cursor"
                    onClick={() => shiftDetailModalHandler()}
                  >
                    {shiftData ? (
                      <>
                        {" "}
                        <a
                          className={`shift ${
                            shiftData?.name == "Off Day" ? "non-clickable" : ""
                          }`}
                        >
                          <span className="icon">
                            <img
                              src={`${FRONTENDURL}/assets/img/${shiftData?.icon}.png`}
                              alt="afternoon icon"
                            />
                          </span>
                          <span
                            className={`text mr-1 text-capitalize active-event 
                          
                            `}
                          >
                            {shiftData?.name == "Off Day"
                              ? "Not Available"
                              : shiftData?.name}
                          </span>
                        </a>
                        <p className="shift-time fw-500">
                          {shiftData?.name == "Off Day"
                            ? `${getDiffrenceHours(
                                shiftData?.start_time,
                                shiftData?.end_time
                              )} h`
                            : moment(shiftData?.start_time).format("hh:mm A") +
                              " - " +
                              moment(shiftData?.end_time).format("hh:mm A")}

                          <span
                            className="font-sm color-text-30"
                            style={{ color: "rgba(32,33,35,.3)" }}
                          >
                            {" "}
                            {shiftData?.name == "Off Day"
                              ? ""
                              : `(${getDiffrenceHours(
                                  shiftData?.start_time,
                                  shiftData?.end_time
                                )} h)`}
                          </span>
                        </p>
                      </>
                    ) : (
                      <a className="non-clickable">
                        <span className="icon">
                          <i className="bg-grey"></i>
                        </span>
                        <span className="text">Shift (0) </span>
                      </a>
                    )}
                  </li>
                  {notificationOnADate.length > 0 ? (
                    <li>
                      <a
                        className="non-clickable"
                        // href="/calendar/notifications/undefined"

                        onClick={() => {
                          if (notificationOnADate.length == 1) {
                            setNotificationModalShow(true);
                            setNotificationModalData(notificationOnADate[0]);
                            setSendingUserName(
                              `${notificationOnADate[0].sending_user?.first_name} ${notificationOnADate[0].sending_user?.last_name}`
                            );
                          } else if (notificationOnADate.length > 1) {
                            navigate(`/notifications-list?date=${currentDate}`);
                          }
                        }}
                      >
                        <span className="icon">
                          <i className="bg-pink"></i>
                        </span>
                        <span className="text active-event">
                          {notificationOnADate.length == 1
                            ? notificationOnADate[0].notification_text
                            : `Notifications (${notificationOnADate.length})`}{" "}
                        </span>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a
                        className="non-clickable"
                        // href="/calendar/notifications/undefined"
                      >
                        <span className="icon">
                          <i className="bg-pink"></i>
                        </span>
                        <span className="text">Notifications (0) </span>
                      </a>
                    </li>
                  )}

                  {eventDataonADate.length > 0 ? (
                    <li
                      onClick={() => {
                        if (eventDataonADate.length == 1) {
                          setEventDetailModalShow(true);
                        } else if (eventDataonADate.length > 1) {
                          navigate(`/day-personal-events/${currentDate}`);
                        }
                      }}
                    >
                      <a
                        className="non-clickable"

                        // href="/day-personal-events/2023-05-23"
                      >
                        <span className="icon">
                          <i className="bg-purple"></i>
                        </span>
                        <span className="text text-capitalize active-event">
                          Personal Events ({eventDataonADate.length})
                        </span>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a
                        className="non-clickable"
                        // href="/day-personal-events/2023-05-23"
                      >
                        <span className="icon">
                          <i className="bg-purple"></i>
                        </span>
                        <span className="text text-capitalize">
                          Personal Events (0)
                        </span>
                      </a>
                    </li>
                  )}

                  {noteDataonADate.length > 0 ? (
                    <li>
                      <a
                        className="non-clickable"
                        onClick={() => {
                          if (noteDataonADate.length == 1) {
                            setNoteDetailModalShow(true);
                          } else if (noteDataonADate.length > 1) {
                            navigate(`/day-note/${currentDate}`);
                          }
                        }}
                      >
                        <span className="icon">
                          <i className="bg-orange"></i>
                        </span>
                        <span className="text active-event limit-word ">
                          Notes ({noteDataonADate.length})
                        </span>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a className="non-clickable">
                        <span className="icon">
                          <i className="bg-orange"></i>
                        </span>
                        <span className="text  limit-word ">Notes (0)</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="dropDownMenu">
                <div>
                  <button
                    // aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    className="btn-rounded btn-add"
                  >
                    <i className="icon-plus" />
                  </button>
                  <Popover
                    className="menu add-to-menu"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <div className="menu-dropdown">
                      <ul className="drop-menu-list">
                        <li>
                          <a onClick={() => setIsAssigningShifts(true)}>
                            <span className="icon">
                              <i className="bg-primary dot-icon" />
                            </span>
                            <span className="text cursor">Add Shift</span>
                          </a>
                        </li>

                        <li
                        // onClick={() => {
                        //   logEvent("click Add Event in Calendar");
                        //   this.handleCurrentDate();
                        // }}
                        >
                          <a>
                            <span className="icon">
                              <i className="bg-purple dot-icon" />
                            </span>
                            <span
                              className="text cursor"
                              onClick={() =>
                                blockUserPermissionHandler("/add-event")
                              }
                            >
                              Add Event
                            </span>
                          </a>
                        </li>

                        <li>
                          <a
                          // onClick={() => {
                          //   logEvent("click Add Note in Calendar");
                          //   this.handleCurrentDate();
                          // }}
                          >
                            <span className="icon">
                              <i className="bg-orange dot-icon" />
                            </span>
                            <span
                              className="text cursor"
                              onClick={() =>
                                blockUserPermissionHandler("/add-note")
                              }
                            >
                              Add Note
                            </span>
                          </a>
                        </li>
                      </ul>

                      <div className="menu-footer">
                        <span className="font-md fw-800 text-capitalize">
                          Add new
                        </span>
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/*Shift Modal */}
      <Modal
        show={shiftDetailModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        // backdrop="static"
        onHide={() => setShiftDetailModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setShiftDetailModalShow(false)}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span className="single-view-label">
                <img
                  src={`/assets/img/${shiftData?.icon}.png`}
                  alt="day icon"
                />
              </span>
              <div>
                <p className="font-lg fw-500 mb10 line-height-1">
                  Shift{" "}
                  {shiftData?.is_shifted &&
                  shiftData?.shifted_type == "giveaway" ? (
                    <span style={{ color: "#29d657" }}> (Pick Up) </span>
                  ) : shiftData?.shifted_type == "switch" ? (
                    <span style={{ color: "#29d657" }}>(Switched)</span>
                  ) : (
                    ""
                  )}{" "}
                </p>
                <p className="color-text-70 text-capitalize">
                  {shiftData?.name == "Off Day"
                    ? "Not Available"
                    : shiftData?.icon}
                </p>
              </div>
            </div>
            <div className="single-view-body">
              <p className="font-md fw-700 mb-4 text-capitalize">
                {shiftData?.name}
              </p>
              <ul className="list-with-icons">
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                  <span className="color-text-50 w-70">Start Time:</span>
                  <span className="color-text-70">
                    {moment(shiftData?.start_time).format("hh:mm A")}
                  </span>
                </li>
                <li>
                  {" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                  <span className="color-text-50 w-70">End Time:</span>
                  <span className="color-text-70">
                    {moment(shiftData?.end_time).format("hh:mm A")}
                  </span>
                </li>
              </ul>
              <p className="color-text-50 w-70 mb-2">
                Duration:{" "}
                {getDiffrenceHours(shiftData?.start_time, shiftData?.end_time)}h
              </p>
              <p className="color-text-50 w-70 mb-0">
                Location: {shiftData?.workplace_name}
              </p>
            </div>
            {!shiftData?.is_shifted && (
              <div
                className="single-view-footer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {/* {shiftData?.name == "Off Day" ? (
                ""
              ) : (
                <Link
                  className="btn-icon w-30 color-text"
                  to={`/edit-shift/${shiftData?.id_shift}`}
                >
                  <i className="icon-edit icon-shadow"></i>
                </Link>
              )} */}
                <a
                  className="btn-icon w-30 color-warning cursor"
                  onClick={() =>
                    shiftDeleteOnDateHandler(shiftData?.id_event_date)
                  }
                >
                  {/* <i className="fas fa-trash" /> */}
                  <DeleteIcon />
                </a>
              </div>
            )}
          </div>
        </div>
      </Modal>

      {/*Event Modal */}
      <Modal
        show={eventDetailModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        // backdrop="static"
        onHide={() => setEventDetailModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setEventDetailModalShow(false)}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span
                className={`${
                  !eventDataonADate[0]?.is_default ? "icon-font" : ""
                } label-icon bg-purple view_design`}
              >
                {/* <span className="label-icon bg-purple view_design"> */}
                <i
                  className={`${
                    eventDataonADate[0]?.is_default
                      ? `icon-${
                          Icons[eventDataonADate[0]?.icon_name]
                        } icon-font-size`
                      : "fas fa-ellipsis-h"
                  } color-white box-30`}
                />
              </span>
              <div className="inner_header_view">
                <p className="font-lg fw-500 mb10 line-height-1">
                  Personal Events
                </p>
                <p className="color-text-70 text-capitalize">
                  {/* {eventDataonADate?.icon} */}{" "}
                  {eventDataonADate[0]?.event_name}
                </p>
              </div>
            </div>

            <div className="single-view-body">
              <p className="font-md fw-700 mb-4 text-capitalize">
                {eventDataonADate[0]?.icon_name}
              </p>
              <p className="font-md fw-700 mb-4 text-capitalize">
                {/* {eventDataonADate?.shift_name} */}
              </p>
              <ul className="list-with-icons">
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                  <span className="color-text-50 w-70">Start Time:</span>
                  <span className="color-text-70">
                    {eventDataonADate[0]?.start_time ? moment(eventDataonADate[0]?.start_time).format("hh:mm A") : ''}
                  </span>
                </li>
                <li>
                  {" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                  <span className="color-text-50 w-70">End Time:</span>
                  <span className="color-text-70">
                    {eventDataonADate[0]?.end_time  ? moment(eventDataonADate[0]?.end_time).format("hh:mm A"): ''}
                  </span>
                </li>
              </ul>
            </div>
            <div
              className="single-view-footer"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span
                className="btn-icon w-30 color-text cursor"
                // to={`/edit-event/${eventDataonADate[0]?.id_event_date}`}
                onClick={() =>
                  navigate(
                    `/edit-event/${eventDataonADate[0]?.id_event_date}`,
                    {
                      state: {
                        date: currentDate,
                      },
                    }
                  )
                }
              >
                <i className="icon-edit icon-shadow blue-color"></i>
              </span>
              <a
                className="btn-icon w-30 color-warning cursor"
                onClick={() =>
                  eventDeleteOnDateHandler(eventDataonADate[0]?.id_event_date)
                }
              >
                {/* <i className="fas fa-trash" /> */}
                <DeleteIcon />
              </a>
            </div>
          </div>
        </div>
      </Modal>

      {/*Note Modal */}
      <Modal
        show={noteDetailModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        // backdrop="static"
        onHide={() => setNoteDetailModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setNoteDetailModalShow(false)}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span className="label-icon bg-orange  view_design"></span>
              <div className="inner_header_view">
                <p className="font-lg fw-500 mb10 line-height-1">Note</p>
              </div>
            </div>

            <div className="single-view-body">
              <p className="font-md fw-700 mb-4">
                {/* this is note */}
                {noteDataonADate[0]?.text}
              </p>
            </div>
            <div
              className="single-view-footer"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <Link
                className="btn-icon w-30 color-text"
                to={`/edit-note/${noteDataonADate[0]?.id_note}`}
              >
                <i className="icon-edit icon-shadow blue-color"></i>
              </Link>
              <a
                className="btn-icon w-30 color-warning cursor"
                onClick={() =>
                  noteDeleteOnDateHandler(noteDataonADate[0]?.id_note)
                }
              >
                <DeleteIcon />
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <NotificationModal
        notificationModalShow={notificationModalShow}
        setNotificationModalShow={setNotificationModalShow}
        notificationModalData={notificationModalData}
        sendingUserName={sendingUserName}
        switchShiftAndGiveawayAcceptRejectHandler={
          switchShiftAndGiveawayAcceptRejectHandler
        }
        invitationStatusHanlder={invitationStatusHanlder}
        switchShiftAndGiveawayAcceptRejectLoading={
          switchShiftAndGiveawayAcceptRejectLoading
        }
        locationChangeWarningModalAcceptSwitchShift={
          locationChangeWarningModalAcceptSwitchShift
        }
        setLocationChangeWarningModalAcceptSwitchShift={
          setLocationChangeWarningModalAcceptSwitchShift
        }
        locationChangeWarningModalAcceptGiveaway={
          locationChangeWarningModalAcceptGiveaway
        }
        setLocationChangeWarningModalAcceptGiveaway={
          setLocationChangeWarningModalAcceptGiveaway
        }
      />

      {isUpgradeModalShow && (
        <UpgradeMessageModal
          isShowModal={isUpgradeModalShow}
          setIsShowModal={setIsUpgradeModalShow}
        />
      )}
    </div>
  );
}

// a custom render function

function renderEventContent(eventInfo) {
  // console.log("eventInfo", eventInfo);
  // console.log("backgroundColor", eventInfo.backgroundColor);
  // console.log("defId", eventInfo.event._instance.defId);
  // console.log("instanceId", eventInfo.event._instance.instanceId);
  // console.log("range", eventInfo.event._instance.range);
  // event._def.extendedProps

  // console.log("eventInfo", eventInfo);

  // const elements = document.getElementsByClassName("deleteMe");

  // for (let i = 0; i < elements.length; i++) {
  //   const ele = elements[i];
  //   ele.remove();
  // }

  // elements.forEach((ele) => {
  //   ele.remove();
  // });

  return (
    <>
      {/* <b>{eventInfo.timeText}</b> */}
      {/* <i>{eventInfo.event.title}</i> */}

      {eventInfo.backgroundColor ? (
        <ul
          className={`event-dot-custom  order_${
            dotColorOrderObj[eventInfo.backgroundColor]
          }`}
        >
          {eventInfo.event._def.extendedProps.dots.map((color, idx) => (
            <li key={idx} className={`bg-${color} deleteMe`}></li>
          ))}
          {/* <li className={`bg-purple`}></li>
          <li className={`bg-yellow`}></li>
          <li className={`bg-red`}></li>
          <li className={`bg-green`}></li> */}
        </ul>
      ) : (
        // : eventInfo.backgroundColor == "yellow" ? (
        //   <ul className="event-dot-custom">
        //     <li className="bg-yellow"></li>
        //   </ul>
        // ) : eventInfo.backgroundColor == "red" ? (
        //   <ul className="event-dot-custom">
        //     <li className="bg-red"></li>
        //   </ul>
        // )
        <div className="shift-day-img">
          <img
            src={`${FRONTENDURL}/${eventInfo.event.extendedProps.image_url}`}
            className="day-icon-img"
            alt="shift day"
          />
        </div>
      )}
    </>
  );
}
