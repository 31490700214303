import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl } from "@mui/material";

import Header from "../../../Component/Header";
import PhoneNumber from "../../../Component/PhoneNumber";
import Picker from "../../../Component/Picker";
import { API } from "../../../constant/api";
import useRequest from "../../../hooks/useRequest";
import { useDispatch, useSelector } from "react-redux";
import { addManager } from "../../../store/auth/action";
import { Spinner } from "../../../Component/Svg";
import { phoneValidation } from "../../../utils/fn";
import { toast } from "react-toastify";
import UpgradeBanner from "../../../Component/UpgradeBanner";

const Edit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { managers, role } = useSelector((state) => state.auth);
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [defaultDate, setDefaultDate] = useState();
  const [countryFormat, setCountryFormat] = useState("");

  // moment().format("DD MMMM YYYY")
  const [image, setImage] = useState();
  const [titleDefaultValue, setTitleDefaultValue] = useState();
  const dobRef = useRef();

  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
    getValues,
    control,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      title: "none",
    },
  });

  const { request, response, loading } = useRequest();
  const { request: managerRequest, response: managerResponse } = useRequest();

  useEffect(() => {
    if (managers.length > 0) {
      let manager = managers.find((m) => m.id_manager == id);
      if (manager) {
        initialValueHandler(manager);
      } else {
        managerRequest("GET", `v1/manager/manager/${id}`);
      }
    } else {
      managerRequest("GET", `v1/manager/manager/${id}`);
    }
  }, []);

  useEffect(() => {
    if (managerResponse) {
      if (managerResponse.status == "SUCCESS") {
        const { manager } = managerResponse.data;
        initialValueHandler(manager);
      }
    }
  }, [managerResponse]);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { manager } = response.data;
        let newManagers = managers.map((m) => {
          if (m.id_manager == id) {
            m = { ...m, ...manager };
          }
          return m;
        });
        dispatch(addManager(newManagers));
        navigate("/manager-list");
      }
    }
  }, [response]);

  const initialValueHandler = (manager) => {
    const {
      birthday,
      email,
      first_name,
      gender,
      picture,
      last_name,
      title,
      mobile_number,
      country_code,
    } = manager;

    if (birthday) {
      setValue("birthday", birthday);
      setDefaultDate(moment(birthday, "YYYY-MM-DD").format("DD MMMM YYYY"));
    }

    setValue("email", email);
    setValue("first_name", first_name);
    setValue("gender", gender);
    setValue("last_name", last_name);
    // setValue("title", title);
    setValue("mobile_number", `${country_code}${mobile_number}`);
    setMobileNumber(mobile_number);
    setCountryCode(country_code);
    if (picture) {
      let image = `${API.IMG}/${picture}`;
      setImage(image);
    }
    setValue("title", title);
  };

  const imageHandler = (e) => {
    // setImage(URL.createObjectURL(e.target.files[0]));
    // setValue("image", e.target.files);
    let type = ["image/png", "image/jpg", "image/jpeg"];
    let file = e.target.files[0];
    if (file && file?.type) {
      if (type.includes(file.type)) {
        setImage(URL.createObjectURL(file));
        setValue("image", e.target.files);
      } else {
        toast.error("Please select image only");
      }
    }
  };

  const birthdayHandler = (e) => {
    let value = e.target.value;
    const birthday = moment(value, "DD MMMM YYYY").format("YYYY-MM-DD");
    setValue("birthday", birthday);
    setDefaultDate(value);
  };

  const profileEditSubmitHandler = (data) => {
    const {
      birthday,
      email,
      first_name,
      gender,
      image,
      last_name,
      title,
      mobile_number,
    } = data;
    // return
    const fd = new FormData();

    fd.append("email", email);
    fd.append("first_name", first_name);
    fd.append("gender", gender);
    fd.append("last_name", last_name);
    fd.append("title", title);
    if (image) {
      fd.append("image", image[0]);
    }
    fd.append("id", id);

    if (mobileNumber) {
      fd.append("mobile_number", mobileNumber);
      fd.append("country_code", countryCode);
    }

    if (birthday) {
      fd.append("birthday", birthday);
    }
    // return;
    request("POST", "v1/manager/edit-manager", fd);
  };

  return (
    <>
      <div className="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

        <div className="headerContant"></div>
        <div className="setting_header light_header">
          <Header heading="Edit Manager" link="/manager-list" />

          <div className="content-header-bottom">
            <div className="profile_img">
              {image ? <img src={image} /> : <i className="icon-user"></i>}
              {/* <img src="/assets/img/logo.png" alt="" /> */}
            </div>
            <div className="select_file">
              <label className="change_image" for="profile_image">
                Change Image{" "}
              </label>
              <input
                type="file"
                onChange={(e) => imageHandler(e)}
                className="d-none"
                id="profile_image"
              />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="list-wrap">
            <form onSubmit={handleSubmit(profileEditSubmitHandler)}>
              <div className="profile-info">
                <div className="form-field">
                  <p className="form-label">First Name</p>
                  <div className="form-input">
                    <input
                      name="first_name"
                      className="form-input border-bottom"
                      type="text"
                      autocomplete="off"
                      // placeholder="Enter Your First Name"
                      {...register("first_name", {
                        required: "This field is mandatory.",
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.first_name && (
                    <p className="error-msg">{errors.first_name.message}</p>
                  )}
                </div>
                <div className="form-field">
                  <p className="form-label">Last Name</p>
                  <div className="form-input">
                    <input
                      className="form-input border-bottom"
                      name="last_name"
                      type="text"
                      autocomplete="off"
                      // placeholder="Enter Your Last Name"
                      {...register("last_name", {
                        required: "This field is mandatory.",
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.last_name && (
                    <p className="error-msg">{errors.last_name.message}</p>
                  )}
                </div>
                <div className="form-field">
                  <p className="form-label">Title</p>
                  <div className="form-input">
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <Controller
                        name="title"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              // {...field}
                              value={value}
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                            >
                              <MenuItem value="none" disabled>
                                Choose Title
                              </MenuItem>
                              <MenuItem value="manager">Manager</MenuItem>
                              <MenuItem value="team lead">Team lead</MenuItem>
                            </Select>
                          );
                        }}
                      />
                    </FormControl>
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.title && (
                    <p className="error-msg">{errors.title.message}</p>
                  )}
                </div>
                <div className="form-field">
                  <p className="form-label">Phone</p>
                  <div className="form-input phoneNumber_box">
                    <Controller
                      name="mobile_number"
                      control={control}
                      rules={{
                        required: false,
                        validate: () => {
                          if (phoneValidation[countryFormat] && mobileNumber) {
                            if (
                              phoneValidation[countryFormat].test(
                                `+${getValues("mobile_number")}`
                              )
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                        },
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <PhoneNumber
                            value={value}
                            setMobileNumber={setMobileNumber}
                            setCountryCode={setCountryCode}
                            onChange={onChange}
                            setCountryFormat={setCountryFormat}
                          />
                        );
                      }}
                    />
                    {/* <PhoneNumber value={mobile} setValue={setMobile} /> */}
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.mobile_number &&
                    errors.mobile_number.type === "validate" && (
                      <p className="error-msg">Invalid phone number.</p>
                    )}
                </div>
                {/* <div className="form-field">
                  <p className="form-label">Email</p>
                  <div className="form-input">
                    <input
                      className="form-input border-bottom"
                      name="email"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter Email Address"
                      {...register("email", {
                        onChange: (e) => {
                          if (errors.email?.message) {
                            let value = e.target.value;
                            if (
                              !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                value
                              )
                            ) {
                              setError("email", {
                                type: "manual",
                                message: "Please provide a valid email.",
                              });
                            } else {
                              clearErrors("email");
                            }
                          }
                        },
                        required: "This field is mandatory.",
                        pattern: {
                          value:
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                          message: "Please provide a valid email.",
                        },
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.email && (
                    <p className="error-msg">{errors.email.message}</p>
                  )}
                </div> */}
                <div className="form-field">
                  <p className="form-label">Birthday</p>
                  <div className="form-input">
                    <Picker
                      format={"DD MMMM YYYY"}
                      timeRef={dobRef}
                      onChange={birthdayHandler}
                      defaultDateForPicker={defaultDate}
                      value={defaultDate}
                      type="text"
                    />
                    {/* <input /> */}
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {/* {errors.birthday && (
                    <p className="error-msg">{errors.birthday.message}</p>
                  )} */}
                </div>
                <div className="form-field mb-0 pb-3">
                  <p className="form-label">Gender</p>
                  <div className="gender_select_box">
                    <div className="radio-wrap mr-8">
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="Female"
                          {...register("gender", {
                            required: "Please select one of the choices..",
                          })}
                        />
                        <span className="icon"></span>
                        <span className="text">Female</span>
                      </label>
                    </div>
                    <div className="radio-wrap">
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="Male"
                          {...register("gender", {
                            required: "Please select one of the choices..",
                          })}
                        />
                        <span className="icon"></span>
                        <span className="text">Male</span>
                      </label>
                    </div>
                    {errors.gender && (
                      <p className="error-msg">{errors.gender.message}</p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="content-footer setting_footer"
                style={{ overflow: "auto" }}
              >
                <button
                  type="button"
                  className="btn-link formBtn color-text-50"
                  id="prevBack"
                  onClick={() => navigate("/manager-list")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-white formBtn"
                  id="nextBtn"
                >
                  {loading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
