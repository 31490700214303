import {
  ADD_ASSIGNED_SHIFTS,
  ADD_EVENT,
  REMOVE_EVENT,
  Is_Calendar_Data_Exist,
  ADD_ASSIGNED_EVENTS,
  ADD_ASSIGNED_NOTES,
  ADD_WORKPLACES,
  ADD_ONE_WORKPLACES,
  ADD_TEAM_SHIFTS,
  CLEAR_CALENDAR_DATA,
} from "./actionTypes";

const initialState = {
  allAssignedShifts: [],
  allAssignedEvents: [],
  allAssignedNotes: [],
  allEvents: [],
  allWorkplaces: [],
  isFirstTime: true,
  teamShifts: [],
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ASSIGNED_SHIFTS:
      return {
        ...state,
        allAssignedShifts: [...action.payload],
      };
    case ADD_ASSIGNED_EVENTS:
      return {
        ...state,
        allAssignedEvents: [...action.payload],
      };
    case ADD_ASSIGNED_NOTES:
      return {
        ...state,
        allAssignedNotes: [...action.payload],
      };
    case ADD_EVENT:
      return {
        ...state,
        allEvents: [...action.payload],
      };
    case ADD_WORKPLACES:
      return {
        ...state,
        allWorkplaces: [...action.payload],
      };
    case ADD_ONE_WORKPLACES:
      return {
        ...state,
        allWorkplaces: [...state.allWorkplaces, action.payload],
      };
    case REMOVE_EVENT:
      return {
        ...state,
      };
    case ADD_TEAM_SHIFTS:
      return {
        ...state,
        teamShifts: [...action.payload],
      };
    case Is_Calendar_Data_Exist:
      return {
        ...state,
        isFirstTime: action.payload,
      };
    case CLEAR_CALENDAR_DATA:
      return {
        ...initialState,
        isFirstTime: true,
      };
    default:
      return state;
  }
};

export default calendarReducer;
