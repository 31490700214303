import {
  ADD_ASSIGNED_SHIFTS,
  ADD_EVENT,
  REMOVE_EVENT,
  Is_Calendar_Data_Exist,
  ADD_ASSIGNED_EVENTS,
  ADD_ASSIGNED_NOTES,
  ADD_WORKPLACES,
  ADD_ONE_WORKPLACES,
  ADD_TEAM_SHIFTS,
  CLEAR_CALENDAR_DATA,
} from "./actionTypes";

export const addAllAssignedShifts = (payload) => {
  return {
    type: ADD_ASSIGNED_SHIFTS,
    payload,
  };
};
export const addAllAssignedEvents = (payload) => {
  return {
    type: ADD_ASSIGNED_EVENTS,
    payload,
  };
};
export const addAllAssignedNotes = (payload) => {
  return {
    type: ADD_ASSIGNED_NOTES,
    payload,
  };
};
export const addEvents = (payload) => {
  return {
    type: ADD_EVENT,
    payload,
  };
};
export const isCalendarDataExist = (payload) => {
  return {
    type: Is_Calendar_Data_Exist,
    payload,
  };
};
export const addAllWorkplaces = (payload) => {
  return {
    type: ADD_WORKPLACES,
    payload,
  };
};
export const addOneWorkplaces = (payload) => {
  return {
    type: ADD_ONE_WORKPLACES,
    payload,
  };
};
export const addTeamShifts = (payload) => {
  return {
    type: ADD_TEAM_SHIFTS,
    payload,
  };
};

export const clearCalendarData = () => {
  console.log("call");
  return {
    type: CLEAR_CALENDAR_DATA,
  };
};
