import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "../../../Component/Svg";
import useRequest from "../../../hooks/useRequest";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({ mode: "onBlur" });

  const navigate = useNavigate();
  const location = useLocation();

  const { request, response, loading } = useRequest();
  const [isChangePasswordClick, setIsChangePasswordClick] = useState(false);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        setIsChangePasswordClick(true);
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    const { password, new_password, confirm_password } = data;

    if (new_password != confirm_password) {
      setError("confirm_password", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }
    if (!loading) {
      request("POST", "v1/auth/change-password", {
        old_password: password,
        new_password,
        confirm_password,
      });
    }
  };

  const navigationHandler = () => {
    const oldLocation = location?.state?.from;
    if (oldLocation) {
      return oldLocation;
    } else {
      return "/";
    }
  };

  return (
    <section className="fullcontent bgBlue">
      <div className="container">
        <div className="content">
          {!isChangePasswordClick ? (
            <div className="sign-up-content color-white">
              <a className="logo" href="/">
                <img src="/assets/img/LOGO.svg" alt="Turno" />
              </a>
              <h1 className="my-6">Change Password</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  className={
                    errors.password
                      ? "form-field form-field-white has-error"
                      : "form-field form-field-white"
                  }
                >
                  <div className="form-input">
                    <input
                      name="password"
                      type="password"
                      placeholder="Current Password (min 6 characters)"
                      {...register("password", {
                        onChange: () => {
                          if (errors.invalid) {
                            clearErrors("invalid");
                          }
                        },
                        required: "This field is mandatory.",
                        pattern: {
                          value: /^.{6,}$/,
                          message: "Please provide a valid password.",
                        },
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="inputborder" />
                  </div>
                  {errors.password && (
                    <p className="error-msg">{errors.password.message}</p>
                  )}
                </div>
                <div
                  className={
                    errors.new_password
                      ? "form-field form-field-white has-error"
                      : "form-field form-field-white"
                  }
                >
                  <div className="form-input">
                    <input
                      name="new_password"
                      type="password"
                      placeholder="New Password (min 6 characters)"
                      autoComplete="off"
                      {...register("new_password", {
                        onChange: (...rest) => {
                          if (errors.invalid) {
                            clearErrors("invalid");
                          }
                        },
                        required: "This field is mandatory.",
                        pattern: {
                          value: /^.{6,}$/,
                          message: "Please provide a valid password.",
                        },
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="inputborder" />
                  </div>
                  {errors.new_password && (
                    <p className="error-msg">{errors.new_password.message}</p>
                  )}
                </div>
                <div
                  className={
                    errors.confirm_password
                      ? "form-field form-field-white has-error"
                      : "form-field form-field-white"
                  }
                >
                  <div className="form-input">
                    <input
                      name="confirm_password"
                      type="password"
                      placeholder="Confirm Password (min 6 characters)"
                      autoComplete="off"
                      {...register("confirm_password", {
                        onChange: (...rest) => {
                          if (errors.invalid) {
                            clearErrors("invalid");
                          }
                        },
                        required: "This field is mandatory.",
                        pattern: {
                          value: /^.{6,}$/,
                          message: "Please provide a valid password.",
                        },
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="inputborder" />
                  </div>
                  {errors.confirm_password && (
                    <p className="error-msg">
                      {errors.confirm_password.message}
                    </p>
                  )}
                  {errors.invalid && (
                    <p className="error-msg has-error with-icon mt-2 error-msg-new">
                      {errors.invalid.message}
                    </p>
                  )}
                </div>
                <div className="profile_save">
                  <button
                    type="button"
                    className="btn mt-6 close_btn btn-link "
                    onClick={() => navigate(navigationHandler())}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-white  mt-6 submitButton"
                  >
                    {loading ? <Spinner /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <>
              <div
                className="sign-up-content color-white"
                style={{ justifyContent: "center" }}
              >
                <Link className="logo" to="/">
                  <img src="assets/img/LOGO.svg" alt="Turno" />
                </Link>
                {/* <span className="spaceSpan"></span> */}
                <h1 className="no-transform mt-10 ">Password Changed!</h1>
                <p className="color-white-70 text-center">
                  Your password has been changed successfully.
                </p>
                <span
                  onClick={() => navigate(navigationHandler())}
                  className="submitButton btn-white"
                >
                  Back To Profile
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
