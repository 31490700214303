import {
  ADD_ALL_NOTIFICATIONS,
  ADD_NOTIFICATION_COUNT,
  CLEAR_NOTIFICATION_DATA,
  INCREASE_NOTIFICATION_COUNT,
  ADD_NOTIFICATIONS,
} from "./actionTypes";

export const addAllNotifications = (updates) => {
  return {
    type: ADD_ALL_NOTIFICATIONS,
    updates,
  };
};

export const addNotification = (updates) => {
  return {
    type: ADD_NOTIFICATIONS,
    updates,
  };
};

export const addNotificationCount = (updates) => {
  return {
    type: ADD_NOTIFICATION_COUNT,
    updates,
  };
};

export const increaseNotificationCount = (updates) => {
  return {
    type: INCREASE_NOTIFICATION_COUNT,
    updates,
  };
};

export const clearNotificationData = () => {
  return {
    type: CLEAR_NOTIFICATION_DATA,
  };
};
