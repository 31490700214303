import React, { useEffect, useState } from "react";
import moment from "moment";
import "pickerjs/dist/picker.min.css";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import useRequest from "../../hooks/useRequest";
import { addHeadingOnPicker, minutesOfDay } from "../../utils/fn";
import RTimePicker from "../../Component/TimePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllAssignedEvents,
  addEvents,
} from "../../store/calendarData/action";
import Icons from "../../Component/Icons";
import { Spinner } from "../../Component/Svg";
import UpgradeBanner from "../../Component/UpgradeBanner";

const AddEvent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allAssignedEvents, allEvents } = useSelector(
    (state) => state.calendar
  );
  const { role } = useSelector((state) => state.auth);
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const [isStartTimeOpen, setIsStartTimeOpen] = useState(false);
  const [isEndTimeOpen, setIsEndTimeOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [addEventModalShow, setAddEventModalShow] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState("");
  const [alreadyExistName, setAlreadyExistName] = useState([]);
  const [personalEvents, setPersonalEvents] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
    getValues,
  } = useForm();

  const {
    register: registerAddEvent,
    handleSubmit: handleSubmitAddEvent,
    formState: { errors: errorsAddEvent },
    clearErrors: clearErrorsAddEvent,
    setError: setErrorAddEvent,
  } = useForm();

  const { request, response, loading } = useRequest();
  const { request: addEventRequest, response: addEventResponse } = useRequest();
  const { request: personalEventRequest, response: personalEventResponse } =
    useRequest();

  useEffect(() => {
    setPersonalEvents(allEvents);
    personalEventRequest("GET", "v1/event/events");
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (personalEventResponse) {
      if (personalEventResponse.status == "SUCCESS") {
        const newEvent = personalEventResponse?.data?.events;
        setPersonalEvents(newEvent);
        setAlreadyExistName(
          newEvent.map((data) => data.event_name.toLowerCase())
        );
      }
    }
  }, [personalEventResponse]);

  useEffect(() => {
    if (currentTab == 0 || currentTab == 1) {
      // register("id_event", {
      //   required: currentTab == 0 && "This field is mandatory.",
      // });
      register("start_time", {
        required: false,
      });
      register("end_time", {
        required: false,
      });
    }
    if (currentTab == 2) {
      register("start_time", {
        // required:
        //   currentTab == 2 &&
        //   "The Start Time and End Time must be selected to continue.",
      });
      register("end_time", {
        // required:
        //   currentTab == 2 &&
        //   "The Start Time and End Time must be selected to continue.",
      });
    }
  }, [currentTab]);

  useEffect(() => {
    if (addEventResponse) {
      setAddEventModalShow(false);
      const prevEvent = personalEvents.filter((p) => p.event_id);
      setPersonalEvents([
        ...prevEvent,
        addEventResponse.data.personalEventData,
      ]);
      dispatch(
        addEvents([...allEvents, addEventResponse.data.personalEventData])
      );
    }
  }, [addEventResponse]);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { new_event_date } = response.data;
        const { event_id, icon_name, start_time, end_time } = new_event_date;
        let newEvents = allAssignedEvents.map((data) => {
          if (data.event_id == event_id) {
            data = { ...data, icon_name, start_time, end_time };
          }
          return data;
        });
        dispatch(addAllAssignedEvents([...newEvents, new_event_date]));
        navigate("/calendar", {
          state: {
            date: location.state?.date || moment().format("YYYY-MM-DD"),
          },
        });
      }
    }
  }, [response]);

  const startTimeFunction = (event) => {
    clearErrors("start_time");
    setStartDate(moment(event._d).format("hh:mm A"));
    setValue("start_time", event._d);
  };

  const endTimeFunction = (event) => {
    clearErrors("start_time");
    setValue("end_time", event._d);
    setEndDate(moment(event._d).format("hh:mm A"));
  };

  const nextHandler = () => {
    if (currentTab === 2) {
      if (startDate === endDate) {
        //show Error
        setError("start_time", {
          type: "manual",
          message: "Please select a valid time.",
        });
      } else {
        setCurrentTab((prev) => prev + 1);
      }
    } else if (currentTab === 0) {
      const { event_id } = getValues();
      if (event_id) {
        setCurrentTab((prev) => prev + 1);
      }
    } else if (currentTab == 1) {
      setCurrentTab((prev) => prev + 1);
    }
  };

  function nextPrev(n) {
    if (n === "next") {
      setCurrentTab((prev) => prev + 1);
    } else if (n === "prev") {
      setCurrentTab((prev) => prev - 1);
    }
  }

  const addEventToADateHandler = (data) => {
    console.log("data>>>>>>>>>>>>>>>>>",data);
    let { end_time, icon_name, start_time, event_id, is_default } = data;
    // if (
    //   startDate === endDate ||
    //   moment(endDate, "h:mma").isBefore(moment(startDate, "h:mma"))
    // ) {
    //   //show Error
    //   setError("start_time", {
    //     type: "manual",
    //     message: "Please select a valid time.",
    //   });
    //   return;
    // }

    start_time = start_time == "undefined"|| start_time == undefined ? null :  moment(start_time).format("YYYY-MM-DD HH:mm:00");
    end_time = end_time == "undefined"|| end_time == undefined ? null :moment(end_time).format("YYYY-MM-DD HH:mm:00");

    // const startTimeMinutes = minutesOfDay(start_time);
    // const endTimeMinutes = minutesOfDay(end_time);

    // if (startTimeMinutes >= endTimeMinutes) {
    //   end_time = end_time.add({ days: 1 });
    // }

    if (!loading) {
      request("POST", "v1/event/add-event-date", {
        // end_time: moment(end_time).format("YYYY-MM-DD HH:mm:00"),
        // start_time: moment(start_time).format("YYYY-MM-DD HH:mm:00"),
        end_time:end_time,
        start_time:start_time,
        date: location.state?.date || moment().format("YYYY-MM-DD"),
        icon_name,
        event_id,
        is_default,
      });
    }

    // let newEvents = allAssignedEvents.map((data) => {
    //   if (data.event_id == event_id) {
    //     data = { ...data, icon_name, start_time, end_time };
    //   }
    //   return data;
    // });

    // let newEventonDate = {
    //   end_time,
    //   start_time,
    //   date: location.state?.date || moment().format("YYYY-MM-DD"),
    //   icon_name,
    //   event_id,
    //   is_default,
    // };

    // dispatch(addAllAssignedEvents([...newEvents, newEventonDate]));

    // navigate("/calendar", {
    //   state: {
    //     date: location.state?.date || moment().format("YYYY-MM-DD"),
    //   },
    // });
  };

  const eventSelectHandler = (id, is_default, name) => {
    setSelectedEvent(id);
    setValue("event_id", id);
    setValue("icon_name", name);
    setValue("is_default", is_default);
    // setAlreadyExistName;
  };

  const addEventSubmitHandler = (data) => {
    const { event_name } = data;

    if (alreadyExistName.includes(event_name.toLowerCase())) {
      setErrorAddEvent("event_name", {
        type: "manual",
        message: "An event with this name already exists.",
      });
      return;
    }
    addEventRequest("POST", "v1/event/add-event", {
      event_name,
      icon_name: event_name,
    });
    setPersonalEvents((prev) => [
      ...prev,
      { event_name, icon_name: event_name },
    ]);
    setAddEventModalShow(false);
  };

  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="headerContant"></div>
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5 cursor"
          onClick={() =>
            navigate("/calendar", {
              state: {
                date: location.state?.date,
              },
            })
          }
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
        </span>
        <div className="add_event_header">
          <h1 className="add_event_title">Add Event</h1>
          <button
            className="event_link"
            onClick={() => setAddEventModalShow(true)}
          >
            Create Event
          </button>
        </div>
      </div>
      {/* Section */}
      <div className="page-section">
        <div className="container">
          <div className="innerPageBox">
            <div className="MuiPaper-root MuiPaper-elevation0 MuiStepper-root MuiStepper-horizontal stepper-header">
              <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 0 || currentTab === 1 || currentTab === 2
                    ? "active"
                    : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span
                    className="MuiStepLabel-labelContainer"
                    onClick={() => setCurrentTab(0)}
                  >
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label MuiStepLabel-active">
                      Event Type
                    </span>
                  </span>
                </span>
              </span>
              <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 1 || currentTab === 2 ? "active" : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal MuiStepLabel-disabled step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>

                  <span
                    className="MuiStepLabel-labelContainer"
                    onClick={() => {
                      if (currentTab === 0) {
                        const { event_id } = getValues();
                        if (event_id) {
                          setCurrentTab(1);
                        }
                      } else if (currentTab === 2) {
                        setCurrentTab(1);
                      }
                    }}
                  >
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label">
                      Name
                    </span>
                  </span>
                </span>
              </span>
              <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 2 ? "active" : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal MuiStepLabel-disabled step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span
                    className="MuiStepLabel-labelContainer"
                    onClick={() => {
                      const { icon_name } = getValues();
                      if (icon_name) {
                        setCurrentTab(2);
                      }
                    }}
                  >
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label">
                      Duration
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="stepper-Outbody h-10000">
              <form
                id="regForm"
                //  onSubmit={handleSubmit(addEventToADateHandler)}
              >
                <div className="stepper-body">
                  <div className="stepper-content">
                    {currentTab === 0 ? (
                      <div className="tab" style={{ display: "block" }}>
                        <div className="eventsListingCard  blue_scroll_bar p-3">
                          <div className="list-wrap new-event-list">
                            <div className="eventsUL pb-3 ">
                              {personalEvents &&
                                personalEvents.map((event, i) => (
                                  <div
                                    className={`liRowCard cursor ${
                                      event?.event_id == selectedEvent
                                        ? "checkActive"
                                        : ""
                                    }`}
                                    key={i}
                                    onClick={() =>
                                      eventSelectHandler(
                                        event?.event_id,
                                        event?.is_default,
                                        event?.event_name
                                      )
                                    }
                                  >
                                    {/* <span className="label-icon bg-purple"> */}
                                    <span
                                      className={`${
                                        !event?.is_default ? "icon-font" : ""
                                      } label-icon bg-purple`}
                                    >
                                      <i
                                        className={`${
                                          event?.is_default
                                            ? `icon-${Icons[event.icon_name]}`
                                            : "fas fa-ellipsis-h"
                                        } color-white box-30`}
                                      />
                                      {/* <i className="fas fa-ellipsis-h color-white box-30" /> */}
                                    </span>
                                    <div className="eventCard-body ">
                                      <p className="font-xmd fw-500 ellipsis text-capitalize">
                                        {event?.event_name}
                                      </p>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn-delete-card btn-icon w-30 color-warning"
                                    >
                                      <i className="fas fa-trash icon-shadow" />
                                    </button>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : currentTab === 1 ? (
                      <div className="tab" style={{ display: "block" }}>
                        <div className="form-field">
                          <div className="form-input">
                            <input
                              name="ShiftName"
                              type="TEXT"
                              placeholder="Shift Name"
                              defaultValue=""
                              {...register("icon_name", {
                                required:
                                  currentTab == 1 && "This field is mandatory.",
                              })}
                            />
                            <span className="inputborder" />
                          </div>
                          {errors.icon_name && (
                            <p className="error-msg">
                              {errors.icon_name.message}
                            </p>
                          )}
                        </div>
                        {/* <div className="form-field">
                          <div className="form-input">
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                            >
                              <InputLabel id="demo-simple-select-filled-label">
                                Work Location
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                // value={age}
                                label="Work Location"
                                onChange={workLocationHandleChange}
                                defaultValue={getValues("id_workplace")}
                              >
                                {workplaces.map((workplace) => (
                                  <MenuItem
                                    key={workplace.id}
                                    value={workplace.id}
                                  >
                                    {workplace.hospital_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <span className="inputborder" />
                          </div>
                          {errors.id_workplace && (
                            <p className="error-msg">
                              {errors.id_workplace.message}
                            </p>
                          )}
                        </div> */}
                      </div>
                    ) : (
                      <div
                        className="tab"
                        style={{ display: currentTab === 2 ? "block" : "none" }}
                      >
                        <div>
                          <div className="time-picker">
                            <div className="time ">
                              {/* <div className="js-time-picker-Start"> */}
                              <RTimePicker
                                open={isStartTimeOpen}
                                close={setIsStartTimeOpen}
                                onChange={startTimeFunction}
                                defaultValue={moment()}
                              />
                              {/* <input
                                value={startDate}
                                ref={startDateRef}
                                type="hidden"
                              /> */}
                              <button
                                type="button"
                                className="btn btn-white"
                                onClick={() => {
                                  setIsStartTimeOpen(true);
                                  addHeadingOnPicker();
                                }}
                                //  onClick={() => setIsStartTimeOpen(true)}
                              >
                                <i className="far fa-clock" />
                                <span>Start Time</span>
                              </button>
                              <p className="selected-time">{startDate}</p>
                              {/* </div> */}
                            </div>
                            <div className="time">
                              {/* <div className="js-time-picker-End"> */}
                              <RTimePicker
                                open={isEndTimeOpen}
                                close={setIsEndTimeOpen}
                                onChange={endTimeFunction}
                                defaultValue={moment()}
                              />
                              {/* <input
                                value={endDate}
                                ref={endDateRef}
                                type="hidden"
                              /> */}
                              <button
                                type="button"
                                className="btn btn-white "
                                onClick={() => {
                                  setIsEndTimeOpen(true);
                                  addHeadingOnPicker();
                                }}
                              >
                                <i className="far fa-clock" />
                                <span>End Time</span>
                              </button>
                              <p className="selected-time">{endDate}</p>
                              {/* </div> */}
                            </div>
                            {(errors.start_time || errors.end_time) && (
                              <p className="error-msg with-icon">
                                {errors.start_time?.message ||
                                  errors.end_time?.message}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* <div class="form-field">
                                  <input type="text" class="form-control js-time-picker" value="02:56">
                              </div> */}
                      </div>
                    )}
                  </div>
                  <div
                    className="content-footer content-footer-new"
                    style={{ overflow: "auto" }}
                  >
                    {currentTab == 0 && (
                      <button
                        type="button"
                        className="btn-link formBtn color-text-50"
                        id="prevBack"
                        onClick={() =>
                          navigate("/calendar", {
                            state: {
                              date: location.state?.date,
                            },
                          })
                        }
                      >
                        Cancel
                      </button>
                    )}
                    {(currentTab == 1 || currentTab == 2) && (
                      <button
                        type="button"
                        className="btn-link formBtn color-text-50"
                        id="prevBtn"
                        onClick={() => setCurrentTab((prev) => prev - 1)}
                      >
                        Back
                      </button>
                    )}
                    {(currentTab == 0 || currentTab == 1) && (
                      <button
                        type="submit"
                        className="btn-white formBtn"
                        id="nextBtn"
                        // onClick={() => nextPrev("next")}
                        onClick={handleSubmit(nextHandler)}
                      >
                        Next
                      </button>
                    )}
                    {currentTab == 2 && (
                      <button
                        type="button"
                        className="btn-white formBtn"
                        id="nextBtn"
                        onClick={handleSubmit(addEventToADateHandler)}
                      >
                        {loading ? <Spinner /> : "Add"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={addEventModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal personal_event"
        onHide={() => setAddEventModalShow(false)}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              // data-bs-dismiss="modal"
              // aria-label="Close"

              onClick={() => {
                setAddEventModalShow(false);
              }}
            />
            <div className="modal-body p-0">
              <div className="single-view-header addEventPopup">
                <span className="label-icon bg-purple">
                  <i className="fas fa-ellipsis-h color-white box-30" />
                </span>
                <div>
                  <p className="font-xmd fw-500 ellipsis text-capitalize">
                    Personal Event
                  </p>
                  <p className="color-text-70 text-capitalize mb-0">Other</p>
                </div>
              </div>
              <form
                className="single-view-body"
                onSubmit={handleSubmitAddEvent(addEventSubmitHandler)}
              >
                <div className="form-field">
                  <div className="form-input">
                    <input
                      name="ShiftName"
                      type="TEXT"
                      placeholder="Event Name"
                      defaultValue=""
                      {...registerAddEvent("event_name", {
                        // setValueAs: (v) => {
                        //   v.toLowerCase();
                        // },
                        onChange: (e) => {
                          if (
                            alreadyExistName.includes(
                              e.target.value.toLowerCase()
                            )
                          ) {
                            setErrorAddEvent("event_name", {
                              type: "manual",
                              message:
                                "An event with this name already exists.",
                            });
                          } else {
                            clearErrorsAddEvent("event_name");
                          }
                        },
                        required: "This field is mandatory",
                      })}
                    />
                    <span className="inputborder" />
                  </div>
                  {errorsAddEvent.event_name && (
                    <p className="error-msg">
                      {errorsAddEvent.event_name.message}
                    </p>
                  )}
                </div>
                <div
                  className="personal_event-footer"
                  style={{ overflow: "auto" }}
                >
                  <button
                    type="button"
                    className="btn-link formBtn color-text-50"
                    onClick={() => setAddEventModalShow(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-white formBtn">
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddEvent;
