
export const API = {
  PORT:
    process.env.NODE_ENV === "development"
      ? "https://appprod.turnoapp.com/api"
      : "https://appprod.turnoapp.com/api",
  IMG:
    process.env.NODE_ENV === "development"
      ? "https://appprod.turnoapp.com/"
      : "https://appprod.turnoapp.com/",
};

export const BASEURL = {
  PORT:
    process.env.NODE_ENV === "development"
      ? "https://appprod.turnoapp.com/api"
      : "https://appprod.turnoapp.com/api",
};

export const SOCKETURL = {
  PORT:
    process.env.NODE_ENV === "development"
      ? "https://appprod.turnoapp.com"
      : "https://appprod.turnoapp.com",
};


export const FRONTENDURL =
    process.env.NODE_ENV === "development"
      ? "http://192.168.235.245:4000"
      : "https://my.turnoapp.com";

      

// For the demo turno app



// export const API = {
//   PORT:
//     process.env.NODE_ENV === "development"
//       ? "http://192.168.235.245:5001/api"
//       : "https://apppy.turnoapp.com/api",
//   IMG:
//     process.env.NODE_ENV === "development"
//       ? "http://192.168.235.245:5001/"
//       : "https://apppy.turnoapp.com/",
// };

// export const BASEURL = {
//   PORT:
//     process.env.NODE_ENV === "development"
//       ? "http://192.168.235.245:5001/api"
//       : "https://apppy.turnoapp.com/api",
// };

// export const SOCKETURL = {
//   PORT:
//     process.env.NODE_ENV === "development"
//       ? "http://192.168.235.245:5001"
//       : "https://apppy.turnoapp.com",
// };


// export const FRONTENDURL =
//     process.env.NODE_ENV === "development"
//       ? "http://192.168.235.245:4000"
//       : "https://apppy.turnoapp.com";

      

