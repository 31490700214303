import moment from "moment";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useDispatch, useSelector } from "react-redux";

import { DatePickerForAdmin } from "../../Component/DatePickerForAdmin";
import { AdminLayout } from "../../Component/AdminLayout";
import useRequest from "../../hooks/useRequest";
import { addStatistics } from "../../store/admin/action";

const AdminStatistics = () => {
  const dispatch = useDispatch();
  const { statistics } = useSelector((state) => state.admin);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [statisticsObj, setStatisticsObj] = useState({});
  const [totalCount, setTotalCount] = useState({
    shiftCount: 0,
    allEvent: 0,
    otherEvent: 0,
  });
  const [isError, setIsError] = useState({
    toDate: null,
    fromDate: null,
  });

  const { request, response } = useRequest();
  const {
    request: statisticsDataByQueryRequest,
    response: statisticsDataByQueryResponse,
  } = useRequest();

  useEffect(() => {
    document.body?.classList?.remove("scroll-off");
    request(
      "GET",
      `v1/admin/admin-dashboard?from_date=${fromDate}&to_date=${toDate}`
    );
    if (Object.keys(statistics).length > 0) {
      statisticsDataHandler(statistics);
    }
  }, []);

  useEffect(() => {
    if (statisticsDataByQueryResponse) {
      statisticsDataHandler(statisticsDataByQueryResponse.data);
    }
  }, [statisticsDataByQueryResponse]);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        statisticsDataHandler(response.data);
        dispatch(addStatistics(response.data));
      }
    }
  }, [response]);

  const statisticsDataHandler = (data) => {
    const { total_shift_events, total_default_events, total_personal_events } =
      data;

    let shiftTotal = total_shift_events.reduce((acc, curr) => {
      acc = acc + curr.shift_event_count;
      return acc;
    }, 0);

    let otherEventTotal = total_personal_events.reduce((acc, curr) => {
      acc = acc + curr.personal_event_count;
      return acc;
    }, 0);

    let allEventTotal = total_default_events.reduce((acc, curr) => {
      acc = acc + curr.default_event_count;
      return acc;
    }, otherEventTotal);

    setTotalCount({
      shiftCount: shiftTotal,
      allEvent: allEventTotal,
      otherEvent: otherEventTotal,
    });

    setStatisticsObj(data);
  };

  const fromDateOnchange = (value) => {
    setFromDate(value);
    if (toDate && toDate.isBefore(value)) {
      setIsError((prev) => ({
        ...prev,
        toDate: "Date should not be before minimal date",
      }));
      return;
    } else {
      setIsError((prev) => ({
        ...prev,
        toDate: null,
      }));
    }
    statisticsDataByQueryRequest(
      "GET",
      `v1/admin/admin-dashboard?from_date=${moment(value).format(
        "YYYY-MM-DD"
      )}&to_date=${toDate ? moment(toDate).format("YYYY-MM-DD") : ""}`
    );
  };

  const toDateOnchange = (value) => {
    if (fromDate && value.isBefore(fromDate)) {
      setIsError((prev) => ({
        ...prev,
        toDate: "Date should not be before minimal date",
      }));
      return;
    } else {
      setIsError((prev) => ({
        ...prev,
        toDate: null,
      }));
    }
    setToDate(value);
    statisticsDataByQueryRequest(
      "GET",
      `v1/admin/admin-dashboard?from_date=${
        fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""
      }&to_date=${moment(value).format("YYYY-MM-DD")}`
    );
  };

  return (
    <AdminLayout>
      <div className="mainContent">
        <div className="adminCard sm">
          <div className="StatisticsPage">
            <div className="inCardHeader">
              <div className="jss325">
                <h6 className="adminCardTitle-h6">Statistics</h6>
              </div>
              <div className="toolbar-filters">
                <div className="datepickers">
                  <div className="date-picker date-picker-icon">
                    <DatePickerForAdmin
                      value={fromDate}
                      // setValue={setFromDate}
                      onChange={fromDateOnchange}
                      label="From"
                    />
                  </div>
                  <div className="date-picker date-picker-icon">
                    <DatePickerForAdmin
                      value={toDate}
                      // setValue={setToDate}
                      onChange={toDateOnchange}
                      minDate={fromDate}
                      label="To"
                    />
                    {isError.toDate && (
                      <p className="error-msg">{isError.toDate}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-table-wrapper">
              <div className="table-responsive">
                <Table
                  className="table expandable-table"
                  aria-labelledby="tableTitle"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell padding="default">Property</TableCell>
                      <TableCell padding="default">Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover>
                      <TableCell>
                        Total number of trials that do not upgrade
                      </TableCell>
                      <TableCell>
                        {statisticsObj.total_number_of_trials_that_do_not_upgrade
                          ? statisticsObj.total_number_of_trials_that_do_not_upgrade
                          : "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow hover className="expandable-row">
                      <TableCell className="nowrap" colSpan={10}>
                        <Accordion>
                          <AccordionSummary>
                            <div className="expansion-row expansion-row-header">
                              <p className="property">
                                Total number of shifts created{" "}
                                <KeyboardArrowDownIcon />
                              </p>
                              <p className="value">{totalCount.shiftCount}</p>
                            </div>
                          </AccordionSummary>

                          {statisticsObj?.total_shift_events?.length > 0 &&
                            statisticsObj.total_shift_events.map((shift) => (
                              <AccordionDetails>
                                <div className="expansion-row">
                                  <p className="property text-capitalize ">
                                    {shift.start_period_type}
                                  </p>
                                  <p className="value">
                                    {shift.shift_event_count}
                                  </p>
                                </div>
                              </AccordionDetails>
                            ))}
                        </Accordion>
                      </TableCell>
                    </TableRow>

                    <TableRow hover className="expandable-row">
                      <TableCell className="nowrap" colSpan={10}>
                        <Accordion>
                          <AccordionSummary>
                            <div className="expansion-row expansion-row-header">
                              <p className="property">
                                Total number of personal events created
                                <KeyboardArrowDownIcon />
                              </p>
                              <p className="value">{totalCount.allEvent}</p>
                            </div>
                          </AccordionSummary>

                          {statisticsObj?.total_default_events?.length > 0 &&
                            statisticsObj.total_default_events.map((event) => (
                              <AccordionDetails>
                                <div className="expansion-row">
                                  <p className="property">{event.event_name}</p>
                                  <p className="value">
                                    {event.default_event_count}
                                  </p>
                                </div>
                              </AccordionDetails>
                            ))}
                          <AccordionDetails>
                            <div className="expansion-row">
                              <p className="property">Other</p>
                              <p className="value">{totalCount.otherEvent}</p>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>

                    {/* <tr className="MuiTableRow-root MuiTableRow-hover expandable-row">
                      <td
                        className="MuiTableCell-root MuiTableCell-body nowrap"
                        colspan="2"
                      >
                        <div className="MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded MuiExpansionPanel-root MuiExpansionPanel-rounded">
                          <div
                            className="MuiButtonBase-root MuiExpansionPanelSummary-root"
                            tabindex="0"
                            role="button"
                            aria-expanded="false"
                          >
                            <div className="MuiExpansionPanelSummary-content">
                              <div className="expansion-row expansion-row-header">
                                <p className="property">
                                  Total number of personal events created
                                  <svg
                                    className="tableDropIcon"
                                    focusable="false"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                    role="presentation"
                                  >
                                    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                  </svg>
                                </p>
                                <p className="value">0</p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="MuiCollapse-container"
                            aria-hidden="true"
                          >
                            <div className="MuiCollapse-wrapper">
                              <div className="MuiCollapse-wrapperInner">
                                <div className="MuiCollapse-wrapperInner">
                                  <div className="MuiExpansionPanelDetails-root">
                                    <div className="expansion-row">
                                      <p className="property">Night</p>
                                      <p className="value">20</p>
                                    </div>
                                  </div>
                                  <div className="MuiExpansionPanelDetails-root">
                                    <div className="expansion-row">
                                      <p className="property">Afternoon</p>
                                      <p className="value">6</p>
                                    </div>
                                  </div>
                                  <div className="MuiExpansionPanelDetails-root">
                                    <div className="expansion-row">
                                      <p className="property">Evening</p>
                                      <p className="value">3</p>
                                    </div>
                                  </div>
                                  <div className="MuiExpansionPanelDetails-root">
                                    <div className="expansion-row">
                                      <p className="property">Day</p>
                                      <p className="value">11</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr> */}
                    <TableRow hover>
                      <TableCell>Total number of shifts switched</TableCell>
                      <TableCell>
                        {statisticsObj.switched_shifts_count
                          ? statisticsObj.switched_shifts_count
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow hover>
                      <TableCell>Total number of hours switched</TableCell>
                      <TableCell>
                        {statisticsObj.switched_shifts_hours
                          ? statisticsObj.switched_shifts_hours
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow hover>
                      <TableCell>Total number of hours scheduled</TableCell>
                      <TableCell>
                        {statisticsObj.shift_event_hours
                          ? statisticsObj.shift_event_hours
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow hover>
                      <TableCell>
                        Average time it takes for a shift to be switched
                      </TableCell>
                      <TableCell>
                        {" "}
                        {statisticsObj.average_time_to_approve_switch
                          ? statisticsObj.average_time_to_approve_switch
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow hover>
                      <TableCell>
                        Average days between trial to upgrade
                      </TableCell>
                      <TableCell>
                        {statisticsObj.avg_days_trial_to_upgrade
                          ? statisticsObj.avg_days_trial_to_upgrade
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow hover>
                      <TableCell>
                        Average days between blocked to upgrade
                      </TableCell>
                      <TableCell>
                        {statisticsObj.avg_days_blocked_to_upgrade
                          ? statisticsObj.avg_days_blocked_to_upgrade
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <tr className="MuiTableRow-root">
                      <td className="adminTableCell-body" colspan="2"></td>
                    </tr>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminStatistics;
