import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../Component/Header";
import { CalendarForModal } from "../../Component/Svg";
import UpgradeBanner from "../../Component/UpgradeBanner";
import UpgradeMessageModal from "../../Component/UpgradeMessageModal";
import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import { addAllConnections } from "../../store/connections/action";

const Connections = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { connections: prevConnections } = useSelector(
    (state) => state.connection
  );
  const { role, socket } = useSelector((state) => state.auth);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isContentEditable, setIsContentEditable] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen]     = useState(false);
  const [connections, setConnections] = useState([]);
  const [connenctionForModal, setConnenctionForModal] = useState({});
  const [isUpgradeModalShow, setIsUpgradeModalShow] = useState(false);

  const [note, setNote] = useState("");
  const [isErrorNote, setIsErrorNote] = useState(false);
  const { request, response } = useRequest();
  const { request: noteEditRequest, response: noteEditResponse } = useRequest();
  const {
    request: userConnectionPermisstionRequest,
    response: userConnectionPermisstionResponse,
  } = useRequest();

  const editHandler = (id) => {
    if (note.length < 1) {
      setIsErrorNote(true);
      return;
    }
    setIsContentEditable(false);
    const oldConnections = [...connections];
    setConnections(
      oldConnections.map((con) => {
        if (con.id_user == id) {
          con.notes = note;
        }
        return con;
      })
    );

    noteEditRequest("POST", "v1/share-calendar/edit-connection", {
      id,
      notes: note,
    });
  };




  useEffect(() => {
    if (prevConnections.length > 0) {
      setConnections(prevConnections);
    }
    request("GET", "v1/share-calendar/connections");
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("ConnectionResponse", (data) => {
        if (data.status) {
          request("GET", "v1/share-calendar/connections");
        }
      });
      return () => {
        socket.off("ConnectionResponse");
      };
    }
  }, [socket]);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { connections } = response.data;
        dispatch(addAllConnections(connections));
        setConnections(connections);
      }
    }
  }, [response]);

  const connenctionModalOpenHandler = (id, isVisible, isStatusUpdatable) => {
    
    const newConnections = [...connections];
    const newConnectionForModal = newConnections.find((c) => c.id_user === id);

    if(!isVisible && !isStatusUpdatable){
     
        setIsInfoModalOpen(true)
     
    }else{
      setIsDetailModalOpen(true);
    }
      if (newConnectionForModal) {
        setConnenctionForModal(newConnectionForModal);
        if (newConnectionForModal.notes) {
          setNote(newConnectionForModal.notes);
        } else {
          setIsContentEditable(true);
        }
      }
    // }
   
  };

  const userConnectionPermisstionHandler = (
    e,
    id,
    is_visible,
    is_removable
  ) => {

    let value = e.target.checked;
    let newConnections;

    newConnections = connections.map((con) => {
      if (con.id_share_calendar_request == id) {
        con = {
          ...con,
          is_visible: !con.is_visible,
          connection_visible:!con.connection_visible, // add this line
        };
      }
      return con;
    });
    setConnections(newConnections);

    userConnectionPermisstionRequest(
      "POST",
      "v1/share-calendar/update-connection-status",
      {
        id_share_calendar_request: id,
        status: is_visible ? "rejected" : "accepted",
        is_request: false,
      }
    );
  };

  const noteChangeHandler = (e) => {
    let value = e.target.value;
    setNote(value);
    if (value.length > 0) {
      setIsErrorNote(false);
    }
  };

  const locationHandler = () => {
    if (location.state?.from) {
      return `/${location.state?.from}`;
    } else {
      return "/settings";
    }
  };

  return (
    <>
      <div className="content-center  manager_list_page">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

        <div className="headerContant"></div>
        <div className="light_header">
          <Header heading="Connections" link={locationHandler()} />
          {/* <Sidebar from="events" /> */}
        </div>
        <div className="content-body">
          <div className="list-wrap ">
            <ul className=" manager_list blue_scroll_bar connection_scroll">
              {connections.length > 0 ? (
                connections.map((con) => (
                  <li
                    className="manager_list_item"
                    onClick={() => connenctionModalOpenHandler(con.id_user, con.is_visible, con.is_status_updatable)}
                    key={con.id_user}
                  >
                    <a
                      className="manager_profile"
                      style={{
                        backgroundColor: `${
                          !con.is_visible && con.connection_visible
                            ? "lightgray"
                            : "white"
                        }`,
                      }}
                    >
                      <span className="manager_img">
                        {con.user_picture ? (
                          <img src={`${API.IMG}${con.user_picture}`} />
                        ) : (
                          <i className="icon-user"></i>
                        )}
                      </span>
                      <div class="connections_info">
                        <span className="manager_name">
                          <span>{`${con.first_name} ${con.last_name}`}</span>
                          <span class="mamber_label">
                            {" "}
                            {con.notes &&
                              `${
                                con.notes.length > 25
                                  ? con.notes.slice(0, 25) + "..."
                                  : con.notes
                              }`}
                          </span>
                        </span>
                        <div
                          class="connections_swich"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div class="toggle-box">
                            <div class="switch">
                              <label>
                                {!con?.is_visible && !con?.is_status_updatable ? (
                                  ""
                                ):(<input
                                  type="checkbox"
                                  onChange={(e) =>
                                    userConnectionPermisstionHandler(
                                      e,
                                      con.id_share_calendar_request,
                                      con.is_visible,
                                      con.is_removable
                                    )
                                  }
                                  checked={con.is_visible}
                                  // checked={con.connection_visible}
                                />)}
                                
                                <span class="toggle-slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                ))
              ) : (
                <div className="content-body no_data_show placeholder-content">
                  <img src="/assets/img/connections.svg" />
                  <p class="font-md">You don't have a connections yet.</p>
                </div>
              )}
              {/* <li className="manager_list_item">
                <a href="#" className="manager_profile">
                  <span className="manager_img">
                    <img src="/assets/img/evening.png" alt="" />
                  </span>
                  <div class="connections_info">
                    <span className="manager_name">
                      <span>test manager </span>
                      <span class="mamber_label"> Team Member</span>
                    </span>
                    <div class="connections_swich">
                      <div class="toggle-box">
                        <div class="switch">
                          <label>
                            <input type="checkbox" />
                            <span class="toggle-slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li> */}
            </ul>

            <button
              onClick={() =>
                navigate("/request-invite", {
                  state: {
                    from: "/connections",
                    backRoute: location.state?.from,
                  },
                })
              }
              className="btn-rounded btn-add"
              variant="contained"
            >
              {/* <a href="add-shifts.html" className="btn-rounded btn-add"> */}
              <i className="fas fa-plus" />
              {/* </a> */}
            </button>
          </div>
        </div>
        {isUpgradeModalShow && (
          <UpgradeMessageModal
            isShowModal={isUpgradeModalShow}
            setIsShowModal={setIsUpgradeModalShow}
          />
        )}

          <Modal
            show={isDetailModalOpen}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="thememodal"
            // backdrop="static"
            onHide={() => setIsDetailModalOpen(false)}
          >
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsDetailModalOpen(false)}
              />
              <div className="modal-body p-0">
                <div className="single-view-header with-icon">
                  <span className="single-view-label">
                    {connenctionForModal?.user_picture ? (
                      <img
                        src={`${API.IMG}${connenctionForModal.user_picture}`}
                        alt="day icon"
                      />
                    ) : (
                      <i className="icon-user"></i>
                    )}
                  </span>
                  <div>
                    <p className="font-lg fw-500 mb10 line-height-1">{`${connenctionForModal?.first_name} ${connenctionForModal?.last_name}`}</p>
                    <p className="color-text-70">
                      {connenctionForModal?.email}
                    </p>
                  </div>
                </div>
                <div className="single-view-body">
                  <p class="font-md fw-700 mb-3 text-capitalize">Notes</p>
                  {isContentEditable ? (
                    <textarea
                      className="font-md w-100 mb-4  editabel_text"
                      onChange={noteChangeHandler}
                    >
                      {note ?? ""}
                    </textarea>
                  ) : (
                    <p
                      className="font-md  mb-4  editabel_text note_text"
                      id="note-edit"
                      data-placeholder="type ..."

                      //  contentEditable={isContentEditable}
                      // onChange={(e) => setNote(e.target.value)}
                    >
                      {note ?? ""}
                    </p>
                  )}
                  {isErrorNote && (
                    <p className="error-msg">This field is mandatory.</p>
                  )}

                  {isContentEditable && (
                    <div>
                      <button
                        className="btn save_btn"
                        onClick={() => editHandler(connenctionForModal.id_user)}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
                <div
                  className="single-view-footer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <button
                    className="btn-icon w-30 color-text"
                    onClick={() => setIsContentEditable(true)}
                  >
                    <i className="icon-edit icon-shadow blue-color"></i>
                  </button>
                  <a
                    className="btn-icon w-30 color-warning cursor"
                    onClick={() =>
                      navigate(
                        `/connection-calendar/${connenctionForModal.id_user}`,
                        {
                          state: {
                            user: connenctionForModal,
                            from: location.state?.from,
                          },
                        }
                      )
                    }
                  >
                    {/* <i className="fas fa-trash" /> */}
                    <CalendarForModal />
                  </a>
                </div>
              </div>
            </div>
          </Modal>
      

          <Modal
            show={isInfoModalOpen}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="thememodal"
            // backdrop="static"
            onHide={() => setIsInfoModalOpen(false)}
          >
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsInfoModalOpen(false)}
              />
              <div className="modal-body p-0">
               
                <div className="single-view-body text-center">
                  <p class="font-md fw-400 mb-3">This user has stopped sharing their calendar.</p>
                  <button  type="button" class="btn invitation_accept text-center" onClick={() => setIsInfoModalOpen(false)}>Accept</button>
                </div>
               
              </div>
            </div>
          </Modal>
          {/* <Modal
            show={isDetailModalOpen}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="thememodal"
            // backdrop="static"
            onHide={() => setIsDetailModalOpen(false)}
          >
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsDetailModalOpen(false)}
              />
              <div className="modal-body p-0">
                <div className="single-view-header with-icon">
                  <div>
                    <p className="font-lg fw-500 mb10 line-height-1">
                      This user has stopped sharing their calender
                    </p>
                    <p className="color-text-70"></p>
                  </div>
                </div>
              </div>
            </div>
          </Modal> */}
      </div>
    </>
  );
};

export default Connections;
