import { useState } from "react";
import { Link } from "react-router-dom";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";
import { toast } from "react-toastify";
import { Spinner } from "./Svg";

const StripeForm = (props) => {
  const {
    handleChange,
    from,
    plan,
    addCardLoading,
    addSubscriptionLoading,
    loadingFromAddPayment,
  } = props;

  const [errors, setErrors] = useState({
    cardExpiry: "",
    cardNumber: "",
    cardCvc: "",
  });

  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    if (props.stripe) {
      props.stripe
        .createSource({
          type: "card",
        })
        .then((payload) => {
          if (payload.error) {
            toast.error(payload.error.message);
          } else {
            payload && handleChange(payload);
          }
        });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  const onChangeHandler = (change) => {
    if (change.error && change.error.message) {
      setErrors((prev) => ({
        ...prev,
        [change.elementType]: change.error.message,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [change.elementType]: "",
      }));
    }
  };

  return (
    <form
      onSubmit={onSubmitHandler}
      className="flex dir-col grow-1 payment-form"
    >
      <div className="content-body pb-0">
        <div className="profile-info">
          <div className="form-field">
            <p className="form-label">Card Number</p>
            <div className="form-input">
              <CardNumberElement
                className="payment-input"
                style={{
                  base: {
                    fontSize: "16px",
                    color: "#202123",
                    lineHeight: "24px",
                    fontFamily: '"SF Pro Display", serif',
                    "::placeholder": {
                      color: "rgba(35,35,35,.5)",
                    },
                  },
                }}
                onChange={onChangeHandler}
                placeholder="Enter Card Number"
              />
              <span className="border" />
            </div>
            {errors.cardNumber && (
              <p className="error-msg">{errors.cardNumber}</p>
            )}
          </div>

          <div className="form-field">
            <p className="form-label">Expiration Date</p>
            <div className="form-input">
              <CardExpiryElement
                className="payment-input"
                style={{
                  base: {
                    fontSize: "16px",
                    color: "#202123",
                    lineHeight: "24px",
                    fontFamily: '"SF Pro Display", serif',
                    "::placeholder": {
                      color: "rgba(35,35,35,.5)",
                    },
                  },
                }}
                onChange={onChangeHandler}
                placeholder="MM/YY"
              />
              <span className="border" />
            </div>
            {errors.cardExpiry && (
              <p className="error-msg">{errors.cardExpiry}</p>
            )}
          </div>

          <div className="form-field">
            <p className="form-label">CVC</p>
            <div className="form-input">
              <CardCVCElement
                className="payment-input"
                style={{
                  base: {
                    fontSize: "16px",
                    color: "#202123",
                    lineHeight: "24px",
                    fontFamily: '"SF Pro Display", serif',
                    "::placeholder": {
                      color: "rgba(35,35,35,.5)",
                    },
                  },
                }}
                onChange={onChangeHandler}
                placeholder="Enter CVC code"
              />
              <span className="border" />
            </div>
            {errors.cardCvc && <p className="error-msg">{errors.cardCvc}</p>}
          </div>

          {from == "upgrade-membership" && (
            <div>
              <p className="font-md fw-500 total_amount">Total Amount</p>
              {plan && <p className="color-text-70 mt-2">${plan.amount} USD</p>}
            </div>
          )}

          {props.error && (
            <div className="mt-6">
              <p className="error-msg with-icon">{props.error}</p>
            </div>
          )}
        </div>
      </div>

      {from == "upgrade-membership" ? (
        <div className="content-footer">
          <button type="submit" className="btn formBtn btn-white">
            {addSubscriptionLoading || addCardLoading ? <Spinner /> : "Buy Now"}
          </button>
        </div>
      ) : (
        <div className="content-footer">
          <Link to="/manage-payment" className="btn btn-link   color-text-50">
            Cancel
          </Link>
          <button
            type="button"
            className="btn formBtn btn-white"
            onClick={onSubmitHandler}
          >
            {loadingFromAddPayment ? <Spinner /> : "Add"}
          </button>
        </div>
      )}
    </form>
  );
};
export default StripeForm;
