import { useNavigate } from "react-router-dom";

const Header = ({ heading, children, link, color = "" }) => {
  const navigate = useNavigate();
  return (
    <div className="content-header">
      <span className="main_profile_box">
        <span
          className="btn-icon w-30 color-text mr-5 cursor back_profile"
          style={{ color }}
          onClick={() => {
            navigate(link);
          }}
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
          <h1 className="page_title" style={{ color }}>
            {heading}
          </h1>
        </span>
        {children}
      </span>
    </div>
  );
};

export default Header;
