import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import TimePicker from "../Component/TimePicker";
// import SwipeCalendar from "swipecalendar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CalendarComponent = () => {
  const calendarRef = useRef(null);
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // const [smoothSwipe, setsSoothSwipe] = useState({ initial: 0, delta: 0 });

  useEffect(() => {
    // const calendarElement = calendarRef.current.getApi().el;
    // $(".calendarElement").on("swipe", function (event, slick, direction) {
    //   console.log(direction);
    //   // left
    // });
  }, []);

  useEffect(() => {
    // const hammerify = (el, options) => {
    //   console.log("calll");
    //   const mc = new Hammer.Manager(el, options);
    //   mc.on("swipeleft", () => {
    //     alert("hello left");
    //     calendarRef.current.getApi().next();
    //   });
    //   mc.on("swiperight", () => {
    //     alert("hello right");
    //     calendarRef.current.getApi().prev();
    //   });
    // };

    // const calendarElement = calendarRef.current.getApi().el;

    // new SwipeCalendar(calendarElement, {
    //   // SwipeCalendar specific options
    //   swipeLicenseKey: "XXXXX-XXXXX-XXXXX-XXXXX",
    //   swipeEffect: "slide",
    //   swipeSpeed: 250,
    //   swipeTitlePosition: "center",

    //   // FullCalendar options and callbacks
    //   plugins: [dayGridPlugin],
    //   height: "100%",
    // });

    // hammerify(calendarElement);

    return () => {
      // Cleanup code if needed
    };
  }, []);

  return (
    <div>
      {/* <Hammer
        onSwipeRight={(...a) => calendarRef.current.getApi().prev()}
        onSwipeLeft={(...a) => calendarRef.current.getApi().next()}
        onPan={(...rest) => console.log(rest, "onPan")}
      > */}
      {/* <div> <TimePicker /> */}
      <Slider
        {...settings}
        dots={false}
        // swipeToSlide={true}
        onSwipe={() => console.log("okk")}
      >
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
        />
      </Slider>
      {/* </div> */}
      {/* </Hammer> */}
    </div>
  );
};

export default CalendarComponent;
