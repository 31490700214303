const Icons = {
  Birthday: "birthday",
  "Date night": "date-night",
  Gym: "gym",
  Movies: "movies",
  Shopping: "shopping",
  "Sick day": "sick-day",
  Doctor: "doctor",
  "Pet care": "pet-care",
  Party: "party",
  Spa: "spa",
  Vacation: "vacation",
  // other: "other"
};

export default Icons;
