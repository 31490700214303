import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import io from "socket.io-client";
import { SOCKETURL } from "./constant/api";

// import CacheBuster from "react-cache-buster";
// import { version } from "../package.json";

import useRequest from "./hooks/useRequest";
import {
  adminRoutes,
  memberUserRoute,
  notPrivateRoutes,
  privateRoutes,
} from "./routes";
import {
  logout,
  authSuccess,
  updateRedux,
  addCardInfo,
  addSubscriptionId,
  addCurrentSubscriptionData,
} from "./store/auth/action";
import {
  addAllAssignedEvents,
  addAllAssignedNotes,
  addAllAssignedShifts,
  addAllWorkplaces,
} from "./store/calendarData/action";
import {
  addAllNotifications,
  addNotificationCount,
  increaseNotificationCount,
} from "./store/notifications/action";

// switch-shift

const scrollablePage = [
  "/terms-and-conditions",
  "day-personal-events",
  "day-details",
  "calendar",
  "signup",
  "/settings",
  "/edit-profile",
  "/profile",
  "/add-work",
  "edit-work",
  "/add-manager",
  "edit-manager",
  "manager",
  "/team",
  "/switch-shift",
  "connection-calendar",
  "/request-giveaway",
  // "/events",
  "admin",
];

const soketAviodRoutes = ["/calendar", "/team"];
const soketAviodRoutesForNotification = ["/calendar", "/notifications-list"];

const App = () => {
  const isProduction = process.env.NODE_ENV === "production";
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const socket = useRef();
  const {
    loggedIn,
    loading,
    role,
    token: reduxToken,
    userId,
  } = useSelector((state) => state.auth);

  const { request, response } = useRequest();
  const { request: cardRequest, response: cardResponse } = useRequest();
  const { request: cardInformationRequest, response: cardInformationResponse } =
    useRequest();
  const { request: workplacesRequest, response: workplacesResponse } =
    useRequest();

  const { request: assignedShiftsRequest, response: assignedShiftsResponse } =
    useRequest();
  const {
    request: customerSubcriptionRequest,
    response: customerSubcriptionResponse,
  } = useRequest();

  const {
    request: notificationRequestBecauseOfSocket,
    response: notificationResponseBecauseOfSocket,
  } = useRequest();

  const [token, setToken] = useState("");

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      setToken(token);
      request("GET", "v1/auth/secure/user");
    }
  }, []);

  useEffect(() => {
    if (
      scrollablePage.includes(location.pathname) ||
      scrollablePage.includes(location.pathname.split("/")[2]) ||
      scrollablePage.includes(location.pathname.split("/")[1])
    ) {
      document.body.classList.add("has-scroll");
    } else {
      document.body.classList.remove("has-scroll");
    }
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (workplacesResponse) {
      const { workplaces } = workplacesResponse.data;
      dispatch(addAllWorkplaces(workplaces));
    }
  }, [workplacesResponse]);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { user, managers } = response.data;
        const {
          first_name,
          last_name,
          email,
          profile_image,
          // gender,
          birthday,
          mobile_number,
          title,
          id_user,
          country_code,
          role,
          remaining_days,
          is_managers_approval,
        } = user;

        dispatch(
          authSuccess({
            token,
            firstName: first_name,
            lastName: last_name,
            email,
            profilePic: profile_image,
            // gender,
            dob: birthday,
            mobile_number,
            countryCode: country_code,
            title,
            userId: id_user,
            managers: managers ?? [],
            role: role.name,
            remainingDays: remaining_days ?? null,
            isManagersApproval: is_managers_approval,
          })
        );
      } else {
        dispatch(logout());
      }
    }
  }, [response]);

  useEffect(() => {
    if (userId) {
  
      if (!socket.current) {
        socket.current = io(SOCKETURL.PORT, {
          transports: ["websocket"],
          reconnect: true
        });
      } else {
        socket.current.open();
      }

      // console.log("socket.current",socket.current)
      if (socket.current) {
        socket.current.emit("JoinRoom", userId);
        dispatch(
          updateRedux({
            socket: socket.current,
          })
        );

        socket.current.io.on("reconnect", (attempt) => {
          socket.current.emit("JoinRoom", userId);

          // const splitted = window.location.pathname.split("/");

          // if (splitted.length === 3 && splitted[1] === "chat") {
          //   socket.current.emit("updatePage", {
          //     conversationId: splitted[2],
          //   });
          // }
        });
      }
      if (role != "admin") {
        workplacesRequest("GET", "v1/workplace/workplaces");
        cardRequest("GET", "v1/stripe/card");

        if (role == "member_user") {
          customerSubcriptionRequest("GET", "v1/stripe/customer-subscription");
        }
      }

      return () => {
        if (socket.current) {
          socket.current.close();
        }
      };
    }
  }, [userId]);

  useEffect(() => {
    if (socket.current && !soketAviodRoutes.includes(pathname)) {
      socket.current.on("SwitchShiftUsersResponse", (data) => {
        if (data.status) {
          assignedShiftsRequest("GET", `v1/event/calender-events`);
        }
      });
      return () => {
        socket.current.off("SwitchShiftUsersResponse");
      };
    }
  }, [socket, pathname]);

  useEffect(() => {
    if (socket.current && !soketAviodRoutesForNotification.includes(pathname)) {
      socket.current.on("NotificationResponse", ({ status, notification }) => {
        if (status) {
          notificationRequestBecauseOfSocket(
            "GET",
            "v1/notification/notifications"
          );
        }
      });

      return () => socket.current.off("NotificationResponse");
    }
  }, [socket.current, pathname]);

  useEffect(() => {
    if (assignedShiftsResponse) {
      let shifts = assignedShiftsResponse.data.shifts;
      let events = assignedShiftsResponse.data.events;
      let notes = assignedShiftsResponse.data.notes;
      dispatch(addAllAssignedShifts(shifts));
      dispatch(addAllAssignedEvents(events));
      dispatch(addAllAssignedNotes(notes));
    }
  }, [assignedShiftsResponse]);

  useEffect(() => {
    if (cardResponse) {
      const { stripe_card } = cardResponse.data;
      if (stripe_card && Object.keys(stripe_card).length > 0) {
        cardInformationRequest(
          "GET",
          `v1/stripe/card-information/${stripe_card.card_id}`
        );
      }
    }
  }, [cardResponse]);

  useEffect(() => {
    if (cardInformationResponse) {
      const { results } = cardInformationResponse.data;
      dispatch(addCardInfo(results));
      // setCardInfo(results);
    }
  }, [cardInformationResponse]);

  useEffect(() => {
    if (
      customerSubcriptionResponse &&
      customerSubcriptionResponse.status == "SUCCESS"
    ) {
      const { subscription_id, id_stripeplans } =
        customerSubcriptionResponse.data.customer_subscription;
      if (subscription_id && id_stripeplans) {
        dispatch(
          addCurrentSubscriptionData(
            customerSubcriptionResponse.data.customer_subscription
          )
        );
      }
    }
  }, [customerSubcriptionResponse]);

  useEffect(() => {
    if (notificationResponseBecauseOfSocket) {
      const { notifications_count, notifications } =
        notificationResponseBecauseOfSocket.data;
      dispatch(addAllNotifications(notifications));
      dispatch(addNotificationCount(notifications_count));
    }
  }, [notificationResponseBecauseOfSocket]);

  return (
    // <CacheBuster
    //   currentVersion={version}
    //   isEnabled={isProduction} //If false, the library is disabled.
    //   isVerboseMode={false} //If true, the library writes verbose logs to console.
    //   metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    // >
    <div>
      {/* {false && <Loader />} */}
      {loggedIn === false ? (
        <Routes>
          {notPrivateRoutes.map((r, i) => (
            <Route key={i} {...r} />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : loggedIn && role == "blocked_user" ? (
        <Routes>
          {privateRoutes.map((r, i) => (
            <Route key={i} {...r} />
          ))}
          <Route path="*" element={<Navigate to="/calendar" replace />} />
        </Routes>
      ) : loggedIn && (role == "member_user" || role == "trial_user"  || role == "ambassador_user") ? (
        <Routes>
          {memberUserRoute.map((r, i) => (
            <Route key={i} {...r} />
          ))}
          <Route path="*" element={<Navigate to="/calendar" replace />} />
        </Routes>
      ) : loggedIn && role == "admin" ? (
        <Routes>
          {adminRoutes.map((r, i) => (
            <Route key={i} {...r} />
          ))}
          <Route
            path="*"
            element={<Navigate to="/admin/statistics" replace />}
          />
        </Routes>
      ) : null}

      <ToastContainer />
    </div>
    // </CacheBuster>
  );
};

export default App;
