export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";
export const LOADING = "LOADING";
export const ADD_MANAGER = "ADD_MANAGER";
export const UPDATE_REDUX = "UPDATE_REDUX";
export const UPGRADEMEMERSHIP = "UPGRADEMEMERSHIP";
export const ADDCARDINFO = "ADDCARDINFO";
export const ADDSUBSCRIPTIONID = "ADDSUBSCRIPTIONID";
export const ADDSTRIPEPLANID = "ADDSTRIPEPLANID";
export const ADDCURRENTSUBSCRIPTIONDATA = "ADDCURRENTSUBSCRIPTIONDATA";
export const CHANGEMANAERAPPROVAL = "CHANGEMANAERAPPROVAL";
export const CHANGECURRENTSUBSCRIPTIONDATA = "CHANGECURRENTSUBSCRIPTIONDATA";
