import { useEffect, useState } from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import useRequest from "../../hooks/useRequest";
import Sidebar from "../../Component/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { DeleteIcon } from "../../Component/Svg";
import { getDiffrenceHours } from "../../utils/fn";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllAssignedEvents,
  addEvents,
} from "../../store/calendarData/action";
import Icons from "../../Component/Icons";
import UpgradeBanner from "../../Component/UpgradeBanner";

const Events = () => {
  const { allAssignedEvents, allEvents } = useSelector(
    (state) => state.calendar
  );
  const { role } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [shifts, setShifts] = useState([]);
  const [modalShift, setModalShift] = useState({});
  const [shiftModalShow, setShiftModalShow] = useState(false);
  const [isShiftTabActive, setIsShiftTabActive] = useState(true);
  const [isDeletedError, setisDeletedError] = useState(false);

  const [alreadyExistName, setAlreadyExistName] = useState([]);

  const [personalEvents, setPersonalEvents] = useState([]);
  const [addEventModalShow, setAddEventModalShow] = useState(false);
  const [deleteEventModalShow, setDeleteEventModalShow] = useState(false);
  const [isEventTabActive, setIsEventTabActive] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
    getValues,
  } = useForm();

  const { request, response } = useRequest();
  const { request: shiftDeleteRequest, response: shiftDeleteResponse } =
    useRequest();
  const { request: personalEventRequest, response: personalEventResponse } =
    useRequest();
  const {
    request: personalEventDeleteRequest,
    response: personalEventDeleteResponse,
  } = useRequest();
  const { request: addEventRequest, response: addEventResponse } = useRequest();

  useEffect(() => {
    request("GET", "v1/event/shifts");
    personalEventRequest("GET", "v1/event/events");
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        setShifts(response?.data?.shifts);
      }
    }
  }, [response]);

  useEffect(() => {
    if (personalEventResponse) {
      if (personalEventResponse.status == "SUCCESS") {
        const newEvent = personalEventResponse?.data?.events;
        setPersonalEvents(newEvent);

        setAlreadyExistName(
          newEvent.map((data) => data.event_name.toLowerCase())
        );
      }
    }
  }, [personalEventResponse]);

  useEffect(() => {
    if (personalEventDeleteResponse) {
      setDeleteEventModalShow(false);
    }
  }, [personalEventDeleteResponse]);

  useEffect(() => {
    if (addEventResponse) {
      setAddEventModalShow(false);
      const prevEvent = personalEvents.filter((p) => p.event_id);
      setPersonalEvents([
        ...prevEvent,
        addEventResponse.data.personalEventData,
      ]);
      dispatch(
        addEvents([...allEvents, addEventResponse.data.personalEventData])
      );

      window.scrollTo(0,0)
      setValue("event_name","")

      // setPersonalEvents(prev => [...prev , ])
    }
  }, [addEventResponse]);

  useEffect(() => {
    if (shiftDeleteResponse) {
      if (shiftDeleteResponse.status === "SUCCESS") {
        const { id } = shiftDeleteResponse.data;
        let newshifts = shifts.filter((s) => s.id_shift != id);
        setShifts(newshifts);
        setisDeletedError(false);
        setShiftModalShow(false);
      } else if (shiftDeleteResponse.status === "FAIL") {
        setisDeletedError(true);
      }
    }
  }, [shiftDeleteResponse]);

  const shiftModalhanlder = (id) => {
    const shift = shifts.find((s) => s.id_shift == id);
    if (shift) {
      setModalShift(shift);
    }
  };

  const shiftDeleteHandler = (id) => {
    shiftDeleteRequest("DELETE", `v1/event/shift-delete/${id}`);
  };

  const addEventSubmitHandler = (data) => {
    const { event_name } = data;
    if (alreadyExistName.includes(event_name.toLowerCase())) {
      setError("event_name", {
        type: "manual",
        message: "An event with this name already exists.",
      });
      return;
    }
    addEventRequest("POST", "v1/event/add-event", {
      event_name,
      icon_name: event_name,
    });
    setPersonalEvents((prev) => [...prev, { event_name }]);
    setAddEventModalShow(false);
  };

  const personalEventDeleteHandler = () => {
    setDeleteEventModalShow(false);

    setPersonalEvents((prev) =>
      prev.filter((p) => p.event_id != deleteEventId)
    );
    const newAllAssidnedEvents = allAssignedEvents.filter(
      (event) => event.event_id != deleteEventId
    );
    const newAllEvents = allEvents.filter(
      (event) => event.event_id != deleteEventId
    );
    dispatch(addAllAssignedEvents(newAllAssidnedEvents));
    dispatch(addEvents(newAllEvents));
    personalEventDeleteRequest(
      "DELETE",
      `v1/event/event-delete/${deleteEventId}`
    );
    setDeleteEventId(null);
  };

  const shiftOpenHandler = () => {
    setShiftModalShow(true);
    setisDeletedError(false);
  };

  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <Sidebar from="events" heading="Events" />
      <div className="page-section">
        <div className="container">
          <div className="innerPageBox position-relative">
            <div className="eventTabs">
              <ul
                className="nav nav-tabs tabsRow"
                id="EventsTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    onClick={() => {
                      setIsShiftTabActive(true);
                      setIsEventTabActive(false);
                    }}
                    id="shifts-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#shifts-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="shifts-tab-pane"
                    aria-selected="true"
                  >
                    shifts
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    onClick={() => {
                      setIsShiftTabActive(false);
                      setIsEventTabActive(true);
                    }}
                    id="personal-events-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#personal-events-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="personal-events-tab-pane"
                    aria-selected="false"
                  >
                    Personal Events
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="shifts-tab-pane"
                  role="tabpanel"
                  aria-labelledby="shifts-tab"
                  tabIndex={0}
                >
                  <div className="shiftListCard blue_scroll_bar p-3">
                    <div className="siftList">
                      {shifts &&
                        shifts.length > 0 &&
                        shifts.map((shift) => (
                          <div
                            className="siftcard"
                            onClick={() =>
                              shift?.shift_name == "Off Day"
                                ? ""
                                : shiftModalhanlder(shift.id_shift)
                            }
                          >
                            <div
                              className="shiftCardRow"
                              // data-bs-toggle="modal"
                              // data-bs-target="#shiftDtlModal"
                              onClick={() =>
                                shift?.shift_name == "Off Day"
                                  ? ""
                                  : shiftOpenHandler()
                              }
                            >
                              <span className="icon">
                                <img
                                  src={`assets/img/${shift?.start_period}.png`}
                                  alt="afternoon icon"
                                />
                              </span>
                              <div className="siftcard-body">
                                <p className="font-xmd fw-500">
                                  {shift?.shift_name == "Off Day"
                                    ? "Not Available"
                                    : shift?.shift_name}
                                </p>
                                <p className="color-text-50 nowrap ml-3">
                                  {shift?.shift_name == "Off Day"
                                    ? `${getDiffrenceHours(
                                        shift?.start_time,
                                        shift?.end_time
                                      )} h`
                                    : moment(shift?.start_time).format(
                                        "hh:mm A"
                                      ) +
                                      " - " +
                                      moment(shift?.end_time).format("hh:mm A")}
                                  {/* {moment(shift?.start_time).format("hh:mm A")}{" "}
                                  - {moment(shift?.end_time).format("hh:mm A")} */}
                                  {/* 7:00 AM - 7:00 PM */}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="personal-events-tab-pane"
                  role="tabpanel"
                  aria-labelledby="personal-events-tab"
                  tabIndex={0}
                >
                  <div className="eventsListingCard blue_scroll_bar p-3 ">
                    <div className="list-wrap">
                      <div className="eventsUL pb-3">
                        {personalEvents.length > 0 &&
                          personalEvents.map((personalEvent) => (
                            <div className="liRowCard">
                              <span
                                className={`${
                                  !personalEvent?.is_default ? "icon-font" : ""
                                } label-icon bg-purple`}
                              >
                                <i
                                  className={`${
                                    personalEvent?.is_default
                                      ? `icon-${
                                          Icons[personalEvent?.icon_name]
                                        }`
                                      : "fas fa-ellipsis-h "
                                  } color-white box-30`}
                                />
                              </span>
                              <div className="eventCard-body">
                                <p className="font-xmd fw-500 ellipsis text-capitalize">
                                  {personalEvent?.event_name}
                                </p>
                              </div>
                              {!personalEvent.is_default && (
                                <button
                                  type="button"
                                  className="btn-delete-card btn-icon w-30 color-warning"
                                  onClick={() => {
                                    setDeleteEventModalShow(true);
                                    setDeleteEventId(personalEvent?.event_id);
                                  }}
                                >
                                  <i className="icon-delete icon-shadow" />
                                </button>
                              )}
                            </div>
                          ))}

                        {/* <div className="liRowCard">
                          <span className="label-icon bg-purple">
                            <i className="fas fa-ellipsis-h color-white box-30" />
                          </span>
                          <div className="eventCard-body">
                            <p className="font-xmd fw-500 ellipsis text-capitalize">
                              hiking
                            </p>
                          </div>
                          <button
                            type="button"
                            className="btn-delete-card btn-icon w-30 color-warning"
                          >
                            <i className="icon-delete icon-shadow" />
                          </button>
                        </div>
                        <div className="liRowCard">
                          <span className="label-icon bg-purple">
                            <i className="fas fa-ellipsis-h color-white box-30" />
                          </span>
                          <div className="eventCard-body">
                            <p className="font-xmd fw-500 ellipsis text-capitalize">
                              hiking
                            </p>
                          </div>
                          <button
                            type="button"
                            className="btn-delete-card btn-icon w-30 color-warning"
                          >
                            <i className="icon-delete icon-shadow" />
                          </button>
                        </div>
                        <div className="liRowCard">
                          <span className="label-icon bg-purple">
                            <i className="fas fa-ellipsis-h color-white box-30" />
                          </span>
                          <div className="eventCard-body">
                            <p className="font-xmd fw-500 ellipsis text-capitalize">
                              hiking
                            </p>
                          </div>
                          <button
                            type="button"
                            className="btn-delete-card btn-icon w-30 color-warning"
                          >
                            <i className="icon-delete icon-shadow" />
                          </button>
                        </div> */}
                      </div>
                      <div />
                    </div>
                    {/* <button
                      type="button"
                      className="btn-rounded btn-add"
                      data-bs-toggle="modal"
                      data-bs-target="#eventDtlModal"
                    >
                      <i className="fas fa-plus" />
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={() => {
            if (isShiftTabActive) {
              navigate("/add-shift");
            } else if (isEventTabActive) {
              setAddEventModalShow(true);
            }
          }}
          className="btn-rounded btn-add"
          variant="contained"
        >
          {/* <a href="add-shifts.html" className="btn-rounded btn-add"> */}
          <i className="fas fa-plus" />
          {/* </a> */}
        </button>
      </div>
      {/* Modal */}
      <div
        className="modal fade thememodal"
        id="eventDtlModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="modal-body p-0">
              <div className="single-view-header addEventPopup">
                <span className="label-icon bg-purple">
                  <i className="fas fa-ellipsis-h color-white box-30" />
                </span>
                <div>
                  <p className="font-xmd fw-500 ellipsis text-capitalize">
                    Personal Event
                  </p>
                  <p className="color-text-70 text-capitalize mb-0">Other</p>
                </div>
              </div>
              <div className="single-view-body">
                <div className="form-field">
                  <div className="form-input">
                    <input
                      name="ShiftName"
                      type="TEXT"
                      placeholder="Event Name"
                      defaultValue=""
                    />
                    <span className="inputborder" />
                  </div>
                  <p className="error-msg">This field is mandatory.</p>
                </div>
                <div className="content-footer" style={{ overflow: "auto" }}>
                  <button
                    type="button"
                    className="btn-link formBtn color-text-50"
                  >
                    Cancel
                  </button>
                  <button type="button" className="btn btn-white formBtn">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade thememodal"
        id="shiftDtlModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="modal-body p-0">
              <div className="single-view-header with-icon">
                <span className="single-view-label">
                  <img
                    src={`/assets/img/${modalShift.start_period}.png`}
                    alt="day icon"
                  />
                </span>
                <div>
                  <p className="font-lg fw-500 mb-2">Shift</p>
                  <p className="color-text-70 text-capitalize">
                    {modalShift.start_period}
                  </p>
                </div>
              </div>
              <div className="single-view-body">
                <p className="font-md fw-700 mb-4 text-capitalize">
                  {modalShift.shift_name}
                </p>
                <ul className="list-with-icons">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                    </svg>
                    <span className="color-text-50 w-70">Start Time:</span>
                    <span className="color-text-70">
                      {moment(modalShift?.start_time).format("hh:mm A")}
                    </span>
                  </li>
                  <li>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                    </svg>
                    <span className="color-text-50 w-70">End Time:</span>
                    <span className="color-text-70">
                      {moment(modalShift?.end_time).format("hh:mm A")}
                    </span>
                  </li>
                </ul>
                <p className="color-text-50 w-70 mb-0">
                  Duration:
                  {moment(modalShift?.end_time).diff(
                    moment(modalShift?.start_time),
                    "hour"
                  )}
                  {/* 12.00h */}
                </p>
              </div>
              <div className="single-view-footer">
                <a
                  className="btn-icon w-30 color-warning cursor"
                  onClick={() => shiftDeleteHandler(modalShift.id_shift)}
                >
                  <i className="fas fa-trash" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body sure_modal_body">
              <div className="text-center pt-8 px-6">
                <p className="font-lg fw-700 mb-4">Are you sure?</p>
                <p className="font-md">
                  This will remove all the occurrences of the shift type on the
                  calendar.
                </p>
              </div>
            </div>
            <div className="modal-footer shift_time_footer">
              <button type="button" className="btn-link formBtn color-text-50">
                No
              </button>
              <button type="button" className="btn-white formBtn">
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={shiftModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        onHide={() => setShiftModalShow(false)}
      >
        {/* <div className="modal-dialog modal-dialog-centered"> */}
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            // data-bs-dismiss="modal"
            // aria-label="Close"

            onClick={() => {
              setShiftModalShow(false);
              setisDeletedError(false);
            }}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span className="single-view-label">
                <img
                  src={`/assets/img/${modalShift.start_period}.png`}
                  alt="day icon"
                />
              </span>
              <div>
                <p className="font-lg fw-500 mb-2">Shift</p>
                <p className="color-text-70 text-capitalize">
                  {modalShift.start_period}
                </p>
              </div>
            </div>
            <div className="single-view-body">
              <p className="font-md fw-700 mb-4 text-capitalize">
                {modalShift.shift_name}
              </p>
              <ul className="list-with-icons">
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                  <span className="color-text-50 w-70">Start Time:</span>
                  <span className="color-text-70">
                    {moment(modalShift?.start_time).format("hh:mm A")}
                  </span>
                </li>
                <li>
                  {" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                  <span className="color-text-50 w-70">End Time:</span>
                  <span className="color-text-70">
                    {moment(modalShift?.end_time).format("hh:mm A")}
                  </span>
                </li>
              </ul>
              <p className="color-text-50 w-70 mb-0">
                Duration:
                {getDiffrenceHours(
                  modalShift?.start_time,
                  modalShift?.end_time
                )}
                h{/* 12.00h */}
              </p>

              <p className="color-text-50 w-70 mb-0">
                Location: {modalShift?.workplace_name}
              </p>
            </div>
            {isDeletedError && (
              <div className="mx-6 mt-2 ">
                <p className="error-msg with-icon">
                  Please remove the following shift from your calendar first.
                </p>
              </div>
            )}
            <div className="single-view-footer">
              <Link
                className="btn-icon w-30 color-text"
                to={`/edit-shift/${modalShift?.id_shift}`}
              >
                <i className="icon-edit icon-shadow blue-color"></i>
              </Link>

              <a
                className="btn-icon w-30 color-warning cursor"
                onClick={() => shiftDeleteHandler(modalShift.id_shift)}
              >
                {/* <i className="fas fa-trash" /> */}
                <DeleteIcon />
              </a>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modal>
      <Modal
        show={addEventModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal personal_event"
        onHide={() => setAddEventModalShow(false)}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              // data-bs-dismiss="modal"
              // aria-label="Close"

              onClick={() => {
                setValue("event_name","");
                setAddEventModalShow(false);
              }}
            />
            <div className="modal-body p-0">
              <div className="single-view-header addEventPopup">
                <span className="label-icon bg-purple">
                  <i className="fas fa-ellipsis-h color-white box-30" />
                </span>
                <div>
                  <p className="font-xmd fw-500 ellipsis text-capitalize">
                    Personal Event
                  </p>
                  <p className="color-text-70 text-capitalize mb-0">Other</p>
                </div>
              </div>
              <form
                className="single-view-body"
                onSubmit={handleSubmit(addEventSubmitHandler)}
              >
                <div className="form-field">
                  <div className="form-input">
                    <input
                      name="ShiftName"
                      type="TEXT"
                      placeholder="Event Name"
                      defaultValue=""
                      {...register("event_name", {
                        onChange: (e) => {
                          if (
                            alreadyExistName.includes(
                              e.target.value.toLowerCase()
                            )
                          ) {
                            setError("event_name", {
                              type: "manual",
                              message:
                                "An event with this name already exists.",
                            });
                          } else {
                            clearErrors("event_name");
                          }
                        },
                        required: "This field is mandatory",
                      })}
                    />
                    <span className="inputborder" />
                  </div>
                  {errors.event_name && (
                    <p className="error-msg">{errors.event_name.message}</p>
                  )}
                </div>
                <div
                  className="personal_event-footer"
                  style={{ overflow: "auto" }}
                >
                  <button
                    type="button"
                    className="btn-link formBtn color-text-50"
                    onClick={() => {
                      setValue("event_name","");
                      setAddEventModalShow(false)
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-white formBtn">
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={deleteEventModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        onHide={() => setDeleteEventModalShow(false)}
      >
        <button
          type="button"
          className="btn-close"
          // data-bs-dismiss="modal"
          // aria-label="Close"

          onClick={() => {
            setDeleteEventModalShow(false);
            setDeleteEventId(null);
          }}
        />
        <div
        // className="modal-content"
        >
          <div className="modal-body sure_modal_body">
            <div className="text-center pt-8 px-6">
              <p className="font-lg fw-700 mb-4">Are you sure?</p>
              <p className="font-md">
                This will remove all the occurrences of the shift type on the
                calendar.
              </p>
            </div>
          </div>
          <div className="modal-footer shift_time_footer">
            <button
              type="button"
              className="btn-link formBtn color-text-50"
              onClick={() => {
                setDeleteEventModalShow(false);
                setDeleteEventId(null);
              }}
            >
              No
            </button>
            <button
              type="button"
              className="btn-white formBtn"
              onClick={() => personalEventDeleteHandler()}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Events;
