import { createStore, compose, combineReducers } from "redux";

import authReducer from "./auth/reducer";
import calendarReducer from "./calendarData/reducer";
import connectionReducer from "./connections/reducer";
import notificationReducer from "./notifications/reducer";
import adminReducer from "./admin/reducer";

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  calendar: calendarReducer,
  connection: connectionReducer,
  notifications: notificationReducer,
  admin: adminReducer,
});

export const store = createStore(rootReducer, composeEnhancers());
