import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useRequest from "../hooks/useRequest";

const SignupVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { request, response } = useRequest();

  useEffect(() => {
    if (token) {
      request("GET", `v1/auth/activate/${token}`);
    } else {
      // navigate("/signup");
    }
  }, []);

  //   useEffect(() => {
  //     if (response) {
  //       if (response.status == "SUCCESS") {
  //       }
  //     }
  //   }, [response]);
  return (
    <section className="fullcontent bgBlue">
      <div className="container">
        <div className="content">
          <div className="sign-up-content color-white">
            <h3 className="my-6" style={{ color: "white" }}>
              Thanks!
            </h3>
            {response?.message}
            <button
              className="btn btn-white w-100 mt-2 submitButton"
              onClick={() => navigate("/login")}
            >
              {" "}
              Login{" "}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupVerification;
