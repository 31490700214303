import {
  ADD_ALL_NOTIFICATIONS,
  ADD_NOTIFICATION_COUNT,
  CLEAR_NOTIFICATION_DATA,
  INCREASE_NOTIFICATION_COUNT,
} from "./actionTypes";

const initialState = {
  notifications: [],
  notificationCount: 0,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...action.updates],
      };
    case ADD_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.updates,
      };
    case INCREASE_NOTIFICATION_COUNT: // this is for when notification comes by socket
      return {
        ...state,
        notificationCount: state.notificationCount + 1,
      };
    case CLEAR_NOTIFICATION_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default notificationReducer;
