import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useRequest from "../hooks/useRequest";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { authSuccess } from "../store/auth/action";
import { Spinner } from "../Component/Svg";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm({ mode: "onBlur" });

  const dispatch = useDispatch();
  const { request, response, loading } = useRequest();
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    const rememberMe = localStorage.getItem("rememberMe");
    if (email) {
      setValue("email", email);
      if (password) {
        setValue("password", password);
        setValue("rememberMe", rememberMe);
      }
    }
  }, []);

  useEffect(() => {
    if (response) {
      // setIsClickSignInButton(false);
      if (response.status === "SUCCESS") {
        {
          const email = getValues("email");
          const password = getValues("password");
          const rememberMe = getValues("rememberMe");
          if (rememberMe) {
            localStorage.setItem("email", email);
            localStorage.setItem("password", password);
            localStorage.setItem("rememberMe", rememberMe);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
            localStorage.removeItem("rememberMe");
          }
        }
        const { token, user, managers } = response.data;
        const {
          first_name,
          last_name,
          email,
          profile_image,
          // gender,
          birthday,
          mobile_number,
          country_code,
          title,
          id_user,
          role,
          remaining_days,
          is_managers_approval,
        } = user;
        dispatch(
          authSuccess({
            token,
            firstName: first_name,
            lastName: last_name,
            email,
            profilePic: profile_image,
            // gender,
            dob: birthday,
            mobile_number,
            countryCode: country_code,
            title,
            userId: id_user,
            managers: managers ?? [],
            role: role.name,
            remainingDays: remaining_days ?? null,
            isManagersApproval: is_managers_approval,
          })
        );
        if (role.name == "admin") {
          navigate("/admin/statistics");
        } else {
          navigate("/calendar");
        }
      } else {
        // toast.error(response.message);
        setError("invalid", {
          type: "invalidDetail",
          message: response.message,
        });
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    const { email, password } = data;
    if (!loading) {
      request("POST", "v1/auth/login", { email, password });
      // setIsClickSignInButton(true);
    }
  };
  return (
    <section className="fullcontent bgBlue">
      <div className="container">
        <div className="content">
          <div className="sign-up-content color-white">
            <a className="logo" href="/">
              <img src="/assets/img/LOGO.svg" alt="Turno" />
            </a>
            <h1 className="my-6">Sign In</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={
                  errors.email
                    ? "form-field form-field-white has-error"
                    : "form-field form-field-white"
                }
              >
                <div className="form-input">
                  <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    {...register("email", {
                      onChange: () => {
                        if (errors.invalid) {
                          clearErrors("invalid");
                        }
                      },
                      required: "This field is mandatory.",
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: "Please provide a valid email.",
                      },
                      setValueAs: (v) => v.trim(),
                    })}
                  />
                  <span className="inputborder" />
                </div>
                {errors.email && (
                  <p className="error-msg">{errors.email.message}</p>
                )}
              </div>
              <div
                className={
                  errors.password
                    ? "form-field form-field-white has-error"
                    : "form-field form-field-white"
                }
              >
                <div className="form-input">
                  <input
                    name="password"
                    type="password"
                    placeholder="Password"
                    autoComplete="off"
                    {...register("password", {
                      onChange: (...rest) => {
                        if (errors.invalid) {
                          clearErrors("invalid");
                        }
                      },
                      required: "This field is mandatory.",
                      pattern: {
                        value: /^.{6,}$/,
                        message: "Please provide a valid password.",
                      },
                      setValueAs: (v) => v.trim(),
                    })}
                  />
                  <span className="inputborder" />
                </div>
                {errors.password && (
                  <p className="error-msg">{errors.password.message}</p>
                )}
                {errors.invalid && (
                  <p className="error-msg has-error with-icon mt-2 error-msg-new">
                    {errors.invalid.message}
                  </p>
                )}
              </div>
              <div className="RememberRow mt-10 pt-4">
                <div className="checkbox-wrap checkbox-wrap-white">
                  <div className="form-group custom_checkbox d-flex ">
                    <input
                      type="checkbox"
                      id="check1"
                      defaultChecked=""
                      {...register("rememberMe")}
                    />
                    <label htmlFor="check1" className="click_reme">
                      Remember me
                    </label>
                  </div>
                </div>
                <Link
                  className="link font-base color-white"
                  to="/forgot-password"
                >
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="btn btn-white w-100 mt-6 submitButton"
              >
                {loading ? <Spinner /> : "Log In"}
              </button>
            </form>
            <p className="mt-6 line-height-1">
              Don't have an account?{" "}
              <Link className="link font-base color-white" to="/signup">
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
