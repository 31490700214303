import { useRef, useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../Component/Header";
import PhoneNumber from "../../../Component/PhoneNumber";
import Picker from "../../../Component/Picker";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { authSuccess } from "../../../store/auth/action";
import useRequest from "../../../hooks/useRequest";
import { API } from "../../../constant/api";
import { Spinner } from "../../../Component/Svg";
import { phoneValidation } from "../../../utils/fn";
import { toast } from "react-toastify";
import UpgradeBanner from "../../../Component/UpgradeBanner";
import ImageCroper from "./Croper/ImageCroper";
import "./Croper/ImageLoader.css";
// import Cropper from './Cropper';



const EditProfile = () => {
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    email,
    profilePic,
    // gender,
    dob,
    mobile_number,
    title,
    token,
    countryCode: country_code,
    role,
  } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [countryCode, setCountryCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const { request, response, loading } = useRequest();
  const [mobile, setMobile] = useState();
  const [dateOfBirth, setdateOfBirth] = useState();
  const [image, setImage] = useState();
  const [countryFormat, setCountryFormat] = useState("");
  const [file, setFile] = useState("");
  const [result, setResult] = useState(null);
  const [crop, setCrop] = useState(false);

  const dobRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    setError,
    clearErrors,
    getValues,
  } = useForm();

  const onChange = (e) => {
    setdateOfBirth(e.target.value);
    clearErrors("dob");
  };

  useEffect(() => {
    setValue("first_name", firstName);
    setValue("last_name", lastName);
    // setValue("email", email);
    // setValue("gender", gender);
    // setValue("dob", moment(dob).format("DD/MM/YYYY"));
    setValue("mobile_number", `${country_code}${mobile_number}`);
    setMobileNumber(mobile_number);
    setCountryCode(country_code);
    setValue("title", title);
    if (dob) {
      setdateOfBirth(moment(dob).format("DD MMMM YYYY"));
    }
    if (profilePic) {
      let image = `${API.IMG}/${profilePic}`;
      setImage(image);
    }
  }, []);
  

  useEffect(() => {
    if (response?.status == "SUCCESS") {
      const { data } = response;
      const {
        first_name,
        last_name,
        email,
        profile_image,
        // gender,
        birthday,
        mobile_number,
        title,
        id_user,
        country_code,
      } = data;
      dispatch(
        authSuccess({
          token,
          firstName: first_name,
          lastName: last_name,
          email,
          profilePic: profile_image,
          // gender,
          dob: birthday,
          mobile_number,
          countryCode: country_code,
          title,
          userId: id_user,
        })
      );
      navigate("/profile");
    }
  }, [response]);

  const profileEditSubmitHandler = (data) => {
    const { first_name, last_name, image, title } = data;

    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    // formData.append("gender", gender);
    if (title) {
      formData.append("title", title);
    }
    if (dateOfBirth) {
      formData.append("birthday", dateOfBirth);
    }
    if (image) {
      formData.append("profile_image", image);
    }
    if (mobileNumber) {
      formData.append("mobile_number", mobileNumber);
      formData.append("country_code", countryCode);
    }

    request("POST", "v1/auth/edit-profile", formData);
  };

  const imageHandler = (e) => {
    let type = ["image/png", "image/jpg", "image/jpeg"];
    let file = e.target.files[0];
    if (file && file?.type) {
      if (type.includes(file.type)) {
        // setImage(URL.createObjectURL(file));
        setFile(e.target.files[0]);
        handleCrop(true);
      } else {
        toast.error("Please select image only");
      }
    }
    // setImage(URL.createObjectURL(e.target.files[0]));
    // setValue("image", e.target.files[0]);
  };

  useEffect(() => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setResult(reader.result);
      handleCrop(true);
    };
    reader.readAsDataURL(file);
  }, [file]);

  const handleCrop = (value) => {
    setCrop(value)
  }

  return (
    <>
      <div className="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

        <div className="headerContant"></div>
        <div className="setting_header light_header">
          <Header heading="Edit Profile" link="/profile" />

          <div className="content-header-bottom">
            <div className="profile_img">
              {image ? <img src={image} /> : <i className="icon-user"></i>}
            </div>
            <div className="select_file">
              <label className="change_image" for="profile_image">
                Change Image{" "}
              </label>
              <input
                type="file"
                className="d-none"
                id="profile_image"
                onChange={(e) => imageHandler(e)}
              />
            </div>

            {crop ? (
              <ImageCroper
                image={result}
                handleCrop={handleCrop}
                setCropedImage={(displayImage, uploadImage) => {
                  setImage(displayImage);
                  handleCrop(false);
                  setValue("image",uploadImage);
                  // callback(newImage);
                }}
              />
            ) : (
              ""
            )}

          </div>
        </div>
        <div className="content-body">
          <div className="list-wrap">
            <form>
              <div className="profile-info">
                <div className="form-field">
                  <p className="form-label">First Name</p>
                  <div className="form-input">
                    <input
                      name="first_name"
                      className="form-input border-bottom"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter Your First Name"
                      {...register("first_name", {
                        required: "This field is mandatory.",
                        // setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.first_name && (
                    <p className="error-msg">{errors.first_name.message}</p>
                  )}
                </div>
                <div className="form-field">
                  <p className="form-label">Last Name</p>
                  <div className="form-input">
                    <input
                      className="form-input border-bottom"
                      name="last_name"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter Your Last Name"
                      {...register("last_name", {
                        required: "This field is mandatory.",
                        // setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.last_name && (
                    <p className="error-msg">{errors.last_name.message}</p>
                  )}
                </div>
                <div className="form-field">
                  <p className="form-label">Title</p>
                  <div className="form-input">
                    <input
                      className="form-input border-bottom"
                      name="title"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter Your title"
                      {...register("title", {
                        // required: "This field is mandatory.",
                        // setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.title && (
                    <p className="error-msg">{errors.title.message}</p>
                  )}
                </div>
                <div className="form-field">
                  <p className="form-label">Phone</p>
                  <div className="form-input phoneNumber_box">
                    <Controller
                      name="mobile_number"
                      control={control}
                      rules={{
                        required: false,
                        validate: () => {
                          if (phoneValidation[countryFormat] && mobileNumber) {
                            if (
                              phoneValidation[countryFormat].test(
                                `+${getValues("mobile_number")}`
                              )
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                        },
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <PhoneNumber
                            value={value}
                            setCountryCode={setCountryCode}
                            setMobileNumber={setMobileNumber}
                            // setValue={setMobile}
                            setCountryFormat={setCountryFormat}
                            onChange={onChange}
                          />
                        );
                      }}
                    />

                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.mobile_number &&
                    errors.mobile_number.type === "validate" && (
                      <p className="error-msg">Invalid phone number.</p>
                    )}
                </div>
                <div className="form-field">
                  <p className="form-label">Birthday</p>
                  <div className="form-input">
                    <Picker
                      format={"DD MMMM YYYY"}
                      timeRef={dobRef}
                      onChange={onChange}
                      // defaultDateForPicker={dateOfBirth}
                      value={dateOfBirth}
                      type="text"
                    />
                    {/* <input /> */}
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.dob && (
                    <p className="error-msg">{errors.dob.message}</p>
                  )}
                </div>
                {/* <div className="form-field mb-0 pb-3">
                  <p className="form-label">Gender</p>
                  <div className="gender_select_box">
                    <div className="radio-wrap mr-8">
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="Female"
                          {...register("gender", {
                            required: "Please select one of the choices..",
                          })}
                        />
                        <span className="icon"></span>
                        <span className="text">Female</span>
                      </label>
                    </div>
                    <div className="radio-wrap">
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="Male"
                          {...register("gender", {
                            required: "Please select one of the choices..",
                          })}
                        />
                        <span className="icon"></span>
                        <span className="text">Male</span>
                      </label>
                    </div>
                    {errors.gender && (
                      <p className="error-msg">{errors.gender.message}</p>
                    )}
                  </div>
                </div> */}
              </div>
              <div
                className="content-footer setting_footer"
                style={{ overflow: "auto" }}
              >
                <button
                  type="button"
                  className="btn-link formBtn color-text-50"
                  id="prevBack"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-white formBtn"
                  id="nextBtn"
                  onClick={handleSubmit(profileEditSubmitHandler)}
                >
                  {loading ? <Spinner /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
