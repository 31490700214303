import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SIDEBAR_TOGGLE,
  LOADING,
  ADD_MANAGER,
  UPDATE_REDUX,
  UPGRADEMEMERSHIP,
  ADDCARDINFO,
  ADDSUBSCRIPTIONID,
  ADDSTRIPEPLANID,
  ADDCURRENTSUBSCRIPTIONDATA,
  CHANGEMANAERAPPROVAL,
  CHANGECURRENTSUBSCRIPTIONDATA,
} from "./actionTypes";
import { toast } from "react-toastify";

export const authSuccess = (updates) => {
  if (updates.token) {
    localStorage.setItem("token", updates.token);
  }
  return {
    type: AUTH_SUCCESS,
    updates,
  };
};

export const sidebarToggle = (updates) => {
  return {
    type: SIDEBAR_TOGGLE,
    updates,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  // toast.success("You are now logged out!");
  return {
    type: AUTH_LOGOUT,
  };
};

export const updateLoading = (updates) => {
  return {
    type: LOADING,
    updates,
  };
};

export const addManager = (updates) => {
  return {
    type: ADD_MANAGER,
    updates,
  };
};

export const updateRedux = (updates) => {
  return {
    type: UPDATE_REDUX,
    updates,
  };
};

export const upgradeMembership = (updates) => {
  return {
    type: UPGRADEMEMERSHIP,
    updates,
  };
};

export const addCardInfo = (updates) => {
  return {
    type: ADDCARDINFO,
    updates,
  };
};

export const addSubscriptionId = (updates) => {
  return {
    type: ADDSUBSCRIPTIONID,
    updates,
  };
};

export const addStripePlanId = (updates) => {
  return {
    type: ADDSTRIPEPLANID,
    updates,
  };
};

export const addCurrentSubscriptionData = (updates) => {
  return {
    type: ADDCURRENTSUBSCRIPTIONDATA,
    updates,
  };
};
export const changeCurrentSubscriptionData = (updates) => {
  return {
    type: CHANGECURRENTSUBSCRIPTIONDATA,
    updates,
  };
};
export const changeManagerApproval = (updates) => {
  return {
    type: CHANGEMANAERAPPROVAL,
    updates,
  };
};
