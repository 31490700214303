import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../Component/Header";
import Sidebar from "../../../Component/Sidebar";
import { Spinner } from "../../../Component/Svg";
import UpgradeBanner from "../../../Component/UpgradeBanner";
import useRequest from "../../../hooks/useRequest";
import { addCardInfo } from "../../../store/auth/action";

let monthList = [
  null,
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ManagePayment = () => {
  const { cardInfo: reduxCardInfo, role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [cardInfo, setCardInfo] = useState({});
  const [isCardDeleteModalOpen, setIsCardDeleteModalOpen] = useState(false);

  const { request, response } = useRequest();
  const { request: cardInformationRequest, response: cardInformationRespose } =
    useRequest();
  const {
    request: deleteCardRequest,
    response: deleteCardRespose,
    loading: deleteCardLoading,
  } = useRequest();

  useEffect(() => {
    if (Object.keys(reduxCardInfo).length <= 0) {
      request("GET", "v1/stripe/card");
    } else {
      setCardInfo(reduxCardInfo);
    }
  }, []);

  useEffect(() => {
    if (response) {
      const { stripe_card } = response.data;
      if (stripe_card && Object.keys(stripe_card).length > 0) {
        cardInformationRequest(
          "GET",
          `v1/stripe/card-information/${stripe_card.card_id}`
        );
      }
    }
  }, [response]);

  useEffect(() => {
    if (cardInformationRespose) {
      const { results } = cardInformationRespose.data;
      setCardInfo(results);
      dispatch(addCardInfo(results));
    }
  }, [cardInformationRespose]);

  useEffect(() => {
    if (deleteCardRespose) {
      setIsCardDeleteModalOpen(false);
      if (deleteCardRespose.status == "SUCCESS") {
        dispatch(addCardInfo({}));
        setCardInfo({});
        toast.success(deleteCardRespose.message);
      } else {
        toast.error(deleteCardRespose.message);
      }
    }
  }, [deleteCardRespose]);

  const deleteCardHandler = () => {
    deleteCardRequest(
      "DELETE",
      `v1/stripe/card-delete/${cardInfo.id_stripecards}`
    );
  };

  return (
    <>
      <div class="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

        <div className="headerContant"></div>
        <div class="setting_header">
          <Header heading="Payment" color={"#fff"} link="/settings"></Header>
          {/* <Sidebar from="settings" heading="Payment" /> */}
          <div className="content-header-bottom">
            <i className="icon-credit-card settings_icon color-white-30"></i>
          </div>
        </div>

        {cardInfo && Object.keys(cardInfo).length > 0 ? (
          <div className="content-body">
            <div className="profile-info">
              <div className="credit-card-header ">
                <p className="credit-card-name mb-0">
                  {cardInfo && cardInfo.card_type && cardInfo.card_type}
                </p>

                {cardInfo.card_type == "unknown" ? (
                  <i className="icon-credit-card" />
                ) : (
                  <img
                    src={`/assets/img/${cardInfo.card_type}.svg`}
                    alt={cardInfo && cardInfo.card_type && cardInfo.card_type}
                  />
                )}
                {/* {img && img !== "other" && (
                  <img
                    src={require("assets/img/" + img + ".svg")}
                    alt={cardInfo && cardInfo.card_type && cardInfo.card_type}
                  />
                )}
                {img && img === "other" && <i className="icon-credit-card" />} */}
              </div>

              <div className="credit-card-detail">
                <p className="main-text">Card Number</p>
                <p className="main-text-info">
                  **** ****{" "}
                  <span>
                    {cardInfo &&
                      cardInfo.last_four_numbers &&
                      cardInfo.last_four_numbers}
                  </span>
                </p>
              </div>

              <div className="credit-card-detail">
                <p className="main-text">Expiration Date</p>
                <p className="main-text-info">
                  {monthList[cardInfo.exp_date.split("-")[1]]},{" "}
                  {cardInfo.exp_date.split("-")[0]}
                </p>
              </div>

              <button type="button" className="btn p-0 main-delete-btn">
                <i
                  className="icon-delete icon-shadow color-warning font-"
                  onClick={() => setIsCardDeleteModalOpen(true)}
                />
                <span
                  className="delete-btn"
                  onClick={() => setIsCardDeleteModalOpen(true)}
                >
                  Delete Card
                </span>
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="information_text">
              <p class="font-md fw-500 text-center">
                You don't have a <br /> payment information yet. <br /> Add now
                !{" "}
              </p>
            </div>

            <Link
              to="/add-payment-method"
              className="btn-rounded btn-add"
              variant="contained"
            >
              {/* <a href="add-shifts.html" className="btn-rounded btn-add"> */}
              <i className="fas fa-plus" />
              {/* </a> */}
            </Link>
          </>
        )}
      </div>

      <Modal
        show={isCardDeleteModalOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal thememodal2"
        onHide={() => setIsCardDeleteModalOpen(false)}
      >
        <button
          type="button"
          className="btn-close"
          // data-bs-dismiss="modal"
          // aria-label="Close"

          onClick={() => setIsCardDeleteModalOpen(false)}
        />
        <div
        // className="modal-content"
        >
          <div className="modal-body sure_modal_body">
            <div className="text-center pt-8 px-6">
              <p className="font-lg fw-700 mb-4">Are you sure?</p>
              <p className="font-md">This will remove Your card permanently.</p>
            </div>
          </div>
          <div className="modal-footer shift_time_footer">
            <button
              type="button"
              className="btn button_no"
              onClick={() => setIsCardDeleteModalOpen(false)}
            >
              No
            </button>
            <button
              type="button"
              className="btn button_yes"
              onClick={deleteCardHandler}
            >
              {deleteCardLoading ? <Spinner /> : "Yes"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManagePayment;
