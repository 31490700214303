import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import TermsAndCondition from "./pages/TermsAndCondition";
import SignupVerification from "./pages/SignupVerification";
import SignupSuccessfully from "./pages/SignupSuccessfully";
import ForgotPassword from "./pages/ForgotPassword";
import Calendar from "./pages/Calendar";
import AddShift from "./pages/Shifts/AddShift";
import Events from "./pages/Shifts/Events";
import DayDetails from "./pages/Shifts/DayDetails";
import EditShift from "./pages/Shifts/EditShift";
import CalendarComponent from "./pages/NewCalender";
import AddEvent from "./pages/Events/AddEvent";
import EditEvent from "./pages/Events/EditEvent";
import DayPersonalEvents from "./pages/Events/DayPersonalEvents";
import AddNote from "./pages/Notes/AddNote";
import EditNote from "./pages/Notes/EditNote";
import DayNote from "./pages/Notes/DayNote";

import Profile from "./pages/Setting/Profile";
import EditProfile from "./pages/Setting/Profile/EditProfile";
import ChangePassword from "./pages/Setting/Profile/ChangePassword";

import ViewAllManager from "./pages/Setting/Manager/ViewAll";
import ViewOneManager from "./pages/Setting/Manager/ViewOne";
import EditManager from "./pages/Setting/Manager/Edit";
import AddManager from "./pages/Setting/Manager/Add";

import AddWork from "./pages/Setting/Work/Add";
import EditWork from "./pages/Setting/Work/Edit";
import ViewOneWork from "./pages/Setting/Work/ViewOne";
import ViewAllWork from "./pages/Setting/Work/ViewAll";
import Setting from "./pages/Setting";
import RequestInvite from "./pages/Team/RequestInvite";
import Connections from "./pages/Team/Connections";
import About from "./pages/Setting/About";
import TeamCalendar from "./pages/Team/Team";
import SwichShift from "./pages/Team/SwitchShift";
import ConnectionCalendar from "./pages/Team/ConnectionCalendar";
import PersonalCalendar from "./Component/PersonalCalendar";
import NotificationsList from "./pages/Notifications/NotificationsList";
import Giveaway from "./pages/Team/Giveaway";
import Feedback from "./pages/Feedback/Feedback";

import AdminStatistics from "./pages/Admin/Statistics";
import AdminSetting from "./pages/Admin/AdminSetting";
import AdminUsers from "./pages/Admin/Users";
import AdminFeedback from "./pages/Admin/AdminFeedback";
import AdminProfile from "./pages/Admin/AdminProfile";
import UpgradeMembership from "./pages/UpgradeMembership/UpgradeMembership";
import ManagePayment from "./pages/Setting/ManagePayment/ManagePayment";
import AddPaymentMethod from "./pages/Setting/ManagePayment/AddPaymentMethod";
import Payment from "./pages/UpgradeMembership/Payment";
import InvitedUser from "./pages/Setting/InvitedUser";

export const privateRoutes = [
  { path: "/calendar", element: <Calendar /> },
  { path: "/ncalendar", element: <CalendarComponent /> },

  { path: "/add-shift", element: <AddShift /> },
  { path: "/edit-shift/:id", element: <EditShift /> },

  { path: "/add-event", element: <AddEvent /> },
  { path: "/edit-event/:id", element: <EditEvent /> },
  { path: "/day-personal-events/:id", element: <DayPersonalEvents /> },

  { path: "/add-note", element: <AddNote /> },
  { path: "/edit-note/:id", element: <EditNote /> },
  { path: "/day-note/:id", element: <DayNote /> },

  { path: "/events", element: <Events /> },
  { path: "/calendar/day-details/:id", element: <DayDetails /> },
  { path: "/terms-and-conditions", element: <TermsAndCondition /> },

  { path: "/settings", element: <Setting /> },

  { path: "/profile", element: <Profile /> },
  { path: "/edit-profile", element: <EditProfile /> },
  { path: "/change-password", element: <ChangePassword /> },

  { path: "/add-manager", element: <AddManager /> },
  { path: "/manager-list", element: <ViewAllManager /> },
  { path: "/manager/:id", element: <ViewOneManager /> },
  { path: "/edit-manager/:id", element: <EditManager /> },

  { path: "/add-work", element: <AddWork /> },
  { path: "/work-list", element: <ViewAllWork /> },
  { path: "/work/:id", element: <ViewOneWork /> },
  { path: "/edit-work/:id", element: <EditWork /> },

  { path: "/team", element: <TeamCalendar /> },
  { path: "/connections", element: <Connections /> },
  { path: "/invited-user", element: <InvitedUser /> },
  { path: "/request-invite", element: <RequestInvite /> },

  { path: "/about", element: <About /> },
  { path: "/personal-calendar", element: <PersonalCalendar /> },
  { path: "/notifications-list", element: <NotificationsList /> },

  { path: "/feedback", element: <Feedback /> },

  { path: "/upgrade-membership", element: <UpgradeMembership /> },
  { path: "/payment/:id", element: <Payment /> },

  { path: "/manage-payment", element: <ManagePayment /> },
  { path: "/add-payment-method", element: <AddPaymentMethod /> },
];

export const memberUserRoute = [
  ...privateRoutes,
  { path: "/switch-shift", element: <SwichShift /> },
  { path: "/connection-calendar/:id", element: <ConnectionCalendar /> },
  { path: "/request-giveaway", element: <Giveaway /> },
];

export const adminRoutes = [
  { path: "/admin/statistics", element: <AdminStatistics /> },
  { path: "/admin/users", element: <AdminUsers /> },
  { path: "/admin/feedback", element: <AdminFeedback /> },
  { path: "/admin/settings", element: <AdminSetting /> },
  { path: "/admin/profile", element: <AdminProfile /> },
  { path: "/change-password", element: <ChangePassword /> },
];
export const notPrivateRoutes = [
  { path: "/signup", element: <Signup /> },
  { path: "/", element: <Login /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/terms-and-conditions", element: <TermsAndCondition /> },
  { path: "/signup-successfully", element: <SignupSuccessfully /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/signup-verification/:token", element: <SignupVerification /> },
];
