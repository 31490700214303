import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../Component/Header";
import UpgradeBanner from "../../../Component/UpgradeBanner";
import useRequest from "../../../hooks/useRequest";
import { addAllWorkplaces } from "../../../store/calendarData/action";

const ViewAll = () => {
  const navigte = useNavigate();
  const { request, response } = useRequest();
  const [workplaces, setWorkPlaces] = useState([]);
  const dispatch = useDispatch();
  const { allWorkplaces } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);

  useEffect(() => {
    if (allWorkplaces.length > 0) {
      setWorkPlaces(allWorkplaces);
    } else {
      request("GET", "v1/workplace/workplaces");
    }
  }, []);

  useEffect(() => {
    if (response) {
      if ((response.status = "SUCCESS")) {
        const { workplaces } = response.data;
        setWorkPlaces(workplaces);
        dispatch(addAllWorkplaces(workplaces));
      }
    }
  }, [response]);

  return (
    <div className="content-center  manager_list_page">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="headerContant"></div>
      <div className="light_header">
        <Header heading="Work" link="/settings"></Header>
        {/* <Sidebar from="events" /> */}
      </div>
      <div className="content-body">
        <div className="list-wrap ">
          <ul className="manager_list blue_scroll_bar">
            {workplaces.length > 0 ? (
              workplaces.map((workplace) => (
                <li className="manager_list_item" key={workplace.id_workplace}>
                  <Link
                    to={`/work/${workplace.id_workplace}`}
                    className="manager_profile"
                  >
                    <span className="manager_img">
                      <i className="icon-building"></i>
                    </span>
                    <span className="manager_name">
                      {workplace.hospital_name}
                    </span>
                  </Link>
                </li>
              ))
            ) : (
              <div className="content-body no_data_show placeholder-content">
                <img src="/assets/img/building_img.svg" />
                <p class="font-md">You don't have a workplace yet.</p>
              </div>
            )}
            {/* <li className="manager_list_item">
              <a href="#" className="manager_profile">
                <span className="manager_img">
                  <img src="/assets/img/evening.png" alt="" />
                </span>
                <span className="manager_name">test manager</span>
              </a>
            </li> */}
          </ul>
        </div>
        <button
          // to="/add-work"
          // type="button"
          className="btn-rounded btn-add"
          onClick={() => navigte("/add-work")}
        >
          <i className="fas fa-plus" />
        </button>
      </div>
    </div>
  );
};

export default ViewAll;
