import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment";
import "pickerjs/dist/picker.min.css";
import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useForm } from "react-hook-form";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";

import useRequest from "../../hooks/useRequest";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../Component/Svg";
import TeamCalendar from "./Team";
import PersonalCalendar from "../../Component/PersonalCalendar";
import UpgradeBanner from "../../Component/UpgradeBanner";

const Giveaway = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { managers, role, isManagersApproval } = useSelector(
    (state) => state.auth
  );

  const [currentTab, setCurrentTab] = useState(0);
  const [isManagerApprove, setIsManagerApprove] = useState(isManagersApproval);
  const [switchableUsers, setSwitchableUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const [isShiftedError, setIsShiftedError] = useState(false);

  const [selectedDateForLocation, setSelectedDateForLocation] = useState(
    location.state?.date
      ? moment(location.state?.date, "YYYY-MM-DD").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
  );

  const [selectDateForTabOne, setSelectDateForTabOne] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
    getValues,
  } = useForm();

  const { request, response, loading } = useRequest();
  const {
    request: switchableUsersRequest,
    response: switchableUsersResponse,
    loading: switchableUsersLoading,
  } = useRequest();

  useEffect(() => {
    // if (allWorkplaces.length > 0) {
    //   console.log(allWorkplaces);
    //   setWorkplaces(allWorkplaces);
    // } else {
    //   getWorkplacesRequest("GET", "v1/workplace/workplaces");
    // }
    register("id_manager", {
      required:
        currentTab == 0 && isManagerApprove && "This field is mandatory.",
    });
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        navigationHandler();
        // navigate("/calendar");
      }
    }
  }, [response]);

  useEffect(() => {
    if (switchableUsersResponse) {
      if (switchableUsersResponse.status == "SUCCESS") {
        const { connection_users } = switchableUsersResponse.data;
        if (connection_users.length > 0) {
          setCurrentTab((prev) => prev + 1);
          setSwitchableUsers(connection_users);
          clearErrors("fromDate");
        } else {
          setError("fromDate", {
            type: "noConnection",
            message: "No connection are available for this date.",
          });
        }
      } else {
      }
    }
  }, [switchableUsersResponse]);

  const navigationHandler = () => {
    if (location.state?.from) {
      return navigate(location.state?.from, {
        state: {
          date: selectedDateForLocation
            ? selectedDateForLocation
            : location.state?.date,
        },
      });
    } else {
      // return navigate("/team");
      return navigate("/team", { state: { date: location.state?.date } });
    }
  };

  const nextHandler = () => {
    if (currentTab === 0) {
      //comment added by naveen
      // if (isShiftedError) {
      //   setError("fromDate", {
      //     type: "manual1",
      //     message: "Give away shift can't be switch",
      //   });
      //   return;
      // }

      clearErrors("fromDate");
      if (selectDateForTabOne) {
        let body = {
          from_date: selectDateForTabOne,
          manager_approval: false,
          type: "giveaway",
        };
        if (isManagerApprove) {
          body.manager_approval = true;
          body.id_manager = getValues("id_manager");
        }
        switchableUsersRequest(
          "POST",
          "v1/switch-shift/switchable-users",
          body
        );
        // setCurrentTab((prev) => prev + 1);
      } else {
        setError("fromDate", {
          type: "manual",
          message: "Please choose a shift first",
        });
        return;
      }
    }
  };

  // function nextPrev(n) {
  //   if (n === "next") {
  //     setCurrentTab((prev) => prev + 1);
  //   } else if (n === "prev") {
  //     setCurrentTab((prev) => prev - 1);
  //   }
  // }

  const giveawayRequestSubmitHandler = (data) => {
    if (selectedUserIds.length < 1) {
      setError("noUser", {
        type: "noUser",
        message: "Please select at least one team member.",
      });
      return;
    } else {
      clearErrors("noUser");
    }
    let body = {
      from_date: selectDateForTabOne,
      users: selectedUserIds,
    };
    if (data.id_manager) {
      body.id_manager = data.id_manager;
      body.manager_approval = true;
    } else {
      body.manager_approval = false;
    }

    request("POST", "v1/switch-shift/switch-request", body);
  };

  const managerApproveHandler = (e) => {
    let value = e.target.checked;
    if (value) {
      setIsManagerApprove(true);
      register("id_manager", {
        required: currentTab == 0 && "This field is mandatory.",
      });
    } else {
      clearErrors("id_manager");
      register("id_manager", {
        required: false,
      });
      setValue("id_manager", null);
      setIsManagerApprove(false);
    }
  };

  const managerApproveOnchange = (e) => {
    let value = e.target.value;

    setValue("id_manager", value);
    clearErrors("id_manager");
  };

  const connectionSelectHandler = (id) => {
    if (selectedUserIds.includes(id)) {
      let newUserId = selectedUserIds.filter((u) => u != id);
      setSelectedUserIds(newUserId);
    } else {
      clearErrors("noUser");
      setSelectedUserIds((prev) => [...prev, id]);
    }
  };
  return (
    <div className="content-center lightBg outer_box_shifting">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="headerContant"></div>
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5 cursor"
          onClick={() => {
            if (currentTab == 0) {
              navigationHandler();
            } else {
              setCurrentTab((prev) => prev - 1);
              if (currentTab == 1) {
                clearErrors("toDate");
              }
            }
          }}
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
        </span>
        <h1>Request Giveaway</h1>
      </div>
      {/* Section */}
      <div className="page-section">
        <div className="container">
          <div className="innerPageBox">
            <div className="MuiPaper-root MuiPaper-elevation0 MuiStepper-root MuiStepper-horizontal stepper-header">
              <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 0 || currentTab === 1 ? "active" : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span className="MuiStepLabel-labelContainer">
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label MuiStepLabel-active">
                      Giveaway Date
                    </span>
                  </span>
                </span>
              </span>
              <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 1 ? "active" : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal MuiStepLabel-disabled step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span className="MuiStepLabel-labelContainer">
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label">
                      Availablility
                    </span>
                  </span>
                </span>
              </span>
              {/* <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 1 ? "active" : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal MuiStepLabel-disabled step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span className="MuiStepLabel-labelContainer">
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label">
                      Availablility
                    </span>
                  </span>
                </span>
              </span> */}
            </div>

            <div className="stepper-Outbody h-10000">
              <form
                className="main_switch-shift"
                id="regForm"
                //  onSubmit={handleSubmit(addShiftHandler)}
              >
                <div className="stepper-body">
                  <div className="stepper-content">
                    {currentTab === 0 ? (
                      <>
                        <PersonalCalendar
                          // selectDateForTabOne={selectDateForTabOne}
                          selectDateForTabOne={selectedDateForLocation}
                          setSelectDateForTabOne={setSelectDateForTabOne}
                          clearErrors={clearErrors}
                          errors={errors}
                          setSelectedDateForLocation={
                            setSelectedDateForLocation
                          }
                          setIsShiftedError={setIsShiftedError}
                          setError={setError}
                          from={"giveaway"}
                        />
                        {/* {errors.fromDate && (
                          <p className="error-msg">{errors.fromDate.message}</p>
                        )} */}
                        {isManagersApproval && (
                          <div className="manager_approval_box">
                            <div className="approval_block">
                              <div className="approval_text">
                                Manager Approval
                              </div>
                              <div class="toggle-box">
                                <div class="switch">
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={isManagerApprove}
                                      onClick={managerApproveHandler}
                                    />
                                    <span class="toggle-slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="choose_manager">
                              {/* <div class="form-input">
                                <select class="form-select form-input border-bottom" >
                                  <option selected>Open this select menu</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                                <span class="border-bottom-animation center"></span>
                            </div> */}
                              {isManagerApprove && (
                                <FormControl
                                  className="select_manager"
                                  variant="standard"
                                  sx={{ m: 1, minWidth: 120 }}
                                >
                                  <InputLabel id="select-label">
                                    Choese a manager
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    placeholder="Choose a Manager"
                                    // value={age}
                                    label="Choose a Manager"
                                    onChange={managerApproveOnchange}
                                    // defaultValue={getValues("id_workplace")}
                                  >
                                    {managers.map((manager) => (
                                      <MenuItem
                                        key={manager.id_manager}
                                        value={manager.id_manager}
                                      >
                                        {manager.first_name} {manager.last_name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                              <div className="invitee_will ">
                                The invitee will have the same manager as yours.
                              </div>
                              {errors.id_manager && (
                                <p className="error-msg">
                                  {errors.id_manager.message}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="list-wrap step_2 p-3 m-0 giveaway_box">
                          <ul class="manager_list blue_scroll_bar">
                            {switchableUsers.length > 0
                              ? switchableUsers.map((user) => (
                                  <li
                                    class={`manager_list_item ${
                                      selectedUserIds.includes(user.id_user) &&
                                      "checkActive"
                                    }`}
                                    onClick={() =>
                                      connectionSelectHandler(user.id_user)
                                    }
                                  >
                                    {false && (
                                      <a class="manager_profile check_main_box">
                                        <span class="card-label bg-primary  giveaway_space"></span>
                                        <div
                                          className="switch_to"
                                          for="cheked_1"
                                        >
                                          <div class="connections_info">
                                            <span class="manager_name">
                                              <span>
                                                {user.first_name}{" "}
                                                {user.last_name}{" "}
                                              </span>
                                            </span>
                                            <span className="manager_time_label">
                                              {" "}
                                              {moment(user.start_time).format(
                                                "hh:mm A"
                                              )}{" "}
                                              -{" "}
                                              {moment(user.end_time).format(
                                                "hh:mm A"
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </a>
                                    )}

                                    <a class="notiListCard ">
                                      <span class="notiCard-label giveaway_space"></span>
                                      <div class="notiCard-body">
                                        <p class="font-md notiTitile mb-1">
                                          {user.first_name} {user.last_name}{" "}
                                          {user.is_requested && (
                                             <span className="acceptance_text">Pending Acceptance</span>
                                          )}
                                         
                                        </p>
                                      
                                        <p class="color-text-70 font-base NotiStatus">
                                          <span class="ellipsis limit-word">
                                            Giveaway
                                          </span>
                                        </p>
                                      </div>
                                    </a>
                                  </li>
                                ))
                              : "No User "}
                          </ul>
                          {errors?.noUser && (
                            <p className="error-msg">{errors.noUser.message}</p>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="content-footer not_fixed"
                    style={{ overflow: "auto" }}
                  >
                    {currentTab == 0 && (
                      <button
                        type="button"
                        className="btn-link formBtn color-text-50"
                        id="prevBack"
                        onClick={navigationHandler}
                      >
                        Cancel
                      </button>
                    )}
                    {currentTab == 1 && (
                      <button
                        type="button"
                        className="btn-link formBtn color-text-50"
                        id="prevBtn"
                        onClick={() => {
                          setCurrentTab((prev) => prev - 1);
                        }}
                      >
                        Back
                      </button>
                    )}
                    {currentTab == 0 && (
                      <button
                        type="submit"
                        className="btn-white formBtn"
                        id="nextBtn"
                        // onClick={() => nextPrev("next")}
                        onClick={handleSubmit(nextHandler)}
                      >
                        {switchableUsersLoading ? <Spinner /> : "Next"}
                      </button>
                    )}
                    {currentTab == 1 && (
                      <button
                        type="button"
                        className="btn-white formBtn"
                        id="nextBtn"
                        onClick={handleSubmit(giveawayRequestSubmitHandler)}
                      >
                        {loading ? <Spinner /> : "Request Pickup"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Giveaway;
