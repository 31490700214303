import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Elements, injectStripe, StripeProvider } from "react-stripe-elements";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import StripeForm from "../../Component/StripeForm";
import useRequest from "../../hooks/useRequest";
import {
  addCardInfo,
  addCurrentSubscriptionData,
  upgradeMembership,
} from "../../store/auth/action";

const SplitForm = injectStripe(StripeForm);

const Payment = () => {
  const {
    currentSubscriptionData: {
      stripePlanId,
      subscription_id: subscriptionId,
      is_deleted: isCurrentPlanDeleted,
    },
    role,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [plan, setPlan] = useState({});
  // const[]

  const { request: getPlanByIdRequest, response: getPlanByIdResponse } =
    useRequest();
  const {
    request: addCardRequest,
    response: addCardResponse,
    loading: addCardLoading,
  } = useRequest();
  const {
    request: addSubscriptionRequest,
    response: addSubscriptionResponse,
    loading: addSubscriptionLoading,
  } = useRequest();

  const {
    request: upgradeSubscriptionRequest,
    response: upgradeSubscriptionResponse,
    loading: upgradeSubscriptionLoading,
  } = useRequest();

  useEffect(() => {
    getPlanByIdRequest("GET", `v1/stripe/plan/${id}`);
  }, []);

  useEffect(() => {
    if (getPlanByIdResponse) {
      const { stripe_plans } = getPlanByIdResponse.data;
      setPlan(stripe_plans);
    }
  }, [getPlanByIdResponse]);

  useEffect(() => {
    if (addCardResponse) {
      if (addCardResponse.status == "SUCCESS") {
        const { results } = addCardResponse.data;
        dispatch(addCardInfo(results));
        // if (role == "member_user") {
        //   upgradeSubscriptionRequest("POST", "v1/stripe/update-subscription", {
        //     id_stripeplans: plan.id_stripeplans,
        //     subscription_id: subscriptionId,
        //   });
        // } else {
        // addSubscriptionRequest("POST", "v1/stripe/add-subscription", {
        //   id_stripeplans: plan.id_stripeplans,
        //   subscription_id: subscriptionId,
        // });
        // }
        let body = {
          id_stripeplans: plan.id_stripeplans,
        };
        if (!isCurrentPlanDeleted) {
          body.subscription_id = subscriptionId;
        }
        addSubscriptionRequest("POST", "v1/stripe/add-subscription", body);
      } else {
        toast.error(addCardResponse.message);
      }
    }
  }, [addCardResponse]);

  useEffect(() => {
    if (addSubscriptionResponse) {
      if (addSubscriptionResponse.status == "SUCCESS") {
        toast.success(addSubscriptionResponse.message);
        dispatch(upgradeMembership("member_user"));
        const { subscription_id, id_stripeplans } =
          addSubscriptionResponse.data;

        // if (subscription_id && id_stripeplans) {
        //   dispatch(
        //     addCurrentSubscriptionData({
        //       subscriptionId: subscription_id,
        //       stripePlanId: id_stripeplans,
        //     })
        //   );
        // }
        dispatch(addCurrentSubscriptionData(addSubscriptionResponse.data));

        navigate("/calendar");
      } else {
        toast.error(addSubscriptionResponse.message);
      }
    }
  }, [addSubscriptionResponse]);

  useEffect(() => {
    if (upgradeSubscriptionResponse) {
      if (upgradeSubscriptionResponse.status == "SUCCESS") {
        toast.success(upgradeSubscriptionResponse.message);
        const { subscription_id, id_stripeplans } =
          upgradeSubscriptionResponse.data;

        if (subscription_id && id_stripeplans) {
          dispatch(
            addCurrentSubscriptionData({
              subscriptionId: subscription_id,
              stripePlanId: id_stripeplans,
            })
          );
        }
        navigate("/calendar");
      } else {
        toast.error(upgradeSubscriptionResponse.message);
      }
    }
  }, [upgradeSubscriptionResponse]);

  const handleChange = (data) => {
    let cardData = undefined;
    if (data.error && !data.source) {
      cardData = undefined;
    }
    if (data.source && !data.error) {
      cardData = {
        // Poxel live-i jamanak
        card_id: data.source.id,
        // card_id: "tok_visa"
      };
    }

    if (typeof cardData === "object") {
      addCardRequest("POST", "v1/stripe/add-card", cardData);
      // const res = await this.props.addCard(cardData);
      // if (res && res.status === 201 && res.data.id) {
      //   this.props.history.push("/manage-payment");
      // }
    }
  };
  return (
    <section className="content-center lightBg">
      <div className="container">
        <div className="content box-shadow justify-start">
          <div className="content-header">
            <Link
              to={"/upgrade-membership"}
              className="btn-icon w-30 color-text mr-5"
            >
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>
            <h1>Payment</h1>
          </div>
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
            <Elements>
              <SplitForm
                handleChange={handleChange}
                from={"upgrade-membership"}
                plan={plan}
                error={error}
                addSubscriptionLoading={addSubscriptionLoading}
                addCardLoading={addCardLoading}
                // type={productsId && productsId[this.props.match.params.id].name}
              />
            </Elements>
          </StripeProvider>
        </div>
      </div>
    </section>
  );
};

export default Payment;
