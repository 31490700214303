import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "../../Component/Sidebar";
import UpgradeBanner from "../../Component/UpgradeBanner";

const Setting = () => {
  const { role } = useSelector((state) => state.auth);

  return (
    <>
      <div class="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

        <div className="headerContant"></div>
        <div class="setting_header">
          <Sidebar from="settings" heading="Settings" />
          <div class="content-header-bottom">
            <i class="icon-settings settings_icon"></i>
          </div>
        </div>
        <div class="content-body">
          <div class="list-wrap">
            <ul class="settings-list">
              <li>
                <Link to="/profile" class="setting_item">
                  <span class="setting_item_text">Profile</span>
                  <span class="setting_item_icon">
                    <i class="icon-next-arrow icon-shadow color-black"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/manager-list" class="setting_item">
                  <span class="setting_item_text">Manager</span>
                  <span class="setting_item_icon">
                    <i class="icon-next-arrow icon-shadow color-black"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link class="setting_item" to="/connections">
                  <span class="setting_item_text">Connections</span>
                  <span class="setting_item_icon">
                    <i class="icon-next-arrow icon-shadow color-black"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/work-list" class="setting_item">
                  <span class="setting_item_text">Work</span>
                  <span class="setting_item_icon">
                    <i class="icon-next-arrow icon-shadow color-black"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/invited-user" class="setting_item">
                  <span class="setting_item_text">Invited Users</span>
                  <span class="setting_item_icon">
                    <i class="icon-next-arrow icon-shadow color-black"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/manage-payment" class="setting_item">
                  <span class="setting_item_text">Payment</span>
                  <span class="setting_item_icon">
                    <i class="icon-next-arrow icon-shadow color-black"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/about" class="setting_item">
                  <span class="setting_item_text">About</span>
                  <span class="setting_item_icon">
                    <i class="icon-next-arrow icon-shadow color-black"></i>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
