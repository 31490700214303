import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../Component/Header";
import UpgradeBanner from "../../../Component/UpgradeBanner";
import { API } from "../../../constant/api";
import useRequest from "../../../hooks/useRequest";
import { changeManagerApproval } from "../../../store/auth/action";

const ViewAll = () => {
  const dispatch = useDispatch();
  const [managers, setManagers] = useState([]);
  const { request, response } = useRequest();
  const {
    request: changeManagerApprovalRequest,
    response: changeManagerApprovalResponse,
  } = useRequest();
  const {
    managers: allManagers,
    role,
    isManagersApproval,
  } = useSelector((state) => state.auth);
  const navigte = useNavigate();

  useEffect(() => {
    if (allManagers.length > 0) {
      setManagers(allManagers);
    } else {
      request("GET", "v1/manager/managers");
    }
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { managers } = response.data;
        setManagers(managers);
      }
    }
  }, [response]);

  const managerApproveHandler = (e) => {
    let value = e.target.checked;
    dispatch(changeManagerApproval(value));
    changeManagerApprovalRequest("POST", "v1/auth/update-managers-approval", {
      is_managers_approval: value,
    });
  };

  return (
    <>
      <div className="content-center  manager_list_page">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

        <div className="headerContant"></div>
        <div className="light_header">
          <Header heading="Managers" link="/settings">
            {" "}
            <div className="approval_block header_approval_switch">
              <div className="approval_text">Manager Approval</div>
              <div class="toggle-box">
                <div class="switch">
                  <label>
                    <input
                      type="checkbox"
                      checked={isManagersApproval}
                      onClick={managerApproveHandler}
                    />
                    <span class="toggle-slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </Header>
          {/* <Sidebar from="events" /> */}
        </div>
        <div className="content-body">
          <div className="list-wrap ">
            <ul className="manager_list blue_scroll_bar">
              {managers.length > 0 &&
                managers.map((m) => (
                  <li className="manager_list_item" key={m.id_manager}>
                    <Link
                      to={`/manager/${m.id_manager}`}
                      className="manager_profile"
                    >
                      <span className="manager_img">
                        {m.picture ? (
                          <img src={`${API.IMG}/${m.picture}`} />
                        ) : (
                          <i className="icon-user"></i>
                        )}
                      </span>
                      <span className="manager_name">
                        {`${m.first_name} ${m.last_name}`}
                      </span>
                    </Link>
                  </li>
                ))}
              {/* <li className="manager_list_item">
                <a href="#" className="manager_profile">
                  <span className="manager_img">
                    <img src="/assets/img/evening.png" alt="" />
                  </span>
                  <span className="manager_name">test manager</span>
                </a>
              </li> */}
            </ul>
          </div>
          <button
            // to="/add-manager"
            // type="button"
            className="btn-rounded btn-add"
            onClick={() => navigte("/add-manager")}
          >
            <i className="fas fa-plus" />
          </button>
        </div>
      </div>
    </>
  );
};

export default ViewAll;
