import { useEffect, useState } from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import useRequest from "../../hooks/useRequest";
import Sidebar from "../../Component/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDiffrenceHours } from "../../utils/fn";
import { Clock, DeleteIcon } from "../../Component/Svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllAssignedEvents,
  addAllAssignedNotes,
  addAllAssignedShifts,
} from "../../store/calendarData/action";
import Icons from "../../Component/Icons";
import UpgradeBanner from "../../Component/UpgradeBanner";

const DayDetails = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const {
    allAssignedShifts,
    isFirstTime,
    allAssignedEvents,
    allAssignedNotes,
  } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [shift, setShift] = useState({});
  const [events, setEvents] = useState([]);
  const [notes, setNotes] = useState([]);
  // const [newNumber, setNewNumber] = false;

  const [modalShift, setModalShift] = useState({});
  const [eventDataForModal, setEventDataForModal] = useState({});
  const [noteDataForModal, setNoteDataForModal] = useState({});

  const [isShiftModalShow, setIsShiftModalShow] = useState(false);
  const [isEventModalShow, setIsEventModalShow] = useState(false);
  const [isNoteModalShow, setIsNoteModalShow] = useState(false);

  const { request, response } = useRequest();
  const { request: deleteOnDateRequest, response: deleteOnDateResponse } =
    useRequest();

  const {
    request: noteDeleteOnDateRequest,
    response: noteDeleteOnDateResponse,
  } = useRequest();

  useEffect(() => {
    let newOneAssignedShift = allAssignedShifts.find(
      (item) => item.date == moment(id).format("DD/MM/YYYY")
    );
    // console.log(allAssignedNotes, "b");
    const newOneAssignedNotes = allAssignedNotes.filter(
      (item) => item.date == moment(id).format("DD/MM/YYYY")
    );
    // console.log(newOneAssignedNotes, "a");
    const newOneAssignedEvents = allAssignedEvents.filter(
      (item) => item.date == moment(id).format("DD/MM/YYYY")
    );

    if (newOneAssignedShift) {
      setShift(newOneAssignedShift);
    } else {
      newOneAssignedShift = {};
    }
    setEvents(newOneAssignedEvents);

    setNotes(newOneAssignedNotes);

    if (
      Object.keys(newOneAssignedShift).length <= 0 &&
      newOneAssignedEvents.length <= 0 &&
      newOneAssignedNotes.length <= 0
    ) {
      request("GET", `v1/event/events-on-date/${id}`);
    }
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { shifts, events, notes } = response?.data;
        setShift(shifts);
        setEvents(events);
        setNotes(notes);
        // setNewNumber(true);
      }
    }
  }, [response]);

  useEffect(() => {
    if (deleteOnDateResponse) {
      if (deleteOnDateResponse.status == "SUCCESS") {
        navigate("/calendar");
      }
    }
  }, [deleteOnDateResponse]);

  const shiftModalhanlder = (id) => {
    // const shift = shifts.find((s) => s.id_shift == id);
    // if (shift) {
    //   setModalShift(shift);
    // }
  };

  const shiftDeleteOnDateHandler = (idEventDate) => {
    const newShift = allAssignedShifts.filter(
      (a) => a.id_event_date != idEventDate
    );
    dispatch(addAllAssignedShifts(newShift));

    deleteOnDateRequest("DELETE", `v1/event/event-date-delete/${idEventDate}`);
  };

  const eventDeleteOnDateHandler = (id) => {
    setIsEventModalShow(false);
    let newEventData = events.filter((e) => e.id_event_date != id);
    setEvents(newEventData);

    const newEvents = allAssignedEvents.filter((a) => a.id_event_date != id);
    dispatch(addAllAssignedEvents(newEvents));

    if (id) {
      deleteOnDateRequest("DELETE", `v1/event/event-date-delete/${id}`);
    }
  };

  const eventModalOpenHandler = (id) => {
    const eventData = events.find((e) => e.id_event_date == id);
    if (eventData) {
      setEventDataForModal(eventData);
    }
    setIsEventModalShow(true);
  };

  const noteModalOpenHandler = (id) => {
    const noteData = notes.find((e) => e.id_note == id);
    if (noteData) {
      setNoteDataForModal(noteData);
    }
    setIsNoteModalShow(true);
  };

  const noteDeleteOnDateHandler = (id) => {
    setIsNoteModalShow(false);
    let newNotesData = notes.filter((e) => e.id_note != id);
    setNotes(newNotesData);

    const newNotes = allAssignedNotes.filter((a) => a.id_note != id);
    dispatch(addAllAssignedNotes(newNotes));

    if (id) {
      noteDeleteOnDateRequest("DELETE", `v1/event/note-delete/${id}`);
    }
  };

  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="headerContant"></div>
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5"
          // to={"/calendar"}
          onClick={() => {
            navigate("/calendar", {
              state: {
                date: id,
              },
            });
          }}
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
        </span>
        <h1>{moment(id).format("MMM Do YYYY")}</h1>
      </div>
      <div className="page-section">
        <div className="container">
          <div className="innerPageBox position-relative">
            <div className="eventTabs">
              <div className="shiftListCard p-3 dayDetailBorder">
                <div className="siftList">
                  {Object.keys(shift).length > 0 && (
                    <div
                      className="siftcard"
                      onClick={() =>
                        shift?.shift?.shift_name == "Off Day"
                          ? ""
                          : setIsShiftModalShow(true)
                      }
                    >
                      <div
                        className="shiftCardRow dayDetailCard"
                        // data-bs-toggle="modal"
                        // data-bs-target="#shiftDtlModal"
                      >
                        <span className="icon">
                          <img
                            src={`/assets/img/${shift?.shift?.start_period}.png`}
                            alt="afternoon icon"
                          />
                        </span>
                        <div className="dayDetailcard-body">
                          <div className="dayRow mb05">
                            <p className="font-md fw-500">Shift</p>
                            <p className="color-text-50 nowrap ml-3 shift-Daytime">
                              {shift?.shift?.shift_name == "Off Day"
                                ? `${getDiffrenceHours(
                                    shift?.shift?.start_time,
                                    shift?.shift?.end_time
                                  )} h`
                                : moment(shift?.shift?.start_time).format(
                                    "hh:mm A"
                                  ) +
                                  " " +
                                  moment(shift?.shift?.end_time).format(
                                    "hh:mm A"
                                  )}
                              {/* {moment(shift?.shift?.start_time).format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(shift?.shift?.end_time).format(
                                "hh:mm A"
                              )} */}
                              {/* 7:00 AM - 7:00 PM */}
                              <span class="font-sm color-text-30">
                                {" "}
                                {shift?.shift?.shift_name == "Off Day"
                                  ? ""
                                  : getDiffrenceHours(
                                      shift?.shift?.start_time,
                                      shift?.shift?.end_time
                                    ) + "h"}
                              </span>
                            </p>
                          </div>
                          <p class="color-text-70 font-base ellipsis text-capitalize mb-0">
                            {shift?.shift?.shift_name == "Off Day"
                              ? "Not Available"
                              : shift?.shift?.shift_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {events.length > 0 &&
                    events.map((e) => (
                      <div
                        className="siftcard cursor"
                        key={e.id_event_date}
                        onClick={() => eventModalOpenHandler(e.id_event_date)}
                      >
                        <div className="eventsUL">
                          <div className="liRowCard">
                            <span className="dayDetailAddon bg-purple"></span>
                            <div className="eventCard-body">
                              <p className="font-md fw-500 ellipsis text-capitalize mb05">
                                Events
                              </p>
                              <p class="color-text-70 font-base ellipsis">
                                {e.event_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {notes.length > 0 &&
                    notes.map((note) => (
                      <div
                        className="siftcard cursor"
                        key={note.id_note}
                        onClick={() => noteModalOpenHandler(note.id_note)}
                      >
                        <div className="eventsUL">
                          <div className="liRowCard">
                            <span className="dayDetailAddon bg-orange"></span>
                            <div className="eventCard-body">
                              <p className="font-md fw-500 ellipsis text-capitalize mb05">
                                Note
                              </p>
                              <p class="color-text-70 font-base ellipsis">
                                {note.text}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade show active"
              id="personal-events-tab-pane"
              role="tabpanel"
              aria-labelledby="personal-events-tab"
              tabIndex={0}
            ></div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade thememodal"
        id="eventDtlModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="modal-body p-0">
              <div className="single-view-header addEventPopup">
                <span className="label-icon bg-purple">
                  <i className="fas fa-ellipsis-h color-white box-30" />
                </span>
                <div>
                  <p className="font-lg mb10 fw-500 ellipsis text-capitalize line-height-1">
                    Personal Event
                  </p>
                  <p className="color-text-70 text-capitalize mb-0">Other</p>
                </div>
              </div>
              <div className="single-view-body">
                <p class="font-md fw-700 mb-4 text-capitalize"></p>
                <ul className="list-with-icons">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                    </svg>
                    <span className="color-text-50 w-70">Start Time:</span>
                    <span className="color-text-70">
                      {moment(modalShift?.start_time).format("hh:mm A")}
                    </span>
                  </li>
                  <li>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                    </svg>
                    <span className="color-text-50 w-70">End Time:</span>
                    <span className="color-text-70">
                      {moment(modalShift?.end_time).format("hh:mm A")}
                    </span>
                  </li>
                </ul>
                <p className="color-text-50 w-70 mb-0">
                  Duration:{" "}
                  {/* {getDiffrenceHours(
                    modalShift?.start_time,
                    modalShift?.end_time
                  )} */}
                  {/* {moment(modalShift?.end_time).diff(
                    moment(modalShift?.start_time)
                  )} */}
                  {/* 12.00h */}
                </p>
              </div>

              <div className="single-view-footer">
                <a className="btn-icon w-30 color-text" href="/">
                  <i className="icon-edit icon-shadow blue-color"></i>
                </a>
                <a href="/" className="btn-icon w-30 color-warning">
                  <i className="fas fa-trash" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade thememodal"
        id="shiftDtlModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="modal-body p-0">
              <div className="single-view-header with-icon">
                <span className="single-view-label">
                  <img
                    src={`/assets/img/${shift.start_period}.png`}
                    alt="day icon"
                  />
                </span>
                <div>
                  <p className="font-lg fw-500 mb10 line-height-1">Shift</p>
                  <p className="color-text-70 text-capitalize">
                    {shift.start_period}
                  </p>
                </div>
              </div>
              <div className="single-view-body">
                <p class="font-md fw-700 mb-4 text-capitalize">day</p>
                <p className="font-md fw-700 mb-4 text-capitalize">
                  {shift.shift_name}
                </p>
                <ul className="list-with-icons">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                    </svg>
                    <span className="color-text-50 w-70">Start Time:</span>
                    <span className="color-text-70">
                      {moment(shift?.start_time).format("hh:mm A")}
                    </span>
                  </li>
                  <li>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                    </svg>
                    <span className="color-text-50 w-70">End Time:</span>
                    <span className="color-text-70">
                      {moment(shift?.end_time).format("hh:mm A")}
                    </span>
                  </li>
                </ul>
                <p className="color-text-50 w-70 mb-0">
                  Duration:
                  {moment(shift?.end_time).diff(
                    moment(shift?.start_time),
                    "hour"
                  )}
                  {/* 12.00h */}
                </p>
              </div>
              <div
                className="single-view-footer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Link
                  className="btn-icon w-30 color-text"
                  to={`/edit-shift/${shift.id_shift}`}
                >
                  <i className="icon-edit icon-shadow blue-color"></i>
                </Link>
                <a
                  className="btn-icon w-30 color-warning cursor"
                  onClick={shiftDeleteOnDateHandler}
                >
                  <i className="fas fa-trash" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Shift Modal */}
      <Modal
        show={isShiftModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        onHide={() => setIsShiftModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setIsShiftModalShow(false)}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span className="single-view-label">
                <img
                  src={`/assets/img/${shift?.shift?.start_period}.png`}
                  alt="day icon"
                />
              </span>
              <div>
                <p className="font-lg fw-500 mb10 line-height-1">Shift</p>
                <p className="color-text-70 text-capitalize">
                  {shift?.shift?.start_period}
                </p>
              </div>
            </div>
            <div className="single-view-body">
              {/* <p class="font-md fw-700 mb-4 text-capitalize">day</p> */}
              <p className="font-md fw-700 mb-4 text-capitalize">
                {shift?.shift?.shift_name}
              </p>
              <ul className="list-with-icons">
                <li>
                  <Clock />
                  <span className="color-text-50 w-70">Start Time:</span>
                  <span className="color-text-70">
                    {moment(shift?.shift?.start_time).format("hh:mm A")}
                  </span>
                </li>
                <li>
                  {" "}
                  <Clock />
                  <span className="color-text-50 w-70">End Time:</span>
                  <span className="color-text-70">
                    {moment(shift?.shift?.end_time).format("hh:mm A")}
                  </span>
                </li>
              </ul>
              <p className="color-text-50 w-70 mb-0">
                Duration:{" "}
                {getDiffrenceHours(
                  shift?.shift?.start_time,
                  shift?.shift?.end_time
                )}
                h
                {/* {moment(shift?.shift?.end_time).diff(
                  moment(shift?.shift?.start_time),
                  "hour"
                )}
                .00h */}
                {/* 12.00h */}
              </p>
            </div>
            <div
              className="single-view-footer"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <Link
                className="btn-icon w-30 color-text"
                to={`/edit-shift/${shift?.shift?.id_shift}`}
              >
                <i className="icon-edit icon-shadow blue-color"></i>
              </Link>
              <a
                className="btn-icon w-30 color-warning cursor"
                onClick={() => shiftDeleteOnDateHandler(shift?.id_event_date)}
              >
                {/* <i className="fas fa-trash" /> */}
                <DeleteIcon />
              </a>
            </div>
          </div>
        </div>
      </Modal>
      {/*Event Modal */}
      <Modal
        show={isEventModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        // backdrop="static"
        onHide={() => setIsEventModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setIsEventModalShow(false)}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span
                className={`${
                  !eventDataForModal?.is_default ? "icon-font" : ""
                } label-icon bg-purple view_design`}
              >
                {/* <span className="label-icon icon-font bg-purple view_design"> */}
                <i
                  className={`${
                    eventDataForModal?.is_default
                      ? `icon-${
                          Icons[eventDataForModal.icon_name]
                        } icon-font-size`
                      : "fas fa-ellipsis-h "
                  } color-white box-30`}
                />
              </span>
              <div className="inner_header_view">
                <p className="font-lg fw-500 mb10 line-height-1">Event</p>
                <p className="color-text-70 text-capitalize">
                  {/* {eventDataForModal?.icon} */}{" "}
                  {eventDataForModal?.event_name}
                </p>
              </div>
            </div>

            <div className="single-view-body">
              <p className="font-md fw-700 mb-4 text-capitalize">
                {eventDataForModal?.icon_name}
              </p>

              <ul className="list-with-icons">
                <li>
                  <Clock />
                  <span className="color-text-50 w-70">Start Time:</span>
                  <span className="color-text-70">
                    {moment(eventDataForModal?.start_time).format("hh:mm A")}
                  </span>
                </li>
                <li>
                  {" "}
                  <Clock />
                  <span className="color-text-50 w-70">End Time:</span>
                  <span className="color-text-70">
                    {moment(eventDataForModal?.end_time).format("hh:mm A")}
                  </span>
                </li>
              </ul>
            </div>
            <div
              className="single-view-footer"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <Link
                className="btn-icon w-30 color-text"
                to={`/edit-event/${eventDataForModal?.id_event_date}`}
              >
                <i className="icon-edit icon-shadow blue-color"></i>
              </Link>
              <a
                className="btn-icon w-30 color-warning cursor"
                onClick={() =>
                  eventDeleteOnDateHandler(eventDataForModal?.id_event_date)
                }
              >
                {/* <i className="fas fa-trash" /> */}
                <DeleteIcon />
              </a>
            </div>
          </div>
        </div>
      </Modal>

      {/*Note Modal */}
      <Modal
        show={isNoteModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        // backdrop="static"
        onHide={() => setIsNoteModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setIsNoteModalShow(false)}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span className="label-icon bg-orange  view_design"></span>
              <div className="inner_header_view">
                <p className="font-lg fw-500 mb10 line-height-1">Note</p>
              </div>
            </div>

            <div className="single-view-body">
              <p className="font-md fw-700 mb-4 limit-word">
                {/* this is note */}
                {noteDataForModal?.text}
              </p>
            </div>
            <div
              className="single-view-footer"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <Link
                className="btn-icon w-30 color-text"
                to={`/edit-note/${noteDataForModal?.id_note}`}
              >
                <i className="icon-edit icon-shadow blue-color"></i>
              </Link>
              <a
                className="btn-icon w-30 color-warning cursor"
                onClick={() =>
                  noteDeleteOnDateHandler(noteDataForModal?.id_note)
                }
              >
                <DeleteIcon />
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default DayDetails;
