import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SIDEBAR_TOGGLE,
  LOADING,
  ADD_MANAGER,
  UPDATE_REDUX,
  UPGRADEMEMERSHIP,
  ADDCARDINFO,
  ADDSUBSCRIPTIONID,
  ADDSTRIPEPLANID,
  ADDCURRENTSUBSCRIPTIONDATA,
  CHANGEMANAERAPPROVAL,
  CHANGECURRENTSUBSCRIPTIONDATA,
} from "./actionTypes";

const initialState = {
  loggedIn: null,
  token: null,
  userId: null,
  profilePic: null,
  email: null,
  firstName: null,
  lastName: null,
  title: null,
  mobile_number: null,
  countryCode: null,
  dob: null,
  gender: null,
  isMobileSidebarOpen: false,
  loading: false,
  managers: [],
  role: null,
  socket: null,
  remainingDays: null,
  cardInfo: {},
  subscriptionId: null,
  currentSubscriptionData: {},
  isManagersApproval: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        ...action.updates,
      };
    case AUTH_LOGOUT:
      return {
        ...initialState,
        loggedIn: false,
      };
    case SIDEBAR_TOGGLE:
      return {
        ...state,
        ...action.updates,
      };
    case LOADING:
      return {
        ...state,
        ...action.updates,
      };
    case ADD_MANAGER:
      return {
        ...state,
        managers: [...action.updates],
      };
    case UPDATE_REDUX:
      return {
        ...state,
        ...action.updates,
      };
    case UPGRADEMEMERSHIP:
      return {
        ...state,
        role: action.updates,
      };
    case ADDCARDINFO:
      return {
        ...state,
        cardInfo: { ...action.updates },
      };
    case ADDSUBSCRIPTIONID:
      return {
        ...state,
        currentSubscriptionData: {
          ...state.currentSubscriptionData,
          subscriptionId: action.updates,
        },
      };
    case ADDSTRIPEPLANID:
      return {
        ...state,
        currentSubscriptionData: {
          ...state.currentSubscriptionData,
          stripePlanId: action.updates,
        },
      };
    case ADDCURRENTSUBSCRIPTIONDATA:
      return {
        ...state,
        currentSubscriptionData: {
          ...action.updates,
        },
      };
    case CHANGECURRENTSUBSCRIPTIONDATA:
      return {
        ...state,
        currentSubscriptionData: {
          ...state.currentSubscriptionData,
          is_deleted: action.updates,
        },
      };
    case CHANGEMANAERAPPROVAL:
      return {
        ...state,
        isManagersApproval: action.updates,
      };
    default:
      return state;
  }
};

export default authReducer;
