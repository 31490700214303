import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment";
import { default as Datepicker } from "pickerjs";
import "pickerjs/dist/picker.min.css";
import { useForm } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl } from "@mui/material";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";

import useRequest from "../../hooks/useRequest";
import { addHeadingOnPicker, minutesOfDay } from "../../utils/fn";
import RTimePicker from "../../Component/TimePicker";
import { useDispatch, useSelector } from "react-redux";
import { addAllWorkplaces } from "../../store/calendarData/action";
import { Spinner } from "../../Component/Svg";
import UpgradeBanner from "../../Component/UpgradeBanner";

const AddSift = () => {
  const navigate = useNavigate();
  const { allWorkplaces } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  let location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const [isStartTimeOpen, setIsStartTimeOpen] = useState(false);
  const [isEndTimeOpen, setIsEndTimeOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [workplaces, setWorkplaces] = useState([]);
  const [shiftType, setShiftType] = useState("");

  const startDateRef = useRef();
  const endDateRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
    getValues,
  } = useForm();

  const { request, response, loading } = useRequest();
  const { request: getWorkplacesRequest, response: getWorkplacesResponse } =
    useRequest();
  console.log(location.state?.shiftName);
  useEffect(() => {
    if (allWorkplaces.length > 0) {
      setWorkplaces(allWorkplaces);
    } else {
      getWorkplacesRequest("GET", "v1/workplace/workplaces");
    }
    if (location.state?.shiftName) {
      setValue("shift_name", location.state?.shiftName);
    }
  }, []);

  useEffect(() => {
    if (currentTab == 0) {
      register("id_workplace", {
        required: currentTab == 0 && "This field is mandatory.",
      });
      register("start_time", {
        required: false,
      });
      register("end_time", {
        required: false,
      });
    }
    if (currentTab == 1) {
      register("start_time", {
        required:
          currentTab == 1 &&
          "The Start Time and End Time must be selected to continue.",
      });
      register("end_time", {
        required:
          currentTab == 1 &&
          "The Start Time and End Time must be selected to continue.",
      });
    }
  }, [currentTab]);

  // useEffect(() => {
  //   // CREATE INSTANCE
  //   if (currentTab == 1) {
  //     const tempInstance = new Datepicker(startDateRef.current, {
  //       format: "HH:mm",
  //       headers: true,
  //       // rows: 3,
  //       // controls: true,
  //       // inline: true,
  //     });

  //     setStartDateInstance(tempInstance);

  //     const tempTwoInstance = new Datepicker(endDateRef.current, {
  //       format: "HH:mm",
  //       headers: true,
  //       // rows: 3,
  //       // controls: true,
  //       // inline: true,
  //     });

  //     setEndDateInstance(tempTwoInstance);
  //     EVENTS
  //     startDateRef.current.addEventListener("change", startTimeFunction);
  //     // EVENTS
  //     endDateRef.current.addEventListener("change", endTimeFunction);

  //     return () => {
  //       startDateRef?.current?.removeEventListener("change", startTimeFunction);
  //       endDateRef?.current?.removeEventListener("change", endTimeFunction);
  //     };
  //   }
  // }, [currentTab]);

  useEffect(() => {
    if (getWorkplacesResponse) {
      if (getWorkplacesResponse.status === "SUCCESS") {
        const { workplaces } = getWorkplacesResponse?.data;
        dispatch(addAllWorkplaces(workplaces));
        // let workplaces = getWorkplacesResponse?.data?.workplaces.map((w) => ({
        //   id: w.id_workplace,
        //   hospital_name: w.hospital_name,
        // }));
        setWorkplaces(workplaces);
      }
    }
  }, [getWorkplacesResponse]);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        navigate("/calendar");
      }
    }
  }, [response]);

  const startTimeFunction = (event) => {
    clearErrors("start_time");

    let startTime = moment(event._d);

    setStartDate(moment(event._d).format("hh:mm A"));
    setValue("start_time", event._d);
    if (
      startTime.isBetween(
        moment("02:59:59", "HH:mm:ss"),
        moment("11:59:59", "HH:mm:ss")
      )
    ) {
      setShiftType("day");
    } else if (
      startTime.isBetween(
        moment("11:59:59", "HH:mm:ss"),
        moment("14:59:59", "HH:mm:ss")
      )
    ) {
      setShiftType("afternoon");
    } else if (
      startTime.isBetween(
        moment("14:59:59", "HH:mm:ss"),
        moment("17:59:59", "HH:mm:ss")
      )
    ) {
      setShiftType("evening");
    } else {
      setShiftType("night");
    }
  };

  const endTimeFunction = (event) => {
    clearErrors("end_time");
    setValue("end_time", event._d);
    setEndDate(moment(event._d).format("hh:mm A"));
  };

  const nextHandler = () => {
    if (currentTab === 1) {
      if (startDate === endDate) {
        //show Error
        setError("start_time", {
          type: "manual",
          message: "Please select a valid time.",
        });
      } else {
        setCurrentTab((prev) => prev + 1);
      }
    } else {
      setCurrentTab((prev) => prev + 1);
    }
  };

  function nextPrev(n) {
    if (n === "next") {
      setCurrentTab((prev) => prev + 1);
    } else if (n === "prev") {
      setCurrentTab((prev) => prev - 1);
    }
  }

  const addShiftHandler = (data) => {
    let { end_time, id_workplace, shift_name, start_time } = data;

    start_time = moment(start_time);
    end_time = moment(end_time);
    // const [startTimeHh, startTimeMm] = start_time.split(":");
    // start_time = moment().set({ h: startTimeHh, m: startTimeMm });

    // start_time = start_time.unix();

    // const [endTimeHh, endTimeMm] = end_time.split(":");
    // end_time = moment().set({ h: endTimeHh, m: endTimeMm });
    // end_time = end_time.unix();

    const startTimeMinutes = minutesOfDay(start_time);
    const endTimeMinutes = minutesOfDay(end_time);

    if (startTimeMinutes > endTimeMinutes) {
      end_time = end_time.add({ days: 1 });
    }
    if (!loading) {
      request("POST", "v1/event/add-shift", {
        end_time: moment(end_time).format("YYYY-MM-DD HH:mm:00"),
        id_workplace,
        shift_name,
        start_time: moment(start_time).format("YYYY-MM-DD HH:mm:00"),
        start_period: shiftType,
        icon_name: "date night",
      });
    }
  };

  const workLocationHandleChange = (e) => {
    clearErrors("id_workplace");
    setValue("id_workplace", e.target.value);
  };

  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="headerContant"></div>
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5 cursor"
          onClick={() => {
            if (location.search) {
              navigate("/calendar", {
                state: {
                  isAssigningShifts: true,
                  replace: true,
                },
              });
            } else {
              navigate("/events");
            }
          }}
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
        </span>
        <h1>Add Shifts</h1>
      </div>
      {/* Section */}
      <div className="page-section">
        <div className="container">
          <div className="innerPageBox">
            <div className="MuiPaper-root MuiPaper-elevation0 MuiStepper-root MuiStepper-horizontal stepper-header">
              <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 0 || currentTab === 1 || currentTab === 2
                    ? "active"
                    : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span className="MuiStepLabel-labelContainer">
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label MuiStepLabel-active">
                      Name
                    </span>
                  </span>
                </span>
              </span>
              <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 1 || currentTab === 2 ? "active" : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal MuiStepLabel-disabled step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span className="MuiStepLabel-labelContainer">
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label">
                      Duration
                    </span>
                  </span>
                </span>
              </span>
              <span
                className={`step MuiStep-root MuiStep-horizontal ${
                  currentTab === 2 ? "active" : ""
                } `}
              >
                <span className="MuiStepLabel-root MuiStepLabel-horizontal MuiStepLabel-disabled step-label">
                  <span className="MuiStepLabel-iconContainer">
                    <svg
                      className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                    </svg>
                  </span>
                  <span className="MuiStepLabel-labelContainer">
                    <span className="MuiTypography-root MuiTypography-body1 MuiStepLabel-label">
                      Start Period
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="stepper-Outbody h-10000">
              <form
                id="regForm"
                //  onSubmit={handleSubmit(addShiftHandler)}
              >
                <div className="stepper-body">
                  <div className="stepper-content">
                    {currentTab === 0 ? (
                      <div className="tab" style={{ display: "block" }}>
                        <div className="form-field">
                          <div className="form-input">
                            <input
                              name="ShiftName"
                              type="TEXT"
                              placeholder="Shift Name"
                              defaultValue=""
                              {...register("shift_name", {
                                required:
                                  currentTab == 0 && "This field is mandatory.",
                                setValueAs: (v) => v.trim(),
                              })}
                            />
                            <span className="inputborder" />
                          </div>
                          {errors.shift_name && (
                            <p className="error-msg">
                              {errors.shift_name.message}
                            </p>
                          )}
                        </div>
                        <div className="form-field">
                          <div className="form-input">
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                            >
                              <InputLabel id="demo-simple-select-filled-label">
                                Work Location
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                // value={age}
                                label="Work Location"
                                onChange={workLocationHandleChange}
                                defaultValue={getValues("id_workplace")}
                              >
                                {workplaces.map((workplace) => (
                                  <MenuItem
                                    key={workplace.id_workplace}
                                    value={workplace.id_workplace}
                                  >
                                    {workplace.hospital_name}
                                  </MenuItem>
                                ))}
                                <MenuItem
                                  onClick={() =>
                                    navigate("/add-work", {
                                      state: {
                                        from: "/add-shift",
                                        shiftName: getValues("shift_name"),
                                      },
                                    })
                                  }
                                >
                                  +Add New Location
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <span className="inputborder" />
                          </div>
                          {errors.id_workplace && (
                            <p className="error-msg">
                              {errors.id_workplace.message}
                            </p>
                          )}
                        </div>
                      </div>
                    ) : currentTab === 1 ? (
                      <div
                        className="tab"
                        style={{ display: currentTab === 1 ? "block" : "none" }}
                      >
                        <div>
                          <div className="time-picker">
                            <div className="time ">
                              {/* <div className="js-time-picker-Start"> */}
                              <RTimePicker
                                open={isStartTimeOpen}
                                close={setIsStartTimeOpen}
                                onChange={startTimeFunction}
                                defaultValue={moment()}
                              />
                              {/* <input
                                value={startDate}
                                ref={startDateRef}
                                type="hidden"
                              /> */}
                              <button
                                type="button"
                                className="btn btn-white"
                                onClick={() => {
                                  setIsStartTimeOpen(true);
                                  addHeadingOnPicker();
                                }}
                                //  onClick={() => setIsStartTimeOpen(true)}
                              >
                                <i className="far fa-clock" />
                                <span>Start Time</span>
                              </button>
                              <p className="selected-time">{startDate}</p>
                              {/* </div> */}
                            </div>
                            <div className="time">
                              {/* <div className="js-time-picker-End"> */}
                              <RTimePicker
                                open={isEndTimeOpen}
                                close={setIsEndTimeOpen}
                                onChange={endTimeFunction}
                                defaultValue={moment()}
                              />
                              {/* <input
                                value={endDate}
                                ref={endDateRef}
                                type="hidden"
                              /> */}
                              <button
                                type="button"
                                className="btn btn-white "
                                onClick={() => {
                                  setIsEndTimeOpen(true);
                                  addHeadingOnPicker();
                                }}
                              >
                                <i className="far fa-clock" />
                                <span>End Time</span>
                              </button>
                              <p className="selected-time">{endDate}</p>
                              {/* </div> */}
                            </div>
                            {(errors.start_time || errors.end_time) && (
                              <p className="error-msg with-icon">
                                {errors.start_time?.message ||
                                  errors.end_time?.message}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* <div class="form-field">
                                  <input type="text" class="form-control js-time-picker" value="02:56">
                              </div> */}
                      </div>
                    ) : (
                      <div className="tab" style={{ display: "block" }}>
                        <div className="siftListCard p-3">
                          <div className="siftList">
                            <div className="form-group custom_radio siftcard">
                              {/* <input
                                type="radio"
                                id="sifhafternoon"
                                name="radio-group"
                              /> */}
                              <label
                                className={`shiftCardRow ${
                                  shiftType === "afternoon"
                                    ? "checkActive"
                                    : "disable"
                                }`}
                                htmlFor="sifhafternoon"
                              >
                                <span className="icon">
                                  <img
                                    src="/assets/img/afternoon.png"
                                    alt="afternoon icon"
                                  />
                                </span>
                                <div className="siftcard-body">
                                  <p className="font-xmd fw-500">Afternoon</p>
                                </div>
                              </label>
                            </div>
                            <div className="form-group custom_radio siftcard">
                              {/* <input
                                type="radio"
                                id="siftday"
                                name="radio-group"
                              /> */}
                              <label
                                className={`shiftCardRow ${
                                  shiftType === "day"
                                    ? "checkActive"
                                    : "disable"
                                }`}
                                htmlFor="siftday"
                              >
                                <span className="icon">
                                  <img
                                    src="/assets/img/day.png"
                                    alt="afternoon icon"
                                  />
                                </span>
                                <div className="siftcard-body">
                                  <p className="font-xmd fw-500">Day</p>
                                </div>
                              </label>
                            </div>
                            <div className="form-group custom_radio siftcard">
                              {/* <input
                                type="radio"
                                id="siftevening"
                                name="radio-group"
                              /> */}
                              <label
                                className={`shiftCardRow ${
                                  shiftType === "evening"
                                    ? "checkActive"
                                    : "disable"
                                }`}
                                htmlFor="siftevening"
                              >
                                <span className="icon">
                                  <img
                                    src="/assets/img/evening.png"
                                    alt="afternoon icon"
                                  />
                                </span>
                                <div className="siftcard-body">
                                  <p className="font-xmd fw-500">Evening</p>
                                </div>
                              </label>
                            </div>
                            <div className="form-group custom_radio siftcard">
                              {/* <input
                                type="radio"
                                id="siftnight"
                                name="radio-group"
                              /> */}
                              <label
                                className={`shiftCardRow ${
                                  shiftType === "night"
                                    ? "checkActive"
                                    : "disable"
                                }`}
                                htmlFor="siftnight"
                              >
                                <span className="icon">
                                  <img
                                    src="/assets/img/night.png"
                                    alt="afternoon icon"
                                  />
                                </span>
                                <div className="siftcard-body">
                                  <p className="font-xmd fw-500">Night</p>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="content-footer" style={{ overflow: "auto" }}>
                    {currentTab == 0 && (
                      <button
                        type="button"
                        className="btn-link formBtn color-text-50"
                        id="prevBack"
                        onClick={() => {
                          if (location.search) {
                            navigate("/calendar", {
                              state: {
                                isAssigningShifts: true,
                              },
                              replace: true,
                            });
                          } else {
                            navigate("/events");
                          }
                        }}
                      >
                        Cancel
                      </button>
                    )}
                    {(currentTab == 1 || currentTab == 2) && (
                      <button
                        type="button"
                        className="btn-link formBtn color-text-50"
                        id="prevBtn"
                        onClick={() => nextPrev("prev")}
                      >
                        Back
                      </button>
                    )}
                    {(currentTab == 0 || currentTab == 1) && (
                      <button
                        type="submit"
                        className="btn-white formBtn"
                        id="nextBtn"
                        // onClick={() => nextPrev("next")}
                        onClick={handleSubmit(nextHandler)}
                      >
                        Next
                      </button>
                    )}
                    {currentTab == 2 && (
                      <button
                        type="button"
                        className="btn-white formBtn"
                        id="nextBtn"
                        onClick={handleSubmit(addShiftHandler)}
                      >
                        {loading ? <Spinner /> : "Add"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSift;
