import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../Component/Header";
import { Spinner } from "../../../Component/Svg";
import UpgradeBanner from "../../../Component/UpgradeBanner";
import useRequest from "../../../hooks/useRequest";
import { addAllWorkplaces } from "../../../store/calendarData/action";

const Edit = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
    getValues,
  } = useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { allWorkplaces } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);

  const { request, response, loading } = useRequest();
  const { request: workPlaceRequest, response: workPlaceResponse } =
    useRequest();

  useEffect(() => {
    if (allWorkplaces.length > 0) {
      const workplace = allWorkplaces.find((w) => w.id_workplace == id);
      if (workplace) {
        initialValueHandler(workplace);
      } else {
        workPlaceRequest("GET", `v1/workplace/workplace/${id}`);
      }
    } else {
      workPlaceRequest("GET", `v1/workplace/workplace/${id}`);
    }
  }, []);

  useEffect(() => {
    if (workPlaceResponse) {
      if (workPlaceResponse.status == "SUCCESS") {
        initialValueHandler(workPlaceResponse.data.workplace);
      }
    }
  }, [workPlaceResponse]);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { workplace } = response.data;
        const { city, country, hospital_address, hospital_name, state } =
          workplace;
        let newWorkplaces = allWorkplaces.map((w) => {
          if (w.id_workplace == id) {
            w = { ...w, city, country, hospital_address, hospital_name, state };
          }
          return w;
        });
        dispatch(addAllWorkplaces(newWorkplaces));
        navigate("/work-list");
      }
    }
  }, [response]);

  const initialValueHandler = (workplace) => {
    const { city, country, hospital_address, hospital_name, state } = workplace;
    setValue("city", city);
    setValue("country", country);
    setValue("hospital_address", hospital_address);
    setValue("hospital_name", hospital_name);
    setValue("state", state);
  };
  const workPlaceAddHandler = (data) => {
    request("POST", "v1/workplace/edit-workplace", {
      ...data,
      id_workplace: id,
    });
  };

  return (
    <>
      <div className="content-center lightBg ">
        {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
        <div className="headerContant"></div>
        <div className="setting_header light_header">
          <Header heading="Edit Work" link="/work-list" />

          <div className="content-header-bottom work_info">
            <div className="profile_img">
              <i className="icon-building"></i>
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="list-wrap">
            <form onSubmit={handleSubmit(workPlaceAddHandler)}>
              <div className="profile-info">
                <div className="form-field">
                  <p className="form-label">Name</p>
                  <div className="form-input">
                    <input
                      name="hospital_name"
                      className="form-input border-bottom"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter Name"
                      {...register("hospital_name", {
                        required: "This field is mandatory.",
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.hospital_name && (
                    <p className="error-msg">{errors.hospital_name.message}</p>
                  )}
                </div>

                <div className="form-field">
                  <p className="form-label">Address</p>
                  <div className="form-input">
                    <input
                      className="form-input border-bottom"
                      name="hospital_address"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter Address"
                      {...register("hospital_address", {
                        // required: "This field is mandatory.",
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.hospital_address && (
                    <p className="error-msg">
                      {errors.hospital_address.message}
                    </p>
                  )}
                </div>

                <div className="form-field">
                  <p className="form-label">City</p>
                  <div className="form-input">
                    <input
                      className="form-input border-bottom"
                      name="city"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter City"
                      {...register("city", {
                        // required: "This field is mandatory.",
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.city && (
                    <p className="error-msg">{errors.city.message}</p>
                  )}
                </div>

                <div className="form-field">
                  <p className="form-label">State/Province</p>
                  <div className="form-input">
                    <input
                      className="form-input border-bottom"
                      name="state"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter State/Province"
                      {...register("state", {
                        // required: "This field is mandatory.",
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.state && (
                    <p className="error-msg">{errors.state.message}</p>
                  )}
                </div>

                <div className="form-field">
                  <p className="form-label">Country</p>
                  <div className="form-input">
                    <input
                      className="form-input border-bottom"
                      name="country"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter Country"
                      {...register("country", {
                        // required: "This field is mandatory.",
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                    <span className="border-bottom-animation center"></span>
                  </div>
                  {errors.country && (
                    <p className="error-msg">{errors.country.message}</p>
                  )}
                </div>
              </div>
              <div
                className="content-footer setting_footer"
                style={{ overflow: "auto" }}
              >
                <button
                  type="button"
                  className="btn-link formBtn color-text-50"
                  id="prevBack"
                  onClick={() => navigate("/worl-list")}
                  // onClick={() => {
                  //   if (location.search) {
                  //     navigate("/calendar", {
                  //       state: {
                  //         isAssigningShifts: true,
                  //       },
                  //       replace: true,
                  //     });
                  //   } else {
                  //     navigate("/events");
                  //   }
                  // }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-white formBtn"
                  id="nextBtn"
                >
                  {loading ? <Spinner /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
