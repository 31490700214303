import { useEffect, useState } from "react";
import { AdminLayout } from "../../Component/AdminLayout";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import useRequest from "../../hooks/useRequest";
import { authSuccess } from "../../store/auth/action";
import { Spinner } from "../../Component/Svg";

const AdminProfile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { firstName, lastName, email } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const { request, response, loading } = useRequest();

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const {
          user: { first_name, last_name },
        } = response.data;
        dispatch(authSuccess({ firstName: first_name, lastName: last_name }));
        setIsEditMode(false);
      }
    }
  }, [response]);

  const editModeHandler = () => {
    setIsEditMode(true);
    setValue("first_name", firstName);
    setValue("last_name", lastName);
  };

  const onSubmit = (data) => {
    const { first_name, last_name } = data;
    request("POST", "v1/admin/admin-user-edit", {
      first_name,
      last_name,
    });
  };

  return (
    <AdminLayout>
      <div className="mainContent ">
        <div className="adminCard sm allSidePadding">
          <div className="SettingPage">
            {isEditMode ? (
              <>
                <h5 class="MuiTypography-root MuiTypography-h5 MuiTypography-colorInherit MuiTypography-noWrap flex align-center justify-between">
                  Profile
                </h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group main-input">
                    <label className="profile_label">First Name </label>
                    <div className="input_icon_box">
                      <input
                        className="form-control profile_edit"
                        type="text"
                        placeholder="First Name"
                        {...register("first_name", {
                          required: "This field is mandatory",
                        })}
                      />
                      <span className="input_icons">
                        <svg
                          class="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                        </svg>
                      </span>
                    </div>
                    {errors.first_name && (
                      <p className="error-msg">{errors.first_name.message}</p>
                    )}
                  </div>

                  <div className="form-group main-input">
                    <label className="profile_label">Last Name </label>
                    <div className="input_icon_box">
                      <input
                        className="form-control profile_edit"
                        type="text"
                        placeholder="Last Name"
                        {...register("last_name", {
                          required: "This field is mandatory",
                        })}
                      />
                      <span className="input_icons">
                        <svg
                          class="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                        </svg>
                      </span>
                    </div>
                    {errors.last_name && (
                      <p className="error-msg">{errors.last_name.message}</p>
                    )}
                  </div>

                  <div>
                    <p className="font-xmd fw-500">Password</p>
                    <Link
                      to={"/change-password"}
                      state={{ from: "/admin/profile" }}
                      className="btn-link font-md color-primary fw-500 mt-2"
                    >
                      Change Password
                    </Link>
                  </div>
                  <div className="admin-buttons">
                    <button
                      type="button"
                      className="btn btn-link color-text-50"
                      onClick={() => setIsEditMode(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-white ml-6 ml-lg-1"
                      // onClick={() => setIsEditMode(false)}
                      // disabled={errors.first_name || errors.last_name}
                    >
                      {loading ? <Spinner /> : "Save"}
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <>
                <div className="inCardHeader">
                  <div className="jss325 edit_main_box">
                    <h6 className="adminCardTitle-h6">Profile</h6>
                    <span className="edit_svg cursor" onClick={editModeHandler}>
                      <svg
                        class="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                        <path fill="none" d="M0 0h24v24H0z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="main-input sm">
                  <div className="profile_block">
                    <div className="form-group">
                      <p class="font-xmd fw-500">Full Name</p>
                      <p class="font-md color-text-70 mt-2">
                        {firstName} {lastName}
                      </p>
                    </div>
                    <div className="form-group">
                      <p className="font-xmd fw-500">Email</p>
                      <p className="font-md color-text-70 mt-2">{email}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminProfile;
