import {
  ADD_STATISTICS_DATA,
  CLEAR_ADMIN_DATA,
  //   ADD_NOTIFICATION_COUNT,
  //   CLEAR_NOTIFICATION_DATA,
} from "./actionTypes";

export const addStatistics = (payload) => {
  return {
    type: ADD_STATISTICS_DATA,
    payload,
  };
};
// export const addNotificationCount = (updates) => {
//   return {
//     type: ADD_NOTIFICATION_COUNT,
//     updates,
//   };
// };
export const clearAdminData = () => {
  return {
    type: CLEAR_ADMIN_DATA,
  };
};
