import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneNumber = ({
  value,
  setMobileNumber,
  setCountryCode,
  onChange,
  setCountryFormat,
}) => {
  return (
    <PhoneInput
      country={"us"}
      value={value}
      onChange={(e, data) => {
        onChange(e);
        setCountryCode(data.dialCode);
        setCountryFormat(data.countryCode);
        setMobileNumber(e.slice(data.dialCode.length));
      }}
    />
  );
};

export default PhoneNumber;
