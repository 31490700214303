import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Switch,
  IconButton,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AdminLayout } from "../../Component/AdminLayout";
import { DatePickerForAdmin } from "../../Component/DatePickerForAdmin";
import { DeleteIcon } from "../../Component/Svg";
import useRequest from "../../hooks/useRequest";

const rows = [
  // { id: "number", label: "#", center: false, sort: true },
  { id: "user_name", label: "User name", center: false, sort: true },
  { id: "title", label: "Title", center: false, sort: true },
  { id: "date", label: "Date submitted", center: false, sort: true },
  { id: "feedback", label: "Feedback", center: false, sort: true },
  { id: "role", label: "Role", center: false, sort: true },
  { id: "checked", label: "Activate/ Deactivate", center: true, sort: true },
  { id: "tools", label: "Tools", center: true, sort: false },
];

const queryHanlder = (
  orderBy = "asc",
  sortBy = "user_name",
  page = 1,
  rowsPerPage = 10,
  fromDate,
  toDate
) => {
  if (fromDate) {
    fromDate = moment(fromDate).format("YYYY-MM-DD");
  }
  if (toDate) {
    toDate = moment(toDate).format("YYYY-MM-DD");
  }

  return `v1/admin/users-feedbacks?from_date=${fromDate}&to_date=${toDate}&page=${page}&items_per_page=${rowsPerPage}&sort_by=${sortBy}&order_by=${orderBy}`;
};

const AdminFeedback = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [feedbacks, setFeedbacks] = useState([]);
  const [totalFeedback, setTotalFeedback] = useState(0);
  const [feedbackDeleteModal, setFeedbackDeleteModal] = useState(false);
  const [feedbackDeleteId, setFeedbackDeleteId] = useState(null);

  const [sortableItem, setSortableItem] = useState({
    orderBy: "asc",
    sortBy: "user_name",
    page: 1,
    rowsPerPage: 10,
  });

  const [isError, setIsError] = useState({
    toDate: null,
    fromDate: null,
  });

  const { request, response } = useRequest();
  const { request: changeStatusRequest, response: changeStatusResponse } =
    useRequest();
  const { request: deleteFeedbackRequest, response: deleteFeedbackResponse } =
    useRequest();

  useEffect(() => {
    document.body?.classList?.remove("scroll-off");
    request(
      "GET",
      queryHanlder(
        sortableItem.orderBy,
        sortableItem.sortBy,
        sortableItem.page,
        sortableItem.rowsPerPage,
        fromDate,
        toDate
      )
    );
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        const { total_items, feedbacks, page } = response.data;
        setTotalFeedback(total_items);
        setFeedbacks(feedbacks);
        // setAllUsers(users);
        setSortableItem((prev) => ({ ...prev, page }));
      }
    }
  }, [response]);

  const sortableItemClickHandler = (sortBy, prevOrder) => {
    if (sortBy == sortableItem.sortBy) {
      let newOrder = prevOrder == "asc" ? "desc" : "asc";
      setSortableItem((prev) => ({ ...prev, sortBy, orderBy: newOrder }));
      request(
        "GET",
        queryHanlder(
          newOrder,
          sortBy,
          sortableItem.page,
          sortableItem.rowsPerPage,
          fromDate,
          toDate
        )
      );
    } else {
      setSortableItem((prev) => ({ ...prev, sortBy, orderBy: "desc" }));
      request(
        "GET",
        queryHanlder(
          "desc",
          sortBy,
          sortableItem.page,
          sortableItem.rowsPerPage,
          fromDate,
          toDate
        )
      );
    }
  };

  const pageChangeHandler = (e, page) => {
    request(
      "GET",
      queryHanlder(
        sortableItem.orderBy,
        sortableItem.sortBy,
        page + 1,
        sortableItem.rowsPerPage,
        fromDate,
        toDate
      )
    );
  };

  const fromDateOnchange = (value) => {
    setFromDate(value);
    if (toDate && toDate.isBefore(value)) {
      setIsError((prev) => ({
        ...prev,
        toDate: "Date should not be before minimal date",
      }));
      return;
    } else {
      setIsError((prev) => ({
        ...prev,
        toDate: null,
      }));
    }

    request(
      "GET",
      queryHanlder(
        sortableItem.orderBy,
        sortableItem.sortBy,
        sortableItem.page,
        sortableItem.rowsPerPage,
        value,
        toDate ? toDate : ""
      )
    );
    setFromDate(value);
  };

  const toDateOnchange = (value) => {
    if (fromDate && value.isBefore(fromDate)) {
      setIsError((prev) => ({
        ...prev,
        toDate: "Date should not be before minimal date",
      }));
      return;
    } else {
      setIsError((prev) => ({
        ...prev,
        toDate: null,
      }));
    }
    request(
      "GET",
      queryHanlder(
        sortableItem.orderBy,
        sortableItem.sortBy,
        sortableItem.page,
        sortableItem.rowsPerPage,
        fromDate ? fromDate : "",
        value
      )
    );
    setToDate(value);
  };

  const handleChangeStatus = (e, id_feedback) => {
    let checked = e.target.checked;
    const oldFeedbacks = [...feedbacks];
    const newFeedbacks = oldFeedbacks.map((f) => {
      if (f.id_feedback == id_feedback) {
        f = { ...f, for_showing: checked };
      }
      return f;
    });

    setFeedbacks(newFeedbacks);
    changeStatusRequest("POST", "v1/admin/edit-user-feedback", {
      id_feedback,
      for_showing: checked,
    });
  };

  const feedbackDeleteHandler = () => {
    const oldFeedbacks = [...feedbacks];
    const newFeedbacks = oldFeedbacks.filter(
      (f) => f.id_feedback != feedbackDeleteId
    );
    setFeedbacks(newFeedbacks);
    setFeedbackDeleteModal(false);
    setFeedbackDeleteId(null);
    deleteFeedbackRequest(
      "DELETE",
      `v1/admin/delete-user-feedback/${feedbackDeleteId}`
    );
  };

  return (
    <AdminLayout>
      <div className="mainContent">
        {/* <div className="adminCard sm"> */}
        <div className="adminCard add_full_size">
          <div className="StatisticsPage">
            <div className="inCardHeader">
              <div className="jss325">
                <h6 className="adminCardTitle-h6">Feedback</h6>
              </div>

              <div className="toolbar-filters-input">
                <div className="toolbar-filters">
                  <div className="datepickers d-flex">
                    <div className="date-picker date-picker-icon">
                      <DatePickerForAdmin
                        value={fromDate}
                        // setValue={setFromDate}
                        onChange={fromDateOnchange}
                        label="From"
                      />
                    </div>
                    <div className="date-picker date-picker-icon">
                      <DatePickerForAdmin
                        value={toDate}
                        // setValue={setToDate}
                        onChange={toDateOnchange}
                        minDate={fromDate}
                        label="To"
                      />
                      {isError.toDate && (
                        <p className="error-msg">{isError.toDate}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-table-wrapper">
              <div className="table-responsive tabl_height">
                <Table
                  className="table expandable-table"
                  aria-labelledby="tableTitle"
                >
                  <TableHead>
                    <TableRow>
                      {rows.map((row) => (
                        <TableCell
                          key={row.id}
                          padding="default"
                          className={row.minWidth ? "min-width" : ""}
                          sortDirection={
                            sortableItem.sortBy === row.id ? "desc" : false
                          }
                        >
                          <Tooltip
                            title="Sort"
                            placement={
                              row.numeric ? "bottom-end" : "bottom-start"
                            }
                            enterDelay={300}
                          >
                            <TableSortLabel
                              active={sortableItem.sortBy === row.id}
                              direction={sortableItem.orderBy}
                              onClick={() =>
                                sortableItemClickHandler(
                                  row.id,
                                  sortableItem.orderBy
                                )
                              }
                            >
                              {row.label}
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {feedbacks.length > 0 &&
                      feedbacks.map((feedback) => (
                        <TableRow hover key={feedback.id_feedback}>
                          <TableCell className="nowrap">
                            {feedback?.user?.first_name}{" "}
                            {feedback?.user?.last_name}
                          </TableCell>
                          <TableCell>{feedback?.user?.title}</TableCell>
                          <TableCell className="nowrap">
                            {moment(feedback?.created_at, "DD/MM/YYYY").format(
                              "DD-MM-YYYY"
                            )}
                          </TableCell>
                          <TableCell>{feedback?.text}</TableCell>
                          <TableCell className="nowrap">
                            {/* {n.role.replace("_user", "")} */}
                          </TableCell>
                          <TableCell className="text-center">
                            <div>
                              <Switch
                                // className="switch sm"
                                checked={feedback.for_showing}
                                onChange={(e) =>
                                  handleChangeStatus(e, feedback.id_feedback)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="text-center">
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="Delete"
                                onClick={() => {
                                  setFeedbackDeleteModal(true);
                                  setFeedbackDeleteId(feedback.id_feedback);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </div>
            <TablePagination
              className={"pagination_box"}
              labelRowsPerPage=""
              rowsPerPageOptions={[]}
              component="div"
              count={totalFeedback}
              rowsPerPage={sortableItem.rowsPerPage}
              page={sortableItem.page - 1}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onPageChange={(e, page) => pageChangeHandler(e, page)}
              onRowsPerPageChange={(e) => console.log(e, "onRowsPerPageChange")}
            />
          </div>
        </div>

        {/* Feedback Delete Modal */}
        <Modal
          show={feedbackDeleteModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="thememodal thememodal2"
          onHide={() => {
            setFeedbackDeleteModal(false);
            setFeedbackDeleteId(null);
          }}
        >
          <button
            type="button"
            className="btn-close"
            // data-bs-dismiss="modal"
            // aria-label="Close"

            onClick={() => {
              setFeedbackDeleteModal(false);
              setFeedbackDeleteId(null);
            }}
          />
          <div
          // className="modal-content"
          >
            <div className="modal-body sure_modal_body">
              <div className="text-center pt-8 px-6">
                <p className="font-lg fw-700 mb-4">Delete Feedback</p>
                <p className="font-md">
                  Are you sure you want to delete the feedback?
                </p>
              </div>
            </div>
            <div className="modal-footer shift_time_footer">
              <button
                type="button"
                className="btn button_no"
                onClick={() => {
                  setFeedbackDeleteModal(false);
                  setFeedbackDeleteId(null);
                }}
              >
                No
              </button>
              <button
                type="button"
                className="btn button_yes"
                onClick={feedbackDeleteHandler}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </AdminLayout>
  );
};

export default AdminFeedback;
