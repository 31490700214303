import moment from "moment";
import { default as Datepicker } from "pickerjs";
import { useEffect } from "react";

const Picker = ({
  onChange,
  timeRef,
  value,
  format,
  defaultDateForPicker,
  type = "hidden",
}) => {
  useEffect(() => {
    const tempInstance = new Datepicker(timeRef.current, {
      format: format,
      headers: true,
      date: defaultDateForPicker,
      
      // rows: 3,
      // controls: true,
      // inline: true,
    });

    timeRef.current.addEventListener("change", onChange);

    return () => {
      timeRef?.current?.removeEventListener("change", onChange);
      tempInstance.destroy();
    };
  }, [defaultDateForPicker, onChange]);

  return (
    <>
      <input value={value} ref={timeRef} type={type} onChange={() => {}} />

      {/* </div> */}
    </>
  );
};

export default Picker;
