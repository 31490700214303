import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useCallback, useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import Snackbar from "@mui/material/Snackbar";
import moment from "moment";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import AlloyFinger from "alloyfinger";
import { useDispatch, useSelector } from "react-redux";

import { API, FRONTENDURL } from "../../constant/api";
import useRequest from "../../hooks/useRequest";

import {
  capitalizeFirstLetter,
  classHandler,
  getDiffrenceHours,
  getStartAndEndDate,
  shiftsImageHanlder,
} from "../../utils/fn";
import {
  addAllAssignedEvents,
  addAllAssignedNotes,
  addAllAssignedShifts,
  addEvents,
  isCalendarDataExist,
} from "../../store/calendarData/action";
import Picker from "../../Component/Picker";
import UpgradeBanner from "../../Component/UpgradeBanner";

export default function ConnectionCalendar({
  selectDateForSwitch,
  setSelectDateForSwitch,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const monthAndYearRef = useRef();

  const { allAssignedShifts, isFirstTime } = useSelector(
    (state) => state.calendar
  );

  const { role } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const calendarRef = useRef();
  const currentMonthAndYearRef = useRef(moment().format("MM-YYYY"));

  const [defaultDateForPicker, setDefaultDateForPicker] = useState(
    moment().format("MMMM YYYY")
  );

  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm");

  const [events, setEvents] = useState([]); // icons on date

  const [selectedDate, setSelectedDate] = useState(
    moment().format("MMMM Do YYYY")
  );
  const [shifts, setShifts] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [shiftForModal, setShiftForModal] = useState({});

  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD")); // this is for go to day-detail page

  const [shiftDetailModalShow, setShiftDetailModalShow] = useState(false);

  const [selectedMonthAndYearForHeading, setSelectedMonthAndYearForHeading] =
    useState(moment().format("MMMM YYYY"));

  const { request: assignedShiftsRequest, response: assignedShiftsRespose } =
    useRequest();
  const {
    request: connectionShiftsRequest,
    response: connectionShiftsResponse,
  } = useRequest();

  const swiperHandler = useCallback(
    function (evt) {
      let elem = document.getElementById("swiper_id");

      if (evt.direction == "Left") {
        elem.classList.add("left_swipe");
        elem.classList.remove("right_swipe");

        calendarRef.current.getApi().next();
        let currMonthYear = calendarRef.current.calendar.currentData.viewTitle;
        currentMonthAndYearRef.current = moment(
          currMonthYear,
          "MMM YYYY"
        ).format("MM-YYYY");

        let startOfMonth = moment(currentMonthAndYearRef.current, "MM-YYYY")
          .startOf("month")
          .format("YYYY-MM-DD hh:mm");

        let endOfMonth = moment(currentMonthAndYearRef.current, "MM-YYYY")
          .endOf("month")
          .format("YYYY-MM-DD hh:mm");

        shiftDataSetHandler(startOfMonth, endOfMonth, shifts);

        setSelectedMonthAndYearForHeading(currMonthYear);

        classHandler(moment(currMonthYear, "MMMM YYYY"));
        dateClickHandler(moment(currMonthYear, "MMMM YYYY"));
        setEvents(
          shifts.map((s) => ({
            start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            image_url: shiftsImageHanlder(
              s.date,
              s?.shift?.start_period,
              currentMonthAndYearRef.current
            ),
          }))
        );
      } else if (evt.direction == "Right") {
        elem.classList.remove("left_swipe");
        elem.classList.add("right_swipe");

        calendarRef.current.getApi().prev();
        let currMonthYear = calendarRef.current.calendar.currentData.viewTitle;
        currentMonthAndYearRef.current = moment(
          currMonthYear,
          "MMM YYYY"
        ).format("MM-YYYY");

        let startOfMonth = moment(currentMonthAndYearRef.current, "MM-YYYY")
          .startOf("month")
          .format("YYYY-MM-DD hh:mm");

        let endOfMonth = moment(currentMonthAndYearRef.current, "MM-YYYY")
          .endOf("month")
          .format("YYYY-MM-DD hh:mm");

        shiftDataSetHandler(startOfMonth, endOfMonth, shifts);

        setSelectedMonthAndYearForHeading(currMonthYear);
        classHandler(moment(currMonthYear, "MMMM YYYY"));
        dateClickHandler(moment(currMonthYear, "MMMM YYYY"));
        // console.log("allAssignedShifts", allAssignedShifts);
        setEvents(
          shifts.map((s) => ({
            start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            image_url: shiftsImageHanlder(
              s.date,
              s?.shift?.start_period,
              currentMonthAndYearRef.current
            ),
          }))
        );
      }
    },
    [shifts, events]
  );

  useEffect(() => {
    const element = document.getElementsByClassName(
      "fc-scrollgrid-section-sticky"
    );
    if (element?.[0]?.classList) {
      element[0].classList.remove("fc-scrollgrid-section-sticky");
    }
    document.body?.classList?.remove("scroll-off");
    connectionShiftsRequest(
      "GET",
      `v1/share-calendar/connection-events?id=${id}`
    );
  }, []);

  useEffect(() => {
    let element = calendarRef.current.getApi().el;
    var af = new AlloyFinger(element, {
      swipe: swiperHandler,
    });
    return () => {
      af = af.destroy();
    };
  }, [swiperHandler]);

  useEffect(() => {
    if (connectionShiftsResponse) {
      if (connectionShiftsResponse.status == "SUCCESS") {
        let shifts = connectionShiftsResponse.data.connection_shifts;

        setShifts(shifts);
        setEvents(
          shifts.map((s) => ({
            start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            image_url: shiftsImageHanlder(
              s.date,
              s?.shift?.start_period,
              currentMonthAndYearRef.current
            ),
          }))
        );

        setShiftDetailModalShow(false);
        shiftDataSetHandler(startOfMonth, endOfMonth, shifts);
      }
    }
  }, [connectionShiftsResponse]);

  const dateClickHandler = (date) => {
    if (currentMonthAndYearRef.current != moment(date).format("MM-YYYY")) {
      return;
    }
    classHandler(date);
    setCurrentDate(moment(date).format("YYYY-MM-DD"));

    setSelectedDate(moment(date).format("MMMM Do YYYY"));
  };
  const shiftDataSetHandler = (startOfMonth, endOfMonth, shifts) => {
    let data = shifts.filter((ad) =>
      moment(ad.date, "DD/MM/YYYY").isBetween(startOfMonth, endOfMonth)
    );
    data = data.sort(function (a, b) {
      const value = moment(b.date, "DD/MM/YYYY").isBefore(
        moment(a.date, "DD/MM/YYYY")
      )
        ? 1
        : -1;
      return value;
    });

    if (data.length > 0) {
      setShiftData(
        data.map((d) => ({
          icon: d.shift.start_period,
          name: d.shift.shift_name,
          start_time: d.shift.start_time,
          end_time: d.shift.end_time,
          id_shift: d.shift.id_shift,
          id_event_date: d.id_event_date,
          workplace_name: d.shift.workplace_name,
          date: d.date,
          user_name: `${d.first_name} ${d.last_name}`,
        }))
      );
    } else {
      setShiftData([]);
    }
  };

  const eventClickHandler = (date) => {
    dateClickHandler(date);
  };

  // const events = [
  //   {
  //     title: "Meeting",
  //     start: new Date(),
  //     image_url: "assets/img/afternoon.png",
  //   },
  //   {
  //     start: new Date("2023-05-14"),
  //     image_url: "assets/img/day.png",
  //   },
  // ];
  // const goToDayDeatailHandler = () => {
  //   if (shiftData) {
  //     navigate(`/calendar/day-details/${currentDate}`);
  //   }
  // };
  const dayCellDidMountHandler = (e) => {
    let cell = e.el;
    if (moment(e.date).format("MM-YYYY") == currentMonthAndYearRef.current) {
      cell.classList.add("cursor");
    }
    if (e.isToday) {
      cell.classList.add("select-today");
    }
  };

  const dayHeaderDidMountHandler = (e) => {
    let day = e.el;
    if (moment().format("ddd") == e.text) {
      day.classList.add("day-color");
    }
  };
  const shiftDetailModalHandler = (id) => {
    const oldShiftData = [...shiftData];

    let data = oldShiftData.find((s) => s.id_event_date == id);
    setShiftForModal(data);
    setShiftDetailModalShow(true);
  };

  const pickerOnChange = useCallback(
    (e) => {
      let value = e.target.value;
      setSelectedMonthAndYearForHeading(value);
      let gotoDateValue = `${value} 1`;
      currentMonthAndYearRef.current = moment(gotoDateValue).format("MM-YYYY");
      gotoDateValue = moment(gotoDateValue).format("YYYY-MM-DD");
      dateClickHandler(gotoDateValue);
      let calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(gotoDateValue);
      classHandler(gotoDateValue);

      let startOfMonth = moment(currentMonthAndYearRef.current, "MM-YYYY")
        .startOf("month")
        .format("YYYY-MM-DD hh:mm");

      let endOfMonth = moment(currentMonthAndYearRef.current, "MM-YYYY")
        .endOf("month")
        .format("YYYY-MM-DD hh:mm");

      shiftDataSetHandler(startOfMonth, endOfMonth, shifts);

      let newShift = shifts.map((s) => ({
        start: moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
        image_url: shiftsImageHanlder(
          s.date,
          s?.shift?.start_period,
          currentMonthAndYearRef.current
        ),
      }));
      setEvents(newShift);
    },
    [shifts]
  );

  return (
    <div className="content-center lightBg ">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}

      {/* <Sidebar
        from="team"
        calendarRef={calendarRef}
        dateClickHandler={dateClickHandler}
        assignedShiftsRequest={assignedShiftsRequest}
        currentMonthAndYearRef={currentMonthAndYearRef}
        setSelectedMonthAndYearForHeading={setSelectedMonthAndYearForHeading}
        selectedMonthAndYearForHeading={selectedMonthAndYearForHeading}
        setEvents={setEvents}
        defaultDateForPicker={defaultDateForPicker}
        setDefaultDateForPicker={setDefaultDateForPicker}
      /> */}

      <div className="connection-calendar-box">
        <Link
          to="/connections"
          state={{ from: location.state?.from }}
          className="back_btns"
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
        </Link>
        <div>
          <span className="connection_user">
            <span
              class="user-img"
              style={{
                background: location.state?.user?.user_picture ? "" : "#0070bf",
              }}
            >
              {location.state?.user?.user_picture ? (
                <img src={`${API.IMG}/${location.state?.user?.user_picture}`} />
              ) : (
                <i class="icon-user"></i>
              )}
            </span>
            <p class="user-name">
              {location.state?.user?.first_name}{" "}
              {location.state?.user?.last_name}
            </p>
          </span>
        </div>
      </div>
      <div className="InnerPageHead bg-white calendar-toolbar p-15">
        <span className="pageHeadTitle">
          <div className="time-picker">
            <div className="time">
              <Picker
                format={"MMMM YYYY"}
                timeRef={monthAndYearRef}
                onChange={pickerOnChange}
                defaultDateForPicker={defaultDateForPicker}
              />
              <a
                href="javascript:void(0)"
                className="calendar-date js-date-picker"
                onClick={() => monthAndYearRef.current.click()}
              >
                {selectedMonthAndYearForHeading}
                <i class="icon-caret-down"></i>
              </a>
            </div>
          </div>
        </span>
      </div>

      <div className="page-section ">
        <div className="container">
          <div className="SingleDatePicker SingleDatePicker_1" id="swiper_id">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={events}
              // hiddenDays={[]}
              eventContent={renderEventContent}
              dateClick={(e) => dateClickHandler(e.date)}
              // selectable={true}
              ref={calendarRef}
              eventClick={(e) => eventClickHandler(e.event.start)}
              dayCellDidMount={dayCellDidMountHandler}
              dayHeaderDidMount={dayHeaderDidMountHandler}
              // eventDisplay={"list-item"}
              // contentHeight={10}
              // dayCellWillUnmount={function (arg) {
              //   console.log(arg, "dayCellWillUnmount");
              // }}
              // dayCellClassNames={function (arg) {
              //   console.log(arg, "dayCellClassNames");
              // }}
              // dayCellContent={function (arg) {
              //   console.log(arg, "dayCellContent");
              // }}

              fixedWeekCount={false}
              height="auto"
            />
          </div>

          <div className="calendar-info">
            <div className="infoHeader">
              <span className="color-text-50 fw-500 py-1">
                Shifts for{" "}
                {moment(currentMonthAndYearRef.current, "MM-YYYY").format(
                  "MMMM,YYYY"
                )}
              </span>
              {/* <a
                className={`btn-icon color-primary ${
                  shiftData ? "cursor" : "eventZero"
                }`}
                onClick={goToDayDeatailHandler}
              >
                <i className="icon-list"></i>
              </a> */}
            </div>
            <ul className="day-details">
              {shiftData.length > 0 ? (
                shiftData.map((sd) => (
                  <li
                    className={`cursor ${
                      moment(sd.date, "DD/MM/YYYY").isBefore(moment())
                        ? "before-date"
                        : ""
                    }`}
                    key={sd.id_event_date}
                    onClick={() => shiftDetailModalHandler(sd.id_event_date)}
                  >
                    <a
                      className={`shift ${
                        sd?.name == "Off Day" ? "non-clickable" : ""
                      }`}
                    >
                      <span className="icon">
                        <img
                          src={`${FRONTENDURL}/assets/img/${sd?.icon}.png`}
                          alt="afternoon icon"
                        />
                      </span>
                      <span
                        className={`text mr-1 text-capitalize active-event 
                          
                            `}
                      >
                        {moment(sd.date, "DD/MM/YYYY").format("dddd, MMMM Do")}
                        {/* {sd?.user_name} */}
                      </span>
                    </a>
                    <p className="shift-time fw-500">
                      {sd?.name == "Off Day"
                        ? `${getDiffrenceHours(sd?.start_time, sd?.end_time)} h`
                        : moment(sd?.start_time).format("hh:mm A") +
                          " - " +
                          moment(sd?.end_time).format("hh:mm A")}

                      <span
                        className="font-sm color-text-30"
                        style={{ color: "rgba(32,33,35,.3)" }}
                      >
                        {" "}
                        {sd?.name == "Off Day"
                          ? ""
                          : `(${getDiffrenceHours(
                              sd?.start_time,
                              sd?.end_time
                            )} h)`}
                      </span>
                    </p>
                  </li>
                ))
              ) : (
                <a className="non-clickable">
                  <span className="text">
                    Your team members have no shift for this day.{" "}
                  </span>
                </a>
              )}
            </ul>
          </div>
        </div>
      </div>
      {/*Shift Modal */}
      <Modal
        show={shiftDetailModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        // backdrop="static"
        onHide={() => setShiftDetailModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setShiftDetailModalShow(false)}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span className="single-view-label">
                <img
                  src={`/assets/img/${shiftForModal?.icon}.png`}
                  alt="day icon"
                />
              </span>
              <div>
                <p className="font-lg fw-500 mb10 line-height-1">Shift</p>
                <p className="color-text-70 text-capitalize">
                  {shiftForModal?.name == "Off Day"
                    ? "Not Available"
                    : shiftForModal?.icon}
                </p>
              </div>
            </div>
            <div className="single-view-body">
              <p class="font-md fw-700 mb-4 text-capitalize">
                {shiftForModal?.user_name}
              </p>
              <p className="font-md fw-700 mb-4 text-capitalize">
                {shiftForModal?.name}
              </p>
              <ul className="list-with-icons">
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                  <span className="color-text-50 w-70">Start Time:</span>
                  <span className="color-text-70">
                    {moment(shiftForModal?.start_time).format("hh:mm A")}
                  </span>
                </li>
                <li>
                  {" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                  <span className="color-text-50 w-70">End Time:</span>
                  <span className="color-text-70">
                    {moment(shiftForModal?.end_time).format("hh:mm A")}
                  </span>
                </li>
              </ul>
              <p className="color-text-50 w-70 mb-2">
                Duration:{" "}
                {getDiffrenceHours(
                  shiftForModal?.start_time,
                  shiftForModal?.end_time
                )}
                h
              </p>
              <p className="color-text-50 w-70 mb-0">
                Location: {shiftForModal?.workplace_name}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      {/* <b>{eventInfo.timeText}</b> */}
      {/* <i>{eventInfo.event.title}</i> */}

      {eventInfo.backgroundColor ? (
        <ul className="event-dot-custom">
          <li className={`bg-${eventInfo.backgroundColor}`}></li>
        </ul>
      ) : (
        <div className="shift-day-img">
          <img
            src={`${FRONTENDURL}/${eventInfo.event.extendedProps.image_url}`}
            className="day-icon-img"
            alt="shift day"
          />
        </div>
      )}
    </>
  );
}
