import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../Component/Svg";

import useRequest from "../../hooks/useRequest";
import { addAllAssignedNotes } from "../../store/calendarData/action";
import { useDispatch, useSelector } from "react-redux";
import UpgradeBanner from "../../Component/UpgradeBanner";

const AddNote = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { allAssignedNotes } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { request, response, loading } = useRequest();

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        dispatch(
          addAllAssignedNotes([...allAssignedNotes, response.data.note])
        );
        navigate("/calendar", {
          state: {
            date: location.state?.date,
          },
        });
      }
    }
  }, [response]);

  const addNoteHandler = (data) => {
    const { text } = data;
    /*

*/
    if (!loading) {
      request("POST", "v1/event/add-note", {
        text,
        // date: location.state?.date || moment().format("YYYY-MM-DD"),
        date: location.state?.date
          ? location.state?.date
          : moment().format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="headerContant"></div>
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5 cursor"
          onClick={() =>
            navigate("/calendar", {
              state: {
                date: location.state?.date,
              },
            })
          }
        >
          <i class="icon-back-arrow font-md icon-shadow"></i>
        </span>
        <h1>Add Note</h1>
      </div>
      <p className="color-text-50 fw-500 mb-3 note_date_title">
        {" "}
        {location.state?.date
          ? moment(location.state?.date, "YYYY-MM-DD").format("MMMM Do, YYYY")
          : moment().format("MMMM Do, YYYY")}{" "}
      </p>
      {/* Section */}
      <div className="page-section">
        <div className="container">
          <div className="innerPageBox">
            <div className="stepper-Outbody h-10000">
              <form id="regForm" onSubmit={handleSubmit(addNoteHandler)}>
                <div className="stepper-body">
                  <div className="stepper-content">
                    <div className="tab" style={{ display: "block" }}>
                      <div className="form-field">
                        <div className="form-input">
                          <input
                            name="ShiftName"
                            type="TEXT"
                            placeholder="Note Text"
                            defaultValue=""
                            {...register("text", {
                              required: "This field is mandatory.",
                              setValueAs: (v) => v.trim(),
                            })}
                          />
                          <span className="inputborder" />
                        </div>
                        {errors.text && (
                          <p className="error-msg">{errors.text.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="content-footer" style={{ overflow: "auto" }}>
                    <button
                      type="button"
                      className="btn-link formBtn color-text-50"
                      id="prevBack"
                      onClick={() =>
                        navigate("/calendar", {
                          state: {
                            date: location.state?.date,
                          },
                        })
                      }
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="btn-white formBtn"
                      id="nextBtn"
                    >
                      {loading ? <Spinner /> : "Add"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNote;
