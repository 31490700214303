import { useEffect, useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import { DeleteIcon } from "../../Component/Svg";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import { addAllAssignedNotes } from "../../store/calendarData/action";
import UpgradeBanner from "../../Component/UpgradeBanner";

const DayNote = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { allAssignedNotes } = useSelector((state) => state.calendar);
  const { role } = useSelector((state) => state.auth);
  const [noteDetailModalShow, setNoteDetailModalShow] = useState(false);
  const [noteDataForModal, setNoteDataForModal] = useState({});
  const [notesData, setNotesData] = useState([]);

  const { request, response } = useRequest();
  const navigate = useNavigate();

  const {
    request: noteDeleteOnDateRequest,
    response: noteDeleteOnDateResponse,
  } = useRequest();

  useEffect(() => {
    if (allAssignedNotes.length > 0) {
      let notesOnADate = allAssignedNotes.filter(
        (data) => data.date == moment(id, "YYYY-MM-DD").format("DD/MM/YYYY")
      );
      if (notesOnADate) {
        setNotesData(notesOnADate);
      } else {
        request("GET", `v1/event/events-on-date/${id}`);
      }
    } else {
      request("GET", `v1/event/events-on-date/${id}`);
    }
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "SUCCESS") {
        setNotesData(response.data?.notes);
      }
    }
  }, [response]);

  useEffect(() => {
    if (noteDeleteOnDateResponse) {
      if (noteDeleteOnDateResponse.status == "SUCCESS") {
      }
    }
  }, [noteDeleteOnDateResponse]);

  const noteModalOpenHandler = (id) => {
    setNoteDetailModalShow(true);
    let noteDataModal = notesData.find((e) => e.id_note == id);
    if (noteDataModal) {
      setNoteDataForModal(noteDataModal);
    }
  };

  const noteDeleteOnDateHandler = (id) => {
    setNoteDetailModalShow(false);
    let newEventData = notesData.filter((e) => e.id_note != id);
    const newAllNotesData = allAssignedNotes.filter((n) => n.id_note != id);
    dispatch(addAllAssignedNotes(newAllNotesData));
    setNotesData(newEventData);
    if (id) {
      noteDeleteOnDateRequest("DELETE", `v1/event/note-delete/${id}`);
    }
  };

  return (
    <div className="content-center lightBg">
      {(role == "trial_user" || role == "blocked_user") && <UpgradeBanner />}
      <div className="content-header">
        <span
          className="btn-icon w-30 color-text mr-5"
          //  to="/calendar"
          onClick={() => {
            navigate("/calendar", {
              state: {
                date: id,
              },
            });
          }}
        >
          <i className="fas fa-arrow-left"></i>
        </span>
        <h1>Notes</h1>
      </div>

      <div className="page-section">
        <div className="container">
          <div className="notificationList">
            <div className="p-3">
              <div className="list-wrap">
                <div>
                  <ul className="notiListUl">
                    <p className="color-text-50 fw-500 mb-2">
                      {moment(id).format("MMM Do YYYY")}
                    </p>
                    {notesData.length > 0 &&
                      notesData.map((note) => (
                        <li
                          className="cursor"
                          onClick={() => noteModalOpenHandler(note.id_note)}
                        >
                          <a className="notiListCard">
                            <span className="notiCard-label bg-orange"></span>
                            <div className="notiCard-body">
                              <p className="font-md notiTitile mb-1">Note</p>
                              <p className="color-text-70 font-base NotiStatus">
                                <span className="ellipsis limit-word">
                                  {note?.text}
                                </span>
                              </p>
                            </div>
                          </a>
                        </li>
                      ))}
                    {/* <li>
                      <a className="notiListCard">
                        <span className="notiCard-label bg-purple"></span>
                        <div className="notiCard-body">
                          <p className="font-md fw-500 mb-1">Event</p>
                          <p className="color-text-70 font-base NotiStatus">
                            <span className="ellipsis">
                              Invitation accepted
                            </span>
                          </p>
                        </div>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={noteDetailModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thememodal"
        // backdrop="static"
        onHide={() => setNoteDetailModalShow(false)}
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => setNoteDetailModalShow(false)}
          />
          <div className="modal-body p-0">
            <div className="single-view-header with-icon">
              <span className="label-icon bg-orange  view_design"></span>
              <div className="inner_header_view">
                <p className="font-lg fw-500 mb10 line-height-1">Note</p>
              </div>
            </div>

            <div className="single-view-body">
              <p className="font-md fw-700 mb-4 limit-word">
                {/* this is note */}
                {noteDataForModal?.text}
              </p>
            </div>
            <div
              className="single-view-footer"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <Link
                className="btn-icon w-30 color-text"
                to={`/edit-note/${noteDataForModal?.id_note}`}
              >
                <i className="icon-edit icon-shadow blue-color"></i>
              </Link>
              <a
                className="btn-icon w-30 color-warning cursor"
                onClick={() =>
                  noteDeleteOnDateHandler(noteDataForModal?.id_note)
                }
              >
                <DeleteIcon />
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default DayNote;
